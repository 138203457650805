import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useMutation } from "urql";
import Button, { ButtonSize, ButtonType } from "../Button";
import Logo from "../Logo";
import WoodSchemaLogo from "../Logo/WoodSchemaLogo";
import { signOutMutation } from "../../graphql/mutations/General/signOut";
import { useAuth } from "../../hooks/useAuth";
import { ROUTES } from "../../lib/Router/routes";
import { IMutationResult } from "../../types/mutations/mutationResult";

const Container = styled.div`
  display: grid;
  grid-template-columns: auto;
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.bottom};
  padding: 1.125em;
  box-shadow: 0px 3px 5px rgba(57, 63, 72, 0.3);
  -webkit-box-shadow: 0px 3px 5px rgba(57, 63, 72, 0.3);
  -moz-box-shadow: 0px 3px 5px rgba(57, 63, 72, 0.3);
  position: relative;
`;

const LogoArea = styled.div`
  grid-column: 1/2;
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSizes.medium};
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  letter-spacing: 0.3125rem;
  text-transform: uppercase;
`;

const ButtonArea = styled.div`
  grid-column: 2/3;
  justify-self: end;
  align-self: center;
`;

const Navbar: React.FC = () => {
  const history = useHistory();
  const isWoodSchema = useLocation().pathname?.includes("wood") ? true : false;
  const isMaterialStreamSchema = useLocation().pathname?.includes("materialflow") ? true : false;
  const [, executeMutation] = useMutation<IMutationResult>(signOutMutation);
  const { signOut, isAdmin } = useAuth();

  const handleAccount = () => {
    history.push(ROUTES.AUTHORIZED.ACCOUNT_ROUTE);
  };

  const handleOrganizations = () => {
    history.push(ROUTES.AUTHORIZED.ORGANIZATIONS_ROUTE);
  };

  const handleUsers = () => {
    history.push(ROUTES.ADMIN.USERS_ROUTE);
  };

  const handleSchemas = () => {
    history.push(ROUTES.ADMIN.SCHEMAS_ROUTE);
  };

  const handleLogout = (event: any) => {
    event.preventDefault();

    executeMutation().then(({ data }) => {
      if (data?.signOut?.success) {
        signOut();
        history.push(ROUTES.PUBLIC.SIGN_IN_ROUTE);
      }
    });
  };

  return (
    <Container>
      <LogoArea>
        <Link to={ROUTES.AUTHORIZED.DASHBOARD_ROUTE}>
          {isWoodSchema && !isMaterialStreamSchema  && <WoodSchemaLogo />}
          {!isWoodSchema && !isMaterialStreamSchema && <Logo />}
        </Link>
      </LogoArea>

      <ButtonArea>
        {isAdmin() && (
          <>
            <Button onClick={handleSchemas} buttonType={ButtonType.Secondary} size={ButtonSize.Medium}>
              Utsendelser
            </Button>

            <Button onClick={handleUsers} buttonType={ButtonType.Secondary} size={ButtonSize.Medium}>
              Brukere
            </Button>
          </>
        )}

        <Button onClick={handleOrganizations} buttonType={ButtonType.Secondary} size={ButtonSize.Medium}>
          {isAdmin() ? "Bedrifter" : "Mine bedrifter"}
        </Button>

        <Button onClick={handleAccount} buttonType={ButtonType.Secondary} size={ButtonSize.Medium}>
          Min konto
        </Button>

        <Button onClick={handleLogout} buttonType={ButtonType.Primary} size={ButtonSize.Medium}>
          Logg ut
        </Button>
      </ButtonArea>
    </Container>
  );
};

export default Navbar;
