import React from "react";
import { Check } from "react-feather";
import styled from "styled-components";
import Bottom from "../Bottom";
import Circle, { CircleSize } from "../Circle";
// import CreateSchema from "../CreateSchema";
import ReturnToSchemaNavigation from "../../components/Navigation/ReturnToSchemaNavigation";

import { StepHandling } from "../../types";
const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.gap.medium};
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-size: ${(props) => props.theme.fontSizes.large};
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  text-align: center;
`;

const CheckMark = styled.div`
  display: flex;
  justify-content: center;
`;
const WebLink = styled.a`
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  text-decoration: none;
`;

const DescriptionText = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
`;

const Text = styled.div`
  grid-column: 2/3;
  margin-bottom: 1rem;
`;
type DeliveredSchemaProps = {
  navigation: StepHandling;
  handleNav: any;
  woodSchema: any;
  materialStreamSchema?: any;
};
const DeliveredSchema: React.FC<Partial<DeliveredSchemaProps>> = (props) => {
  const isWoodSchema = props.woodSchema ? true : false;
  const isMaterialStreamSchema = props.materialStreamSchema ? true : false;
  return (
    <Container>
      <Title>Skjema sendt</Title>
      <CheckMark>
        <Circle size={CircleSize.XL}>
          <Check size={150} />
        </Circle>
      </CheckMark>
      <Title>Takk for utfylling av skjema</Title>
      <DescriptionText>
        {!isWoodSchema && isMaterialStreamSchema && (
          <Text>
            Data i denne undersøkelsen danner grunnlag for videre arbeid med kartlegging og utnyttelse av sidestrømmer fra
            prosessindustrien. Vi skal sammen jobbe i workshops, samlinger og fagfora for å se på mulige områder for utnyttelse og
            reduksjon. Følg gjerne med på <WebLink href="https://eydecluster.com">eydecluster.com</WebLink> eller send en e-post til{" "}
            <WebLink href="mailto:stine@eydecluster.com">stine@eydecluster.com</WebLink> hvis du har innspill eller forslag til aktiviteter
            eller utnyttelsespotensial.
          </Text>
        )}
        {isWoodSchema && !isMaterialStreamSchema && (
          <>
            <Text>
              Dataene danner grunnlag for videre arbeid med kartlegging og potensiell utnyttelse av avfall/ressursstrømmer for trevirke som
              en del av prosjektet «Agder symbiose». Prosjektet skal legge til rette for den regionale overgangen mot en ressurseffektiv og
              konkurransedyktig næringsutvikling, basert på sirkulærøkonomi.
            </Text>
            <Text>
              Kontakt <WebLink href="mailto:anne.kristin.peersen@agderfk.no">anne.kristin.peersen@agderfk.no</WebLink> dersom du har
              spørsmål eller innspill til kartleggingen.
            </Text>
          </>
        )}
        {!isWoodSchema && !isMaterialStreamSchema && (
          <Text>
            Dataene danner grunnlag for videre arbeid med utnyttelse av spillvarme i regi av Eyde-klyngen. Vi skal sammen jobbe systematisk
            i workshops, samlinger og fagfora. Følg gjerne med på <WebLink href="https://eydecluster.com">eydecluster.com</WebLink> eller
            send en e-post til <WebLink href="mailto:stine@eydecluster.com">stine@eydecluster.com</WebLink> hvis du har innspill eller
            spørsmål
          </Text>
        )}
      </DescriptionText>
      <Bottom>
        {/* <Navigation
            counter={props.counter}
            handleNext={props.handleNav}
            handlePrev={() => {}}
          /> */}
      </Bottom>
      {/* <CreateSchema /> */}
      <ReturnToSchemaNavigation />
    </Container>
  );
};
export default DeliveredSchema;
