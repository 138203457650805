import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useMutation, useQuery } from "urql";
import Bottom from "../../../components/Bottom";
import Navigation from "../../../components/Navigation";
import QuestionBox from "../../../components/QuestionBox";
import { NavigationContext } from "../../../providers/NavigationProvider";

import { MaterialFlowAdvantage as MaterialFlowAdvantageType, Param, TBooleanSelectExtended } from "../../../types";

import MaterialFlowUtilizationData from "./MaterialFlowUtilizationData";
import { getMaterialStreamAdvantage } from "../../../graphql/queries/Materialflow/getMaterialStreamAdvantage";
import { updateMaterialUtilization } from "../../../graphql/mutations/Materialflow/updateMaterialUtilization";
import { findSelectValue } from "../../../utils/form-utils";
import { BooleanSelectExtended } from "../../../dictionaries";
import InternalQuestionBox from "../../../components/InternalQuestionBox";
import { yupResolver } from "@hookform/resolvers/yup";
import { materialFlowUtilizationSchema } from "../../../schemas";
import Spinner from "../../../components/Loading";
import { IMutationResult } from "../../../types/mutations/mutationResult";
import { handleMutationError } from "../../../lib/toaster";
import { InformationBoilerPlateUtilization } from "../../../components/InformationBoilerPlate";
import Required from "../../../components/Required";

const Container = styled.div`
  grid-column: 2/3;
`;
const LoadingContainer = styled.div`
  display: flex;
  place-content: center;
`;

type IMaterialFlowAdvantage = {
  materialStreamUsage: MaterialFlowAdvantageType;
};

const MaterialFlowUtilization: React.FC = () => {
  const [isReset, setIsReset] = React.useState<boolean>(true);
  const context = React.useContext(NavigationContext);

  const { id } = useParams<Param>();
  const [materialFlowAdvantageResult] = useQuery({
    query: getMaterialStreamAdvantage,
    variables: { id: id },
  });

  const { data, fetching } = materialFlowAdvantageResult;

  const [, executeMutation] = useMutation<IMutationResult>(updateMaterialUtilization);

  const { register, handleSubmit, watch, control, reset, errors } = useForm<IMaterialFlowAdvantage>({
    resolver: yupResolver(materialFlowUtilizationSchema),
    defaultValues: {},
  });

  function handleExecuteMutation(materialStreamUsage: any, nav: boolean) {
    materialStreamUsage &&
      executeMutation({
        id: id,
        internalDeposition: materialStreamUsage.internalDeposition || null,
        externalDeposition: materialStreamUsage.externalDeposition || null,
        internalRecycling: materialStreamUsage.internalRecycling || null,
        externalSales: materialStreamUsage.externalSales || null,
        isBurned: findSelectValue<TBooleanSelectExtended>(materialStreamUsage.isBurned, BooleanSelectExtended),
        previousInternalAndExternalUsage: materialStreamUsage.previousInternalAndExternalUsage ? "YES" : "NO",
        previousInternalUsage: materialStreamUsage.previousInternalUsage ? "YES" : "NO",
        previousExternalUsage: materialStreamUsage.previousExternalUsage ? "YES" : "NO",
        previousInternalAndExternalUsageComment: materialStreamUsage.previousInternalAndExternalUsageComment,
        internalAndExternalActivitesForMaterialUsage: materialStreamUsage.internalAndExternalActivitesForMaterialUsage ? "YES" : "NO",
        internalActivitesForMaterialUsage: materialStreamUsage.internalActivitesForMaterialUsage ? "YES" : "NO",
        externalActivitesForMaterialUsage: materialStreamUsage.externalActivitesForMaterialUsage ? "YES" : "NO",
        internalAndExternalActivitesForMaterialUsageComment: materialStreamUsage.internalAndExternalActivitesForMaterialUsageComment,
        internalAndExternalGreaterPotential: materialStreamUsage.internalAndExternalGreaterPotential ? "YES" : "NO",
        internalGreaterPotential: materialStreamUsage.internalGreaterPotential ? "YES" : "NO",
        externalGreaterPotential: materialStreamUsage.externalGreaterPotential ? "YES" : "NO",
        internalAndExternalGreaterPotentialComment: materialStreamUsage.internalAndExternalGreaterPotentialComment,
        links: materialStreamUsage.links,
        resourcePersons: materialStreamUsage.resourcePersons,
        otherComments: materialStreamUsage.otherComments || null,
      })
        .then((result) => {
          if (result.error) {
            handleMutationError(result.error);
          } else {
            context.handleNav(nav);
          }
        })
        .catch(() => handleMutationError());
  }

  const onSubmit: SubmitHandler<IMaterialFlowAdvantage> = async ({ materialStreamUsage }) => {
    handleExecuteMutation(materialStreamUsage, true);
  };
  const onPreviousSubmit: SubmitHandler<IMaterialFlowAdvantage> = async ({ materialStreamUsage }) => {
    handleExecuteMutation(materialStreamUsage, false);
  };

  React.useEffect(() => {
    if (!fetching && data.materialStreamUsage) {
      if (isReset) {
        reset({
          materialStreamUsage: {
            ...data.materialStreamUsage,
            isBurned: BooleanSelectExtended[data.materialStreamUsage.isBurned as TBooleanSelectExtended] as TBooleanSelectExtended,
            previousInternalAndExternalUsage: data.materialStreamUsage.previousInternalAndExternalUsage === "YES" ? true : false,
            previousInternalUsage: data.materialStreamUsage.previousInternalUsage === "YES" ? true : false,
            previousExternalUsage: data.materialStreamUsage.previousExternalUsage === "YES" ? true : false,
            internalAndExternalActivitesForMaterialUsage:
              data.materialStreamUsage.internalAndExternalActivitesForMaterialUsage === "YES" ? true : false,
            internalActivitesForMaterialUsage: data.materialStreamUsage.internalActivitesForMaterialUsage === "YES" ? true : false,
            externalActivitesForMaterialUsage: data.materialStreamUsage.externalActivitesForMaterialUsage === "YES" ? true : false,
            internalAndExternalGreaterPotential: data.materialStreamUsage.internalAndExternalGreaterPotential === "YES" ? true : false,
            internalGreaterPotential: data.materialStreamUsage.internalGreaterPotential === "YES" ? true : false,
            externalGreaterPotential: data.materialStreamUsage.externalGreaterPotential === "YES" ? true : false,
            links: data.materialStreamUsage.links,
            resourcePersons: data.materialStreamUsage.resourcePersons,
          },
        });
      }
      setIsReset(false);
    }
  }, [fetching, data, isReset, reset]);

  return (
    <Container>
      <QuestionBox title="Utnyttelse" required>
        <InformationBoilerPlateUtilization />
        <Required />
        {fetching ? (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        ) : (
          <InternalQuestionBox>
            <MaterialFlowUtilizationData register={register} watch={watch} control={control} errors={errors} />
          </InternalQuestionBox>
        )}
      </QuestionBox>
      <Bottom>
        <Navigation navigation={context.navigation} handleNext={handleSubmit(onSubmit)} handlePrev={handleSubmit(onPreviousSubmit)} />
      </Bottom>
    </Container>
  );
};

export default MaterialFlowUtilization;
