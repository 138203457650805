import { gql } from "urql";

export const getWasteCodes = gql`
  query {
    wasteCode {
      id
      codeId
      name
      __typename
    }
  }
`;
