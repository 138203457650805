import React from "react";
import styled from "styled-components";
import AboutMaterialFlowData from "./AboutMaterialFlowData";
import QuestionBox from "../../../components/QuestionBox";
import Bottom from "../../../components/Bottom";
import Navigation from "../../../components/Navigation";
import { NavigationContext } from "../../../providers/NavigationProvider";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "urql";
import {
  AboutMaterialFlowType,
  TMaterialState,
  TMaterialType,
  TOrganic,
  TProductionPeriod,
  TProductionVolumeUnit,
  TProductionBatch,
  Param,
} from "../../../types";
import { getAboutMaterialStream } from "../../../graphql/queries/Materialflow/getAboutMaterialStream";
import { updateAboutMaterialFlow } from "../../../graphql/mutations/Materialflow/updateAboutMaterialFlow";
import { findSelectValue, mapToSelectOption } from "../../../utils/form-utils";
import { MaterialState, MaterialType, Organic, ProductionBatch, ProductionPeriod, ProductionVolumeUnit } from "../../../dictionaries";
import InternalQuestionBox from "../../../components/InternalQuestionBox";
import { yupResolver } from "@hookform/resolvers/yup";
import { aboutMaterialFlowSchema } from "../../../schemas";
import Spinner from "../../../components/Loading";
import { IMutationResult } from "../../../types/mutations/mutationResult";
import { handleMutationError } from "../../../lib/toaster";
import InformationBoilerPlateSideStrom from "../../../components/InformationBoilerPlate";
import Required from "../../../components/Required";

const Container = styled.div`
  grid-column: 2/3;
`;

const LoadingContainer = styled.div`
  display: flex;
  place-content: center;
`;

type IAboutMaterialFlowType = {
  materialStreamAbout: AboutMaterialFlowType;
};
const AboutMaterialFlow: React.FC = () => {
  const [isReset, setIsReset] = React.useState<boolean>(true);

  const { register, watch, reset, handleSubmit, errors, control } = useForm<IAboutMaterialFlowType>({
    defaultValues: { materialStreamAbout: {} },
    resolver: yupResolver(aboutMaterialFlowSchema),
  });
  const context = React.useContext(NavigationContext);
  const { id } = useParams<Param>();
  const [aboutMaterialFlowResult] = useQuery({
    query: getAboutMaterialStream,
    variables: { id: id },
  });
  const { data, fetching } = aboutMaterialFlowResult;

  const [, executeMutation] = useMutation<IMutationResult>(updateAboutMaterialFlow);

  function handleExecuteMutation(materialStreamAbout: any, nav: boolean) {
    materialStreamAbout &&
      executeMutation({
        id: id,
        givenName: materialStreamAbout.givenName,
        materialType: findSelectValue<TMaterialType>(materialStreamAbout.materialType, MaterialType),
        eALCodeId: materialStreamAbout.eALCode?.value,
        wasteCodeId: materialStreamAbout.wasteCode?.value,
        sideStreamOrigin: materialStreamAbout.sideStreamOrigin,
        yearlyProduction: materialStreamAbout.yearlyProduction,
        productionVolumeUnit: findSelectValue<TProductionVolumeUnit>(materialStreamAbout.productionVolumeUnit, ProductionVolumeUnit),
        productionPeriod: findSelectValue<TProductionPeriod>(materialStreamAbout.productionPeriod, ProductionPeriod),
        productionVolumeBatch: materialStreamAbout.productionVolumeBatch,
        productionBatch: findSelectValue<TProductionBatch>(materialStreamAbout.productionBatch, ProductionBatch),
        materialState: findSelectValue<TMaterialState>(materialStreamAbout.materialState, MaterialState),
        materialStateComment: materialStreamAbout.materialStateComment,
        organic: findSelectValue<TOrganic>(materialStreamAbout.organic, Organic),
        organicComment: materialStreamAbout.organicComment,
      })
        .then(() => {
          context.handleNav(nav);
        })
        .catch(() => handleMutationError());
  }

  const onSubmit: SubmitHandler<IAboutMaterialFlowType> = async ({ materialStreamAbout }) => {
    handleExecuteMutation(materialStreamAbout, true);
  };
  const onPreviousSubmit: SubmitHandler<IAboutMaterialFlowType> = async ({ materialStreamAbout }) => {
    handleExecuteMutation(materialStreamAbout, false);
  };

  React.useEffect(() => {
    if (!fetching && data.materialStreamAbout) {
      if (isReset) {
        reset({
          materialStreamAbout: {
            ...data.materialStreamAbout,
            materialType: MaterialType[data.materialStreamAbout.materialType as TMaterialType] as TMaterialType,
            productionVolumeUnit: ProductionVolumeUnit[
              data.materialStreamAbout.productionVolumeUnit as TProductionVolumeUnit
            ] as TProductionVolumeUnit,
            productionPeriod: ProductionPeriod[data.materialStreamAbout.productionPeriod as TProductionPeriod] as TProductionPeriod,
            productionBatch: ProductionBatch[data.materialStreamAbout.productionBatch as TProductionBatch] as TProductionBatch,
            materialState: MaterialState[data.materialStreamAbout.materialState as TMaterialState] as TMaterialState,
            organic: Organic[data.materialStreamAbout.organic as TOrganic] as TOrganic,
            eALCode: data?.materialStreamAbout?.eALCode
              ? mapToSelectOption(
                  data.materialStreamAbout.eALCode.id,
                  `${data.materialStreamAbout.eALCode.codeId} - ${data.materialStreamAbout.eALCode.name}`
                )
              : "",
            wasteCode: data?.materialStreamAbout?.wasteCode
              ? mapToSelectOption(
                  data.materialStreamAbout.wasteCode.id,
                  `${data.materialStreamAbout.wasteCode.codeId} - ${data.materialStreamAbout.wasteCode.name}`
                )
              : "",
          },
        });

        setIsReset(false);
      }
    }
  }, [fetching, data, isReset, reset]);

  return (
    <Container>
      <QuestionBox title="Om sidestrømmen" required>
        <InformationBoilerPlateSideStrom />
        <Required />
        {fetching ? (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        ) : (
          <InternalQuestionBox>
            <AboutMaterialFlowData register={register} watch={watch} errors={errors} control={control} />
          </InternalQuestionBox>
        )}
      </QuestionBox>
      <Bottom>
        <Navigation navigation={context.navigation} handleNext={handleSubmit(onSubmit)} handlePrev={handleSubmit(onPreviousSubmit)} />
      </Bottom>
    </Container>
  );
};

export default AboutMaterialFlow;
