import { gql } from "urql";

export const updateWoodUtilization = gql`
  mutation(
    $id: ID
    $internalEnergyRecycling: Float
    $externalEnergyRecycling: Float
    $internalMaterialRecycling: Float
    $externalMaterialRecycling: Float
    $reuse: Float
    $reuseFurniture: Float
    $reuseBuildingMaterials: Float
    $previousInternalAndExternalUsage: BoolEnum
    $previousInternalUsage: BoolEnum
    $previousExternalUsage: BoolEnum
    $previousInternalAndExternalUsageComment: String
    $internalAndExternalActivitesForMaterialUsage: BoolEnum
    $internalActivitesForMaterialUsage: BoolEnum
    $externalActivitesForMaterialUsage: BoolEnum
    $internalAndExternalActivitesForMaterialUsageComment: String
    $internalAndExternalGreaterPotential: BoolEnum
    $internalGreaterPotential: BoolEnum
    $externalGreaterPotential: BoolEnum
    $internalAndExternalGreaterPotentialComment: String
    $links: [CreateOrUpdateWoodSchemaLinkMutationType]
    $resourcePersons: [CreateOrUpdateWoodSchemaResourcePersonMutationType]
    $otherComments: String
  ) {
    updateWoodSchemaUsage(
      input: {
        id: $id
        internalEnergyRecycling: $internalEnergyRecycling
        externalEnergyRecycling: $externalEnergyRecycling
        internalMaterialRecycling: $internalMaterialRecycling
        externalMaterialRecycling: $externalMaterialRecycling
        reuse: $reuse
        reuseFurniture: $reuseFurniture
        reuseBuildingMaterials: $reuseBuildingMaterials
        previousInternalAndExternalUsage: $previousInternalAndExternalUsage
        previousInternalUsage: $previousInternalUsage
        previousExternalUsage: $previousExternalUsage
        previousInternalAndExternalUsageComment: $previousInternalAndExternalUsageComment
        internalAndExternalActivitesForMaterialUsage: $internalAndExternalActivitesForMaterialUsage
        internalActivitesForMaterialUsage: $internalActivitesForMaterialUsage
        externalActivitesForMaterialUsage: $externalActivitesForMaterialUsage
        internalAndExternalActivitesForMaterialUsageComment: $internalAndExternalActivitesForMaterialUsageComment
        internalAndExternalGreaterPotential: $internalAndExternalGreaterPotential
        internalGreaterPotential: $internalGreaterPotential
        externalGreaterPotential: $externalGreaterPotential
        internalAndExternalGreaterPotentialComment: $internalAndExternalGreaterPotentialComment
        links: $links
        resourcePersons: $resourcePersons
        otherComments: $otherComments
      }
    ) {
      __typename
      id
      success
    }
  }
`;
