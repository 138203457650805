import React, { useState } from "react";
import styled from "styled-components";
import { EnergyType, EnergyUsage as EnergyUsageType, Param, StepHandling, TEnergyUsageNames } from "../../../types";

import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { energyUsageSchema } from "../../../schemas";
import Navigation from "../../../components/Navigation";
import Bottom from "../../../components/Bottom";
import Button, { ButtonSize, ButtonType } from "../../../components/Button";

import { useMutation, useQuery } from "urql";
import { createOrUpdateEnergyType } from "../../../graphql/mutations/Energyflow/createOrUpdateEnergyType";
import { getEnergyTypes } from "../../../graphql/queries/Energyflow/getEnergyTypes";
import { findSelectValue } from "../../../utils/form-utils";
import SurveyEnergyUsageData from "./SurveyEnergyUsageData";
import { useParams } from "react-router-dom";
import Spinner from "../../../components/Loading";
import { EnergyUsageNames } from "../../../dictionaries";
import { IMutationResult } from "../../../types/mutations/mutationResult";
import { handleMutationError } from "../../../lib/toaster";
const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.bottom};
  font-size: ${(props) => props.theme.fontSizes.large};
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  margin-bottom: 1.5rem;
`;
const Description = styled.div`
  margin-bottom: 1.875rem;
`;
const DescriptionBold = styled.span`
  font-weight: 500;
`;

const Box = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr 1fr auto;
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.middle};
  padding: ${(props) => props.theme.padding.medium};
`;
const LoadingContainer = styled.div`
  grid-row: 1/3;
  grid-column: 2/3;
  place-self: center;
`;

const YearText = styled.div`
  grid-column: 1/3;
  font-style: italic;
  font-size: ${(props) => props.theme.fontSizes.small};
`;

const IContainer = styled.div`
  grid-row: 3/4;
  grid-column: 1/-1;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Headers = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2em;
  color: ${(props) => props.theme.colors.textColor.mainTextColor};
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.middle};
  margin-bottom: 0.3125rem;
  justify-content: start;
`;

const QuestionTitle = styled.div`
  grid-column: 1/2;
  margin-left: 1.2rem;
`;
const Comment = styled.div`
  grid-column: 2/3;
  margin-left: 3.6rem;
`;
const Measurement = styled.div`
  grid-column: 3/4;
  margin-left: 0.6rem;
`;

const ButtonArea = styled.div`
  grid-column: 1/2;
  grid-row: 4/5;
  margin-top: 0.937rem;
`;

type SurveyQuestionProps = {
  navigation: StepHandling;
  handleNav: (next: boolean) => void;
};

const SurveyEnergyUsage: React.FC<SurveyQuestionProps> = (props) => {
  const { id } = useParams<Param>();

  const [, energyUsageMutate] = useMutation<IMutationResult>(createOrUpdateEnergyType);

  const [energyTypesQueryResult] = useQuery({
    query: getEnergyTypes,
    variables: { energySchemaId: id },
  });
  const { data, fetching } = energyTypesQueryResult;

  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,

    reset,
  } = useForm<EnergyUsageType>({
    resolver: yupResolver(energyUsageSchema),
    defaultValues: { energyTypes: [{ id: undefined }] },
  });

  const { fields, append, remove } = useFieldArray<EnergyType, any>({
    control,
    name: "energyTypes",
    keyName: "fieldId",
  });
  const [isReset, setIsReset] = useState<boolean>(true);

  const onSubmit: SubmitHandler<EnergyUsageType> = (data) => {
    data.energyTypes &&
      data.energyTypes.map(async (et) => {
        await energyUsageMutate({
          energyTypeId: et.id ? et.id : null,
          energyTypeName: findSelectValue<TEnergyUsageNames>(et.energyTypeName, EnergyUsageNames),
          additionalInformation: et.additionalInformation,
          energyUsage: et.energyUsage,
          energySchemaId: id,
        })
          .then((result) => {
            if (result.error) {
              handleMutationError(result.error);
            } else {
              props.handleNav(true);
            }
          })
          .catch(() => handleMutationError());
      });
  };

  const onSubmitPrevious = () => {
    const data = getValues();
    data.energyTypes &&
      data.energyTypes.map(async (et) => {
        await energyUsageMutate({
          energyTypeId: et.id ? et.id : null,
          additionalInformation: et.additionalInformation,
          energyUsage: et.energyUsage,
          energyTypeName: findSelectValue<TEnergyUsageNames>(et.energyTypeName, EnergyUsageNames),
          energySchemaId: id,
        }).then((result: any) => {
          if (result.error) {
            console.error("Oh no...", result.error);
          } else {
            props.handleNav(false);
          }
        });
      });
  };

  React.useEffect(() => {
    if (!fetching && data && data.energyTypes?.length !== 0) {
      //Only want to populate/reset defaultValues once
      //after receiving data from API.
      if (isReset) {
        reset(data);
        setIsReset(false);
      }
    }
  }, [fetching, data, reset, isReset, setIsReset]);

  return (
    <Container>
      <Title>Energibruk</Title>
      <Description>
        Denne delen av skjema omhandler bedriftens energibruk ekskl. <DescriptionBold>transportformål</DescriptionBold>
      </Description>
      {!fetching ? (
        <Box>
          <YearText>Legg inn tall for et år med normal produksjon</YearText>
          <IContainer>
            <QuestionContainer>
              <Headers>
                <QuestionTitle>Energiform</QuestionTitle>
                <Comment>Eventuell tilleggsinformasjon</Comment>
                <Measurement>GWh/år</Measurement>
              </Headers>

              {fields.map((row, index) => {
                return (
                  <React.Fragment key={row.fieldId}>
                    <SurveyEnergyUsageData
                      register={register()}
                      errors={errors}
                      index={index}
                      data={data}
                      remove={remove}
                      row={row}
                      temp={isReset}
                    />
                    <Controller control={control} name={`energyTypes[${index}].id`} defaultValue={row.id ? row.id : null} as={<span />} />
                  </React.Fragment>
                );
              })}
            </QuestionContainer>

            <ButtonArea>
              <Button buttonType={ButtonType.Primary} size={ButtonSize.Large} onClick={() => append({})}>
                Legg til ekstra rad
              </Button>
            </ButtonArea>
          </IContainer>
        </Box>
      ) : (
        <Box>
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        </Box>
      )}

      <Bottom>
        <Navigation navigation={props.navigation} handleNext={handleSubmit(onSubmit)} handlePrev={onSubmitPrevious} />
      </Bottom>
    </Container>
  );
};

export default SurveyEnergyUsage;
