import { gql } from "urql";

export const getOrganizationInfo = gql`
  query ($organizationId: ID) {
    organization(filter: { id: $organizationId }) {
      name
      orgId
      location
      mainProductSelected {
        id
        name
      }
      municipality {
        id
        name
      }
      contactPersonId
      industryType
      industryCode
      shareSchemaApproval
      id
      allowedSidestreamTypes
      __typename
    }
  }
`;
