import { gql } from "urql";

export const createSidestream = gql`
  mutation($name: String!, $type: SidestreamTypeEnum!, $organizationId: ID!) {
    createSidestream(input: { name: $name, type: $type, organizationId: $organizationId }) {
      __typename
      id
      success
    }
  }
`;
