import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useMutation } from "urql";
import Button, { ButtonSize, ButtonType } from "../../components/Button";
import ErrorContainer from "../../components/ErrorContainer";
import FormFieldError from "../../components/FormFieldError";
import FormLabel from "../../components/FormLabel";
import Heading from "../../components/Heading";
import MainProductSelect from "../../components/MainProductSelect";
import MunicipalitySelect from "../../components/MunicipalitySelect";
import { IndustryType } from "../../dictionaries";
import { createOrganization } from "../../graphql/mutations/Organization/createOrganization";
import { handleMutationError, handleMutationSuccess } from "../../lib/toaster";
import { createOrganizationSchema } from "../../schemas";
import { CreateOrganizationFormData, TIndustryType } from "../../types";
import { IMutationResult } from "../../types/mutations/mutationResult";
import { findSelectValue, SelectValues } from "../../utils/form-utils";

type Props = {
  handleSave: () => void;
  handleCancel: () => void;
};

const ButtonArea = styled.div`
  text-align: right;
  margin-top: 10px;
`;

const ValidationContainer = styled.div`
  margin-left: 10px;
`;

const CreateOrganization: React.FC<Props> = (props) => {
  const [result, executeMutation] = useMutation<IMutationResult>(createOrganization);
  const { register, handleSubmit, errors, control } = useForm<CreateOrganizationFormData>({
    resolver: yupResolver(createOrganizationSchema),
  });
  const onSubmit = handleSubmit(({ orgId, name, mainProduct, industryType, municipality, location }) => {
    executeMutation({
      orgId,
      name,
      mainProductId: mainProduct ? mainProduct.value.toString() : undefined,
      industryType: industryType ? findSelectValue<TIndustryType>(industryType, IndustryType) : undefined,
      municipalityId: municipality ? municipality.value.toString() : undefined,
      location,
    })
      .then(({ data }) => {
        if (data?.createOrganization?.success) {
          handleMutationSuccess(data.createOrganization);

          props.handleSave();
        } else {
          handleMutationError();
        }
      })
      .catch(() => handleMutationError());
  });

  return (
    <form onSubmit={onSubmit} noValidate>
      <Heading>Legg til bedrift</Heading>

      <FormLabel>Bedriftens navn</FormLabel>
      <input ref={register} name="name" />
      <ValidationContainer>
        <FormFieldError error={errors.name}></FormFieldError>
      </ValidationContainer>

      <FormLabel>Organisasjonsnummer</FormLabel>
      <input ref={register} name="orgId" />
      <ValidationContainer>
        <FormFieldError error={errors.orgId}></FormFieldError>
      </ValidationContainer>

      <FormLabel>Type industri</FormLabel>
      <select ref={register} name="industryType">
        <option>Vennligst velg</option>
        {SelectValues(IndustryType)}
      </select>
      <ValidationContainer>
        <FormFieldError error={errors.industryType}></FormFieldError>
      </ValidationContainer>

      <FormLabel>Hovedprodukt</FormLabel>
      <MainProductSelect control={control} register={register} fieldName="mainProduct" />
      <ValidationContainer>
        <FormFieldError error={errors.mainProduct?.value}></FormFieldError>
      </ValidationContainer>

      <FormLabel>By/kommune</FormLabel>
      <MunicipalitySelect control={control} register={register} fieldName="municipality" />
      <ValidationContainer>
        <FormFieldError error={errors.municipality?.value}></FormFieldError>
      </ValidationContainer>

      <FormLabel>Adresse</FormLabel>
      <input ref={register} name="location" />
      <ValidationContainer>
        <FormFieldError error={errors.location}></FormFieldError>
      </ValidationContainer>

      {result.error && <ErrorContainer>Kan ikke opprette bedrift</ErrorContainer>}

      <ButtonArea>
        <Button type="button" size={ButtonSize.Large} buttonType={ButtonType.Secondary} onClick={() => props.handleCancel()}>
          Avbryt
        </Button>

        <Button type="submit" disabled={result.fetching} size={ButtonSize.Large} buttonType={ButtonType.Primary}>
          {result.fetching ? "Legger til..." : "Legg til bedrift"}
        </Button>
      </ButtonArea>
    </form>
  );
};

export default CreateOrganization;
