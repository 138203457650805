import React from "react";
import styled from "styled-components";
import { NonUtilizedType, TExternalPlan, TInternalPlan, TPollution, TWasteSource } from "../../../../types";

import { X } from "react-feather";
import { useMutation } from "urql";
import { deleteEnergyWaste } from "../../../../graphql/mutations/Energyflow/deleteEnergyWaste";
import FormInput from "../../../../components/FormInput";
import { ExternalPlan, InternalPlan, Pollution, WasteSource } from "../../../../dictionaries";
import { IMutationResult } from "../../../../types/mutations/mutationResult";
import { handleMutationError } from "../../../../lib/toaster";

const MainForm = styled.div`
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-row-gap: 0.0625rem;
  padding-top: 1rem;
`;
const FormHeader = styled.div`
  grid-column: 1;
  font-weight: 600;
  align-self: end;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;
const FormLabel = styled.label`
  grid-column: 1;
  margin-top: 0.3125rem;
`;

const FormInputContainer = styled.div`
  grid-column: 2/3;
`;
const FormMeasurement = styled.span`
  grid-column: 3/3;
  padding-top: 0.3125rem;
  padding-left: 0.3125rem;
`;
const FormError = styled.div`
  grid-column: 2;
  margin-bottom: 1em;
`;
const StyledSelect = styled.select`
  width: 100%;
  height: 100%;
`;

const CommentInput = styled.textarea`
  grid-column: 1/3;
  margin-bottom: 1.875rem;
  height: 6.25rem;
`;

const DeleteRowContainer = styled.div`
  grid-column: 3/4;
  grid-row: 1/2;
  justify-self: end;
  padding: ${(props) => props.theme.padding.light};

  > * {
    cursor: pointer;
  }
`;

type NonUtilizedEnergyDataProps = {
  index: number;
  watchWasteSource: (index: number) => TWasteSource;
  register: any;
  row: any;
  remove: any;
  data: NonUtilizedType;
  errors: any;
};

const NonUtilizedEnergyData: React.FC<NonUtilizedEnergyDataProps> = (props) => {
  const [, deleteEnergyWasteMutate] = useMutation<IMutationResult>(deleteEnergyWaste);
  const checkForSteamOrGas = (): boolean => {
    if (props.watchWasteSource(props.index) === WasteSource.STEAM || props.watchWasteSource(props.index) === WasteSource.GAS) {
      return true;
    } else {
      return false;
    }
  };

  const checkForWater = (): boolean => {
    if (props.data.energyWastes[props.index]) {
      if (
        props.row.wasteSource === WasteSource.WATER ||
        props.watchWasteSource(props.index) === WasteSource.WATER ||
        props.row.wasteSource === WasteSource.AIR ||
        props.watchWasteSource(props.index) === WasteSource.AIR
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (props.watchWasteSource(props.index) === WasteSource.WATER || props.watchWasteSource(props.index) === WasteSource.AIR) {
      return true;
    } else {
      return false;
    }
  };

  const handleDeleteRow = async (removeIndex: number) => {
    if (props.data.energyWastes[props.index]) {
      await deleteEnergyWasteMutate({ id: props.data.energyWastes[props.index].id })
        .then(() => {
          props.remove(removeIndex);
        })
        .catch(() => handleMutationError());
    } else {
      props.remove(removeIndex);
    }
  };

  return (
    <>
      <DeleteRowContainer>
        <X onClick={() => handleDeleteRow(props.index!)} />
      </DeleteRowContainer>
      <MainForm>
        <FormHeader> Spillvarmekilde</FormHeader>
        <FormInput
          inputName={`energyWastes[${props.index}].energyWasteName`}
          labelText="Gi spillvarmekilden et navn*"
          register={props.register}
          defaultValue={props.row?.energyWasteName}
        />
        {props.errors.energyWastes && (
          <FormError className="error">{props.errors.energyWastes[props.index]?.energyWasteName?.message}</FormError>
        )}

        <FormLabel htmlFor={`energyWastes[${props.index}].wasteSource`}>Type spillvarmekilde*</FormLabel>
        <FormInputContainer>
          <StyledSelect
            ref={props.register}
            name={`energyWastes[${props.index}].wasteSource`}
            defaultValue={WasteSource[props.row?.wasteSource as TWasteSource] || props.row.wasteSource}
          >
            <option value="">Velg spillvarmekilde</option>

            {(Object.values(WasteSource) as Array<TWasteSource>).map((name) => {
              return <option key={name}>{name}</option>;
            })}
          </StyledSelect>
          {props.errors.energyWastes && (
            <FormError className="error">{props.errors.energyWastes[props.index]?.wasteSource?.message}</FormError>
          )}
        </FormInputContainer>
        <FormMeasurement></FormMeasurement>
        <FormInput
          inputName={`energyWastes[${props.index}].temperature`}
          labelText="Temperatur ut*"
          register={props.register}
          defaultValue={props.row.temperature}
          measureUnit="&deg;C"
        />
        {props.errors.energyWastes && (
          <FormError className="error">{props.errors.energyWastes[props.index]?.temperature?.message}</FormError>
        )}

        {checkForSteamOrGas() && (
          <>
            <FormInput
              inputName={`energyWastes[${props.index}].pressure`}
              labelText="Trykk (hvis damp)"
              register={props.register}
              defaultValue={props.row.pressure}
              measureUnit="bar"
            />
            {props.errors.energyWastes && (
              <FormError className="error">{props.errors.energyWastes[props.index]?.pressure?.message}</FormError>
            )}
          </>
        )}

        <FormInput
          inputName={`energyWastes[${props.index}].energyAmount`}
          labelText="Mengde, energi (T<sub>ref</sub>=0&#8451;)"
          register={props.register}
          defaultValue={props.row.energyAmount}
          measureUnit="GWh/år"
        />
        {props.errors.energyWastes && (
          <FormError className="error">{props.errors.energyWastes[props.index]?.energyAmount?.message}</FormError>
        )}

        <FormInput
          inputName={`energyWastes[${props.index}].averageEffect`}
          labelText="Gjennomsnittlig effekt"
          register={props.register}
          defaultValue={props.row.averageEffect}
          measureUnit="MW"
        />
        {props.errors.energyWastes && (
          <FormError className="error">{props.errors.energyWastes[props.index]?.averageEffect?.message}</FormError>
        )}

        <FormInput
          inputName={`energyWastes[${props.index}].maxEffect`}
          labelText="Maks effekt"
          register={props.register}
          defaultValue={props.row.maxEffect}
          measureUnit="MW"
        />
        {props.errors.energyWastes && <FormError className="error">{props.errors.energyWastes[props.index]?.maxEffect?.message}</FormError>}

        {!checkForWater() && (
          <>
            <FormInput
              inputName={`energyWastes[${props.index}].massAmount`}
              labelText="Mengde, masse"
              register={props.register}
              defaultValue={props.row.massAmount}
              measureUnit="kg/s"
            />
            {props.errors.energyWastes && (
              <FormError className="error">{props.errors.energyWastes[props.index]?.massAmount?.message}</FormError>
            )}
          </>
        )}

        <FormInput
          inputName={`energyWastes[${props.index}].availability`}
          labelText="Tilgjengelighet*"
          register={props.register}
          defaultValue={props.row.availability}
          measureUnit="h/år"
        />
        {props.errors.energyWastes && (
          <FormError className="error">{props.errors.energyWastes[props.index]?.availability?.message}</FormError>
        )}

        <FormHeader>Forurensing</FormHeader>

        <FormLabel htmlFor={`energyWastes[${props.index}].pollutedWith`}>Er spillvarmen forurenset med*</FormLabel>
        <FormInputContainer>
          <StyledSelect
            ref={props.register}
            name={`energyWastes[${props.index}].pollutedWith`}
            defaultValue={Pollution[props.row.pollutedWith as TPollution] || props.row.pollutedWith}
          >
            <option value="">Velg forurensing</option>

            {(Object.values(Pollution) as Array<TPollution>).map((name) => {
              return <option key={name}>{name}</option>;
            })}
          </StyledSelect>
          {props.errors.energyWastes && (
            <FormError className="error">{props.errors.energyWastes[props.index]?.pollutedWith?.message}</FormError>
          )}
        </FormInputContainer>
        <FormMeasurement></FormMeasurement>

        <FormHeader>Kundegrunnlag</FormHeader>

        <FormLabel htmlFor={`energyWastes[${props.index}].internalPlan`}>Planer for intern spillvarmeutnyttelse*</FormLabel>
        <FormInputContainer>
          <StyledSelect
            ref={props.register}
            name={`energyWastes[${props.index}].internalPlan`}
            defaultValue={InternalPlan[props.row.internalPlan as TInternalPlan] || props.row.internalPlan}
          >
            <option value="">Velg plan</option>

            {(Object.values(InternalPlan) as Array<TInternalPlan>).map((name) => {
              return <option key={name}>{name}</option>;
            })}
          </StyledSelect>
          {props.errors.energyWastes && (
            <FormError className="error">{props.errors.energyWastes[props.index]?.internalPlan?.message}</FormError>
          )}
        </FormInputContainer>
        <FormMeasurement></FormMeasurement>

        <FormInput
          inputName={`energyWastes[${props.index}].plannedRecoveredInternal`}
          labelText="Planlagt gjenvunnet energimengde internt"
          register={props.register}
          defaultValue={props.row.plannedRecoveredInternal}
          measureUnit="GWh/år"
        />
        {props.errors.energyWastes && (
          <FormError className="error">{props.errors.energyWastes[props.index]?.plannedRecoveredInternal?.message}</FormError>
        )}
        <FormHeader></FormHeader>
        <FormLabel htmlFor={`energyWastes[${props.index}].externalPlan`}>Planer for ekstern spillvarmeutnyttelse*</FormLabel>
        <FormInputContainer>
          <StyledSelect
            ref={props.register}
            name={`energyWastes[${props.index}].externalPlan`}
            defaultValue={ExternalPlan[props.row.externalPlan as TExternalPlan] || props.row.externalPlan}
          >
            <option value="">Velg plan</option>

            {(Object.values(ExternalPlan) as Array<TExternalPlan>).map((name) => {
              return <option key={name}>{name}</option>;
            })}
          </StyledSelect>
          {props.errors.energyWastes && (
            <FormError className="error">{props.errors.energyWastes[props.index]?.externalPlan?.message}</FormError>
          )}
        </FormInputContainer>
        <FormMeasurement></FormMeasurement>

        <FormInput
          inputName={`energyWastes[${props.index}].distanceToExternal`}
          labelText="Avstand til ekstern spillvarmeutnyttelse"
          register={props.register}
          defaultValue={props.row.distanceToExternal}
          measureUnit="m"
        />
        {props.errors.energyWastes && (
          <FormError className="error">{props.errors.energyWastes[props.index]?.distanceToExternal?.message}</FormError>
        )}

        <FormInput
          inputName={`energyWastes[${props.index}].temperatureNeeds`}
          labelText="Temperaturbehov ved ekstern spillvarmeutnyttelse"
          register={props.register}
          defaultValue={props.row.temperatureNeeds}
          measureUnit="&deg;C"
        />
        {props.errors.energyWastes && (
          <FormError className="error">{props.errors.energyWastes[props.index]?.temperatureNeeds?.message}</FormError>
        )}

        <FormInput
          inputName={`energyWastes[${props.index}].plannedRecoveredExternal`}
          labelText="Planlagt gjenvunnet energimengde eksternt"
          register={props.register}
          defaultValue={props.row.plannedRecoveredExternal}
          measureUnit="GWh/år"
        />
        {props.errors.energyWastes && (
          <FormError className="error">{props.errors.energyWastes[props.index]?.plannedRecoveredExternal?.message}</FormError>
        )}

        <FormHeader>Kommentar</FormHeader>
        <CommentInput ref={props.register} name={`energyWastes[${props.index}].comment`} defaultValue={props.row.comment} />
      </MainForm>
    </>
  );
};

export default NonUtilizedEnergyData;
