import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from "react-router-dom";
import { ROUTES } from "./routes";
import { useAuth } from "../../hooks/useAuth";
import NoAccess from "../../components/NoAccess/NoAccess";

export const AuthorizedRoute = ({
  children,
  ...rest
}: RouteProps): JSX.Element => {
  const { isAuthorized, canVisit } = useAuth();
  const message = "Unauthorized";

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) => {
        const attemptedPath = rest.path as string;

        if (isAuthorized() && !canVisit(attemptedPath)) {
          return <NoAccess />;
        }

        return isAuthorized() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.PUBLIC.SIGN_IN_ROUTE,
              state: {
                message,
                requestedPath: rest.path,
                from: props.location,
              },
            }}
          />
        );
      }}
    ></Route>
  );
};
