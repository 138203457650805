import React from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";

const Month = styled.div`
  grid-column: 2/3;
  padding-left: ${(props) => props.theme.padding.medium};
  align-self: center;
  text-align: left;
`;
const MonthInputField = styled.input`
  grid-column: 3/4;
  margin-left: 1.1em;
`;

const MonthUnit = styled.div`
  grid-column: 4/5;
  align-self: center;
`;

const FormError = styled.div`
  grid-column: 3;
  margin-left: 1em;
  margin-bottom: 1em;
`;

type MonthInputProps = {
  name: string;
  inputName: any;
  inputId: any;
  register: any;
  control: any;
  errors: any;
};

const MonthInput: React.FC<MonthInputProps> = ({
  name,
  inputName,
  inputId,
  register,
  control,
  errors,
}) => {
  return (
    <>
      <Month>{name}</Month>

      <MonthInputField ref={register} name={inputName} />
      <MonthUnit>råtonn/mnd</MonthUnit>
      {errors && <FormError className="error">{errors?.message}</FormError>}
      <Controller
        control={control}
        name={inputId}
        defaultValue={null}
        as={<span />}
      />
    </>
  );
};

export default MonthInput;
