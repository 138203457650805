import { gql } from "urql";

export const addOrganizationUserAccess = gql`
  mutation($userId: String!, $organizationId: String!) {
    addOrganizationUserAccess(input: { userId: $userId, organizationId: $organizationId }) {
      __typename
      id
      success
    }
  }
`;
