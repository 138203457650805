import React from "react";
import { Control } from "react-hook-form/dist/types";
import { Controller } from "react-hook-form";
import Select from "react-select";
import styled from "styled-components";
import { useQuery } from "urql";
import { GetOrganizationsQueryResult, ISelectOption, SidestreamTypeEnum } from "../../types";
import { mapToSelectOption, selectAllOption } from "../../utils/form-utils";
import Spinner from "../../components/Loading";
import { getOrganizations } from "../../graphql/queries/Organizations/getOrganizations";

interface SelectProps {
  fieldName: string;
  errors?: any;
  currentValues?: Array<ISelectOption>;
  control: Control<any>;
  register: any;
  required?: boolean;
  sidestreamType?: SidestreamTypeEnum;
}

const SelectContainer = styled.div`
  margin-bottom: 1rem;
  .react-select__value-container {
    max-height: 300px;
    overflow: auto;
  }
`;

const FormError = styled.div`
  grid-column: 2;
  margin-bottom: 1em;
`;

const OrganizationSelect: React.FunctionComponent<SelectProps> = (props) => {
  const [{ data, fetching, error }] = useQuery<GetOrganizationsQueryResult>({ query: getOrganizations });
  const organizations = !data ? [] : data.organizations;
  const sidestreamType = props.sidestreamType;
  const filteredOrganizations =
    sidestreamType === undefined ? organizations : organizations.filter((org) => org.allowedSidestreamTypes.includes(sidestreamType));

  organizations.map((org) => console.log(sidestreamType === undefined ? "Undef" : org.allowedSidestreamTypes.includes(sidestreamType)));
  const organizationsList = filteredOrganizations.map((org) => mapToSelectOption(org.id, org.name));

  return (
    <>
      {fetching || !data ? (
        <Spinner />
      ) : (
        <SelectContainer>
          <Controller
            control={props.control}
            defaultValue=""
            name={props.fieldName}
            render={(props) => (
              <Select
                // Select all capability
                onChange={(selected, event) => {
                  if (selected !== null && selected.length > 0) {
                    if (selected[selected.length - 1].value === selectAllOption.value) {
                      return props.onChange([selectAllOption, ...organizationsList]);
                    }
                    let result: Array<any> = [];
                    if (selected.length === organizationsList.length) {
                      if (selected.includes(selectAllOption)) {
                        result = selected.filter((option) => option.value !== selectAllOption.value);
                      } else if (event.action === "select-option") {
                        result = [selectAllOption, ...organizationsList];
                      }
                      return props.onChange(result);
                    }
                  }

                  return props.onChange(selected);
                }}
                placeholder="Vennligst velg"
                isMulti
                isSearchable
                classNamePrefix="react-select"
                value={props.value}
                options={[selectAllOption, ...organizationsList]}
                menuPortalTarget={document.body}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            )}
          ></Controller>
        </SelectContainer>
      )}

      {props.errors && <FormError className="error">{props.errors?.organizations?.message}</FormError>}
      {error && <div>Feil: Kunne ikke hente liste</div>}
    </>
  );
};

export default OrganizationSelect;
