import React from "react";
import styled from "styled-components";

const Title = styled.div`
  display: flex;
  justify-content: start;
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  font-size: ${(props) => props.theme.fontSizes.large};
`;
const Box = styled.div`
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.middle};
  border-radius: ${(props) => props.theme.borderRadius.light};
  width: 95%;
  padding: ${(props) => props.theme.padding.medium};
`;
const Description = styled(Box)`
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: center;
`;
const DescriptionLine = styled.p``;
const DescriptionBold = styled.span`
  font-weight: 600;
`;
const SummaryBoilerplate: React.FC = () => {
  return (
    <>
      <Title>Oppsummering</Title>
      <Description>
        <DescriptionLine>
          Under følger en oppsummering av dine data. Vennligst se gjennom at
          verdiene
        </DescriptionLine>{" "}
        <DescriptionLine>
          stemmer og <DescriptionBold>trykk send inn</DescriptionBold> for å
          avslutte dette skjemaet
        </DescriptionLine>
      </Description>
    </>
  );
};

export default SummaryBoilerplate;
