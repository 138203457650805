import React from "react";
import styled from "styled-components";
import MonthCheckbox from "../MonthCheckbox";

const MonthsInputContainer = styled.div`
  grid-column: 1/4;
  display: grid;
  grid-template-columns: 3fr repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: ${(props) => props.theme.gap.extraLight};
`;

type MonthCheckboxProps = {
  control: any;
  register: any;
};

const MonthCheckboxContainer: React.FC<MonthCheckboxProps> = ({
  control,
  register,
}) => {
  return (
    <>
      <MonthsInputContainer>
        <MonthCheckbox
          name="Januar"
          checkboxName="JANUARY.checked"
          checkboxId="JANUARY.id"
          control={control}
          register={register}
        />

        <MonthCheckbox
          name="Februar"
          checkboxName="FEBRUARY.checked"
          checkboxId="FEBRUARY.id"
          control={control}
          register={register}
        />
        <MonthCheckbox
          name="Mars"
          checkboxName="MARCH.checked"
          checkboxId="MARCH.id"
          control={control}
          register={register}
        />
        <MonthCheckbox
          name="April"
          checkboxName="APRIL.checked"
          checkboxId="APRIL.id"
          control={control}
          register={register}
        />
        <MonthCheckbox
          name="Mai"
          checkboxName="MAY.checked"
          checkboxId="MAY.id"
          control={control}
          register={register}
        />
        <MonthCheckbox
          name="Juni"
          checkboxName="JUNE.checked"
          checkboxId="JUNE.id"
          control={control}
          register={register}
        />
        <MonthCheckbox
          name="Juli"
          checkboxName="JULY.checked"
          checkboxId="JULY.id"
          control={control}
          register={register}
        />
        <MonthCheckbox
          name="August"
          checkboxName="AUGUST.checked"
          checkboxId="AUGUST.id"
          control={control}
          register={register}
        />
        <MonthCheckbox
          name="September"
          checkboxName="SEPTEMBER.checked"
          checkboxId="SEPTEMBER.id"
          control={control}
          register={register}
        />
        <MonthCheckbox
          name="Oktober"
          checkboxName="OCTOBER.checked"
          checkboxId="OCTOBER.id"
          control={control}
          register={register}
        />
        <MonthCheckbox
          name="November"
          checkboxName="NOVEMBER.checked"
          checkboxId="NOVEMBER.id"
          control={control}
          register={register}
        />
        <MonthCheckbox
          name="Desember"
          checkboxName="DECEMBER.checked"
          checkboxId="DECEMBER.id"
          control={control}
          register={register}
        />
      </MonthsInputContainer>
    </>
  );
};

export default MonthCheckboxContainer;
