import { gql } from "urql";

export const getMaterialStreamAdvantage = gql`
  query($id: ID) {
    materialStreamUsage(filter: { id: $id }) {
      __typename
      internalDeposition
      externalDeposition
      internalRecycling
      externalSales
      isBurned
      previousInternalAndExternalUsage
      previousInternalUsage
      previousExternalUsage
      previousInternalAndExternalUsageComment
      internalAndExternalActivitesForMaterialUsage
      internalActivitesForMaterialUsage
      externalActivitesForMaterialUsage
      internalAndExternalActivitesForMaterialUsageComment
      internalAndExternalGreaterPotential
      internalGreaterPotential
      externalGreaterPotential
      internalAndExternalGreaterPotentialComment
      links {
        id
        url
      }
      resourcePersons {
        id
        fullName
        organizationName
      }
      otherComments
      id
    }
  }
`;
