import { ToastContainer } from "react-toastify";
import styled from "styled-components";

export default styled(ToastContainer)`
  .Toastify__toast-container {
  }
  .Toastify__toast {
  }
  .Toastify__toast--error {
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
    background-color: ${(props) => props.theme.colors.buttonColor.primary};
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;
