import { gql } from "urql";

export const deleteEnergyType = gql`
  mutation($id: ID) {
    deleteEnergyType(input: { id: $id }) {
      id
      success
    }
  }
`;
