import React from "react";
import styled from "styled-components";

const Box = styled.div`
  font-size: 0.9rem;
  padding: 1em 0;
`;
const Description = styled(Box)`
  display: flex;
  flex-direction: column;
  place-items: left;
  justify-content: left;
`;

const DescriptionForAnalysis = styled(Box)`
  display: flex;
  flex-direction: column;
  place-items: left;
  justify-content: left;
`;

const DescriptionLine = styled.p`
  padding-bottom: 0.1em;
`;

export const InformationBoilerPlateSideStrom: React.FC = () => {
  return (
    <>
      <Description>
        <DescriptionLine>
          Det meste av informasjonen i skjemaet er allerede utfylt ved tidligere innrapportering. Gå likevel igjennom for å sikre at dataene
          er riktige.
        </DescriptionLine>
        <DescriptionLine>
          Flere steder er det en tekstboks for «Comments». All tekst her skal være på engelsk. Dette fordi vi ønsker at
          informasjon skal være tilgjengelig på engelsk.
        </DescriptionLine>
        <DescriptionLine>«Hevdnavn»: Fylles inn dersom det er tilgjengelig (engelsk navn dersom tilgjengelig).</DescriptionLine>{" "}
        <DescriptionLine>«Prosess»: Sidestrømmens opprinnelse, prosesstrinn,- bruk engelsk så langt det er mulig.</DescriptionLine>{" "}
      </Description>
    </>
  );
};

export const InformationBoilerPlateUtilization: React.FC = () => {
  return (
    <>
      <Description>
        <DescriptionLine>Dersom nøyaktige tall på utnyttelse og fordeling ikke foreligger, før inn cirka-tall.</DescriptionLine>
        <DescriptionLine>
          Under «Potensiell utnyttelse» og beskrivelse av hva som er gjort: Det er ønskelig å beskrive hva man har gjort, men ikke
          nødvendig. Vurder hva dere vil dele med andre. Husk engelsk tekst.
        </DescriptionLine>
      </Description>
    </>
  );
};

export const InformationBoilerPlateMaterial: React.FC = () => {
  return (
    <>
      <Description>
        <DescriptionLine>Fyll inn R-, S- og H-setninger dersom det foreligger.</DescriptionLine>{" "}
        <DescriptionLine>
          Under boksen «Kommentar til partikkelstørrelse»: Kommenter spesielle egenskaper, etc til partiklene. Oppgi også målemetode.
        </DescriptionLine>{" "}
        <DescriptionLine>Husk engelsk tekst.</DescriptionLine>{" "}
      </Description>
    </>
  );
};

export const InformationBoilerPlateAnalysis: React.FC = () => {
  return (
    <>
      <DescriptionForAnalysis>
        <DescriptionLine>
          Materialsammensetningen skal oppgis som formler – IKKE TEKST. (Dvs. eksempelvis Al2O3 og ikke aluminiumoksid, likeså Al og ikke
          aluminium).
        </DescriptionLine>{" "}
        <DescriptionLine>Oppgi typisk analyseverdi.</DescriptionLine> <DescriptionLine>Husk engelsk tekst.</DescriptionLine>{" "}
      </DescriptionForAnalysis>
    </>
  );
};

export const InformationBoilerPlateOther: React.FC = () => {
  return (
    <>
      <Description>
        <DescriptionLine>Her kan dere oppgi annen relevant informasjon om sidestrømmene som ikke allerede er oppgitt.</DescriptionLine>
        <DescriptionLine>Eksempelvis muligheter eller kjente begrensninger.</DescriptionLine>
        <DescriptionLine>Husk engelsk tekst.</DescriptionLine>
      </Description>
    </>
  );
};

export default InformationBoilerPlateSideStrom;
