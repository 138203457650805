import React from "react";
import styled from "styled-components";

type InputProps = {
  inputName: string;
  labelText: string;
  register: any;
  defaultValue?: string | number;
  measureUnit?: string;
};

const FormLabel = styled.label`
  grid-column: 1;
  margin-top: 0.3125rem;
`;

const FormInputContainer = styled.div`
  grid-column: 2/3;
`;
const FormMeasurement = styled.span`
  grid-column: 3/3;
  padding-top: 0.3125rem;
  padding-left: 0.3125rem;
`;

const FormInput: React.FC<InputProps> = (props) => {
  return (
    <>
      <FormLabel htmlFor={props.inputName} dangerouslySetInnerHTML={{ __html: props.labelText }}></FormLabel>
      <FormInputContainer>
        <input ref={props.register} name={props.inputName} defaultValue={props.defaultValue} />
      </FormInputContainer>
      <FormMeasurement>{props.measureUnit}</FormMeasurement>
    </>
  );
};

export default FormInput;
