import React from "react";
import styled from "styled-components";
import AboutMaterialFlow from "./AboutMaterialFlow";
import MaterialFlowUtilization from "./MaterialFlowUtilization";
import AboutMaterial from "./AboutMaterial";
import MaterialAnalysis from "./MaterialAnalysis";
import MaterialOtherInformation from "./MaterialOtherInformation";
import Summary from "./Summary";
import Steps from "../../components/Steps";
import Circle from "../../components/Circle";
import Navigation from "../../components/Navigation";
import { StepHandling, Navigation as NavigationType, Param } from "../../types";
import { NavigationContext } from "../../providers/NavigationProvider";
import General from "../../components/General";
import { useParams } from "react-router-dom";
import { useQuery } from "urql";
import { getOrganization } from "../../graphql/queries/Materialflow/getOrganization";
import DeliveredSchema from "../../components/DeliveredSchema";
import { StepsArea, StepsOverview, StepsContainer, StepsCircle, StepsName, StepsButtons } from "../../components/Steps/StepsLayout";
import { getGeneralInfoConfirmed } from "../../generalInfoConfirmed";
import SidestreamName from "../../components/SidestreamName";
import SaveText from "../../components/SaveText";

const ButtonContainer = styled.div`
  grid-column: 1/3;
  display: grid;
  grid-template-rows: auto 1fr;
  justify-content: end;
  margin-top: -4.4rem;
  min-width: 1000px;
`;

const MaterialFlow: React.FC = () => {
  const generalInfoIsConfirmed = getGeneralInfoConfirmed() ? true : false;
  const [navigation, setNavigation] = React.useState<StepHandling>({
    step: generalInfoIsConfirmed ? 2 : 1,
    amountOfSteps: 8,
  });

  const { id } = useParams<Param>();
  const [organizationResult] = useQuery({
    query: getOrganization,
    variables: { id },
  });

  const { data, fetching } = organizationResult;
  const handleNav = (next: boolean) => {
    if (next) {
      if (navigation.step < navigation.amountOfSteps) {
        setNavigation({ ...navigation, step: navigation.step + 1 });
      }
    } else {
      if (navigation.step > 1) {
        setNavigation({ ...navigation, step: navigation.step - 1 });
      }
    }
  };

  const handleNavigation: NavigationType = {
    navigation: navigation,
    handleNav: handleNav,
  };

  return (
    <>
      {navigation.step >= 2 && (
        <Steps>
          <StepsArea>
            <StepsOverview>
              <StepsContainer>
                {/* <StepsCircle className="first">
                  <Circle active={navigation.step === 2 ? true : false} finished={navigation.step > 2 ? true : false}>
                    1
                  </Circle>
                </StepsCircle>

                <StepsName>Intro</StepsName> */}
                <StepsCircle className="first">
                  <Circle active={navigation.step === 2 ? true : false} finished={navigation.step > 2 ? true : false}>
                    1
                  </Circle>
                </StepsCircle>
                <StepsName>Om sidestrømmen</StepsName>
                <StepsCircle>
                  <Circle active={navigation.step === 3 ? true : false} finished={navigation.step > 3 ? true : false}>
                    2
                  </Circle>
                </StepsCircle>
                <StepsName>Utnyttelse</StepsName>
                <StepsCircle>
                  <Circle active={navigation.step === 4 ? true : false} finished={navigation.step > 4 ? true : false}>
                    3
                  </Circle>
                </StepsCircle>
                <StepsName>Om materialet</StepsName>
                <StepsCircle>
                  <Circle active={navigation.step === 5 ? true : false} finished={navigation.step > 5 ? true : false}>
                    4
                  </Circle>
                </StepsCircle>
                <StepsName>Analyse</StepsName>
                <StepsCircle className="">
                  <Circle active={navigation.step === 6 ? true : false} finished={navigation.step > 6 ? true : false}>
                    5
                  </Circle>
                </StepsCircle>
                <StepsName>Øvrig informasjon</StepsName>
                <StepsCircle className="last">
                  <Circle active={navigation.step === 7 ? true : false} finished={navigation.step > 7 ? true : false}>
                    6
                  </Circle>
                </StepsCircle>
                <StepsName>Sammendrag</StepsName>
              </StepsContainer>
              <ButtonContainer>
                <StepsButtons>
                  {navigation.step < navigation.amountOfSteps && (
                    <Navigation
                      navigation={navigation}
                      handleNext={() => {
                        let element = document.getElementsByClassName("next-button")[1] as HTMLElement;
                        element?.click();
                      }}
                      handlePrev={() => {
                        let element = document.getElementsByClassName("prev-button")[1] as HTMLElement;
                        element.click();
                      }}
                    />
                  )}
                </StepsButtons>
              </ButtonContainer>
            </StepsOverview>
          </StepsArea>
        </Steps>
      )}
      {navigation.step > 1 && <SidestreamName name={data?.materialStreamGeneral.sidestreamName} />}
      <NavigationContext.Provider value={handleNavigation}>
        {navigation.step === 1 && !fetching && <General orgId={data?.materialStreamGeneral.organizationId} data={data} schemaId={id} />}
        {/* {navigation.step === 2 && <MaterialFlowIntroduction />} */}
        {navigation.step === 2 && <AboutMaterialFlow />}
        {navigation.step === 3 && <MaterialFlowUtilization />}
        {navigation.step === 4 && <AboutMaterial />}
        {navigation.step === 5 && <MaterialAnalysis />}
        {navigation.step === 6 && <MaterialOtherInformation />}
        {navigation.step === 7 && <Summary />}
        {navigation.step === 8 && <DeliveredSchema />}
      </NavigationContext.Provider>
      <SaveText />
    </>
  );
};

export default MaterialFlow;
