import { gql } from "urql";

export const removeOrganizationUserAccess = gql`
  mutation($userId: ID!, $organizationId: ID!) {
    removeOrganizationUserAccess(input: { userId: $userId, organizationId: $organizationId }) {
      __typename
      id
      success
    }
  }
`;
