import React from "react";
import { X } from "react-feather";
import { ArrayField, Controller } from "react-hook-form";
import styled from "styled-components";
import { useMutation } from "urql";
import Button, { ButtonSize, ButtonType } from "../../../../components/Button";
import Required from "../../../../components/Required";
import { ChemicalAnalysis, TChemicalUnit, FormType } from "../../../../types";
import { SelectValues } from "../../../../utils/form-utils";
import { deleteChemicalAnalysis } from "../../../../graphql/mutations/Materialflow/deleteChemicalAnalysis";
import { ChemicalUnit } from "../../../../dictionaries";
import { IMutationResult } from "../../../../types/mutations/mutationResult";
import { handleMutationError } from "../../../../lib/toaster";
import { InformationBoilerPlateAnalysis } from "../../../../components/InformationBoilerPlate";

enum InputWidth {
  LONG,
  SHORT,
}
enum InputDirection {
  COLUMN,
  ROW,
}

const Consent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.middle};
  padding: 2em;
`;
const ConsentTitle = styled.div`
  font-weight: 600;
`;
const ConsentDescription = styled.div``;
const FirstColumn = styled.div`
  grid-column: 1/2;
`;
const Title = styled.div`
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.bottom};
  font-size: ${(props) => props.theme.fontSizes.large};
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  margin-bottom: 1.5rem;
`;

const InputField = styled.input<InputProps>`
  width: ${(props) => (props.size === InputWidth.LONG ? "100%" : props.size === InputWidth.SHORT ? "70%" : "100%")};
`;
const SelectField = styled.select<InputProps>`
  width: ${(props) => (props.size === InputWidth.LONG ? "80%" : props.size === InputWidth.SHORT ? "50%" : "100%")};
  height: 2.5rem;
`;

const HeaderTitle = styled(FirstColumn)`
  font-weight: 600;
`;

type IContainerProps = {
  header: boolean;
};
const IContainer = styled.div<IContainerProps>`
  grid-column: 1/-1;
  background-color: ${(props) =>
    props.header ? props.theme.colors.bodyBackgroundColor.middle : props.theme.colors.bodyBackgroundColor.middle};
  display: grid;
  grid-template-columns: 3fr 2fr repeat(4, 1fr) 2em;
  grid-template-rows: 1fr auto;
  padding: ${(props) => props.theme.padding.light};
  grid-gap: 1em;
`;

const Row = styled.div`
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 3fr 2fr repeat(4, 1fr) 2em;
  gap: ${(props) => props.theme.gap.extraLight};
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.top};
  padding: ${(props) => props.theme.padding.light};
  border-radius: 1em;
`;
const RowHeaders = styled.div`
  grid-row: 1/2;
`;
const FirstHeader = styled(RowHeaders)`
  grid-column: 1/2;
`;
const AnalysisText = styled(InputField)`
  grid-column: 1/2;
`;
const UnitSelect = styled(SelectField)`
  grid-column: 2/3;
`;
const TypicalValueText = styled(InputField)`
  grid-column: 3/4;
`;
const MinimumValueText = styled(InputField)`
  grid-column: 4/5;
`;

const MaximumValueText = styled(InputField)`
  grid-column: 5/6;
`;
const ApprovalInputField = styled(InputField)`
  grid-column: 6/6;
  margin-top: 0.8rem;
`;

const RemoveRow = styled.div`
  grid-column: 7/7;
  display: flex;
  align-self: center;
  cursor: pointer;
`;
const SecondHeader = styled(RowHeaders)`
  grid-column: 2/3;
`;

const ThirdHeader = styled(RowHeaders)`
  grid-column: 3/4;
`;

const FourthHeader = styled(RowHeaders)`
  grid-column: 4/5;
`;

const FifthHeader = styled(RowHeaders)`
  grid-column: 5/6;
`;
const SixthHeader = styled(RowHeaders)`
  grid-column: 6/7;
`;
const SeventhHeader = styled(RowHeaders)`
  grid-column: 7/7;
`;

const AddRowContainer = styled.div`
  grid-column: 1/-1;
  display: flex;
  justify-self: end;
`;

const FormError = styled.div`
  grid-column: 2;
  margin-bottom: 1em;
`;

interface InputProps {
  size?: InputWidth;
  direction?: InputDirection;
}
type MaterialAnalysisDataType = {
  form: FormType;
  errors: any;
  control: any;
};
const MaterialAnalysisData: React.FC<MaterialAnalysisDataType> = (props) => {
  const [, executeMutation] = useMutation<IMutationResult>(deleteChemicalAnalysis);

  const handleDeleteRow = async (index: number) => {
    if (props.form.fields[index].id) {
      await executeMutation({
        id: props.form.fields[index].id,
      })
        .then(() => {
          props.form.remove(index);
        })
        .catch(() => handleMutationError());
    } else {
      props.form.remove(index);
    }
  };

  return (
    <>
      <Title>Analyse</Title>
      <InformationBoilerPlateAnalysis />
      <Required />
      <Consent>
        <ConsentTitle>Samtykke deling av kjemisk analyse</ConsentTitle>
        <ConsentDescription>
          For å begrense hva som skal deles av innhold i den kjemiske analysen, kan man velge hvilke elementer man IKKE ønsker å dele i
          tabellen under. Rader der er gitt samtykke til å deles (avkrysning er satt til default) vil være å anse som tilgjengelig
          informasjon og kan deles iht ditt valg av offentlig eller utvidet deling.
        </ConsentDescription>
      </Consent>
      {/* <OuterBox>
        <InnerBox> */}
      <HeaderTitle>Kjemisk analyse</HeaderTitle>

      <IContainer header={true}>
        <FirstHeader>Kjemisk analyse - innhold *</FirstHeader>
        <SecondHeader>Enhet *</SecondHeader>
        <ThirdHeader>Typisk verdi *</ThirdHeader>
        <FourthHeader>Minimum</FourthHeader>
        <FifthHeader>Maksimum</FifthHeader>
        <SixthHeader>Samtykke til deling</SixthHeader>
        <SeventhHeader> </SeventhHeader>

        {props.form.fields.map((row: Partial<ArrayField<ChemicalAnalysis, any>>, index: number) => {
          return (
            <Row key={row.fieldId}>
              <div>
                <AnalysisText
                  name={`chemicalAnalysises[${index}].chemicalAnalysisName`}
                  ref={props.form.register()}
                  defaultValue={row.chemicalAnalysisName}
                  size={InputWidth.LONG}
                />
                {props.errors?.chemicalAnalysises && (
                  <FormError className="error">{props.errors?.chemicalAnalysises[index]?.chemicalAnalysisName?.message}</FormError>
                )}{" "}
              </div>
              <div>
                <UnitSelect
                  name={`chemicalAnalysises[${index}].chemicalUnit`}
                  ref={props.form.register()}
                  defaultValue={ChemicalUnit[row.chemicalUnit as TChemicalUnit]}
                >
                  <option value="">Vennligst velg</option>
                  {SelectValues(ChemicalUnit)}
                </UnitSelect>
                {props.errors?.chemicalAnalysises && (
                  <FormError className="error">{props.errors?.chemicalAnalysises[index]?.chemicalUnit?.message}</FormError>
                )}
              </div>
              <div>
                <TypicalValueText
                  size={InputWidth.SHORT}
                  name={`chemicalAnalysises[${index}].typicalValue`}
                  ref={props.form.register()}
                  defaultValue={row.typicalValue}
                />
                {props.errors?.chemicalAnalysises && (
                  <FormError className="error">{props.errors?.chemicalAnalysises[index]?.typicalValue?.message}</FormError>
                )}
              </div>
              <div>
                <MinimumValueText
                  size={InputWidth.SHORT}
                  name={`chemicalAnalysises[${index}].minValue`}
                  ref={props.form.register()}
                  defaultValue={row.minValue}
                />
                {props.errors?.chemicalAnalysises && (
                  <FormError className="error">{props.errors?.chemicalAnalysises[index]?.minValue?.message}</FormError>
                )}
              </div>
              <div>
                <MaximumValueText
                  size={InputWidth.SHORT}
                  name={`chemicalAnalysises[${index}].maxValue`}
                  ref={props.form.register()}
                  defaultValue={row.maxValue}
                />
                {props.errors?.chemicalAnalysises && (
                  <FormError className="error">{props.errors?.chemicalAnalysises[index]?.maxValue?.message}</FormError>
                )}
              </div>{" "}
              <div>
                <ApprovalInputField
                  ref={props.form.register()}
                  name={`chemicalAnalysises[${index}].shareChemicalAnalysisApproval`}
                  type="checkbox"
                  defaultChecked={row.shareChemicalAnalysisApproval === undefined ? true : row.shareChemicalAnalysisApproval ? true : false}
                />
              </div>
              <RemoveRow>
                <X size={25} onClick={() => handleDeleteRow(index)} />
              </RemoveRow>
              <Controller
                control={props.form.control}
                name={`chemicalAnalysises[${index}].id`}
                defaultValue={row.id ?? null}
                as={<span />}
              />
            </Row>
          );
        })}

        <AddRowContainer>
          <Button buttonType={ButtonType.Primary} size={ButtonSize.Large} onClick={() => props.form.append({})}>
            Legg til
          </Button>
        </AddRowContainer>
      </IContainer>
    </>
  );
};

export default MaterialAnalysisData;
