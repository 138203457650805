import { ArrayField } from "react-hook-form";
import { IUser } from "./types/domain/user";

//#region parent types

type AboutSidestreamType = {
  yearlyProduction: number;
  productionVolumeUnit: TProductionVolumeUnit;
  productionPeriod: TProductionPeriod;
  materialState: string;
  materialStateComment: string;
};
type UtilizationType = {
  previousInternalAndExternalUsage: boolean;
  previousInternalUsage: boolean;
  previousExternalUsage: boolean;
  previousInternalAndExternalUsageComment: string;
  internalAndExternalActivitesForMaterialUsage: boolean;
  internalActivitesForMaterialUsage: boolean;
  externalActivitesForMaterialUsage: boolean;
  internalAndExternalActivitesForMaterialUsageComment: string;
  internalAndExternalGreaterPotential: boolean;
  internalGreaterPotential: boolean;
  externalGreaterPotential: boolean;
  internalAndExternalGreaterPotentialComment: string;
  otherComments: string;
};

type AboutSidestreamMaterial = {
  dangerousWaste: TBooleanSelectExtended;
  limitationsToMaterialDeposit: TBooleanSelectExtended;
  depositDemandsComment: string;
  autoIgnitionHazard: TBooleanSelect;
};
//#endregion

//#region sidestream energy

export type EnergyUsage = {
  // energySchema: EnergySchema;
  energyTypes: Array<EnergyType>;
};

export type EnergyType = {
  energyTypeName: TEnergyUsageNames;
  energyUsage: number;
  additionalInformation: string;
  id?: string;
};

//Types for second step: Utilized waste energy
export type UtilizedEnergyType = {
  isWasteHeatUtilized: boolean;
  utilizedWasteHeats: Array<UtilizedWasteHeat>;
};
export type UtilizedWasteHeat = {
  utilizedWasteHeat: TUtilizedWasteHeatNames;
  utilizedWasteHeatInformation: string;
  utilizedWasteHeatUsage: number;
  id?: string;
};

//Types for third step: Non utilized waste energy
export type NonUtilizedType = {
  isWasteHeatNotUtilized: boolean;
  energyWastes: Array<NonUtilizedWasteHeatType>;
};
export type NonUtilizedWasteHeatType = {
  energyWasteName: string;
  wasteSource: TWasteSource;
  temperature: number;
  pressure?: number | undefined;
  energyAmount: number;
  averageEffect: number;
  maxEffect: number;
  massAmount: number;
  availability: number;
  pollutedWith: TPollution;
  internalPlan: TInternalPlan;
  plannedRecoveredInternal: number;
  externalPlan: TExternalPlan;
  distanceToExternal: number;
  temperatureNeeds: number;
  plannedRecoveredExternal: number;
  comment: string;
  id?: string;
};

//#endregion

//#region sidestream material
export type AboutMaterialFlowType = AboutSidestreamType & {
  givenName?: string;
  materialType: TMaterialType;
  eALCodeId?: string;
  eALCode: ISelectOption;
  wasteCodeId?: string;
  wasteCode: ISelectOption;
  sideStreamOrigin: string;
  productionBatch: TProductionBatch;
  productionVolumeBatch: number;
  organic: TOrganic;
  organicComment: string;
};

export type MaterialFlowAdvantage = UtilizationType & {
  internalDeposition: number;
  externalDeposition: number;
  internalRecycling: number;
  externalSales: number;
  isBurned: TBooleanSelectExtended;
  links: Array<UtilizationLink>;
  resourcePersons: Array<UtilizationResourcePerson>;
};

// export type RSentences = {
//   R1: string;
//   R2: string;
//   R3: string;
// };
// export type SSentences = {
//   S1: string;
//   S2: string;
//   S3: string;
// };
// export type HSentences = {
//   H1: string;
//   H2: string;
//   H3: string;
// };

export type AboutMaterial = AboutSidestreamMaterial & {
  rSentences: Array<RSentence>;
  sSentences: Array<SSentence>;
  hSentences: Array<HSentence>;
  waterReactivity: TWaterReactivity;
  autoIgnitionHazard: TAutoIgnitionHazard;
  particleType: TParticleType;
  particleSizeUnit: TParticleUnitSize;
  particleSizeD50: number;
  particleSizeMin: number;
  particleSizeMax: number;
  particleSizeComment: string;
  moistureContent: number;
};

export type RSentence = {
  materialStreamSchemaId: string;
  rSentenceId: string;
};

export type HSentence = {
  materialStreamSchemaId: string;
  hSentenceId: string;
};

export type SSentence = {
  materialStreamSchemaId: string;
  sSentenceId: string;
};

export type ChemicalAnalysis = {
  chemicalAnalysisName: string;
  chemicalUnit: TChemicalUnit;
  typicalValue: number;
  minValue: number;
  maxValue: number;
  shareChemicalAnalysisApproval: {
    id: string;
    checked: boolean;
  };
  id: string;
};
export type ChemicalAnalysisType = {
  chemicalAnalysises: Array<ChemicalAnalysis>;
};

export type MaterialOtherInformationType = {
  materialStream: {
    id: string;
    otherInformation: string;
  };
};
//#endregion
type MonthProd = {
  id: string;
  production: number;
  checked?: boolean;
};
//#region sidestream wood
export type AboutWoodType = Pick<AboutSidestreamType, "yearlyProduction"> & {
  materialTypeComment: string;
  reportPeriod: TTimePeriod;
  reportYear: string;
  JANUARY: MonthProd;
  FEBRUARY: MonthProd;
  MARCH: MonthProd;
  APRIL: MonthProd;
  MAY: MonthProd;
  JUNE: MonthProd;
  JULY: MonthProd;
  AUGUST: MonthProd;
  SEPTEMBER: MonthProd;
  OCTOBER: MonthProd;
  NOVEMBER: MonthProd;
  DECEMBER: MonthProd;
  productionPeriodPartTime: TBooleanSelect;
  productionMonths: Array<MonthlyProduction>;
  storage: TWoodStorage;
  storageComment: string;
  // producedInMonths: TMonths;
};
export type MonthlyProduction = {
  month: string;
  id: string;
  monthlyProduction: number;
};

export type WoodUtilizationType = UtilizationType & {
  internalEnergyRecycling: number;
  externalEnergyRecycling: number;
  internalMaterialRecycling: number;
  externalMaterialRecycling: number;
  reuse: number;
  reuseFurniture: number;
  reuseBuildingMaterials: number;
  links: Array<UtilizationLink>;
  resourcePersons: Array<UtilizationResourcePerson>;
};

export type UtilizationLink = {
  url: string;
};

export type UtilizationResourcePerson = {
  fullName: string;
  organizationName: string;
};

export type AboutWoodMaterialType = AboutSidestreamMaterial & {
  treatmentAfterDelivery: TTreatment;
};
//#endregion

//#region select types
export type TUtilizedWasteHeatNames = "ELECTRIC" | "THERMAL";
export type TEnergyUsageNames =
  | "PURCHASED_ELECTRIC_POWER"
  | "PURCHASED_ELECTRIC_FUEL_OIL"
  | "PURCHASED_FUEL_OIL"
  | "PURCHASED_GAS"
  | "PURCHASED_COAL_PRODUCTS"
  | "PURCHASED_DISTICT_HEAT_OR_STEAM"
  | "PURCHASED_BIO_ENERGY"
  | "PURCHASED_OTHER"
  | "SELF_MADE_BIO_ENERGY"
  | "SELF_MADE_POWER"
  | "SELF_MADE_GAS"
  | "SELF_MADE_OTHER";

export type TWasteSource = "WATER" | "STEAM" | "GAS" | "SOLIDS" | "AIR";

export type TPollution = "NONE" | "DUST" | "GAS" | "CORROSIVE_ELEMENTS" | "OTHER";

export type TInternalPlan = "NONE" | "PROCESSED_HEAT" | "HEAT_BUILDING" | "OTHER";

export type TExternalPlan = "NONE" | "POWER_RECOVERY" | "DISTRICT_OR_LOCAL_HEATING" | "AGRICULTURE" | "FISH_FARMING" | "OTHER";

export type TMaterialType = "WASTE" | "BY_PRODUCT" | "DEPARTURE_MASS";

export type TProductionVolumeUnit = "T_YEAR" | "KT_YEAR" | "TD";

export type TProductionPeriod = "CONTINOUS" | "WEEKLY" | "MONTHLY" | "QUARTERLY" | "SEMI_ANNUALLY" | "NOT_ANSWERED";

export type TProductionBatch = "KG_BATCH" | "T_BATCH" | "KT_BATCH";

export type TOrganic = "ORGANIC" | "NOT_ORGANIC";

export type TMaterialState = "SOLID" | "LIQUID" | "GAS" | "DISPERSION" | "EMULSION";

export type TBooleanSelect = "YES" | "NO";

export type TBooleanSelectExtended = TBooleanSelect;

export type TWaterReactivity = TBooleanSelect | "EXPLOTION" | "DONT_KNOW";

export type TAutoIgnitionHazard = TBooleanSelect | "DONT_KNOW";

export type TParticleType = "NOT_RELEVANT" | "DUST" | "SLAM" | "POWDER" | "NOT_HOMOGENEOUS" | "FIBER" | "GRAVEL" | "STONE";

export type TParticleUnitSize = "CM" | "MM" | "UM" | "NM";

export type TChemicalUnit = "PERCENT_WT" | "PERCENT_VOL" | "PPM_WT" | "PPM_VOL" | "GL" | "MG_L" | "UG_L" | "MG_ML" | "UG_ML" | "M";

export type TWoodMaterialType =
  | "PURE_WOOD_SPON"
  | "PURE_WOOD"
  | "PAINTED_OILED_WOOD"
  | "IMPREGNATED"
  | "VENEER"
  | "MDF"
  | "UNSORTED"
  | "OTHER";

export type TTimePeriod = "YEAR" | "MONTH";
export type TMonths =
  | "JANUARY"
  | "FEBRUARY"
  | "MARCH"
  | "APRIL"
  | "MAY"
  | "JUNE"
  | "JULY"
  | "AUGUST"
  | "SEPTEMBER"
  | "OCTOBER"
  | "NOVEMBER"
  | "DECEMBER";

export type TWoodStorage = "INSIDE" | "UNDER_ROOF" | "OPEN";

export type TTreatment = "CLEANED" | "DISASSEMBLED" | "SOURCE_SORTED" | "PACKED" | "DIVISION" | "NONE";

export type TIndustryType =
  | "NOTSET"
  | "RECYCLING"
  | "CEMENT_INDUSTRY"
  | "WOOD_PROCESSING"
  | "METALLURGICAL_INDUSTRY"
  | "MINERAL_INDUSTRY"
  | "CHEMICAL_INDUSTRY"
  | "OTHER_PROCESS_INDUSTRY";

export type Selects =
  | TChemicalUnit
  | TParticleUnitSize
  | TBooleanSelect
  | TBooleanSelectExtended
  | TEnergyUsageNames
  | TExternalPlan
  | TInternalPlan
  | TMaterialState
  | TMaterialType
  | TOrganic
  | TParticleType
  | TParticleUnitSize
  | TPollution
  | TProductionBatch
  | TProductionPeriod
  | TProductionVolumeUnit
  | TUtilizedWasteHeatNames
  | TWasteSource
  | TWaterReactivity
  | TAutoIgnitionHazard
  | TWoodMaterialType
  | TTreatment
  | TIndustryType
  | TWoodStorage
  | TTimePeriod
  | TMonths;
//#endregion

//#region misc

export type StepHandling = {
  step: number;
  amountOfSteps: number;
};

export type Navigation = {
  navigation: StepHandling;
  handleNav: (next: boolean) => void;
};

export interface FormType {
  register: any;
  fields: Partial<ArrayField<ChemicalAnalysis, any>>[];
  append: (e: any) => void;
  remove: (e: any) => void;
  control: any;
}

export type Param = {
  id: string;
};
//#endregion

export type SignInFormData = {
  email: string;
  password: string;
};

export type ChangePasswordFormData = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export type GenerateResetPasswordFormData = {
  email: string;
};

export type ResetPasswordFormData = {
  email: string;
  resetToken: string;
  newPassword: string;
};

export type Organization = {
  id: string;
  orgId: string;
  name: string;
  location: string;
  mainProduct: string;
  mainProductSelected: MainProductSelected;
  industryType: TIndustryType;
  industryCode: string;
  municipality: Municipality;
  sidestreams: Array<Sidestream>;
  allowedSidestreamTypes: Array<SidestreamTypeEnum>;
  contactPersonId: string;
};

export type UpdateGeneralOrganizationFormData = {
  organization: {
    name: string;
    orgId: string;
    location: string;
    mainProductSelected: ISelectOption;
    municipality: ISelectOption;
    industryType: TIndustryType;
    industryCode: string;
    contactPersonId: string;
  };
  user: {
    id: string;
    fullName: string;
    email: string;
    phone: string;
  };
};

export type GetOrganizationsQueryResult = {
  organizations: Array<Organization>;
};

export type CreateOrganizationFormData = {
  name: string;
  orgId: string;
  location: string;
  municipality: ISelectOption;
  mainProduct: ISelectOption;
  industryType: TIndustryType;
};

export type UpdateOrganizationFormData = {
  name: string;
  orgId: string;
  location: string;
  municipality: ISelectOption;
  mainProduct: ISelectOption;
  industryType: TIndustryType;
  contactPerson: ISelectOption;
};

export type UpdateOrganizationSidestreamTypeData = {
  id: string;
  sidestreamTypes: Array<SidestreamTypeEnum>;
};

export type GetUsersQueryResult = {
  users: Array<IUser>;
};

export type AddOrganizationUserAccessFormData = {
  user: { label: string; value: string };
};

export type CodeFormData = {
  codeId: { label: string; value: string };
};

export interface ISelectOption {
  label: string;
  value: string | number | boolean;
}

export type CreateUserFormData = {
  fullName: string;
  phone: string;
  email: string;
};

export type Sidestream = {
  id: string;
  name: string;
  isInactive: boolean;
  type: SidestreamTypeEnum;
  latestSchema: SidestreamSchema;
};

export type SidestreamSchema = {
  id: string;
  delivered: boolean;
  updatedAt: Date;
  clonedFromSchemaId: string;
};

export type OrganizationSidestreamType = {
  id: string;
  organizationId: string;
  sidestreamType: SidestreamType;
};

export type SidestreamType = {
  id: string;
  type: SidestreamTypeEnum;
};

export enum SidestreamTypeEnum {
  WOOD = "WOOD",
  ENERGY = "ENERGY",
  MATERIALSTREAM = "MATERIALSTREAM",
}

export type TSidestreamType = "WOOD" | "ENERGY" | "MATERIALSTREAM";

export type GetSidestreamsQueryResult = {
  sidestreams: Array<Sidestream>;
};

export type GetOrganizationInfoQueryResult = {
  organization: Organization;
};

export type CreateSidestreamFormData = {
  name: string;
  type: SidestreamTypeEnum;
};

export type UpdateSidestreamFormData = {
  name: string;
  isInactive: boolean;
};

export enum AttachmentTypeEnum {
  ProcessFlowDiagram = "PROCESS_FLOW_DIAGRAM",
  PotentialUseDocuments = "POTENTIAL_USE_DOCUMENTS",
}

export type CreateNewSchemasFormData = {
  organizationIds: Array<ISelectOption>;
  sidestreamType: SidestreamTypeEnum;
};

export enum ShareschemaApprovalEnum {
  MinimumSharing = "MINIMUM_SHARING",
  ExtendedSharing = "EXTENDED_SHARING",
  PublicSharing = "PUBLIC_SHARING",
}

export type MainProductSelected = {
  id: string;
  name: string;
};

export type Municipality = {
  id: string;
  name: string;
};

export type GetMunicipalitiesQueryResult = {
  municipalities: Array<Municipality>;
};

export type AggregatedSchema = {
  type: SidestreamTypeEnum;
  issuedBy: string;
  issuedAt: Date;
  affectedOrganizations: Array<string>;
};

export type GetAggregatedSchemasQueryResult = {
  aggregatedSchemas: Array<AggregatedSchema>;
};

export type ContactPerson = {
  id: string;
  name: string;
}
