import { gql } from "urql";

export const getAboutMaterialStream = gql`
  query ($id: ID) {
    materialStreamAbout(filter: { id: $id }) {
      __typename
      givenName
      materialType
      eALCodeId
      eALCode {
        id
        name
        codeId
      }
      wasteCodeId
      wasteCode {
        id
        name
        codeId
      }
      sideStreamOrigin
      yearlyProduction
      productionVolumeUnit
      productionPeriod
      productionBatch
      productionVolumeBatch
      materialState
      materialStateComment
      organic
      organicComment
      id
    }
  }
`;
