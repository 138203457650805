import React from "react";
import styled from "styled-components";
import { OperationContext, useMutation } from "urql";
import Button, { ButtonSize, ButtonType } from "../../../components/Button";
import Label from "../../../components/Label";
import { assignUserRole } from "../../../graphql/mutations/User/assignUserRole";
import { userIsAdmin } from "../../../helpers/userHelpers";
import { useAuth } from "../../../hooks/useAuth";
import { handleMutationError } from "../../../lib/toaster";
import { IUser } from "../../../types/domain/user";
import { IMutationResult } from "../../../types/mutations/mutationResult";

const ItemsContainer = styled.ul`
  list-style: none;
  padding: 0;
`;

const ItemContainer = styled.li`
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding-bottom: 10px;
  margin-top: 10px;

  :first-child {
    margin-top: 0px;
  }

  :last-child {
    padding-bottom: 0;
  }

  :not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
`;

const ItemContent = styled.div`
  display: flex;
  align-items: center;
`;

const ItemTitle = styled.span`
  font-weight: 500;
  margin-right: 10px;
`;

const ItemSecondary = styled.small`
  margin-right: 10px;
`;

const ActionsContainer = styled.div`
  width: 100%;
  text-align: right;
`;

type Props = {
  users: Array<IUser>;
  refetchUsersQuery: (opts?: Partial<OperationContext> | undefined) => void;
};

const UserList: React.FC<Props> = (props) => {
  const { isAdmin, currentUser } = useAuth();
  const [, executeMutation] = useMutation<IMutationResult>(assignUserRole);

  const onAssignUserAdminRole = async (userId: string) => {
    const assignConfirmed = window.confirm(`Er du sikker?`);

    if (assignConfirmed) {
      await executeMutation({
        userId: userId,
      })
        .then(({ data }) => {
          if (data?.assignUserRole.success) {
            props.refetchUsersQuery();
          } else {
            handleMutationError();
          }
        })
        .catch(() => handleMutationError());
    }
  };

  return (
    <ItemsContainer>
      {props.users.map((user) => {
        const name = user.fullName ? user.fullName : "(Ingen navn)";
        const email = user.email ? user.email : "(Ingen e-post)";
        const toggleAdminLabel = userIsAdmin(user) ? "Fjern administrator" : "Sett som administrator";

        return (
          <ItemContainer key={user.id}>
            <ItemContent>
              <ItemTitle>{name}</ItemTitle>

              <ItemSecondary>{email}</ItemSecondary>

              {user.roles.map((role) => (
                <Label key={role}>{role}</Label>
              ))}
            </ItemContent>

            <ItemContent>
              {isAdmin() && user.id !== currentUser?.id && (
                <ActionsContainer>
                  <Button
                    buttonType={ButtonType.Secondary}
                    size={ButtonSize.Medium}
                    onClick={() => {
                      onAssignUserAdminRole(user.id);
                    }}
                  >
                    {toggleAdminLabel}
                  </Button>
                </ActionsContainer>
              )}
            </ItemContent>
          </ItemContainer>
        );
      })}
    </ItemsContainer>
  );
};

export default UserList;
