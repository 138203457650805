import React from "react";
import styled from "styled-components";

const BottomAreaContainer = styled.div`
  grid-column: 1/-1;

  display: grid;
  grid-template-rows: 1fr 2fr;
`;

const BottomLine = styled.hr`
  grid-row: 1/2;
  grid-column: 1/-1;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.textDecorationColor};
  margin-bottom: 0.937rem;
`;

const ButtonArea = styled.div`
  grid-row: 2/3;
  display: flex;
  flex-direction: row;
  justify-self: end;
  gap: ${(props) => props.theme.gap.extraLight};
`;

const Bottom: React.FC = (props) => {
  return (
    <BottomAreaContainer>
      <BottomLine />
      <ButtonArea data-testid="bottomttest">{props.children}</ButtonArea>
    </BottomAreaContainer>
  );
};

export default Bottom;
