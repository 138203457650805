import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useMutation } from "urql";
import Button, { ButtonSize, ButtonType } from "../../components/Button";
import ContentBox from "../../components/ContentBox";
import ErrorContainer from "../../components/ErrorContainer";
import FormFieldError from "../../components/FormFieldError";
import FormLabel from "../../components/FormLabel";
import Logo from "../../components/Logo";
import { signInMutation } from "../../graphql/mutations/General/signIn";
import { useAuth } from "../../hooks/useAuth";
import { getAuthenticatedUserFromInfoCookie } from "../../lib/authenticationService";
import { ROUTES } from "../../lib/Router/routes";
import { handleMutationError } from "../../lib/toaster";
import { signInSchema } from "../../schemas";
import { SignInFormData } from "../../types";
import { IMutationResult } from "../../types/mutations/mutationResult";

const LogoContainer = styled.div`
  text-align: center;
`;

const LoginTitle = styled.div`
  font-size: ${(props) => props.theme.fontSizes.large};
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  text-align: center;
  margin-bottom: 20px;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1em;
`;

const ButtonArea = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface LocationState {
  from: {
    pathname: string;
  };
}

const Login: React.FC = (props) => {
  const [result, executeMutation] = useMutation<IMutationResult>(signInMutation);
  const { signIn } = useAuth();
  const location = useLocation<LocationState>();
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm<SignInFormData>({
    resolver: yupResolver(signInSchema),
  });

  const { from } = location.state || {
    from: { pathname: ROUTES.AUTHORIZED.DASHBOARD_ROUTE },
  };

  const onSubmit = handleSubmit(async (formValues) => {
    executeMutation({ email: formValues.email, password: formValues.password })
      .then(({ data }) => {
        if (data?.signIn.success) {
          const user = getAuthenticatedUserFromInfoCookie();

          if (user) {
            signIn(user);
            history.push(from);
          }
        } else {
          handleMutationError();
        }
      })
      .catch(() => handleMutationError());
  });

  return (
    <div>
      <LoginTitle>Logg inn</LoginTitle>

      <ContentBox>
        <LogoContainer>
          <Logo />
        </LogoContainer>

        <form onSubmit={onSubmit} noValidate>
          <InputsContainer>
            <FormLabel>E-post</FormLabel>

            <input type="email" name="email" ref={register} placeholder="Din e-post" autoComplete="off" />

            <FormFieldError error={errors.email}></FormFieldError>

            <FormLabel>Passord</FormLabel>

            <input type="password" name="password" ref={register} placeholder="Ditt passord" autoComplete="off" />
          </InputsContainer>

          {result.error && <ErrorContainer>Innlogging feilet. Sjekk om brukernavn eller passord er riktig</ErrorContainer>}

          <ButtonArea>
            <Button type="submit" disabled={result.fetching} size={ButtonSize.Large} buttonType={ButtonType.Primary}>
              {result.fetching ? "Logger inn..." : "Logg inn"}
            </Button>

            <Link to={ROUTES.PUBLIC.GENERATE_RESET_PASSWORD_ROUTE}>Glemt passord?</Link>
          </ButtonArea>
        </form>

        <div>Dersom du har utfordringer med å logge inn, så kan du kontakte stine@eydecluster.no for å få oppdatert login-informasjon</div>
      </ContentBox>
    </div>
  );
};

export default Login;
