import { gql } from "urql";

export const createOrganization = gql`
  mutation($orgId: String!, $name: String!, $mainProductId: ID, $industryType: IndustryTypeEnum, $location: String, $municipalityId: ID) {
    createOrganization(
      input: {
        orgId: $orgId
        name: $name
        mainProductId: $mainProductId
        industryType: $industryType
        location: $location
        municipalityId: $municipalityId
      }
    ) {
      __typename
      id
      success
    }
  }
`;
