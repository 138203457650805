import { gql } from "urql";
export const getOrganization = gql`
  query($id: ID) {
    materialStreamGeneral(filter: { id: $id }) {
      organizationId
      sidestreamName
      __typename
      id
    }
  }
`;
