import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useMutation, useQuery } from "urql";
import Button, { ButtonSize, ButtonType } from "../../components/Button";
import ContentBox from "../../components/ContentBox";
import ErrorContainer from "../../components/ErrorContainer";
import Spinner from "../../components/Loading";
import Heading from "../../components/Heading";
import { GetOrganizationInfoQueryResult, Param, SidestreamTypeEnum } from "../../types";
import { IMutationResult } from "../../types/mutations/mutationResult";
import { handleMutationError, handleMutationSuccess } from "../../lib/toaster";
import ErrorMessage from "../../components/ErrorMessage";
import { getOrganizationInfo } from "../../graphql/queries/General/getOrganizationInfo";
import { SidestreamType } from "../../dictionaries";
import { updateOrganizationSidestreamType } from "../../graphql/mutations/Organization/updateOrganizationSidestreamType";
import { UpdateOrganizationSidestreamTypeData } from "../../types";

const ButtonArea = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CheckboxQuestion = styled.label``;
const CheckboxInput = styled.input`
  margin-right: 1rem;
`;

const UserInputCheckbox = styled.div`
  margin-bottom: 0.5rem;
`;

const OrganizationSidestreamType: React.FC = () => {
  const [addOrganizationSidestreamTypeResult, executeAddOrganizationSidestreamTypeMutation] =
    useMutation<IMutationResult>(updateOrganizationSidestreamType);

  const { id: organizationId } = useParams<Param>();
  //const [isWaitingForData, setIsWaitingForData] = React.useState<boolean>(false);

  const [{ data: organization, fetching: organizationFetching, error: organizationError }] = useQuery<GetOrganizationInfoQueryResult>({
    query: getOrganizationInfo,
    variables: { organizationId: organizationId },
  });

  const { handleSubmit, register } = useForm<UpdateOrganizationSidestreamTypeData>();

  const onSubmit = handleSubmit(async (formValues) => {
    let sidestreamTypes: string[] = [];
    if (formValues["WOOD"] === true) sidestreamTypes.push("WOOD");
    if (formValues["MATERIALSTREAM"] === true) sidestreamTypes.push("MATERIALSTREAM");
    if (formValues["ENERGY"] === true) sidestreamTypes.push("ENERGY");

    executeAddOrganizationSidestreamTypeMutation({
      id: organization?.organization.id,
      sidestreamTypes: sidestreamTypes,
    })
      .then(({ data }) => {
        if (data?.updateOrganizationSidestreamType.success) {
          handleMutationSuccess(data.updateOrganizationSidestreamType);
        } else {
          handleMutationError();
        }
      })
      .catch(() => handleMutationError());
  });

  if (organizationFetching) return <Spinner />;
  if (organizationError) return <ErrorMessage />;

  return (
    <ContentBox>
      <form onSubmit={onSubmit} noValidate>
        <Heading>Legg til sidestrøm for {organization?.organization.name}</Heading>

        {Object.entries(SidestreamTypeEnum).map(([key, value], index) => {
          return (
            <UserInputCheckbox key={key}>
              <CheckboxQuestion>
                <CheckboxInput
                  name={key}
                  ref={register}
                  type="checkbox"
                  defaultChecked={organization?.organization.allowedSidestreamTypes.some((item) => item === key)}
                />
                {SidestreamType[key]}
              </CheckboxQuestion>
            </UserInputCheckbox>
          );
        })}

        {addOrganizationSidestreamTypeResult.error && <ErrorContainer>Kunne ikke legge inn tilgang.</ErrorContainer>}

        <ButtonArea>
          <Button type="submit" size={ButtonSize.Large} buttonType={ButtonType.Primary}>
            Legg til
          </Button>
        </ButtonArea>
      </form>
    </ContentBox>
  );
};

export default OrganizationSidestreamType;
