import React from "react";
import { Control } from "react-hook-form/dist/types";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useQuery } from "urql";
import { getMunicipalities } from "../../graphql/queries/Municipality/getMunicipalities";
import { mapToSelectOption } from "../../utils/form-utils";
import Spinner from "../../components/Loading";
import { GetMunicipalitiesQueryResult } from "../../types";

interface MunicipalitySelectProps {
  fieldName: string;
  errors?: any;
  control: Control<any>;
  register: any;
  required?: boolean;
}

const MunicipalitySelect: React.FunctionComponent<MunicipalitySelectProps> = (props) => {
  const [{ data, fetching, error }] = useQuery<GetMunicipalitiesQueryResult>({
    query: getMunicipalities,
  });
  const municipalities = !data || !data.municipalities || !data.municipalities.length ? [] : data.municipalities;
  const municipalitiesList = municipalities.map((municipality) => mapToSelectOption(municipality.id, municipality.name));

  return (
    <>
      {fetching || !data ? (
        <Spinner />
      ) : (
        <Controller
          control={props.control}
          name={props.fieldName}
          defaultValue=""
          options={municipalitiesList}
          render={(props) => (
            <Select
              onChange={(e) => props.onChange(e)}
              placeholder="Vennligst velg"
              value={props.value}
              options={municipalitiesList}
              menuPortalTarget={document.body}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          )}
        ></Controller>
      )}

      {error && <div>Feil: Kunne ikke hente kommuner</div>}
    </>
  );
};

export default MunicipalitySelect;
