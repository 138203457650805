import styled from "styled-components";

const SidestreamNameContainer = styled.div`
  font-size: ${(props) => props.theme.fontSizes.small};
  text-align: right;
  margin: 1rem;
`;

type SidestreamNameProps = {
  name: string;
};

const SidestreamName: React.FC<SidestreamNameProps> = (props) => {
  return (
    <SidestreamNameContainer>
      Sidestrøm: <b>{props.name}</b>
    </SidestreamNameContainer>
  );
};

export default SidestreamName;
