import logo from "./AGDER_logo_sort.png";

const WoodSchemaLogo: React.FC = () => {
  return (
    <>
      <img src={logo} alt="logo" width="150" />
    </>
  );
};

export default WoodSchemaLogo;
