import { gql } from "urql";

export const signInMutation = gql`
  mutation ($email: String!, $password: String!) {
    signIn(input: { email: $email, password: $password }) {
      __typename
      success
      id
    }
  }
`;
