import styled from "styled-components";

type QuestionGroupContainerProps = {
  title: string;
};

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.middle};
  border-radius: ${(props) => props.theme.borderRadius.light};
  padding: ${(props) => props.theme.padding.light};
  width: 100%;
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 0.6em;
`;

const QuestionGroupContainer: React.FC<QuestionGroupContainerProps> = (
  props
) => {
  return (
    <Container>
      <Title>{props.title}</Title>
      {props.children}
    </Container>
  );
};

export default QuestionGroupContainer;
