import React from "react";
import { FiUsers, FiEdit } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import Button, { ButtonSize, ButtonType } from "../../../components/Button";
import { IndustryType, SidestreamType } from "../../../dictionaries";
import { useAuth } from "../../../hooks/useAuth";
import { GET_ORGANIZATION_ACCESS_ROUTE, GET_ORGANIZATION_SIDESTREAMTYPE_ROUTE, GET_ORGANIZATION_ROUTE } from "../../../lib/Router/routes";
import { Organization } from "../../../types";

const ItemsContainer = styled.ul`
  list-style: none;
  padding: 0;
`;

const ItemsHeaderContainer = styled(ItemsContainer)`
  margin-bottom: 20px !important;
  border-bottom: 2px solid #ccc;
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  font-weight: 600;
`;

const ItemContainer = styled.li`
  display: grid;
  grid-template-columns: 2fr 1.5fr 1.5fr 1fr 1fr;
  padding-bottom: 10px;
  margin-top: 10px;

  :first-child {
    margin-top: 0px;
  }

  :last-child {
    padding-bottom: 0;
  }

  :not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
`;

const ItemContent = styled.div`
  display: flex;
  align-items: center;
`;

const ItemContentList = styled.ul`
  list-style-type: none;
`;

const ItemTitle = styled.span`
  font-weight: 500;
`;

const ActionsContainer = styled.div`
  width: 100%;
  text-align: right;
`;

type Props = {
  organizations: Array<Organization>;
};

const OrganizationList: React.FC<Props> = (props) => {
  const history = useHistory();
  const { isAdmin } = useAuth();

  return (
    <>
      <ItemsHeaderContainer>
        <ItemContainer>
          <ItemContent>Bedrift</ItemContent>
          <ItemContent>Industri</ItemContent>
          <ItemContent>Hovedprodukt</ItemContent>
          <ItemContent>Type sidestrømmer</ItemContent>
          <ItemContent></ItemContent>
        </ItemContainer>
      </ItemsHeaderContainer>

      <ItemsContainer>
        {props.organizations.map((organization) => {
          const name = organization.name ? organization.name : "Ingen navn";

          return (
            <ItemContainer key={organization.id}>
              <ItemContent>
                <Link to={GET_ORGANIZATION_ROUTE(organization.id)}>
                  <ItemTitle>{name}</ItemTitle>
                </Link>
              </ItemContent>

              <ItemContent>{IndustryType[organization.industryType]}</ItemContent>

              <ItemContent>{organization.mainProductSelected?.name}</ItemContent>

              <ItemContent>
                <ItemContentList>
                  {organization.allowedSidestreamTypes?.map((sidestreamType) => {
                    return <li key={sidestreamType}>{SidestreamType[sidestreamType]} </li>;
                  })}
                </ItemContentList>

                {isAdmin() && (
                  <ActionsContainer>
                    <Button
                      buttonType={ButtonType.Secondary}
                      size={ButtonSize.Medium}
                      onClick={() => {
                        history.push(GET_ORGANIZATION_SIDESTREAMTYPE_ROUTE(organization.id));
                      }}
                    >
                      <FiEdit />
                    </Button>
                  </ActionsContainer>
                )}
              </ItemContent>

              <ItemContent>
                {isAdmin() && (
                  <ActionsContainer>
                    <Button
                      buttonType={ButtonType.Secondary}
                      size={ButtonSize.Medium}
                      onClick={() => {
                        history.push(GET_ORGANIZATION_ACCESS_ROUTE(organization.id));
                      }}
                    >
                      <FiUsers /> Tilganger
                    </Button>
                  </ActionsContainer>
                )}
              </ItemContent>
            </ItemContainer>
          );
        })}
      </ItemsContainer>
    </>
  );
};

export default OrganizationList;
