import { gql } from "urql";

export const createUser = gql`
  mutation($email: String!, $fullName: String!, $phone: String) {
    createUser(input: { email: $email, fullName: $fullName, phone: $phone }) {
      __typename
      id
      success
    }
  }
`;
