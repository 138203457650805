import React from "react";
import styled from "styled-components";
import Steps from "../../components/Steps";
import Circle from "../../components/Circle";
import Navigation from "../../components/Navigation";
import { StepHandling, Navigation as NavigationType, Param } from "../../types";
import { NavigationContext } from "../../providers/NavigationProvider";
//import WoodIntroduction from "./WoodIntroduction";
import AboutWood from "./AboutWood";
import WoodUtilization from "./WoodUtilization";
import AboutWoodMaterial from "./AboutWoodMaterial";
import Summary from "./Summary";
import { useParams } from "react-router-dom";
import { getOrganizationId } from "../../graphql/queries/Wood/getOrganizationId";
import { useQuery } from "urql";
import General from "../../components/General";
import DeliveredSchema from "../../components/DeliveredSchema";
import { getGeneralInfoConfirmed } from "../../generalInfoConfirmed";
import ErrorMessage from "../../components/ErrorMessage";
import { StepsArea, StepsOverview, StepsContainer, StepsCircle, StepsName, StepsButtons } from "../../components/Steps/StepsLayout";
import SidestreamName from "../../components/SidestreamName";
import SaveText from "../../components/SaveText";

const ButtonContainer = styled.div`
  grid-column: 1/3;
  display: grid;
  grid-template-rows: auto 1fr;
  justify-content: end;
  margin-top: -4.4rem;
  min-width: 1000px;
`;

const Wood: React.FC = () => {
  const generalInfoIsConfirmed = getGeneralInfoConfirmed() ? true : false;
  const [navigation, setNavigation] = React.useState<StepHandling>({
    step: generalInfoIsConfirmed ? 2 : 1,
    amountOfSteps: 6,
  });
  const { id } = useParams<Param>();
  const [organizationResult, reExecuteQuery] = useQuery({
    query: getOrganizationId,
    variables: { id: id },
  });
  const { data, fetching, error } = organizationResult;
  const handleNav = (next: boolean) => {
    if (next) {
      if (navigation.step < navigation.amountOfSteps) {
        setNavigation({ ...navigation, step: navigation.step + 1 });
      }
    } else {
      if (navigation.step > 1) {
        setNavigation({ ...navigation, step: navigation.step - 1 });
      }
    }
  };

  const handleNavigation: NavigationType = {
    navigation: navigation,
    handleNav: handleNav,
  };
  React.useEffect(() => {
    if (navigation.step === 1) {
      reExecuteQuery();
    }
  }, [navigation.step, reExecuteQuery]);
  return (
    <>
      {navigation.step >= 1 && (
        <Steps>
          <StepsArea>
            <StepsOverview>
              <StepsContainer>
                {/* <StepsCircle className="first">
                  <Circle active={navigation.step === 1 ? true : false} finished={navigation.step > 1 ? true : false}>
                    1
                  </Circle>
                </StepsCircle>

                <StepsName>Intro</StepsName> */}
                <StepsCircle className="first">
                  <Circle active={navigation.step === 2 ? true : false} finished={navigation.step > 2 ? true : false}>
                    1
                  </Circle>
                </StepsCircle>
                <StepsName>Om sidestrømmen</StepsName>
                <StepsCircle>
                  <Circle active={navigation.step === 3 ? true : false} finished={navigation.step > 3 ? true : false}>
                    2
                  </Circle>
                </StepsCircle>
                <StepsName>Utnyttelse</StepsName>
                <StepsCircle>
                  <Circle active={navigation.step === 4 ? true : false} finished={navigation.step > 4 ? true : false}>
                    3
                  </Circle>
                </StepsCircle>
                <StepsName>Om materialet</StepsName>

                <StepsCircle className="last">
                  <Circle active={navigation.step === 5 ? true : false} finished={navigation.step > 5 ? true : false}>
                    4
                  </Circle>
                </StepsCircle>

                <StepsName>Sammendrag</StepsName>
              </StepsContainer>
              <ButtonContainer>
                <StepsButtons>
                  {navigation.step < navigation.amountOfSteps && (
                    <Navigation
                      navigation={navigation}
                      handleNext={() => {
                        let element = document.getElementsByClassName("next-button")[1] as HTMLElement;
                        element?.click();
                      }}
                      handlePrev={() => {
                        let element = document.getElementsByClassName("prev-button")[1] as HTMLElement;
                        element.click();
                      }}
                    />
                  )}
                </StepsButtons>
              </ButtonContainer>
            </StepsOverview>
          </StepsArea>
        </Steps>
      )}
      {navigation.step > 1 && <SidestreamName name={data?.woodSchema.sidestreamName} />}
      <NavigationContext.Provider value={handleNavigation}>
        {error ? (
          <ErrorMessage />
        ) : (
          navigation.step === 1 && !fetching && <General data={data} orgId={data.woodSchema.organizationId} schemaId={id} />
        )}
        {/* {navigation.step === 2 && <WoodIntroduction />} */}
        {navigation.step === 2 && <AboutWood />}
        {navigation.step === 3 && <WoodUtilization />}
        {navigation.step === 4 && <AboutWoodMaterial />}
        {navigation.step === 5 && <Summary />}
        {navigation.step === 6 && <DeliveredSchema woodSchema="true" />}
      </NavigationContext.Provider>
      <SaveText />
    </>
  );
};

export default Wood;
