import { gql } from "urql";

export const createMaterialStreamAttachment = gql`
  mutation(
    $attachementType: AttachementTypeEnum
    $materialStreamSchemaId: ID
    $fileName: String
    $fileContentType: String
    $fileAsBase64: String
  ) {
    createAttachment(
      input: {
        attachementType: $attachementType
        materialStreamSchemaId: $materialStreamSchemaId
        fileName: $fileName
        fileContentType: $fileContentType
        fileAsBase64: $fileAsBase64
      }
    ) {
      __typename
      id
      success
    }
  }
`;
