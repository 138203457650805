import { gql } from "urql";
export const getEnergyTypes = gql`
  query($energySchemaId: ID!) {
    energyTypes(filter: { energySchemaId: $energySchemaId }) {
      __typename
      additionalInformation
      energyTypeName
      energyUsage
      energySchemaId
      id
    }
  }
`;
