import { gql } from "urql";

export const getWoodUtilization = gql`
  query($id: ID) {
    woodSchema(filter: { id: $id }) {
      __typename
      id
      internalEnergyRecycling
      externalEnergyRecycling
      internalMaterialRecycling
      externalMaterialRecycling
      reuse
      reuseFurniture
      reuseBuildingMaterials
      previousInternalAndExternalUsage
      previousInternalUsage
      previousExternalUsage
      previousInternalAndExternalUsageComment
      internalAndExternalActivitesForMaterialUsage
      internalActivitesForMaterialUsage
      externalActivitesForMaterialUsage
      internalAndExternalActivitesForMaterialUsageComment
      internalAndExternalGreaterPotential
      internalGreaterPotential
      externalGreaterPotential
      internalAndExternalGreaterPotentialComment
      links {
        id
        url
      }
      resourcePersons {
        id
        fullName
        organizationName
      }
      otherComments
    }
  }
`;
