import { toast } from "react-toastify";
import { MutationResult } from "../types/mutations/mutationResult";

const ENVIRONMENT_SHOW_LOGGING = false; // For debugging purposes

/**
 * Displays a generic success toast unless message is supplied. When debugging is enabled it also logs to the console
 * @param data
 * @param message
 */

export const handleMutationSuccess = (data?: MutationResult, message?: string) => {
  const msg = message ? message : "Suksess";

  if (ENVIRONMENT_SHOW_LOGGING) {
    data ? console.info(message, data) : console.info(message);
  }

  toast.success(msg);
};

/**
 * Displays a generic error toast unless message is supplied. When debugging is enabled it also logs to the console
 * @param data
 * @param message
 */

export const handleMutationError = (error?: any, message?: string) => {
  const msg = message ? message : "En feil har oppstått";

  if (ENVIRONMENT_SHOW_LOGGING) {
    error ? console.error(message, error) : console.error(message);
  }

  toast.error(msg);
};
