import { gql } from "urql";

export const getOrganizations = gql`
  query {
    organizations {
      name
      orgId
      location
      mainProduct
      mainProductSelected {
        id
        name
      }
      municipality {
        id
        name
      }
      industryType
      industryCode
      id
      allowedSidestreamTypes
      __typename
    }
  }
`;

export const getOrganizationsList = gql`
  query {
    organizations {
      id
      name
      sidestreams {
        id
        name
        type
      }
      __typename
    }
  }
`;
