import React from "react";
import { FieldError } from "react-hook-form/dist/types";
import ErrorContainer from "../ErrorContainer";

interface FormFieldErrorProps {
  error?: FieldError;
}

const FormFieldError: React.FunctionComponent<FormFieldErrorProps> = (props) => {
  if (props.error) {
    return <ErrorContainer>{props.children ? props.children : props.error.message}</ErrorContainer>;
  }

  return null;
};

export default FormFieldError;
