import { gql } from "urql";

export const getSSentences = gql`
  query {
    sSentence {
      id
      sentenceId
      description
      __typename
    }
  }
`;
