import { gql } from "urql";

export const createOrUpdateChemicalAnalysis = gql`
  mutation(
    $materialStreamSchemaId: ID!
    $chemicalAnalysisId: ID
    $chemicalAnalysisName: String
    $chemicalUnit: ChemicalUnitEnum!
    $typicalValue: Float
    $minValue: Float
    $maxValue: Float
    $shareChemicalAnalysisApproval: Boolean
  ) {
    createOrUpdateChemicalAnalysis(
      input: {
        materialStreamSchemaId: $materialStreamSchemaId
        chemicalAnalysisId: $chemicalAnalysisId
        chemicalAnalysisName: $chemicalAnalysisName
        chemicalUnit: $chemicalUnit
        typicalValue: $typicalValue
        minValue: $minValue
        maxValue: $maxValue
        shareChemicalAnalysisApproval: $shareChemicalAnalysisApproval
      }
    ) {
      __typename
      id
      success
    }
  }
`;
