import { gql } from "urql";

export const getHSentences = gql`
  query {
    hSentence {
      id
      sentenceId
      description
      __typename
    }
  }
`;
