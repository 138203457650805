import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Button, { ButtonSize, ButtonType } from "../../Button";
import { ROUTES } from "../../../lib/Router/routes";

const ButtonContainer = styled.div`
  text-align: center;
`;

const ReturnToSchemaNavigation: React.FC = (props) => {
  const history = useHistory();
  const handleNavigation = () => {
    history.push(ROUTES.AUTHORIZED.DASHBOARD_ROUTE);
  };

  return (
    <>
      <ButtonContainer>
        <Button className="next-button" onClick={handleNavigation} size={ButtonSize.Medium} buttonType={ButtonType.Primary}>
          Tilbake til min oversikt
        </Button>
      </ButtonContainer>
    </>
  );
};

export default ReturnToSchemaNavigation;
