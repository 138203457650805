import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useMutation, useQuery } from "urql";
import Bottom from "../../../components/Bottom";
import Spinner from "../../../components/Loading";
import Navigation from "../../../components/Navigation";
import { getSideStreamSummary } from "../../../graphql/queries/Energyflow/getSideStreamSummary";
import { updateEnergySchema } from "../../../graphql/mutations/Energyflow/updateEnergySchema";
import { EnergyType, NonUtilizedWasteHeatType, Param, StepHandling, UtilizedWasteHeat } from "../../../types";
import SummaryBoilerplate from "../../../components/SummaryBoilerplate";
import { EnergyUsageNames, UtilizedWasteHeatNames, WasteSource } from "../../../dictionaries";
import { IMutationResult } from "../../../types/mutations/mutationResult";
import { handleMutationError } from "../../../lib/toaster";

const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.gap.medium};
`;
const LoadingContainer = styled.div`
  display: flex;
  place-content: center;
`;

const Box = styled.div`
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.middle};
  border-radius: ${(props) => props.theme.borderRadius.light};
  width: 95%;
  padding: ${(props) => props.theme.padding.medium};
`;
const GridBox = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 2fr) 1fr repeat(2, 2fr);
  grid-template-rows: 1fr 1fr auto;
`;
const BoxTitle = styled.div`
  text-align: start;
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  font-weight: 500;
`;
const LineContainer = styled.div`
  grid-column: 1/-1;
  grid-row: 2/3;
`;
const HeaderLine = styled.hr`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.textDecorationColor};
`;
const Headers = styled.div`
  font-weight: 500;
  grid-row: 1/2;
`;
const FirstHeader = styled(Headers)`
  grid-column: 1/2;
`;
const SecondHeader = styled(Headers)`
  grid-column: 2/3;
`;
const ThirdHeader = styled(Headers)`
  grid-column: 3/4;
  place-self: end;
`;
const FourthHeader = styled(Headers)`
  grid-column: 4/5;
  place-self: end;
`;

const FifthHeader = styled(Headers)`
  grid-column: 5/6;
  place-self: end;
`;
const SpecialHeaderContainer = styled(Headers)`
  grid-column: 2/4;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
const SpecialHeader = styled.div`
  grid-column: 2/3;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const FirstData = styled(BodyContainer)`
  grid-column: 1/2;
  grid-row: 3/4;
`;

const FourthData = styled(BodyContainer)`
  grid-column: 4/5;
  grid-row: 3/4;
  text-align: end;
`;

const SpecialDataContainer = styled(BodyContainer)`
  grid-column: 2/4;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
const SpecialData = styled.div`
  grid-column: 2/3;
`;

const NonUtilizedDataContainer = styled.div`
  grid-row: 3/4;
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: repeat(2, 2fr) 1fr repeat(2, 2fr);
`;

const NonUtilizedFirstData = styled.div`
  grid-column: 1/2;
`;
const NonUtilizedSecondData = styled.div`
  grid-column: 2/3;
`;
const NonUtilizedThirdData = styled.div`
  grid-column: 3/4;
  justify-self: end;
`;
const NonUtilizedFourthtData = styled.div`
  grid-column: 4/5;
  justify-self: end;
`;
const NonUtilizedFifthData = styled.div`
  grid-column: 5/6;
  justify-self: end;
`;

type SummaryProps = {
  navigation: StepHandling;
  handleNav: any;
};
const Summary: React.FC<SummaryProps> = (props) => {
  const { id } = useParams<Param>();
  const [summaryResult] = useQuery({
    query: getSideStreamSummary,
    variables: { energySchemaId: id },
  });
  const { data, fetching } = summaryResult;
  const [, setSchemaDeliveredMutate] = useMutation<IMutationResult>(updateEnergySchema);

  const onSubmit = async () => {
    await setSchemaDeliveredMutate({ id: id, schemaIsDelivered: true }).catch(() => handleMutationError());
    props.handleNav(true);
  };
  return (
    <Container>
      <SummaryBoilerplate />

      {!fetching ? (
        <>
          {" "}
          <BodyContainer>
            <BoxTitle>Energibruk</BoxTitle>
            <GridBox>
              <FirstHeader>Energiform</FirstHeader>
              <FourthHeader>GWh/år</FourthHeader>
              <LineContainer>
                <HeaderLine />
              </LineContainer>{" "}
              <FirstData>
                {data &&
                  data.energyTypes.map((entry: EnergyType) => {
                    return <div key={entry.id!}>{EnergyUsageNames[entry.energyTypeName]}</div>;
                  })}
              </FirstData>
              <FourthData>
                {data &&
                  data.energyTypes.map((entry: EnergyType) => {
                    return <div key={entry.id!}>{entry.energyUsage}</div>;
                  })}
              </FourthData>
            </GridBox>
          </BodyContainer>
          <BodyContainer>
            <BoxTitle>Spillvarme som utnyttes</BoxTitle>
            <GridBox>
              <FirstHeader>Hva utnyttes spillvarme til?</FirstHeader>
              <SpecialHeaderContainer>
                <SpecialHeader>Elektrisk eller termisk?</SpecialHeader>
              </SpecialHeaderContainer>

              <FourthHeader>GWh/år</FourthHeader>
              <LineContainer>
                <HeaderLine />
              </LineContainer>

              <FirstData>
                {data &&
                  data.utilizedWasteHeats.map((entry: UtilizedWasteHeat) => {
                    return <div key={entry.id}>{entry.utilizedWasteHeatInformation}</div>;
                  })}
              </FirstData>
              <SpecialDataContainer>
                <SpecialData>
                  {data &&
                    data.utilizedWasteHeats.map((entry: UtilizedWasteHeat) => {
                      return <div key={entry.id}>{UtilizedWasteHeatNames[entry.utilizedWasteHeat]}</div>;
                    })}
                </SpecialData>
              </SpecialDataContainer>
              <FourthData>
                {data &&
                  data.utilizedWasteHeats.map((entry: UtilizedWasteHeat) => {
                    return <div key={entry.id}>{entry.utilizedWasteHeatUsage}</div>;
                  })}
              </FourthData>
            </GridBox>
          </BodyContainer>
          <BodyContainer>
            <BoxTitle>Spillvarme som ikke utnyttes</BoxTitle>
            <GridBox>
              <FirstHeader>Navn på spillvarmekilde</FirstHeader>
              <SecondHeader>Type spillvarmekilde</SecondHeader>
              <ThirdHeader>Temperatur ut</ThirdHeader>
              <FourthHeader>Mengde, energi (GWh/år)</FourthHeader>
              <FifthHeader>Gjennomsnittlig effekt (MW)</FifthHeader>
              <LineContainer>
                <HeaderLine />
              </LineContainer>
              <NonUtilizedDataContainer>
                {data &&
                  data.energyWastes.map((entry: NonUtilizedWasteHeatType) => {
                    return (
                      <React.Fragment key={entry.id}>
                        <NonUtilizedFirstData>{entry.energyWasteName} </NonUtilizedFirstData>
                        <NonUtilizedSecondData>{WasteSource[entry.wasteSource]}</NonUtilizedSecondData>
                        <NonUtilizedThirdData>{entry.temperature}</NonUtilizedThirdData>
                        <NonUtilizedFourthtData>{entry.energyAmount}</NonUtilizedFourthtData>
                        <NonUtilizedFifthData>{entry.averageEffect} </NonUtilizedFifthData>
                      </React.Fragment>
                    );
                  })}
              </NonUtilizedDataContainer>
            </GridBox>
          </BodyContainer>{" "}
        </>
      ) : (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      )}

      <Bottom>
        <Navigation navigation={props.navigation} handlePrev={() => props.handleNav(false)} handleNext={onSubmit} />
      </Bottom>
    </Container>
  );
};

export default Summary;
