import { gql } from "urql";

export const getSidestreams = gql`
  query($organizationId: ID) {
    sidestreams(filter: { organizationId: $organizationId }) {
      id
      name
      type
      isInactive
      latestSchema {
        id
        delivered
        updatedAt
        clonedFromSchemaId
      }
    }
  }
`;
