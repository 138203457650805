import React from "react";
import { Control } from "react-hook-form/dist/types";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useQuery } from "urql";
import { getMainProducts } from "../../graphql/queries/MainProduct/getMainProducts";
import { mapToSelectOption } from "../../utils/form-utils";
import Spinner from "../../components/Loading";

interface MainProductSelectProps {
  fieldName: string;
  errors?: any;
  control: Control<any>;
  register: any;
  required?: boolean;
}

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    height: "2.29rem",
    minHeight: "2.29rem",
  }),
  valueContainer: (provided: any, state: any) => {
    const height = "2.29rem";
    return { ...provided, height };
  },
  dropdownIndicator: (provided: any, state: any) => {
    const color = "#000";
    const height = "2";
    const width = "2";
    return { ...provided, color, height, width };
  },
};

const MainProductSelect: React.FunctionComponent<MainProductSelectProps> = (props) => {
  const [mainProductQueryResult] = useQuery({
    query: getMainProducts,
  });
  const { data, fetching } = mainProductQueryResult;
  const mainProducts = !data || !data.mainProducts || !data.mainProducts.length ? [] : data.mainProducts;
  const mainProductsList = mainProducts.map((mainProduct: any) => mapToSelectOption(mainProduct.id, mainProduct.name));

  return (
    <>
      {fetching || !data ? (
        <Spinner />
      ) : (
        <Controller
          control={props.control}
          name={props.fieldName}
          defaultValue=""
          options={mainProductsList}
          render={(props) => (
            <Select
              onChange={(e) => props.onChange(e)}
              placeholder="Vennligst velg"
              value={props.value}
              options={mainProductsList}
              styles={customStyles}
              menuPortalTarget={document.body}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          )}
        ></Controller>
      )}
      {/* {errors?. && <FormError className="error">{errors..message}</FormError>} */}
    </>
  );
};

export default MainProductSelect;
