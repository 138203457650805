import { gql } from "urql";

export const updateOrganization = gql`
  mutation (
    $id: ID!
    $orgId: String
    $name: String!
    $mainProduct: String
    $mainProductId: ID
    $industryType: IndustryTypeEnum
    $industryCode: String
    $location: String
    $municipalityId: ID
    $shareSchemaApproval: ShareschemaApprovalEnum
    $contactPersonId: ID
  ) {
    updateOrganization(
      input: {
        id: $id
        orgId: $orgId
        name: $name
        mainProduct: $mainProduct
        mainProductId: $mainProductId
        industryType: $industryType
        industryCode: $industryCode
        location: $location
        municipalityId: $municipalityId
        shareSchemaApproval: $shareSchemaApproval
        contactPersonId: $contactPersonId
      }
    ) {
      __typename
      id
      success
    }
  }
`;
