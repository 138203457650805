import { gql } from "urql";

export const updateUser = gql`
  mutation($userId: String!, $fullName: String!, $phone: String, $privacyConsent: BoolEnum) {
    updateUser(input: { userId: $userId, fullName: $fullName, phone: $phone, privacyConsent: $privacyConsent }) {
      __typename
      id
      success
    }
  }
`;
