import React from "react";
import styled from "styled-components";
import { useAuth } from "../../hooks/useAuth";
import { IUser } from "../../types/domain/user";
import Button, { ButtonType, ButtonSize } from "../Button";

const ItemsContainer = styled.ul`
  list-style: none;
  padding: 0;
`;

const ItemContainer = styled.li`
  display: grid;
  grid-template-columns: 2fr 2fr;
  padding-bottom: 10px;
  margin-top: 10px;

  :first-child {
    margin-top: 0px;
  }

  :last-child {
    padding-bottom: 0;
  }

  :not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
`;

const ItemContent = styled.div`
  display: flex;
  align-items: center;
`;

const ItemTitle = styled.span`
  font-weight: 500;
  margin-right: 10px;
`;

const ActionsContainer = styled.div`
  width: 100%;
  text-align: right;
`;

type Props = {
  users: Array<IUser>;
  onRemove?: (userId: string) => Promise<void>;
};

const OrganizationUserList: React.FC<Props> = (props) => {
  const { isAdmin } = useAuth();

  return (
    <ItemsContainer>
      {props.users.map((user) => {
        const name = user.fullName ? user.fullName : "Ingen navn";

        return (
          <ItemContainer key={user.id}>
            <ItemContent>
              <ItemTitle>{name}</ItemTitle>
            </ItemContent>

            <ItemContent>
              {isAdmin() && (
                <ActionsContainer>
                  <Button
                    buttonType={ButtonType.Secondary}
                    size={ButtonSize.Medium}
                    onClick={() => {
                      if (props.onRemove !== undefined) {
                        props.onRemove(user.id);
                      }
                    }}
                  >
                    Fjern tilgang
                  </Button>
                </ActionsContainer>
              )}
            </ItemContent>
          </ItemContainer>
        );
      })}
    </ItemsContainer>
  );
};

export default OrganizationUserList;
