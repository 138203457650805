import React from "react";
import styled from "styled-components";
import { useMutation } from "urql";
import { useForm } from "react-hook-form";
import Heading from "../Heading";
import Button, { ButtonSize, ButtonType } from "../Button";
import FormFieldError from "../FormFieldError";
import ErrorContainer from "../ErrorContainer";
import FormLabel from "../FormLabel";
import { createNewSchemasMutation } from "../../graphql/mutations/AdminDashboard/createNewSchemasMutation";
import { handleMutationError, handleMutationSuccess } from "../../lib/toaster";
import { createNewSchemas } from "../../schemas";
import { CreateNewSchemasFormData, SidestreamTypeEnum } from "../../types";
import { IMutationResult } from "../../types/mutations/mutationResult";
import { yupResolver } from "@hookform/resolvers/yup";
import OrganizationSelect from "../OrganizationSelect";

type Props = {
  handleSave: () => void;
  handleCancel: () => void;
};

const ActionsContainer = styled.div`
  text-align: right;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const Lead = styled.div`
  margin-bottom: 1.5rem;
`;

const CreateNewSchemasForm: React.FC<Props> = (props) => {
  const [result, executeMutation] = useMutation<IMutationResult>(createNewSchemasMutation);
  const { register, handleSubmit, control, watch, errors } = useForm<CreateNewSchemasFormData>({
    resolver: yupResolver(createNewSchemas),
  });
  const onSubmit = handleSubmit(({ organizationIds, sidestreamType }) => {
    executeMutation({
      organizationIds: organizationIds.map((organizationId) => organizationId.value),
      sidestreamType: sidestreamType,
    })
      .then(({ data }) => {
        if (data?.createNewSchemas.success) {
          handleMutationSuccess(data.createNewSchemas);
          props.handleSave();
        } else {
          handleMutationError();
        }
      })
      .catch(() => handleMutationError());
  });
  return (
    <form onSubmit={onSubmit} noValidate>
      <Heading>Ny utsendelse</Heading>
      <Lead>Lag en ny utsendelse av skjema for bedrifters sidestrømmer.</Lead>

      <Lead>
        Alle bedrifter som har sidestrømmer av samme type skjema vil dermed få nye skjema til utfylling. Dersom det er blitt levert
        tidligere skjema, vil bedriften få en kopi av denne.
      </Lead>

      <FormLabel>Velg skjema</FormLabel>
      <select name="sidestreamType" ref={register}>
        <option value="">Vennligst velg</option>
        <option value={SidestreamTypeEnum.ENERGY}>Energi</option>
        <option value={SidestreamTypeEnum.MATERIALSTREAM}>Materialstrøm</option>
        <option value={SidestreamTypeEnum.WOOD}>Trevirke</option>
      </select>
      <FormFieldError error={errors.sidestreamType}></FormFieldError>

      <FormLabel>Velg bedrifter</FormLabel>

      <OrganizationSelect
        control={control}
        register={register}
        fieldName="organizationIds"
        sidestreamType={watch("sidestreamType")}
        errors={errors}
      />

      {result.error && <ErrorContainer>Kan ikke opprette skjemaer</ErrorContainer>}

      <ActionsContainer>
        <Button type="button" size={ButtonSize.Large} buttonType={ButtonType.Secondary} onClick={() => props.handleCancel()}>
          Avbryt
        </Button>

        <Button type="submit" disabled={result.fetching} size={ButtonSize.Large} buttonType={ButtonType.Primary}>
          {result.fetching ? "Vennligst vent..." : "Start oppretting"}
        </Button>
      </ActionsContainer>
    </form>
  );
};

export default CreateNewSchemasForm;
