import { gql } from "urql";

export const createOrUpdateUtilizedWasteHeat = gql`
  mutation(
    $utilizedWasteHeatId: ID
    $utilizedWasteHeatInformation: String
    $utilizedWasteHeatEnum: UtilizedWasteHeatEnum
    $utilizedWasteHeatUsage: Float
    $energySchemaId: ID!
  ) {
    createOrUpdateUtilizedWasteHeat(
      input: {
        utilizedWasteHeatId: $utilizedWasteHeatId
        utilizedWasteHeatInformation: $utilizedWasteHeatInformation
        utilizedWasteHeatEnum: $utilizedWasteHeatEnum
        utilizedWasteHeatUsage: $utilizedWasteHeatUsage
        energySchemaId: $energySchemaId
      }
    ) {
      __typename
      id
      success
    }
  }
`;
