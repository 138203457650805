import { gql } from "urql";

export const createOrUpdateEnergyWaste = gql`
  mutation(
    $energyWasteId: ID
    $energyWasteName: String!
    $wasteSource: WasteSourceEnum
    $temperature: Float!
    $pressure: Float
    $energyAmount: Float
    $averageEffect: Float
    $maxEffect: Float
    $massAmount: Float
    $availability: Float!
    $pollutedWith: PollutionEnum
    $internalPlan: WasteHeatInternalUsageEnum
    $plannedRecoveredInternal: Float
    $externalPlan: WasteHeatExternalUsageEnum
    $distanceToExternal: Float
    $temperatureNeeds: Float
    $plannedRecoveredExternal: Float
    $comment: String
    $energySchemaId: ID!
  ) {
    createOrUpdateEnergyWaste(
      input: {
        energyWasteId: $energyWasteId
        energyWasteName: $energyWasteName
        wasteSource: $wasteSource
        temperature: $temperature
        pressure: $pressure
        energyAmount: $energyAmount
        averageEffect: $averageEffect
        maxEffect: $maxEffect
        massAmount: $massAmount
        availability: $availability
        pollutedWith: $pollutedWith
        internalPlan: $internalPlan
        plannedRecoveredInternal: $plannedRecoveredInternal
        externalPlan: $externalPlan
        distanceToExternal: $distanceToExternal
        temperatureNeeds: $temperatureNeeds
        plannedRecoveredExternal: $plannedRecoveredExternal
        comment: $comment
        energySchemaId: $energySchemaId
      }
    ) {
      __typename
      id
      success
    }
  }
`;
