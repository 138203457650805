import React, { SyntheticEvent } from "react";
import { File as FileIcon } from "react-feather";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useMutation, useQuery } from "urql";
import { Param, AttachmentTypeEnum } from "../../types";
import Button, { ButtonSize, ButtonType } from "../Button";
import Circle, { CircleSize } from "../Circle";
import { createWoodAttachment } from "../../graphql/mutations/Attachment/createWoodAttachment";
import { createMaterialStreamAttachment } from "../../graphql/mutations/Attachment/createMaterialStreamAttachment";
import { deleteAttachment } from "../../graphql/mutations/Attachment/deleteAttachment";
import { getWoodAttachment } from "../../graphql/queries/Attachment/getWoodAttachment";
import { getMaterialStreamAttachment } from "../../graphql/queries/Attachment/getMaterialStreamAttachment";
import { IMutationResult } from "../../types/mutations/mutationResult";
import { handleMutationError } from "../../lib/toaster";
import Spinner from "../Loading";

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.middle};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr auto;
  gap: ${(props) => props.theme.gap.light};
  border-radius: ${(props) => props.theme.borderRadius.light};
  padding: ${(props) => props.theme.padding.light};
  width: 100%;
`;
const UploadTitle = styled.div`
  grid-column: 1/2;
  grid-row: 1/2;
  font-weight: 600;
`;

const UploadDescription = styled.div`
  grid-column: 1/2;
  grid-row: 2/3;
`;
const UploadButtonArea = styled.div`
  grid-column: 2/3;
  grid-row: 1/3;
  justify-self: end;
`;
const FilesContainer = styled.div`
  grid-column: 1/-1;
  grid-row: 3/-1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.gap.light};
`;
const FileContainer = styled.div`
  display: flex;
  width: 100%;
`;

const FileBorder = styled.div`
  position: relative;
  border-style: solid;
  border-width: thin;
  border-color: black;
  border-radius: 0.4em;
  width: 4em;
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FileName = styled.div`
  margin-left: 1rem;
`;

const DeleteFile = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
`;
const HiddenFileInput = styled.input`
  display: none;
`;
interface UploadProps {
  title: string;
  description: string;
  attachmentType: string;
}

type Attachment = {
  id: string;
  sourceFileName: string;
  fileName: string;
  attachementType: AttachmentTypeEnum;
  __typename: string;
};

const Upload: React.FC<UploadProps> = (props) => {
  //This state holds files from the API, it's a different state than the local
  //because the local files are not the same type (File) as the API file (Attachment)
  const isWoodSchema = useLocation().pathname?.includes("wood") ? true : false;
  const FILE_MUTATION = isWoodSchema ? createWoodAttachment : createMaterialStreamAttachment;
  const FILE_QUERY = isWoodSchema ? getWoodAttachment : getMaterialStreamAttachment;
  const FILE_DELETE = deleteAttachment;

  const [apiFiles, setApiFiles] = React.useState<Array<Attachment>>([]);
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const { id } = useParams<Param>();
  const [fileResult, reExecuteQuery] = useQuery({
    query: FILE_QUERY,
    variables: { id: id },
  });
  const { data, fetching } = fileResult;
  const [, executeUploadMutation] = useMutation<IMutationResult>(FILE_MUTATION);
  const [, executeDeleteMutation] = useMutation<IMutationResult>(FILE_DELETE);

  const handleFileInput = () => {
    hiddenFileInput.current!.click();
  };

  const handleChange = (event: SyntheticEvent) => {
    const filesUploaded = (event.target as HTMLInputElement).files!;

    const items: Array<File> = [];

    //Iterates through all the selected files and sends mutations for each.
    for (let i = 0; i < filesUploaded.length; i++) {
      if (filesUploaded[i]) {
        items.push(filesUploaded[i]!);
        const reader = new FileReader();

        //called when the file is read
        reader.onload = async function (e) {
          let dataURL = reader.result;
          await executeUploadMutation({
            woodSchemaId: id,
            materialStreamSchemaId: id,
            attachementType: props.attachmentType,
            fileName: filesUploaded[i].name,
            fileContentType: filesUploaded[i].type.split("/")[1],
            fileAsBase64: dataURL?.toString().split(",")[1]!, //the text before "," is not part of base64
          })
            .then(() => {
              reExecuteQuery();
            })
            .catch(() => handleMutationError());
        };
        reader.readAsDataURL(filesUploaded[i]);
      }
    }
  };

  const handleRemove = async (id: string) => {
    await executeDeleteMutation({ id: id })
      .then(() => {
        reExecuteQuery();
      })
      .catch(() => handleMutationError());
  };

  React.useEffect(() => {
    if (!fetching && data) {
      if (isWoodSchema) {
        setApiFiles((__prevFiles) => [...data.woodSchema.attachments]);
      } else {
        setApiFiles((__prevFiles) => [...data.materialStreamAttachment.attachments]);
      }
    }
  }, [isWoodSchema, data, fetching]);

  return (
    <Container>
      <UploadTitle>{props.title}</UploadTitle>
      <UploadDescription>
        {props.description}
        <br></br>
        <small>Kun word og pdf</small>
      </UploadDescription>

      <UploadButtonArea>
        <Button buttonType={ButtonType.Primary} onClick={handleFileInput} size={ButtonSize.Medium}>
          Legg til
        </Button>
        <HiddenFileInput type="file" multiple ref={hiddenFileInput} onChange={handleChange} />
      </UploadButtonArea>
      <FilesContainer>
        {fetching || !data ? (
          <Spinner />
        ) : (
          apiFiles.map((file, index) => {
            return (
              file.attachementType === props.attachmentType && (
                <React.Fragment key={index}>
                  <FileContainer>
                    <FileBorder>
                      <FileIcon size={25} />
                      <DeleteFile onClick={() => handleRemove(file.id)}>
                        {" "}
                        <Circle size={CircleSize.SMALL}>X</Circle>
                      </DeleteFile>
                    </FileBorder>
                    <FileName>{file.sourceFileName}</FileName>
                  </FileContainer>
                </React.Fragment>
              )
            );
          })
        )}
      </FilesContainer>
    </Container>
  );
};
export default Upload;
