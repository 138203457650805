import { gql } from "urql";

export const updateOrganizationSidestreamType = gql`
  mutation ($id: ID!, $sidestreamTypes: [SidestreamTypeEnum]) {
    updateOrganizationSidestreamType(input: { id: $id, sidestreamTypes: $sidestreamTypes }) {
      __typename
      id
      success
    }
  }
`;
