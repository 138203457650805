import { IAuthenticatedUser, JwtToken, TokenClaims } from "../types/domain/user";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";

const infoCookieName = process.env.REACT_APP_INFO_COOKIE_NAME!;

export const getRolesFromDecodedToken = (decodedToken: JwtToken) => {
  const tokenRoles = decodedToken[TokenClaims.ROLE];

  if (tokenRoles) {
    if (Array.isArray(tokenRoles)) {
      return tokenRoles;
    } else {
      return [tokenRoles];
    }
  }

  return [];
};

export const decodeJwtToken = (token: string) => {
  return jwt_decode<JwtToken>(token);
};

export const getUserJwtFromCookie = () => {
  const cookie = Cookies.get(infoCookieName);
  return cookie;
};

export const getAuthenticatedUserFromInfoCookie = () => {
  const jwt = getUserJwtFromCookie();

  if (jwt) {
    const decodedToken = decodeJwtToken(jwt);

    const authenticatedUser: IAuthenticatedUser = {
      id: decodedToken[TokenClaims.NAMEIDENTIFIER],
      email: decodedToken.sub,
      userName: decodedToken.sub,
      fullName: decodedToken[TokenClaims.NAME],
      phone: "",
      roles: getRolesFromDecodedToken(decodedToken),
      expiry: decodedToken.exp,
    };

    return authenticatedUser;
  }

  return undefined;
};

export const deleteInfoCookie = () => {
  Cookies.remove(infoCookieName);
};
