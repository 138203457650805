import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import EnergyFlow from "../../pages/EnergyFlow";
import MaterialFlow from "../../pages/MaterialFlow";
import Wood from "../../pages/Wood";
import Dashboard from "../../pages/Dashboard/index";
import { ROUTES } from "./routes";
import Login from "../../pages/Login";
import Layout from "../../components/Layouts/Layout";
import { AuthorizedRoute } from "./AuthorizedRoute";
import Account from "../../pages/Account";
import LayoutPublic from "../../components/Layouts/LayoutPublic";
import GenerateResetPassword from "../../pages/GenerateResetPassword";
import ResetPassword from "../../pages/ResetPassword";
import Organizations from "../../pages/Organizations";
import OrganizationAccess from "../../pages/OrganizationAccess";
import OrganizationSidestreamType from "../../pages/OrganizationSidestreamType";
import Users from "../../pages/Users";
import OrganizationDetail from "../../pages/OrganizationDetail";
import WoodIntroduction from "../../pages/Wood/WoodIntroduction";
import SurveyIntroduction from "../../pages/EnergyFlow/SurveyIntroduction";
import MaterialFlowIntroduction from "../../pages/MaterialFlow/MaterialFlowIntroduction";
import AggregatedSchemas from "../../pages/AggregatedSchemas";

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* Public routes */}

        <Route path={ROUTES.PUBLIC.SIGN_IN_ROUTE}>
          <LayoutPublic>
            <Login />
          </LayoutPublic>
        </Route>

        <Route path={ROUTES.PUBLIC.GENERATE_RESET_PASSWORD_ROUTE}>
          <LayoutPublic>
            <GenerateResetPassword />
          </LayoutPublic>
        </Route>

        <Route path={ROUTES.PUBLIC.RESET_PASSWORD_ROUTE}>
          <LayoutPublic>
            <ResetPassword page="resetPassword" />
          </LayoutPublic>
        </Route>

        <Route path={ROUTES.PUBLIC.COMPLETE_SIGNUP_ROUTE}>
          <LayoutPublic>
            <ResetPassword page="completeSignup" />
          </LayoutPublic>
        </Route>

        {/* Admin routes */}

        <AuthorizedRoute path={ROUTES.ADMIN.SCHEMAS_ROUTE}>
          <Layout>
            <AggregatedSchemas />
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.ORGANIZATION_ACCESS_ROUTE}>
          <Layout>
            <OrganizationAccess />
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.ORGANIZATION_SIDESTREAMTYPE_ROUTE}>
          <Layout>
            <OrganizationSidestreamType />
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.ADMIN.USERS_ROUTE}>
          <Layout>
            <Users />
          </Layout>
        </AuthorizedRoute>

        {/* Authorized routes */}

        <AuthorizedRoute path={ROUTES.AUTHORIZED.ORGANIZATION_ROUTE}>
          <Layout>
            <OrganizationDetail />
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.ORGANIZATIONS_ROUTE}>
          <Layout>
            <Organizations />
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.ACCOUNT_ROUTE}>
          <Layout>
            <Account />
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.MATERIAL_FLOW_SCHEMA_ROUTE}>
          <Layout>
            <MaterialFlow />
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.WOOD_SCHEMA_ROUTE}>
          <Layout>
            <Wood />
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.ENERGY_FLOW_SCHEMA_ROUTE}>
          <Layout>
            <EnergyFlow />
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.MATERIAL_FLOW_INTRO_ROUTE}>
          <Layout>
            <MaterialFlowIntroduction />
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.WOOD_SCHEMA_INTRO_ROUTE}>
          <Layout>
            <WoodIntroduction />
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.ENERGY_FLOW_INTRO_ROUTE}>
          <Layout>
            <SurveyIntroduction />
          </Layout>
        </AuthorizedRoute>

        <AuthorizedRoute path={ROUTES.AUTHORIZED.DASHBOARD_ROUTE}>
          <Layout>
            <Dashboard />
          </Layout>
        </AuthorizedRoute>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
