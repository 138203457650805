import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useMutation, useQuery } from "urql";
import Bottom from "../../../components/Bottom";
import InternalQuestionBox from "../../../components/InternalQuestionBox";
import Navigation from "../../../components/Navigation";
import QuestionBox from "../../../components/QuestionBox";
import { NavigationContext } from "../../../providers/NavigationProvider";
import { AboutWoodMaterialType, Param, TBooleanSelect, TTreatment } from "../../../types";
import AboutWoodMaterialData from "./AboutWoodMaterialData";
import { updateWoodMaterial } from "../../../graphql/mutations/Wood/updateWoodMaterial";
import { findSelectValue } from "../../../utils/form-utils";
import { BooleanSelect, Treatment } from "../../../dictionaries";
import { getAboutWoodMaterial } from "../../../graphql/queries/Wood/getAboutWoodMaterial";
import Spinner from "../../../components/Loading";
import { yupResolver } from "@hookform/resolvers/yup";
import { aboutWoodMaterialSchema } from "../../../schemas";
import { IMutationResult } from "../../../types/mutations/mutationResult";
import { handleMutationError } from "../../../lib/toaster";

const Container = styled.div`
  grid-column: 2/3;
`;
const LoadingContainer = styled.div`
  display: flex;
  place-content: center;
`;
const AboutWoodMaterial: React.FC = () => {
  const [isReset, setIsReset] = React.useState<boolean>(true);
  const context = React.useContext(NavigationContext);
  const { register, getValues, handleSubmit, reset, errors } = useForm<AboutWoodMaterialType>({
    resolver: yupResolver(aboutWoodMaterialSchema),
  });
  const { id } = useParams<Param>();
  const [, executeMutation] = useMutation<IMutationResult>(updateWoodMaterial);
  const [aboutWoodMaterialResult] = useQuery({
    query: getAboutWoodMaterial,
    variables: { id: id },
  });
  const { data, fetching } = aboutWoodMaterialResult;

  const onSubmit: SubmitHandler<AboutWoodMaterialType> = async (data) => {
    data &&
      (await executeMutation({
        id: id,
        dangerousWaste: findSelectValue<TBooleanSelect>(data.dangerousWaste, BooleanSelect),
        limitationsToMaterialDeposit: findSelectValue<TBooleanSelect>(data.limitationsToMaterialDeposit, BooleanSelect),
        depositDemandsComment: data.depositDemandsComment,
        autoIgnitionHazard: findSelectValue<TBooleanSelect>(data.autoIgnitionHazard, BooleanSelect),
        treatmentAfterReceving: findSelectValue<TTreatment>(data.treatmentAfterDelivery, Treatment),
      })
        .then((res) => {
          context.handleNav(true);
        })
        .catch(() => handleMutationError()));
  };

  const onPreviousSubmit: SubmitHandler<AboutWoodMaterialType> = async () => {
    const data = getValues();
    await executeMutation({
      id: id,
      dangerousWaste: findSelectValue<TBooleanSelect>(data.dangerousWaste, BooleanSelect),
      limitationsToMaterialDeposit: findSelectValue<TBooleanSelect>(data.limitationsToMaterialDeposit, BooleanSelect),
      depositDemandsComment: data.depositDemandsComment,
      autoIgnitionHazard: findSelectValue<TBooleanSelect>(data.autoIgnitionHazard, BooleanSelect),
      treatmentAfterReceving: findSelectValue<TTreatment>(data.treatmentAfterDelivery, Treatment),
    })
      .then((res) => {
        context.handleNav(false);
      })
      .catch(() => handleMutationError());
  };

  React.useEffect(() => {
    if (data && !fetching) {
      if (isReset) {
        reset({
          ...data.woodSchema,
          dangerousWaste: BooleanSelect[data.woodSchema.dangerousWaste as TBooleanSelect],
          limitationsToMaterialDeposit: BooleanSelect[data.woodSchema.limitationsToMaterialDeposit as TBooleanSelect],
          autoIgnitionHazard: BooleanSelect[data.woodSchema.autoIgnitionHazard as TBooleanSelect],
          treatmentAfterDelivery: Treatment[data.woodSchema.treatmentAfterReceving as TTreatment],
        });

        setIsReset(false);
      }
    }
  }, [data, fetching, reset, isReset]);
  return (
    <Container>
      <QuestionBox title="Om materialet">
        {fetching ? (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        ) : (
          <InternalQuestionBox>
            <AboutWoodMaterialData register={register} errors={errors} />
          </InternalQuestionBox>
        )}
      </QuestionBox>
      <Bottom>
        <Navigation navigation={context.navigation} handleNext={handleSubmit(onSubmit)} handlePrev={handleSubmit(onPreviousSubmit)} />
      </Bottom>
    </Container>
  );
};

export default AboutWoodMaterial;
