import styled from "styled-components";

const ContentBox = styled.div`
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.middle};
  padding: ${(props) => props.theme.padding.heavy};
  border-radius: ${(props) => props.theme.borderRadius.light};
`;

export default ContentBox;
