import { gql } from "urql";

export const updateWoodSubmit = gql`
  mutation($id: ID, $schemaIsDelivered: BoolEnum) {
    updateWoodSchemaSubmit(
      input: { id: $id, schemaIsDelivered: $schemaIsDelivered }
    ) {
      __typename
      id
      success
    }
  }
`;
