import React from "react";
import styled from "styled-components";

const SaveTextContainer = styled.div`
  color: #898a88;
  font-size: 0.8rem;
  float: right;
  margin-top: 1rem;
`;

const SaveText: React.FC = () => {
  return <SaveTextContainer>Innholdet i skjema blir lagret mellom hvert steg</SaveTextContainer>;
};

export default SaveText;
