import { gql } from "urql";

export const updateMaterialFlowSubmit = gql`
  mutation($id: ID, $schemaIsDelivered: ExtendedBoolEnum) {
    updateMaterialStreamSubmit(input: { id: $id, schemaIsDelivered: $schemaIsDelivered }) {
      __typename
      id
      success
    }
  }
`;
