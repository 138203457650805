import React from "react";
import styled from "styled-components";
import { useQuery } from "urql";
import Button, { ButtonSize, ButtonType } from "../../components/Button";
import ErrorMessage from "../../components/ErrorMessage";
import Spinner from "../../components/Loading";
import Heading from "../../components/Heading";
import { getOrganizations } from "../../graphql/queries/Organizations/getOrganizations";
import { useAuth } from "../../hooks/useAuth";
import { GetOrganizationsQueryResult } from "../../types";
import OrganizationList from "./OrganizationList";
import { FiPlus } from "react-icons/fi";
import { defaultStyles } from "../../lib/modal";
import Modal from "react-modal";
import CreateOrganization from "../CreateOrganization";
import EmptyState from "../../components/EmptyState";

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const ActionsContainer = styled.div`
  margin-bottom: 20px;
`;

const Organizations: React.FC = () => {
  const [{ data, fetching, error }, refetchOrganizationsQuery] = useQuery<GetOrganizationsQueryResult>({
    query: getOrganizations,
  });
  const { isAdmin } = useAuth();
  const [createModalIsOpen, setCreateModalIsOpen] = React.useState(false);

  if (fetching) return <Spinner />;
  if (error) return <ErrorMessage />;

  return (
    <>
      <Nav>
        <Heading>{isAdmin() ? "Bedrifter" : "Mine bedrifter"}</Heading>

        {isAdmin() && (
          <ActionsContainer>
            <Button buttonType={ButtonType.Primary} size={ButtonSize.Large} onClick={() => setCreateModalIsOpen(true)}>
              <FiPlus size={"1.2em"} /> Legg til bedrift
            </Button>
          </ActionsContainer>
        )}
      </Nav>

      {data && data.organizations.length > 0 ? (
        <OrganizationList organizations={data.organizations}></OrganizationList>
      ) : (
        <EmptyState title="Ingen bedrifter er lagt til"></EmptyState>
      )}

      <Modal isOpen={createModalIsOpen} onRequestClose={() => setCreateModalIsOpen(false)} style={defaultStyles}>
        <CreateOrganization
          handleSave={() => {
            setCreateModalIsOpen(false);
            refetchOrganizationsQuery();
          }}
          handleCancel={() => setCreateModalIsOpen(false)}
        />
      </Modal>
    </>
  );
};

export default Organizations;
