import { gql } from "urql";

export const getRSentences = gql`
  query {
    rSentence {
      id
      sentenceId
      description
      __typename
    }
  }
`;
