import { generatePath } from "react-router-dom";

export const ROUTES = {
  PUBLIC: {
    SIGN_IN_ROUTE: "/signin",
    GENERATE_RESET_PASSWORD_ROUTE: "/getReset",
    RESET_PASSWORD_ROUTE: "/reset",
    COMPLETE_SIGNUP_ROUTE: "/completeSignup",
  },
  AUTHORIZED: {
    DASHBOARD_ROUTE: "/",
    ACCOUNT_ROUTE: "/account",
    MATERIAL_FLOW_SCHEMA_ROUTE: "/materialflow/:id",
    WOOD_SCHEMA_ROUTE: "/wood/:id",
    ENERGY_FLOW_SCHEMA_ROUTE: "/energyflow/:id",
    ORGANIZATIONS_ROUTE: "/organizations",
    ORGANIZATION_ROUTE: "/organizations/:id",
    ENERGY_FLOW_INTRO_ROUTE: "/energyflowintro",
    WOOD_SCHEMA_INTRO_ROUTE: "/woodintro",
    MATERIAL_FLOW_INTRO_ROUTE: "/materialflowintro",
  },
  ADMIN: {
    SCHEMAS_ROUTE: "/schemas",
    USERS_ROUTE: "/users",
    ORGANIZATION_ACCESS_ROUTE: "/organizations/:id/access",
    ORGANIZATION_SIDESTREAMTYPE_ROUTE: "/organizations/:id/sidestreamtype",
  },
};

export const GET_ORGANIZATION_ROUTE = (id: string) => {
  return generatePath(ROUTES.AUTHORIZED.ORGANIZATION_ROUTE, {
    id: id,
  });
};

export const GET_ORGANIZATION_ACCESS_ROUTE = (id: string) => {
  return generatePath(ROUTES.ADMIN.ORGANIZATION_ACCESS_ROUTE, {
    id: id,
  });
};

export const GET_ORGANIZATION_SIDESTREAMTYPE_ROUTE = (id: string) => {
  return generatePath(ROUTES.ADMIN.ORGANIZATION_SIDESTREAMTYPE_ROUTE, {
    id: id,
  });
};

export const GET_MATERIAL_FLOW_SCHEMA_ROUTE = (id: string) => {
  return generatePath(ROUTES.AUTHORIZED.MATERIAL_FLOW_SCHEMA_ROUTE, {
    id: id,
  });
};

export const GET_WOOD_SCHEMA_ROUTE = (id: string) => {
  return generatePath(ROUTES.AUTHORIZED.WOOD_SCHEMA_ROUTE, {
    id: id,
  });
};

export const GET_ENERGY_FLOW_SCHEMA_ROUTE = (id: string) => {
  return generatePath(ROUTES.AUTHORIZED.ENERGY_FLOW_SCHEMA_ROUTE, {
    id: id,
  });
};

export const GetAdminRoutes = () => {
  return Object.values(ROUTES.ADMIN);
};

export const GetAuthorizedRoutes = () => {
  return Object.values(ROUTES.AUTHORIZED);
};

export const GetPublicRoutes = () => {
  return Object.values(ROUTES.PUBLIC);
};
