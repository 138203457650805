import { gql } from "urql";

export const deleteUtilizedWasteHeat = gql`
  mutation($id: ID) {
    deleteUtilizedWasteHeat(input: { id: $id }) {
      __typename
      id
      success
    }
  }
`;
