import React from "react";
import { Control } from "react-hook-form/dist/types";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useQuery } from "urql";
import { mapToSelectOption } from "../../utils/form-utils";
import Spinner from "../../components/Loading";
import { GetUsersQueryResult } from "../../types";
import { getUsers } from "../../graphql/queries/User/getUsers";
import { IUser } from "../../types/domain/user";

interface ContactPersonSelectProps {
  fieldName: string;
  errors?: any;
  control: Control<any>;
  register: any;
  required?: boolean;
  orgId?: string
}

const ContactPersonSelect: React.FunctionComponent<ContactPersonSelectProps> = (props) => {


  const [{ data, fetching: organizationUsersFetching, error }] = useQuery<GetUsersQueryResult>({
    query: getUsers,
    variables: { filterByOrganizationId: props.orgId },
  });
  const contactPersons = !data || !data.users || !data.users.length ? [] : data.users;

  const contactPersonList = contactPersons.map((organizationUser: IUser) => mapToSelectOption(organizationUser.id, organizationUser.fullName));

  return (
    <>
      {organizationUsersFetching || !data ? (
        <Spinner />
      ) : (

        <Controller
          control={props.control}
          name={props.fieldName}
          defaultValue=""
          options={contactPersonList}
          render={(props) => (
            <Select
              onChange={(e) => props.onChange(e)}
              placeholder="Vennligst velg"
              value={props.value}
              options={contactPersonList}
              // styles={customStyles}
              menuPortalTarget={document.body}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          )}
        ></Controller>
      )
      }

      {error && <div>Feil: Kunne ikke hente kontaktpersoner</div>}
    </>
  );
};

export default ContactPersonSelect;
