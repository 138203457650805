import { gql } from "urql";

export const generateResetPasswordToken = gql`
  mutation($email: String!) {
    generateResetPasswordToken(input: { email: $email }) {
      id
      success
    }
  }
`;
