import React from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";
import { day } from "../theme";
const localStorageKey = "theme";
type Dispatch = (theme: DefaultTheme) => void;

type State = {
  theme: DefaultTheme;
};
const getThemeFromLocalStorage = (): DefaultTheme | undefined => {
  let storedTheme = localStorage.getItem(localStorageKey);
  if (storedTheme !== null) {
    if (storedTheme === day.name) {
      return day;
    }
    return day; //Change this to a new theme in the future (e.g. "night")
  }
  return undefined;
};

const getDefaultTheme = (): DefaultTheme => {
  const storedTheme = getThemeFromLocalStorage();
  return storedTheme ? storedTheme : day;
};

export const ThemeContext = React.createContext<State>({
  theme: day,
});

export const ThemeDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

const themeReducer = (state: State, theme: DefaultTheme) => {
  localStorage.setItem(localStorageKey, theme.name);
  return { theme: theme };
};

const EydeThemeProvider: React.FC = (props) => {
  const [state, dispatch] = React.useReducer(themeReducer, {
    theme: getDefaultTheme(),
  });

  return (
    <ThemeContext.Provider value={state}>
      <ThemeProvider theme={state.theme}>
        <ThemeDispatchContext.Provider value={dispatch}>
          {props.children}
        </ThemeDispatchContext.Provider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default EydeThemeProvider;
