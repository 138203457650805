import React from "react";
import styled from "styled-components";
import MaterialOtherInformationData from "./MaterialOtherInformationData";
import Bottom from "../../../components/Bottom";
import Navigation from "../../../components/Navigation";
import { NavigationContext } from "../../../providers/NavigationProvider";
import { SubmitHandler, useForm } from "react-hook-form";
import { Param, MaterialOtherInformationType } from "../../../types";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "urql";
import { getMaterialStreamOtherInformation } from "../../../graphql/queries/Materialflow/getMaterialStreamOtherInformation";
import { updateMaterialFlowOtherInformation } from "../../../graphql/mutations/Materialflow/updateMaterialFlowOtherInformation";
//import { yupResolver } from "@hookform/resolvers/yup";
//import { materialOtherInformationSchema } from "../../../schemas";
import Spinner from "../../../components/Loading";
import { IMutationResult } from "../../../types/mutations/mutationResult";
import { handleMutationError } from "../../../lib/toaster";

const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const LoadingContainer = styled.div`
  display: flex;
  place-content: center;
`;

const MaterialOtherInformation: React.FC = () => {
  const context = React.useContext(NavigationContext);
  const [isReset, setIsReset] = React.useState<boolean>(true);
  const { id } = useParams<Param>();

  const [otherInformationResult] = useQuery({
    query: getMaterialStreamOtherInformation,
    variables: { id: id },
  });

  const { data, fetching } = otherInformationResult;
  const [, executeMutation] = useMutation<IMutationResult>(updateMaterialFlowOtherInformation);
  const { register, handleSubmit, reset, errors } = useForm<MaterialOtherInformationType>({
    // defaultValues: {},
    // resolver: yupResolver(materialOtherInformationSchema),
  });

  function handleExecuteMutation(data: any, nav: boolean) {
    data.materialStreamOtherInformation &&
      executeMutation({
        id: id,
        otherInformation: data.materialStreamOtherInformation.otherInformation || null,
      })
        .then((result) => {
          if (result.error) {
            handleMutationError(result.error);
          } else {
            context.handleNav(nav);
          }
        })
        .catch(() => handleMutationError());
  }

  const onSubmit: SubmitHandler<MaterialOtherInformationType> = (data) => {
    handleExecuteMutation(data, true);
  };
  const onPreviousSubmit: SubmitHandler<MaterialOtherInformationType> = (data) => {
    handleExecuteMutation(data, false);
  };

  React.useEffect(() => {
    if (!fetching) {
      if (isReset) {
        reset({
          ...data.materialStreamOtherInformation,
        });
        setIsReset(false);
      }
    }
  }, [data, fetching, reset, isReset]);

  return (
    <Container>
      {fetching ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : (
        <MaterialOtherInformationData register={register} errors={errors} data={data} />
      )}

      <Bottom>
        <Navigation navigation={context.navigation} handleNext={handleSubmit(onSubmit)} handlePrev={handleSubmit(onPreviousSubmit)} />
      </Bottom>
    </Container>
  );
};

export default MaterialOtherInformation;
