import { SidestreamTypeEnum } from "../types";

export const getReadableSideStreamType = (type: SidestreamTypeEnum) => {
  switch (type) {
    case SidestreamTypeEnum.ENERGY:
      return "Energi";
    case SidestreamTypeEnum.MATERIALSTREAM:
      return "Materialstrøm";
    case SidestreamTypeEnum.WOOD:
      return "Trevirke";

    default:
      return "";
  }
};
