import { cacheExchange } from "@urql/exchange-graphcache";

export const cache = cacheExchange({
  keys: {
    AggregatedSchemaType: () => null, // We do this to specify that these data types do not provide an identifyable attribute (e.g. ID)
  },
  updates: {
    Mutation: {
      // createOrUpdateEnergyType: (result, args: any, cache) => {
      //   cache.updateQuery(
      //     {
      //       query: energyTypesQuery,
      //       variables: { energySchemaId: args.input.energySchemaId },
      //     },

      //     (data) => {
      //       if (data) {
      //         return {
      //           ...data,
      //           energyTypes: [
      //             ...data.energyTypes,
      //             result.createOrUpdateEnergyType,
      //           ],
      //         };
      //       } else {
      //         return null;
      //       }
      //     }
      //   );
      // },

      // createOrUpdateUtilizedWasteHeat: (result, args: any, cache) => {
      //   cache.updateQuery(
      //     {
      //       query: utilizedWasteHeatsQuery,
      //       variables: { energySchemaId: args.input.energySchemaId },
      //     },
      //     (data) => {
      //       if (data) {
      //         return {
      //           ...data,
      //           utilizedWasteHeats: [
      //             ...data.utilizedWasteHeats,
      //             result.createOrUpdateUtilizedWasteHeat,
      //           ],
      //         };
      //       } else {
      //         return null;
      //       }
      //     }
      //   );
      // },
      // createOrUpdateEnergyWaste: (result, args: any, cache) => {
      //   cache.updateQuery(
      //     {
      //       query: energyWastesQuery,
      //       variables: { energySchemaId: args.input.energySchemaId },
      //     },
      //     (data) => {
      //       if (data) {
      //         return {
      //           ...data,
      //           energyWastes: [
      //             ...data.energyWastes,
      //             result.createOrUpdateEnergyWaste,
      //           ],
      //         };
      //       } else {
      //         return null;
      //       }
      //     }
      //   );
      // },
      deleteEnergyType: (result, args, cache, info) => {
        const getEnergyTypes = cache.inspectFields("Query").filter((x) => x.fieldName === "energyTypes");
        getEnergyTypes.forEach(({ fieldName, arguments: variables }) => {
          cache.invalidate("Query", fieldName, variables!);
        });
      },
      deleteUtilizedWasteHeat: (result, args, cache, info) => {
        const getUtilizedWasteHeats = cache.inspectFields("Query").filter((x) => x.fieldName === "utilizedWasteHeats");
        getUtilizedWasteHeats.forEach(({ fieldName, arguments: variables }) => {
          cache.invalidate("Query", fieldName, variables!);
        });
      },
      deleteEnergyWaste: (result, args, cache, info) => {
        const getEnergyWastes = cache.inspectFields("Query").filter((x) => x.fieldName === "energyWastes");
        getEnergyWastes.forEach(({ fieldName, arguments: variables }) => {
          cache.invalidate("Query", fieldName, variables!);
        });
      },
    },
  },
});
