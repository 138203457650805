import { gql } from "urql";

export const updateSidestream = gql`
  mutation($id: ID!, $name: String!, $isInactive: Boolean!) {
    updateSidestream(input: { id: $id, name: $name, isInactive: $isInactive }) {
      __typename
      id
      success
    }
  }
`;
