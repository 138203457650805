import React from "react";
import styled from "styled-components";
import Upload from "../../../../components/Upload";
import CodeSelect from "../../../../components/CodeSelect";
import { MaterialState, MaterialType, Organic, ProductionBatch, ProductionPeriod, ProductionVolumeUnit } from "../../../../dictionaries";
import { getEALCodes } from "../../../../graphql/queries/Materialflow/getEalCodes";
import { getWasteCodes } from "../../../../graphql/queries/Materialflow/getWasteCodes";
import { SelectValues } from "../../../../utils/form-utils";
import { AttachmentTypeEnum } from "../../../../types";

enum InputWidth {
  LONG,
  SHORT,
}
enum InputDirection {
  COLUMN,
  ROW,
}
const ContainerTitle = styled.div`
  grid-column: 1/2;
  grid-row: 1/2;
  font-weight: 600;
  padding-bottom: 1em;
`;

const InputField = styled.input<InputProps>`
  width: ${(props) => (props.size === InputWidth.LONG ? "50%" : props.size === InputWidth.SHORT ? "50%" : "100%")};
`;
const SelectField = styled.select<InputProps>`
  width: ${(props) => (props.size === InputWidth.LONG ? "80%" : props.size === InputWidth.SHORT ? "50%" : "100%")};
`;
const CommentField = styled.textarea`
  height: 50%;
`;
const LargeCommentField = styled.textarea`
  height: 100%;
`;

const UploadField = styled.div`
  grid-column: 1/3;
`;
const FirstColumn = styled.div`
  grid-column: 1/2;
`;

const SecondColumn = styled.div`
  grid-column: 2/3;
`;

const HeaderTitle = styled(FirstColumn)`
  font-weight: 600;
`;
const Question = styled(FirstColumn)``;
const UserInput = styled(SecondColumn)``;
const SpecialUserInput = styled(UserInput)<InputProps>`
  display: flex;
  flex-direction: ${(props) => (props.direction === InputDirection.ROW ? "row" : "column")};
  column-gap: ${(props) => props.theme.gap.medium};
  row-gap: ${(props) => props.theme.gap.light};
`;

const CodeContainer = styled.div`
  grid-column: 1/3;
  display: grid;
  grid-template-columns: 1fr 2fr;
`;

const FormError = styled.div`
  grid-column: 2;
  margin-bottom: 1em;
`;

interface InputProps {
  size?: InputWidth;
  direction?: InputDirection;
}

type AboutMaterialFlowDataProps = {
  register: any;
  watch: any;
  errors: any;
  control: any;
};
const AboutMaterialFlowData: React.FC<AboutMaterialFlowDataProps> = ({ register, watch, errors, control }) => {
  const [isWaste, setIsWaste] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (watch("materialStreamAbout.materialType") === MaterialType.WASTE) {
      setIsWaste(true);
    } else {
      setIsWaste(false);
    }
  }, [watch, errors, register, isWaste]);

  return (
    <>
      <ContainerTitle>Innledende beskrivelse av sidestrømmen</ContainerTitle>
      <Question>Hevdnavn på sidestrøm/material (hvis tilgjenglig)</Question>
      <UserInput>
        <InputField ref={register} name="materialStreamAbout.givenName" />
      </UserInput>
      <Question>Er materialet biprodukt, avfall eller avgangsmasse? *</Question>
      <UserInput>
        <SelectField name="materialStreamAbout.materialType" ref={register}>
          <option value="">Vennligst velg</option>
          {SelectValues(MaterialType)}
        </SelectField>
        {errors.materialStreamAbout?.materialType && (
          <FormError className="error">{errors.materialStreamAbout?.materialType.message}</FormError>
        )}
      </UserInput>
      {isWaste && (
        <>
          <CodeContainer>
            <CodeSelect
              query={getEALCodes}
              control={control}
              register={register}
              fieldName="materialStreamAbout.eALCode"
              label="EAL kode"
            />
          </CodeContainer>
          <CodeContainer>
            <CodeSelect
              query={getWasteCodes}
              control={control}
              register={register}
              fieldName="materialStreamAbout.wasteCode"
              label="Avfallskode (Norsk Standard)"
            />
          </CodeContainer>
        </>
      )}

      <HeaderTitle>Prosess</HeaderTitle>
      <Question>Sidestrømmens opprinnelse (prosesstrinn etc.)</Question>
      <UserInput>
        <LargeCommentField ref={register} name="materialStreamAbout.sideStreamOrigin" placeholder="" />
      </UserInput>
      {errors.materialStreamAbout?.sideStreamOrigin && (
        <FormError className="error">{errors.materialStreamAbout?.sideStreamOrigin.message}</FormError>
      )}
      <UploadField>
        <Upload
          title="Prosessflytdiagram"
          description="Legg gjerne til vedlegg som beskriver prosess"
          attachmentType={AttachmentTypeEnum.ProcessFlowDiagram}
        />
      </UploadField>

      <HeaderTitle>Produksjon og frekvens</HeaderTitle>
      <Question>Årlig produksjon *</Question>
      <SpecialUserInput direction={InputDirection.ROW}>
        <InputField ref={register} name="materialStreamAbout.yearlyProduction" size={InputWidth.LONG} />
        <SelectField ref={register} name="materialStreamAbout.productionVolumeUnit" size={InputWidth.SHORT}>
          <option value="">Vennligst velg</option>
          {SelectValues(ProductionVolumeUnit)}
        </SelectField>
      </SpecialUserInput>
      {errors.materialStreamAbout?.yearlyProduction && (
        <FormError className="error">{errors.materialStreamAbout?.yearlyProduction.message}</FormError>
      )}
      {errors.materialStreamAbout?.productionVolumeUnit && (
        <FormError className="error">{errors.materialStreamAbout?.productionVolumeUnit.message}</FormError>
      )}

      <Question>Produseres materialet kontinuerlig eller sporadisk/batch? *</Question>
      <UserInput>
        <SelectField defaultValue="Vennligst velg" ref={register} name="materialStreamAbout.productionPeriod">
          <option value="">Vennligst velg</option>
          {SelectValues(ProductionPeriod)}
        </SelectField>
      </UserInput>
      {errors.materialStreamAbout?.productionPeriod && (
        <FormError className="error">{errors.materialStreamAbout?.productionPeriod.message}</FormError>
      )}
      {watch("materialStreamAbout.productionPeriod") !== ProductionPeriod.CONTINOUS && (
        <>
          <Question>Produksjonsvolum ved sporadisk eller batch*</Question>
          <SpecialUserInput direction={InputDirection.ROW}>
            <InputField size={InputWidth.LONG} ref={register} name="materialStreamAbout.productionVolumeBatch" />
            <SelectField size={InputWidth.SHORT} ref={register} name="materialStreamAbout.productionBatch">
              <option value="">Vennligst velg</option>
              {SelectValues(ProductionBatch)}
            </SelectField>
          </SpecialUserInput>
          {errors.materialStreamAbout?.productionVolumeBatch && (
            <FormError className="error">{errors.materialStreamAbout?.productionVolumeBatch.message}</FormError>
          )}
          {errors.productionBatch && <FormError className="error">{errors.productionBatch.message}</FormError>}
        </>
      )}

      <HeaderTitle>Tilstand</HeaderTitle>
      <Question>Materialtilstand *</Question>
      <SpecialUserInput direction={InputDirection.COLUMN}>
        <SelectField defaultValue="Vennligst velg" ref={register} name="materialStreamAbout.materialState">
          <option value="">Vennligst velg</option>
          {SelectValues(MaterialState)}
        </SelectField>
        {errors.materialStreamAbout?.materialState && (
          <FormError className="error">{errors.materialStreamAbout?.materialState.message}</FormError>
        )}
        <CommentField ref={register} name="materialStreamAbout.materialStateComment" placeholder="Comments" />
      </SpecialUserInput>
      <Question>Er materialet organisk eller uorganisk? *</Question>
      <SpecialUserInput direction={InputDirection.COLUMN}>
        <SelectField defaultValue="Vennligst velg" ref={register} name="materialStreamAbout.organic">
          <option value="">Vennligst velg</option>
          {SelectValues(Organic)}
        </SelectField>
        {errors.materialStreamAbout?.organic && <FormError className="error">{errors.materialStreamAbout?.organic.message}</FormError>}
        <CommentField ref={register} name="materialStreamAbout.organicComment" placeholder="Comments" />
      </SpecialUserInput>
    </>
  );
};
export default AboutMaterialFlowData;
