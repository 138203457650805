import React from "react";
import { Control } from "react-hook-form/dist/types";
import { Controller } from "react-hook-form";
import Select from "react-select";
import styled from "styled-components";
import { useQuery } from "urql";
import { ISelectOption } from "../../types";
import { mapToSelectOption } from "../../utils/form-utils";
import Spinner from "../../components/Loading";
interface SentenceSelectProps {
  fieldName: string;
  query: any;
  errors?: any;
  currentValues?: Array<ISelectOption>;
  control: Control<any>;
  register: any;
  label?: string;
  required?: boolean;
}

const Label = styled.label`
  grid-column: 1;
`;
const SelectContainer = styled.div`
  grid-column: 2;
`;

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    height: "5rem",
    minHeight: "5rem",
  }),
  valueContainer: (provided: any, state: any) => {
    const height = "5rem";
    return { ...provided, height };
  },
  dropdownIndicator: (provided: any, state: any) => {
    const color = "#000";
    const height = "2";
    const width = "2";
    return { ...provided, color, height, width };
  },
  multiValueLabel: (provided: any, state: any) => {
    const fontSize = "100%";
    return { ...provided, fontSize };
  },
};

const SentenceSelect: React.FunctionComponent<SentenceSelectProps> = (props) => {
  const [sentenceQueryResult] = useQuery({
    query: props.query,
  });
  const { data, fetching } = sentenceQueryResult;
  const sentences = !data ? [] : data.sSentence ? data.sSentence : data.rSentence ? data.rSentence : data.hSentence;
  const sentencesList = sentences.map((sentence: any) => mapToSelectOption(sentence.id, sentence.sentenceId));

  return (
    <>
      <Label>{props.label}</Label>
      {fetching || !data ? (
        <Spinner />
      ) : (
        <SelectContainer>
          <Controller
            control={props.control}
            defaultValue=""
            name={props.fieldName}
            options={sentencesList}
            render={(props: any) => (
              <Select
                onChange={(e) => props.onChange(e)}
                isMulti
                placeholder="Vennligst velg"
                isSearchable
                value={props.value}
                options={sentencesList}
                styles={customStyles}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            )}
          ></Controller>
        </SelectContainer>
      )}
      {/* {errors?. && <FormError className="error">{errors..message}</FormError>} */}
    </>
  );
};

export default SentenceSelect;
