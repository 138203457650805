import React from "react";
import styled from "styled-components";

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Content = styled.div`
  width: 500px;
`;

const LayoutPublic: React.FC = (props) => {
  return (
    <FlexContainer>
      <Content>{props.children}</Content>
    </FlexContainer>
  );
};

export default LayoutPublic;
