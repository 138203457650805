import React from "react";
import { Control } from "react-hook-form/dist/types";
import { Controller } from "react-hook-form";
import Select from "react-select";
import styled from "styled-components";
import { useQuery } from "urql";
import { CodeFormData } from "../../types";
import { compare, mapToSelectOption } from "../../utils/form-utils";
import Spinner from "../../components/Loading";

interface CodeSelectProps {
  fieldName: string;
  errors?: any;
  query: any;
  control: Control<CodeFormData>;
  register: any;
  label?: string;
  required?: boolean;
}

const Label = styled.label`
  grid-column: 1/2;
`;
const SelectContainer = styled.div`
  grid-column: 2;
`;
const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    height: "2.29rem",
    minHeight: "2.29rem",
  }),
  valueContainer: (provided: any, state: any) => {
    const height = "2.29rem";
    return { ...provided, height };
  },
  dropdownIndicator: (provided: any, state: any) => {
    const color = "#000";
    const height = "2";
    const width = "2";
    return { ...provided, color, height, width };
  },
};


const CodeSelect: React.FunctionComponent<CodeSelectProps> = (props) => {
  const [codesQueryResult] = useQuery({
    query: props.query,
  });
  const { data, fetching } = codesQueryResult;
  const codes = !data ? [] : data.eALCodes ? data.eALCodes : data.wasteCode;
  const codesList = codes?.sort(compare).map((code: any) => mapToSelectOption(code.id, code.codeId + " - " + code.name));
  return (
    <>
      <Label>{props.label}</Label>
      {fetching || !data ? (
        <Spinner />
      ) : (
        <SelectContainer>
          <Controller
            control={props.control}
            name={props.fieldName}
            defaultValue=""
            options={codesList}
            render={(props: any) => (
              <Select
                onChange={(e) => props.onChange(e)}
                placeholder="Vennligst velg"
                value={props.value}
                options={codesList}
                styles={customStyles}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            )}
          ></Controller>
        </SelectContainer>
      )}
    </>
  );
};

export default CodeSelect;
