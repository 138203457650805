import React from "react";
import styled from "styled-components";
import { useQuery } from "urql";
import Button, { ButtonSize, ButtonType } from "../../components/Button";
import ErrorMessage from "../../components/ErrorMessage";
import Spinner from "../../components/Loading";
import Heading from "../../components/Heading";
import { GetAggregatedSchemasQueryResult } from "../../types";
import AggregatedSchemasList from "./AggregatedSchemasList";
import { FiPlus } from "react-icons/fi";
import { defaultStyles } from "../../lib/modal";
import Modal from "react-modal";
import EmptyState from "../../components/EmptyState";
import CreateNewSchemasForm from "../../components/CreateNewSchemasForm";
import { getAggregatedSchemas } from "../../graphql/queries/Schemas/getAggregatedSchemas";

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const ActionsContainer = styled.div`
  margin-bottom: 20px;
`;

const AggregatedSchemas: React.FC = () => {
  const [{ data, fetching, error }, refetchQuery] = useQuery<GetAggregatedSchemasQueryResult>({
    query: getAggregatedSchemas,
  });
  const [createSchemasModalIsOpen, setCreateSchemasModalIsOpen] = React.useState(false);

  if (fetching) return <Spinner />;
  if (error) return <ErrorMessage />;

  return (
    <>
      <Nav>
        <Heading>Utsendelser</Heading>

        <ActionsContainer>
          <Button buttonType={ButtonType.Primary} size={ButtonSize.Large} onClick={() => setCreateSchemasModalIsOpen(true)}>
            <FiPlus size={"1.2em"} /> Ny utsendelse
          </Button>
        </ActionsContainer>
      </Nav>

      {data && data.aggregatedSchemas.length > 0 ? (
        <AggregatedSchemasList aggregatedSchemas={data.aggregatedSchemas}></AggregatedSchemasList>
      ) : (
        <EmptyState title="Ingen utsendelser"></EmptyState>
      )}

      <Modal isOpen={createSchemasModalIsOpen} onRequestClose={() => setCreateSchemasModalIsOpen(false)} style={defaultStyles}>
        <CreateNewSchemasForm
          handleSave={() => {
            setCreateSchemasModalIsOpen(false);
            refetchQuery();
          }}
          handleCancel={() => setCreateSchemasModalIsOpen(false)}
        />
      </Modal>
    </>
  );
};

export default AggregatedSchemas;
