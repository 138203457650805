import { gql } from "urql";

export const getWoodAttachment = gql`
  query($id: ID) {
    woodSchema(filter: { id: $id }) {
      __typename
      id
      attachments {
        id
        sourceFileName
        fileName
      }
    }
  }
`;
