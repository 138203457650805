import { gql } from "urql";
export const getEnergySchemaInfo = gql`
  query($id: ID!) {
    energySchema(filter: { id: $id }) {
      __typename
      id
      isNonUtilizedWasteHeat
      isWasteHeatUtilized
      organizationId
      schemaIsDelivered
      year
      sidestreamName
    }
  }
`;
