import React from "react";
import { Redirect } from "react-router-dom";
import { useQuery } from "urql";
import EmptyState from "../../components/EmptyState";
import ErrorMessage from "../../components/ErrorMessage";
import Spinner from "../../components/Loading";
import { getOrganizationsList } from "../../graphql/queries/Organizations/getOrganizations";
import { GET_ORGANIZATION_ROUTE, ROUTES } from "../../lib/Router/routes";
import { GetOrganizationsQueryResult, SidestreamTypeEnum } from "../../types";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { INTRODUCTION_SHOWED } from "../../lib/constants";

const Dashboard: React.FC = () => {
  const [introductionShowed] = useSessionStorage(INTRODUCTION_SHOWED, false);
  const [{ data, fetching, error }] = useQuery<GetOrganizationsQueryResult>({
    query: getOrganizationsList,
  });

  if (fetching) return <Spinner />;
  if (error) return <ErrorMessage />;
  if (!data || data.organizations.length < 1) return <EmptyState title="Du har ingen tilganger"></EmptyState>;

  if (data.organizations.length === 1) {
    const organization = data.organizations[0];

    if (introductionShowed) {
      return <Redirect to={GET_ORGANIZATION_ROUTE(organization.id)} />;
    } else {
      if (organization.sidestreams.length > 0) {
        const firstOrganizationSidestream = organization.sidestreams[0];

        if (firstOrganizationSidestream.type === SidestreamTypeEnum.WOOD)
          return <Redirect to={ROUTES.AUTHORIZED.WOOD_SCHEMA_INTRO_ROUTE} />;
        else if (firstOrganizationSidestream.type === SidestreamTypeEnum.ENERGY)
          return <Redirect to={ROUTES.AUTHORIZED.ENERGY_FLOW_INTRO_ROUTE} />;
        else if (firstOrganizationSidestream.type === SidestreamTypeEnum.MATERIALSTREAM) {
          return <Redirect to={ROUTES.AUTHORIZED.MATERIAL_FLOW_INTRO_ROUTE} />;
        }
      }
    }
  }

  return <Redirect to={ROUTES.AUTHORIZED.ORGANIZATIONS_ROUTE} />;
};

export default Dashboard;
