import React from "react";
import styled from "styled-components";
import { BooleanSelectExtended, ParticleType, ParticleUnitSize, WaterReactivity, AutoIgnitionHazard } from "../../../../dictionaries";
//import { getMaterialStreamAttachment } from "../../../../graphql/queries/Attachment/getMaterialStreamAttachment";
import { getHSentences } from "../../../../graphql/queries/Materialflow/getHSentences";
import { getRSentences } from "../../../../graphql/queries/Materialflow/getRSentences";
import { getSSentences } from "../../../../graphql/queries/Materialflow/getSSentences";
import SentenceSelect from "../../../../components/SentenceSelect";
import { SelectValues } from "../../../../utils/form-utils";

enum InputWidth {
  LONG,
  SHORT,
}
enum InputDirection {
  COLUMN,
  ROW,
}

const FirstColumn = styled.div`
  grid-column: 1/2;
`;

const Question = styled(FirstColumn)``;
const HeaderTitle = styled(FirstColumn)`
  font-weight: 600;
`;
const CommentField = styled.textarea`
  grid-column: 1/3;
  height: 6em;
  justify-self: end;
`;
const InputField = styled.textarea<InputProps>`
  grid-column: 2/3;
  width: ${(props) => (props.size === InputWidth.LONG ? "50%" : props.size === InputWidth.SHORT ? "10%" : "100%")};
`;
const SelectField = styled.select<InputProps>`
  width: ${(props) => (props.size === InputWidth.LONG ? "80%" : props.size === InputWidth.SHORT ? "30%" : "100%")};
`;
const SpecialUserInput = styled.div`
  display: grid;
  grid-column: 1/3;
`;
const SpecialQuestion = styled.div`
  grid-column: 1/2;
`;
const SpecialInput = styled.div`
  grid-column: 2/3;
  display: flex;
  column-gap: ${(props) => props.theme.gap.medium};
  justify-content: flex-end;
  flex-direction: row;
`;
const SentenceContainer = styled.div`
  grid-column: 1/3;
  display: grid;
  grid-template-columns: 1fr 2fr;
`;
const Unit = styled.div`
  grid-column: 3/4;
  align-self: center;
  margin-left: 1rem;
`;

const FormError = styled.div`
  grid-column: 2;
  margin-bottom: 1em;
`;

interface InputProps {
  size?: InputWidth;
  direction?: InputDirection;
}
type AboutMaterialDataProps = {
  register: any;
  errors: any;
  data: any;
  control: any;
  getValues: any;
};

const AboutMaterialData: React.FC<AboutMaterialDataProps> = ({ register, errors, data, control, getValues }) => {
  return (
    <>
      <HeaderTitle>HMS klassifiseringer</HeaderTitle>
      <SentenceContainer>
        <SentenceSelect
          query={getRSentences}
          control={control}
          register={register}
          fieldName="materialStreamMaterial.rSentences"
          label="R-setninger"
        />
      </SentenceContainer>{" "}
      <SentenceContainer>
        <SentenceSelect
          query={getSSentences}
          control={control}
          register={register}
          fieldName="materialStreamMaterial.sSentences"
          label="S-setninger"
        />
      </SentenceContainer>{" "}
      <SentenceContainer>
        <SentenceSelect
          query={getHSentences}
          control={control}
          register={register}
          fieldName="materialStreamMaterial.hSentences"
          label="H-setninger"
        />
      </SentenceContainer>
      <Question>Er materialet klassifisert som farlig avfall? *</Question>
      <SelectField ref={register} name="materialStreamMaterial.dangerousWaste">
        <option value="">Vennligst velg</option>
        {SelectValues(BooleanSelectExtended)}
      </SelectField>
      {errors.materialStreamMaterial?.dangerousWaste && (
        <FormError className="error">{errors.materialStreamMaterial?.dangerousWaste.message}</FormError>
      )}
      <Question>Eksisterer det begrensninger eller andre myndighetskrav til deponering av materialet? *</Question>
      <SelectField ref={register} name="materialStreamMaterial.limitationsToMaterialDeposit">
        <option value="">Vennligst velg</option>
        {SelectValues(BooleanSelectExtended)}
      </SelectField>
      {errors.materialStreamMaterial?.limitationsToMaterialDeposit && (
        <FormError className="error">{errors.materialStreamMaterial?.limitationsToMaterialDeposit.message}</FormError>
      )}
      <Question>Kort beskrivelse av evt. krav til deponering</Question>
      <CommentField
        ref={register}
        name="materialStreamMaterial.depositDemandsComment"
        data-testid="disposaldescriptiontest"
        role="textbox"
        placeholder="Comments"
      />
      <HeaderTitle>Reaktivitet</HeaderTitle>
      <Question>Reaktivitet med vann *</Question>
      <SelectField ref={register} name="materialStreamMaterial.waterReactivity">
        <option value="">Vennligst velg</option>
        {SelectValues(WaterReactivity)}
      </SelectField>
      {errors.materialStreamMaterial?.waterReactivity && (
        <FormError className="error">{errors.materialStreamMaterial?.waterReactivity.message}</FormError>
      )}
      <Question>Selvantennelsesfare *</Question>
      <SelectField ref={register} name="materialStreamMaterial.autoIgnitionHazard">
        <option value="">Vennligst velg</option>
        {SelectValues(AutoIgnitionHazard)}
      </SelectField>
      {errors.materialStreamMaterial?.autoIgnitionHazard && (
        <FormError className="error">{errors.materialStreamMaterial?.autoIgnitionHazard.message}</FormError>
      )}
      <HeaderTitle>Partikkel</HeaderTitle>
      <Question>Type partikkel *</Question>
      <SelectField ref={register} name="materialStreamMaterial.particleType">
        <option value="">Vennligst velg</option>
        {SelectValues(ParticleType)}
      </SelectField>
      {errors.materialStreamMaterial?.particleType && (
        <FormError className="error">{errors.materialStreamMaterial?.particleType.message}</FormError>
      )}
      <Question>Velg enhet for partikkelstørrelse</Question>
      <SelectField ref={register} name="materialStreamMaterial.particeSizeUnit">
        <option value="">Vennligst velg</option>
        {SelectValues(ParticleUnitSize)}
      </SelectField>
      {errors.materialStreamMaterial?.particeSizeUnit && (
        <FormError className="error">{errors.materialStreamMaterial?.particeSizeUnit.message}</FormError>
      )}
      <SpecialUserInput>
        <SpecialQuestion>Partikkelstørrelse</SpecialQuestion>
        <SpecialInput>
          <label htmlFor="materialStreamMaterial.particleSizeD50">D50 (median diameter)</label>
          <InputField size={InputWidth.SHORT} ref={register} name="materialStreamMaterial.particleSizeD50" />
          {errors.materialStreamMaterial?.particleSizeD50 && (
            <FormError className="error">{errors.materialStreamMaterial?.particleSizeD50.message}</FormError>
          )}
          <label htmlFor="materialStreamMaterial.particleSizeMin">Min</label>
          <InputField size={InputWidth.SHORT} ref={register} name="materialStreamMaterial.particleSizeMin" />
          {errors.materialStreamMaterial?.particleSizeMin && (
            <FormError className="error">{errors.materialStreamMaterial?.particleSizeMin.message}</FormError>
          )}
          <label htmlFor="materialStreamMaterial.particleSizeMax">Max</label>
          <InputField size={InputWidth.SHORT} ref={register} name="materialStreamMaterial.particleSizeMax" />
          {errors.materialStreamMaterial?.particleSizeMax && (
            <FormError className="error">{errors.materialStreamMaterial?.particleSizeMax.message}</FormError>
          )}
        </SpecialInput>
      </SpecialUserInput>
      <Question>Kommentar til partikkelstørrelse (eksempelvis målemetode)</Question>
      <CommentField ref={register} name="materialStreamMaterial.particleSizeComment" placeholder="Comments" />
      <Question>Typisk fuktighetsinnhold</Question>
      <InputField ref={register} name="materialStreamMaterial.moistureContent" /> <Unit>%</Unit>
      {errors.materialStreamMaterial?.moistureContent && (
        <FormError className="error">{errors.materialStreamMaterial?.moistureContent.message}</FormError>
      )}
    </>
  );
};

export default AboutMaterialData;
