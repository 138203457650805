import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Bottom from "../../../components/Bottom";
import Button, { ButtonSize, ButtonType } from "../../../components/Button";
import Introduction, { Intro } from "../../../components/Introduction";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
import { INTRODUCTION_SHOWED } from "../../../lib/constants";
import { ROUTES } from "../../../lib/Router/routes";

const DescriptionContainer = styled.div`
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: ${(props) => props.theme.gap.light};
`;

const SurveyIntroduction: React.FC = () => {
  const [, setIntroductionShowed] = useSessionStorage(INTRODUCTION_SHOWED, false);
  const history = useHistory();

  const introText: Intro = {
    title: "Veikart for spillvarme",
    contactPerson: [
      {
        email: "stine@eydecluster.no",
        name: "Stine Skagestad",
        phone: "992 35 515",
      },
    ],
    description: `
    Veikart for spillvarme er et prosjekt som er startet for å fremme økt
          ressurseffektivitet i regionen. Kraftkrevende industri har gjort mye
          for å energieffektivisere og utnytte spillvarme de siste årene. Men
          fortsatt er det store mengder overskuddsvarme tilgjengelig. Denne
          kartleggingen er et ledd i fortsatt fremdrift på dette området.
    `,
    footNote: `Norsk Energi har bistått med utarbeidelse og kvalitetssikring av registreringsskjemaet.`,
    question: `Spørsmål om prosjektet
    eller undersøkelsen?`,
    orderedList: [
      {
        subTitle: "Vi ønsker å finne potensialet for:",
        orderedItemText: [
          `Utnyttelse av spillvarme internt i bedriften (f.eks. til å erstatte
            annen oppvarming eller tørke materialer etc)`,
          `Utnyttelse av spillvarme eksternt ("Industriell Symbiose")`,
        ],
      },
      {
        subTitle: "I tillegg til dette ønsker vi:",
        orderedItemText: [
          ` Å få til en deling av gode energitiltak som er gjort i bedrifter -
        En database for læring`,
          `Å gi en oversikt over de muliggjørende teknologiene for
        spillvarmeutnyttelse (litteraturstudium)`,
        ],
      },
    ],
    boxTitle: "INCUBIS",
    boxDescription: `Noen resultater fra kartleggingen vil bli brukt videre i forbindelse
    med et EU prosjekt som omhandler Industriell Symbiose.`,
    unorderedList: [
      {
        subTitle: "Hensikten med denne undersøkelsen er:",
        unorderedItemText: [
          "Å få en oversikt over energibruk",
          " Å få en oversikt over uutnyttende spillvarmekilder",
          ` Å få oversikt over interne spillvarmemottakere i bedriften for å
        se på potensiale for egen spillvarmeutnyttelse`,
          `Å få en oversikt over tiltak og prosjekter som er gjennomført
        eller planlagt for å få erfaringsoverføring mellom bedrifter`,
          `Å få en oversikt over potensialet for eksterne spillvarmemottakere
        f.eks fjernvarme eller andre bedrifter (industriell symbiose)`,
        ],
      },
    ],
  };

  return (
    <DescriptionContainer>
      <Introduction introText={introText} />
      <Bottom>
        <Button
          buttonType={ButtonType.Primary}
          size={ButtonSize.Large}
          onClick={() => {
            setIntroductionShowed(true);

            history.push(ROUTES.AUTHORIZED.DASHBOARD_ROUTE);
          }}
        >
          Gå videre
        </Button>
      </Bottom>
    </DescriptionContainer>
  );
};

export default SurveyIntroduction;
