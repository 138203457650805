import { GlobalStyle } from "./GlobalStyle";
import EydeThemeProvider from "./providers/EydeThemeProvider";
import { createClient, Provider, dedupExchange, fetchExchange } from "urql";
import { cache } from "./utils/cache-utils";
import { AuthProvider } from "./hooks/useAuth";
import Router from "./lib/Router/Router";
import Toaster from "./components/Toaster";
import Modal from "react-modal";
import { IconContext } from "react-icons";

const client = createClient({
  url: process.env.REACT_APP_API_URL!,
  exchanges: [dedupExchange, cache, fetchExchange],
  fetchOptions: { credentials: "include" },
  requestPolicy: "network-only",
});

// Modal requires the app element for accessibility
Modal.setAppElement("#root");

function App() {
  return (
    <AuthProvider>
      <Provider value={client}>
        <EydeThemeProvider>
          <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
            <GlobalStyle />
            <Router />
            <Toaster position="bottom-left" autoClose={4000} hideProgressBar closeOnClick pauseOnHover />
          </IconContext.Provider>
        </EydeThemeProvider>
      </Provider>
    </AuthProvider>
  );
}

export default App;
