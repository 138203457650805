import * as yup from "yup";

export const generalSchema = yup.object().shape({
  organization: yup.object().shape({
    name: yup.string().required("Vennligst oppgi et navn"),
  }),
});

export const energyUsageSchema = yup.object().shape({
  energyTypes: yup.array().of(
    yup.object().shape({
      energyTypeName: yup.string().required("Feltet Energiform, vennligst velg ett av alternativene"),
      energyUsage: yup
        .number()

        .typeError("Feltet GWh/år må fylles ut med tall")

        .positive("Feltet GWh/år må fylles ut med positive tall")
        .transform((_value, originalValue) => {
          if (originalValue.includes(",")) {
            return Number(originalValue.replace(/,/, "."));
          } else {
            return Number(originalValue);
          }
        })
        .required("Feltet GWh/år må fylles ut"),
      additionalInformation: yup.string(),
    })
  ),
});

export const utilizedEnergySchema = yup.object().shape({
  utilizedWasteHeats: yup.array().of(
    yup.object().shape({
      utilizedWasteHeatInformation: yup.string().required("Feltet Hva utnyttes spillvarme til?, må fylles ut"),
      utilizedWasteHeatUsage: yup
        .number()

        .typeError("Feltet GWh/år må fylles ut med tall")

        .positive("Feltet GWh/år må fylles ut med positive tall")
        .transform((_value, originalValue) => {
          if (originalValue.includes(",")) {
            return Number(originalValue.replace(/,/, "."));
          } else {
            return Number(originalValue);
          }
        })
        .required("Feltet GWh/år må fylles ut"),
      utilizedWasteHeat: yup.string().required("Feltet Elektrisk eller Termisk, vennligst velg ett av alternativene"),
    })
  ),
});

export const nonUtilizedEnergySchema = yup.object().shape({
  energyWastes: yup.array().of(
    yup.object().shape({
      energyWasteName: yup.string().required("Feltet må fylles ut"),

      wasteSource: yup

        .string()

        .required("Vennligst velg ett av alternativene"),

      temperature: yup
        .number()
        .transform((_value, originalValue) => {
          if (originalValue.includes(",")) {
            return Number(originalValue.replace(/,/, "."));
          } else {
            return Number(originalValue);
          }
        })
        .typeError("Feltet må fylles ut med tall")
        .positive("Feltet må fylles ut")

        .required("Feltet må fylles ut"),

      pressure: yup
        .number()
        .transform((_value, originalValue) => {
          if (originalValue.includes(",")) {
            return Number(originalValue.replace(/,/, "."));
          } else {
            return Number(originalValue);
          }
        })
        .typeError("Feltet må være et tall")
        .nullable(),
      energyAmount: yup
        .number()
        .transform((_value, originalValue) => {
          if (originalValue.includes(",")) {
            return Number(originalValue.replace(/,/, "."));
          } else {
            return Number(originalValue);
          }
        })
        .typeError("Feltet må være et tall")
        .nullable(),
      averageEffect: yup
        .number()
        .transform((_value, originalValue) => {
          if (originalValue.includes(",")) {
            return Number(originalValue.replace(/,/, "."));
          } else {
            return Number(originalValue);
          }
        })
        .typeError("Feltet må være et tall")
        .nullable(),
      maxEffect: yup
        .number()
        .transform((_value, originalValue) => {
          if (originalValue.includes(",")) {
            return Number(originalValue.replace(/,/, "."));
          } else {
            return Number(originalValue);
          }
        })
        .typeError("Feltet må være et tall")
        .nullable(),
      massAmount: yup
        .number()
        .transform((_value, originalValue) => {
          if (originalValue.includes(",")) {
            return Number(originalValue.replace(/,/, "."));
          } else {
            return Number(originalValue);
          }
        })
        .typeError("Feltet må være et tall")
        .nullable(),
      availability: yup
        .number()
        .transform((_value, originalValue) => {
          if (originalValue.includes(",")) {
            return Number(originalValue.replace(/,/, "."));
          } else {
            return Number(originalValue);
          }
        })
        .typeError("Feltet må fylles ut med tall")
        .positive("Feltet må fylles ut med tall")

        .required("Feltet må fylles ut"),

      pollutedWith: yup
        .string()

        .required("Vennligst velg ett av alternativene"),

      internalPlan: yup
        .string()

        .required("Vennligst velg ett av alternativene"),

      plannedRecoveredInternal: yup
        .number()
        .transform((_value, originalValue) => {
          if (originalValue.includes(",")) {
            return Number(originalValue.replace(/,/, "."));
          } else {
            return Number(originalValue);
          }
        })
        .typeError("Feltet må være et tall")
        .nullable(),
      externalPlan: yup.string().required("Vennligst velg ett av alternativene"),

      distanceToExternal: yup
        .number()
        .transform((_value, originalValue) => {
          if (originalValue.includes(",")) {
            return Number(originalValue.replace(/,/, "."));
          } else {
            return Number(originalValue);
          }
        })
        .typeError("Feltet må være et tall")
        .nullable(),
      temperatureNeeds: yup
        .number()
        .transform((_value, originalValue) => {
          if (originalValue.includes(",")) {
            return Number(originalValue.replace(/,/, "."));
          } else {
            return Number(originalValue);
          }
        })
        .typeError("Feltet må være et tall")
        .nullable(),
      plannedRecoveredExternal: yup
        .number()
        .transform((_value, originalValue) => {
          if (originalValue.includes(",")) {
            return Number(originalValue.replace(/,/, "."));
          } else {
            return Number(originalValue);
          }
        })
        .typeError("Feltet må være et tall")
        .nullable(),
      comment: yup.string(),
    })
  ),
});

export const aboutMaterialSchema = yup.object().shape({
  materialStreamMaterial: yup.object().shape({
    dangerousWaste: yup.string().required("Vennligst velg ett av alternativene"),
    limitationsToMaterialDeposit: yup.string().required("Vennligst velg ett av alternativene"),
    waterReactivity: yup.string().required("Vennligst velg ett av alternativene"),
    autoIgnitionHazard: yup.string().required("Vennligst velg ett av alternativene"),
    particleType: yup.string().required("Vennligst velg ett av alternativene"),
    particeSizeUnit: yup.string().required("Vennligst velg ett av alternativene"),
    particleSizeD50: yup
      .number()
      .transform((_value, originalValue) => {
        if (originalValue.includes(",")) {
          return Number(originalValue.replace(/,/, "."));
        } else {
          return Number(originalValue);
        }
      })
      .typeError("Feltet må være et tall")
      .nullable(),
    particleSizeMin: yup
      .number()
      .transform((_value, originalValue) => {
        if (originalValue.includes(",")) {
          return Number(originalValue.replace(/,/, "."));
        } else {
          return Number(originalValue);
        }
      })
      .typeError("Feltet må være et tall")
      .nullable(),
    particleSizeMax: yup
      .number()
      .transform((_value, originalValue) => {
        if (originalValue.includes(",")) {
          return Number(originalValue.replace(/,/, "."));
        } else {
          return Number(originalValue);
        }
      })
      .typeError("Feltet må være et tall")
      .nullable(),
    moistureContent: yup
      .number()
      .transform((_value, originalValue) => {
        if (originalValue.includes(",")) {
          return Number(originalValue.replace(/,/, "."));
        } else {
          return Number(originalValue);
        }
      })
      .max(100, "Oppgi prosent (max 100)")
      .typeError("Feltet må være et tall")
      .nullable(),
  }),
});

export const aboutMaterialFlowSchema = yup.object().shape({
  materialStreamAbout: yup.object().shape({
    materialType: yup.string().required("Vennligst velg ett av alternativene"),
    productionVolumeUnit: yup.string().required("Vennligst velg ett av alternativene"),
    yearlyProduction: yup
      .number()
      .transform((_value, originalValue) => {
        if (originalValue.includes(",")) {
          return Number(originalValue.replace(/,/, "."));
        } else {
          return Number(originalValue);
        }
      })
      .typeError("Feltet må være et tall")
      .nullable(),
    productionPeriod: yup.string().required("Vennligst velg ett av alternativene"),
    productionVolumeBatch: yup
      .number()
      .transform((_value, originalValue) => {
        if (originalValue.includes(",")) {
          return Number(originalValue.replace(/,/, "."));
        } else {
          return Number(originalValue);
        }
      })
      .typeError("Feltet må være et tall")
      .nullable(),
    materialState: yup.string().required("Vennligst velg ett av alternativene"),
    organic: yup.string().required("Vennligst velg ett av alternativene"),
  }),
});

export const materialAnalysisSchema = yup.object().shape({
  chemicalAnalysises: yup.array().of(
    yup.object().shape({
      chemicalAnalysisName: yup.string().required("Vennligst oppgi et navn"),
      chemicalUnit: yup.string().required("Vennligst velg ett av alternativene"),
      typicalValue: yup
        .number()
        .required()
        .transform((_value, originalValue) => {
          if (originalValue.includes(",")) {
            return Number(originalValue.replace(/,/, "."));
          } else {
            return Number(originalValue);
          }
        })
        .typeError("Feltet må være et tall"),
      minValue: yup
        .number()
        .transform((_value, originalValue) => {
          if (originalValue.includes(",")) {
            return Number(originalValue.replace(/,/, "."));
          } else {
            return Number(originalValue);
          }
        })
        .typeError("Feltet må være et tall")
        .nullable(),
      maxValue: yup
        .number()
        .transform((_value, originalValue) => {
          if (originalValue.includes(",")) {
            return Number(originalValue.replace(/,/, "."));
          } else {
            return Number(originalValue);
          }
        })
        .typeError("Feltet må være et tall")
        .nullable(),
    })
  ),
});

export const materialFlowUtilizationSchema = yup.object().shape({
  materialStreamUsage: yup.object().shape({
    isBurned: yup.string().required("Vennligst velg ett av alternativene"),
    internalDeposition: yup
      .number()
      .transform((_value, originalValue) => {
        if (originalValue.includes(",")) {
          return Number(originalValue.replace(/,/, "."));
        } else {
          return Number(originalValue);
        }
      })
      .typeError("Feltet må være et tall")
      .nullable(),
    externalDeposition: yup
      .number()
      .transform((_value, originalValue) => {
        if (originalValue.includes(",")) {
          return Number(originalValue.replace(/,/, "."));
        } else {
          return Number(originalValue);
        }
      })
      .typeError("Feltet må være et tall")
      .nullable(),
    internalRecycling: yup
      .number()
      .transform((_value, originalValue) => {
        if (originalValue.includes(",")) {
          return Number(originalValue.replace(/,/, "."));
        } else {
          return Number(originalValue);
        }
      })
      .typeError("Feltet må være et tall")
      .nullable(),
    externalSales: yup
      .number()
      .transform((_value, originalValue) => {
        if (originalValue.includes(",")) {
          return Number(originalValue.replace(/,/, "."));
        } else {
          return Number(originalValue);
        }
      })
      .typeError("Feltet må være et tall")
      .nullable(),
  }),
});

export const materialOtherInformationSchema = yup.object().shape({
  otherInformation: yup.string().required("Vennligst velg ett av alternativene"),
});

export const aboutWoodSchema = yup.object().shape({
  reportPeriod: yup.string().required("Vennligst velg ett av alternativene"),
  storage: yup.string().required("Vennligst velg ett av alternativene"),
  reportYear: yup.string().required("Vennligst fyll inn et årstall"),
  yearlyProduction: yup
    .number()
    .transform((_value, originalValue) => {
      if (originalValue.includes(",")) {
        return Number(originalValue.replace(/,/, "."));
      } else {
        return Number(originalValue);
      }
    })
    .typeError("Feltet må være et tall")
    .nullable(),
  // januaryProduction: yup
  //   .number()
  //   .transform((_value, originalValue) => {
  //     if (originalValue.includes(",")) {
  //       return Number(originalValue.replace(/,/, "."));
  //     } else {
  //       return Number(originalValue);
  //     }
  //   })
  //   .typeError("Feltet må være et tall")
  //   .nullable(),
  // februaryProduction: yup
  //   .number()
  //   .transform((_value, originalValue) => {
  //     if (originalValue.includes(",")) {
  //       return Number(originalValue.replace(/,/, "."));
  //     } else {
  //       return Number(originalValue);
  //     }
  //   })
  //   .typeError("Feltet må være et tall")
  //   .nullable(),
  // marchProduction: yup
  //   .number()
  //   .transform((_value, originalValue) => {
  //     if (originalValue.includes(",")) {
  //       return Number(originalValue.replace(/,/, "."));
  //     } else {
  //       return Number(originalValue);
  //     }
  //   })
  //   .typeError("Feltet må være et tall")
  //   .nullable(),
  // aprilProduction: yup
  //   .number()
  //   .transform((_value, originalValue) => {
  //     if (originalValue.includes(",")) {
  //       return Number(originalValue.replace(/,/, "."));
  //     } else {
  //       return Number(originalValue);
  //     }
  //   })
  //   .typeError("Feltet må være et tall")
  //   .nullable(),
  // mayProduction: yup
  //   .number()
  //   .transform((_value, originalValue) => {
  //     if (originalValue.includes(",")) {
  //       return Number(originalValue.replace(/,/, "."));
  //     } else {
  //       return Number(originalValue);
  //     }
  //   })
  //   .typeError("Feltet må være et tall")
  //   .nullable(),
  // juneProduction: yup
  //   .number()
  //   .transform((_value, originalValue) => {
  //     if (originalValue.includes(",")) {
  //       return Number(originalValue.replace(/,/, "."));
  //     } else {
  //       return Number(originalValue);
  //     }
  //   })
  //   .typeError("Feltet må være et tall")
  //   .nullable(),
  // julyProduction: yup
  //   .number()
  //   .transform((_value, originalValue) => {
  //     if (originalValue.includes(",")) {
  //       return Number(originalValue.replace(/,/, "."));
  //     } else {
  //       return Number(originalValue);
  //     }
  //   })
  //   .typeError("Feltet må være et tall")
  //   .nullable(),
  // augustProduction: yup
  //   .number()
  //   .transform((_value, originalValue) => {
  //     if (originalValue.includes(",")) {
  //       return Number(originalValue.replace(/,/, "."));
  //     } else {
  //       return Number(originalValue);
  //     }
  //   })
  //   .typeError("Feltet må være et tall")
  //   .nullable(),
  // septemberProduction: yup
  //   .number()
  //   .transform((_value, originalValue) => {
  //     if (originalValue.includes(",")) {
  //       return Number(originalValue.replace(/,/, "."));
  //     } else {
  //       return Number(originalValue);
  //     }
  //   })
  //   .typeError("Feltet må være et tall")
  //   .nullable(),
  // octoberProduction: yup
  //   .number()
  //   .transform((_value, originalValue) => {
  //     if (originalValue.includes(",")) {
  //       return Number(originalValue.replace(/,/, "."));
  //     } else {
  //       return Number(originalValue);
  //     }
  //   })
  //   .typeError("Feltet må være et tall")
  //   .nullable(),
  // novemberProduction: yup
  //   .number()
  //   .transform((_value, originalValue) => {
  //     if (originalValue.includes(",")) {
  //       return Number(originalValue.replace(/,/, "."));
  //     } else {
  //       return Number(originalValue);
  //     }
  //   })
  //   .typeError("Feltet må være et tall")
  //   .nullable(),
  // decemberProduction: yup
  //   .number()
  //   .transform((_value, originalValue) => {
  //     if (originalValue.includes(",")) {
  //       return Number(originalValue.replace(/,/, "."));
  //     } else {
  //       return Number(originalValue);
  //     }
  //   })
  //   .typeError("Feltet må være et tall")
  //   .nullable(),
});

export const woodUtilizationSchema = yup.object().shape({
  internalEnergyRecycling: yup
    .number()
    .transform((_value, originalValue) => {
      if (originalValue.includes(",")) {
        return Number(originalValue.replace(/,/, "."));
      } else {
        return Number(originalValue);
      }
    })
    .typeError("Feltet må være et tall")
    .nullable(),
  externalEnergyRecycling: yup
    .number()
    .transform((_value, originalValue) => {
      if (originalValue.includes(",")) {
        return Number(originalValue.replace(/,/, "."));
      } else {
        return Number(originalValue);
      }
    })
    .typeError("Feltet må være et tall")
    .nullable(),
  internalMaterialRecycling: yup
    .number()
    .transform((_value, originalValue) => {
      if (originalValue.includes(",")) {
        return Number(originalValue.replace(/,/, "."));
      } else {
        return Number(originalValue);
      }
    })
    .typeError("Feltet må være et tall")
    .nullable(),
  externalMaterialRecycling: yup
    .number()
    .transform((_value, originalValue) => {
      if (originalValue.includes(",")) {
        return Number(originalValue.replace(/,/, "."));
      } else {
        return Number(originalValue);
      }
    })
    .typeError("Feltet må være et tall")
    .nullable(),
  reuse: yup
    .number()
    .transform((_value, originalValue) => {
      if (originalValue.includes(",")) {
        return Number(originalValue.replace(/,/, "."));
      } else {
        return Number(originalValue);
      }
    })
    .typeError("Feltet må være et tall")
    .nullable(),
  reuseFurniture: yup
    .number()
    .transform((_value, originalValue) => {
      if (originalValue.includes(",")) {
        return Number(originalValue.replace(/,/, "."));
      } else {
        return Number(originalValue);
      }
    })
    .typeError("Feltet må være et tall")
    .nullable(),
  reuseBuildingMaterials: yup
    .number()
    .transform((_value, originalValue) => {
      if (originalValue.includes(",")) {
        return Number(originalValue.replace(/,/, "."));
      } else {
        return Number(originalValue);
      }
    })
    .typeError("Feltet må være et tall")
    .nullable(),
  links: yup.array().of(
    yup.object().shape({
      url: yup
        .string()
        .url("Adressen er ikke en gyldig lenke. Må utfylles med følgende format: https://www.eksempel.no")
        .required("Lenke kan ikke være blank"),
    })
  ),
});

export const aboutWoodMaterialSchema = yup.object().shape({
  dangerousWaste: yup.string().required("Vennligst velg ett av alternativene"),
  limitationsToMaterialDeposit: yup.string().required("Vennligst velg ett av alternativene"),
  autoIgnitionHazard: yup.string().required("Vennligst velg ett av alternativene"),
  treatmentAfterDelivery: yup.string().required("Vennligst velg ett av alternativene"),
});

export const passwordSchema = yup
  .string()
  .required("Må fylles ut")
  .matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,}$/,
    "Passord må være minst 8 tegn, inneholde store og små bokstaver, tall og tegn"
  );

export const emailSchema = yup.string().email("Må være en gyldig e-post").required("Må fylles ut");

export const changePasswordSchema = yup.object().shape({
  currentPassword: yup.string().required("Må fylles ut"),
  newPassword: passwordSchema,
  confirmPassword: yup
    .string()
    .required("Må fylles ut")
    .oneOf([yup.ref("newPassword"), null], "Bekreftet passord må være lik nytt passord"),
});

export const generateResetPasswordSchema = yup.object().shape({
  email: emailSchema,
});

export const resetPasswordSchema = yup.object().shape({
  email: emailSchema,
  resetToken: yup.string().required("Må fylles ut"),
  newPassword: passwordSchema,
});

export const signInSchema = yup.object().shape({
  email: emailSchema,
  password: yup.string().required("Må fylles ut"),
});

export const createOrganizationSchema = yup.object().shape({
  name: yup.string().required("Må fylles ut"),
  orgId: yup.string().required("Må fylles ut"),
});

export const updateOrganizationSchema = yup.object().shape({
  name: yup.string().required("Må fylles ut"),
  orgId: yup.string().required("Må fylles ut"),
});

export const addOrganizationUserAccessSchema = yup.object().shape({
  user: yup.object().shape({
    value: yup.string().required("Må fylles ut"),
  }),
});

export const createUserSchema = yup.object().shape({
  email: emailSchema,
  fullName: yup.string().required("Må fylles ut"),
  phone: yup.string(),
});

export const createSidestreamSchema = yup.object().shape({
  name: yup.string().required("Må fylles ut"),
  type: yup.string().required("Må fylles ut"),
});

export const updateSidestreamSchema = yup.object().shape({
  name: yup.string().required("Må fylles ut"),
  isInactive: yup.bool().required("Må fylles ut"),
});

export const createNewSchemas = yup.object().shape({
  sidestreamType: yup.string().required("Må fylles ut"),
  organizationIds: yup.array().of(
    yup.object().shape({
      value: yup.string().required("Må fylles ut"),
    })
  ),
});
