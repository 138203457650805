import styled from "styled-components";

export const StepsArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  padding: 1.875rem;
`;
export const StepsOverview = styled.div`
  grid-column: 2/3;
  display: grid;
  grid-template-rows: auto 1fr;
  justify-content: space-between;
`;
export const StepsContainer = styled.div`
  grid-column: 1/3;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  justify-content: space-between;
  width: 55%;
  max-width: 750px;
  min-width: 600px;
`;

export const StepsCircle = styled.div`
  grid-row: 1;
  display: flex;
  flex-direction: row;
  justify-self: center;
  position: relative;
  &:before,
  &:after {
    content: " ";
    width: 100%;
    border-bottom: 6px solid ${(props) => props.theme.colors.bodyBackgroundColor.top};
    position: absolute;
    top: 48%;
  }
  &:before {
    margin-left: -100%;
  }
  &:after {
    margin-left: 100%;
  }
  &.first {
    &:before {
      display: none;
    }
  }
  &.last {
    &:before {
      margin-left: -80%;
    }
    &:after {
      display: none;
    }
  }
`;

export const StepsName = styled.div`
  grid-row: 2/3;
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  display: inline;
  flex-direction: row;
  font-weight: 500;
  font-family: "Roboto Condensed";
  margin-top: 0.3125rem;
  text-align: center;
`;
export const StepsButtons = styled.div`
  grid-column: 4/5;
`;
