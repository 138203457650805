import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Bottom from "../../../components/Bottom";
import Button, { ButtonSize, ButtonType } from "../../../components/Button";
import Introduction, { Intro } from "../../../components/Introduction";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
import { INTRODUCTION_SHOWED } from "../../../lib/constants";
import { ROUTES } from "../../../lib/Router/routes";

const DescriptionContainer = styled.div`
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: ${(props) => props.theme.gap.light};
`;

const WoodIntroduction: React.FC = () => {
  const [, setIntroductionShowed] = useSessionStorage(INTRODUCTION_SHOWED, false);
  const history = useHistory();

  const introText: Intro = {
    title: "Kartlegging av avfallsstrømmer trevirke",
    contactPerson: [
      {
        email: "anne.kristin.peersen@agderfk.no",
        name: "Anne Kristin Peersen",
        phone: "922 27 462",
      },
      {
        email: "geir.hammersmark@agderfk.no",
        name: "Geir Hammersmark",
        phone: "970 27 798",
      },
    ],
    description: `Agder fylkeskommune leder prosjektet «Agder symbiose», og en viktig del av prosjektet er å gjennomføre materialkartlegging. For å få en oversikt over tilgjengelige ressurser ønsker vi å kartlegge avfall/ressursstrømmen «trevirke», og trenger nå din/deres innspill. Vi ønsker å få så god regional oversikt som mulig med hensyn til volumer, og utfordringer for å kunne se på potensielle forretningsmuligheter og mulige pilotprosjekter. 
    `,
    footNote: ``,
    question: `Spørsmål om prosjektet
        eller undersøkelsen?`,
    orderedList: [
      {
        subTitle: "",
        orderedItemText: [],
      },
      {
        subTitle: "",
        orderedItemText: [],
      },
    ],
    boxTitle: "Agder symbiose",
    boxDescription: `«Agder symbiose» er et forprosjekt med støtte fra Klimasats, og gjøres i samarbeid med Eyde-klyngen, UIA (ENTERS), Kristiansand kommune, Arendal kommune og Lister Nyskaping. 
    Prosjekters hovedformål er å finne en fornuftig regional samhandlingsstruktur for offentlig-privat samarbeid, som skal legge til rette for den regionale overgangen mot en ressurseffektiv og konkurransedyktig næringsutvikling, basert på sirkulærøkonomi.
     `,
    unorderedList: [],
  };

  return (
    <DescriptionContainer>
      <Introduction introText={introText} />
      <Bottom>
        <Button
          buttonType={ButtonType.Primary}
          size={ButtonSize.Large}
          onClick={() => {
            setIntroductionShowed(true);

            history.push(ROUTES.AUTHORIZED.DASHBOARD_ROUTE);
          }}
        >
          Gå videre
        </Button>
      </Bottom>
    </DescriptionContainer>
  );
};

export default WoodIntroduction;
