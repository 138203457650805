import { gql } from "urql";

export const getAboutWoodMaterial = gql`
  query($id: ID) {
    woodSchema(filter: { id: $id }) {
      __typename
      id
      dangerousWaste
      limitationsToMaterialDeposit
      depositDemandsComment
      autoIgnitionHazard
      treatmentAfterReceving
    }
  }
`;
