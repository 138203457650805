import { createGlobalStyle } from "styled-components";
import "react-toastify/dist/ReactToastify.css";

export const GlobalStyle = createGlobalStyle` 

body {
  background-color: white;
  font-family: ${(props) => props.theme.fontFamily.roboto};
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 400;
}
#root {
    height: 100vh;
    overflow-y: auto;
  }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
}
 
  
  /*Default Margins */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
    border: 1px solid #ccc;
    border-radius: 0.3125rem; 

  }
  input[type="radio"]{
    height: 1rem;
  }
  input[type="checkbox"]{
    height: 1rem;
    width: 1.1rem;
    margin-bottom: 0;
  }  

  input, select, textarea {
    width: 100%;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    height: 2.375rem;   
    padding: 0 0.75rem;
  }

  textarea {
    border: 1px solid #ccc;
    border-radius: 0.3125rem;
    height: 2.02rem;  
    resize: vertical; 
  }

  select option:first-child{
    color: #898A88;
  }

  label {
    height: 2.3rem;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }

  .label-double-line {
    height: 3rem;
    margin-top: 0;
    margin-bottom: 1.475rem;
  }

  .error {
    color: #B11C39;
    margin-top: 0.1875rem;
    margin-left: 0.1875rem;
  }  

  a {
    color: #064F59;
    text-decoration: none; 

    &:link {}
    &:visited {}
    &:hover {
      text-decoration: underline; 
    }

    &:active {}
}

`;
