import React from "react";

const NoAccess: React.FunctionComponent = () => {
  return (
    <div>
      <h1>Ingen tilgang...</h1>
      <p>Du har ikke tilgang til denne siden.</p>
    </div>
  );
};

export default NoAccess;
