import { gql } from "urql";

export const getUsers = gql`
  query($filterByOrganizationId: ID) {
    users(filter: { filterByOrganizationId: $filterByOrganizationId }) {
      __typename
      id
      fullName
      email
      phone
      roles
    }
  }
`;
