import React from "react";
import { HelpCircle, Mail, Phone } from "react-feather";
import styled from "styled-components";

const IntroductionText = styled.div`
  grid-column: 1/2;
  padding: 0.7em;
`;
const Title = styled.div`
  font-size: ${(props) => props.theme.fontSizes.large};
  font-family: ${(props) => props.theme.fontFamily.robotoCondensed};
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  margin-bottom: 1.5rem;
`;
const Description = styled.div`
  font-size: 1.125rem;
  line-height: 2em;
  white-space: pre-wrap;
`;
const SubTitle = styled.div`
  font-weight: 500;
  font-size: 1.125rem;
  color: ${(props) => props.theme.colors.textColor.mainTextColor};
  padding-bottom: 1em;
  padding-top: 1em;
`;

const OrderedList = styled.ol``;

const OrderedItem = styled.li``;

const UnorderedList = styled.ul`
  padding-top: 0.75rem;
`;

const UnorderedItem = styled.li`
  ::marker {
    color: ${(props) => props.theme.colors.bulletColor};
    font-size: 3em;
    white-space: pre;
  }
`;

const UnorderedItemText = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 1.75rem;
`;

const FootNote = styled.div`
  font-style: italic;
  font-size: 1rem;
  margin-top: 1rem;
`;

const ContactInfoContainer = styled.div`
  grid-column: 1/2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: ${(props) => props.theme.gap.light};
`;
const ContactQuestion = styled.div`
  grid-column: 1/-1;
  grid-row: 1/2;
  font-size: ${(props) => props.theme.fontSizes.medium};
  display: flex;
  gap: 0.3em;
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  justify-self: center;
  font-family: ${(props) => props.theme.fontFamily.robotoCondensed};
  padding-top: 1em;
`;
const Contacts = styled.div`
  grid-column: 1/-1;
  grid-row: 2/3;
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.gap.light};
  justify-content: space-evenly;
`;
const SpecificContactInfo = styled.div`
  display: grid;
  font-size: 1.125rem;
  grid-template-rows: 1fr 1fr 1fr;
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.middle};
  padding: 1.5em;
  border-radius: 1.25em;
  width: 100%;
`;

const QuestionMark = styled(HelpCircle)`
  display: flex;
  place-self: center;
`;

const ContactName = styled.div`
  grid-row: 1/2;
  font-weight: 500;
  margin-bottom: 0.3125em;
`;
const ContactPhone = styled.div`
  grid-row: 2/3;
  display: flex;
  gap: ${(props) => props.theme.gap.light};
  margin-bottom: 0.3125em;
`;

const ContactEmail = styled.a`
  grid-row: 3/4;
  display: flex;
  gap: ${(props) => props.theme.gap.light};
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  font-size: 0.9em;
  text-decoration: none;
  z-index: 0;
  & svg {
    margin-top: 0.1875rem;
  }
`;
const Incubis = styled.div`
  grid-row: 1/2;
  grid-column: 2/3;
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.middle};
  padding: ${(props) => props.theme.padding.light};
  line-height: 2em;
  border-radius: ${(props) => props.theme.borderRadius.light};
`;

const IncubisTitle = styled.div`
  font-size: ${(props) => props.theme.fontSizes.medium};
  display: flex;
  justify-content: center;
  font-family: ${(props) => props.theme.fontFamily.robotoCondensed};
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
`;

const IncubisLine = styled.hr`
  border: 1px solid ${(props) => props.theme.colors.horizontalLineColor.incubas};
`;
const IncubisDescription = styled.div`
  padding: 1em;
`;

interface ContactPerson {
  name: string;
  phone: string;
  email: string;
}
interface IOrderedList {
  subTitle: string;
  orderedItemText: Array<string>;
}
interface IUnorderedList {
  subTitle: string;
  unorderedItemText: Array<string>;
}
export interface Intro {
  title: string;
  description: string;
  orderedList: Array<IOrderedList>;
  footNote: string;
  question: string;
  contactPerson: Array<ContactPerson>;
  boxTitle: string;
  boxDescription: string;
  unorderedList: Array<IUnorderedList>;
}

type IntroductionProps = {
  introText: Intro;
};
const Introduction: React.FC<IntroductionProps> = (props) => {
  /**
   * Returns the start number of the ordered list
   */
  function startNumber(item: IOrderedList[], index: number): number {
    let startingNumber = item[0].orderedItemText.length + 1;
    for (let i = index - 1; i--; i > 0) {
      let temp = item[i].orderedItemText.length;
      startingNumber += temp;
    }
    return startingNumber;
  }

  return (
    <>
      <IntroductionText>
        <Title>{props.introText.title}</Title>
        <Description>
          {props.introText.description}
          {props.introText.orderedList.map((item, index, arr) => {
            return (
              <React.Fragment key={index}>
                <SubTitle>{item.subTitle}</SubTitle>

                <OrderedList start={index === 0 ? 1 : startNumber(arr, index)}>
                  {item.orderedItemText.map((text, index) => {
                    return <OrderedItem key={index}>{text}</OrderedItem>;
                  })}
                </OrderedList>
              </React.Fragment>
            );
          })}

          <FootNote>{props.introText.footNote}</FootNote>
        </Description>
        <ContactInfoContainer>
          <ContactQuestion>
            <QuestionMark size={24} color={"black"} />
            {props.introText.question}
          </ContactQuestion>
          <Contacts>
            {props.introText.contactPerson.map((person) => {
              return (
                <SpecificContactInfo key={person.phone}>
                  <ContactName>{person.name}</ContactName>
                  <ContactPhone>
                    <Phone size={24} />
                    {person.phone}
                  </ContactPhone>
                  <ContactEmail href={`mailto: ${person.email}`}>
                    <Mail size={24} color={"black"} />
                    {person.email}
                  </ContactEmail>
                </SpecificContactInfo>
              );
            })}
          </Contacts>
        </ContactInfoContainer>
      </IntroductionText>
      <Incubis>
        <IncubisTitle>{props.introText.boxTitle}</IncubisTitle>
        <IncubisLine />
        <IncubisDescription>{props.introText.boxDescription}</IncubisDescription>
        {props.introText.unorderedList.map((item, index) => {
          return (
            <React.Fragment key={`item_${index}`}>
              <div>{item.subTitle}</div>
              <UnorderedList>
                {item.unorderedItemText.map((text, index) => {
                  return (
                    <UnorderedItem key={`text_${index}`}>
                      <UnorderedItemText>{text}</UnorderedItemText>
                    </UnorderedItem>
                  );
                })}
              </UnorderedList>
            </React.Fragment>
          );
        })}
      </Incubis>
    </>
  );
};
export default Introduction;
