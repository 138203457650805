import { gql } from "urql";

export const getMaterialStreamOtherInformation = gql`
  query($id: ID) {
    materialStreamOtherInformation(filter: { id: $id }) {
      __typename
      otherInformation
      id
    }
  }
`;
