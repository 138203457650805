import { gql } from "urql";

export const getMunicipalities = gql`
  query {
    municipalities {
      id
      name
      __typename
    }
  }
`;
