import { gql } from "urql";

export const updateAboutMaterial = gql`
  mutation(
    $id: ID
    $dangerousWaste: ExtendedBoolEnum
    $limitationsToMaterialDeposit: ExtendedBoolEnum
    $depositDemandsComment: String
    $waterReactivity: WaterReactivityEnum
    $autoIgnitionHazard: AutoIgnitionEnum
    $particleType: ParticleTypeEnum
    $particeSizeUnit: ParticleSizeEnum
    $particleSizeD50: Float
    $particleSizeMin: Float
    $particleSizeMax: Float
    $particleSizeComment: String
    $moistureContent: Float
    $materialStreamHSentences: [CreateOrUpdateMaterialStreamHSentenceMutationType]
    $materialStreamRSentences: [CreateOrUpdateMaterialStreamRSentenceMutationType]
    $materialStreamSSentences: [CreateOrUpdateMaterialStreamSSentenceMutationType]
  ) {
    updateMaterialStreamMaterial(
      input: {
        id: $id
        dangerousWaste: $dangerousWaste
        limitationsToMaterialDeposit: $limitationsToMaterialDeposit
        depositDemandsComment: $depositDemandsComment
        waterReactivity: $waterReactivity
        autoIgnitionHazard: $autoIgnitionHazard
        particleType: $particleType
        particeSizeUnit: $particeSizeUnit
        particleSizeD50: $particleSizeD50
        particleSizeMin: $particleSizeMin
        particleSizeMax: $particleSizeMax
        particleSizeComment: $particleSizeComment
        moistureContent: $moistureContent
        materialStreamHSentences: $materialStreamHSentences
        materialStreamRSentences: $materialStreamRSentences
        materialStreamSSentences: $materialStreamSSentences
      }
    ) {
      __typename
      id
      success
    }
  }
`;
