import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useMutation } from "urql";
import Button, { ButtonSize, ButtonType } from "../../components/Button";
import ContactPersonSelect from "../../components/ContactPersonSelect";
import ErrorContainer from "../../components/ErrorContainer";
import FormFieldError from "../../components/FormFieldError";
import FormLabel from "../../components/FormLabel";
import Heading from "../../components/Heading";
import MainProductSelect from "../../components/MainProductSelect";
import MunicipalitySelect from "../../components/MunicipalitySelect";
import { IndustryType } from "../../dictionaries";
import { updateOrganization } from "../../graphql/mutations/Organization/updateOrganization";
import { handleMutationError, handleMutationSuccess } from "../../lib/toaster";
import { updateOrganizationSchema } from "../../schemas";
import { UpdateOrganizationFormData, TIndustryType, Organization } from "../../types";
import { IUser } from "../../types/domain/user";
import { IMutationResult } from "../../types/mutations/mutationResult";
import { findSelectValue, SelectValues } from "../../utils/form-utils";

type Props = {
  organization: Organization;
  contactPerson?: IUser;
  handleSave: () => void;
  handleCancel: () => void;
};

const ButtonArea = styled.div`
  text-align: right;
  margin-top: 10px;
`;

const ValidationContainer = styled.div`
  margin-left: 10px;
`;

const UpdateOrganization: React.FC<Props> = (props) => {
  const [result, executeMutation] = useMutation<IMutationResult>(updateOrganization);
  const { register, handleSubmit, errors, control } = useForm<UpdateOrganizationFormData>({
    resolver: yupResolver(updateOrganizationSchema),
    defaultValues: {
      name: props.organization.name,
      orgId: props.organization.orgId,
      industryType: IndustryType[props.organization.industryType as TIndustryType] as TIndustryType,
      location: props.organization.location,
      mainProduct: props.organization.mainProductSelected
        ? { value: props.organization.mainProductSelected.id, label: props.organization.mainProductSelected.name }
        : undefined,
      municipality: props.organization.municipality
        ? { value: props.organization.municipality.id, label: props.organization.municipality.name }
        : undefined,
      contactPerson: props.contactPerson ? { value: props.contactPerson.id, label: props.contactPerson.fullName } : undefined,
    },
  });

  const onSubmit = handleSubmit(({ orgId, name, mainProduct, industryType, municipality, location, contactPerson }) => {
    executeMutation({
      id: props.organization.id,
      orgId,
      name,
      mainProductId: mainProduct ? mainProduct.value.toString() : undefined,
      industryType: industryType ? findSelectValue<TIndustryType>(industryType, IndustryType) : undefined,
      municipalityId: municipality ? municipality.value.toString() : undefined,
      location,
      contactPersonId: contactPerson ? contactPerson.value.toString() : undefined,
    })
      .then(({ data }) => {
        if (data?.updateOrganization?.success) {
          handleMutationSuccess(data.updateOrganization);

          props.handleSave();
        } else {
          handleMutationError();
        }
      })
      .catch(() => handleMutationError());
  });

  return (
    <form onSubmit={onSubmit} noValidate>
      <Heading>Rediger bedriftsopplysninger</Heading>

      <FormLabel>Bedriftens navn</FormLabel>
      <input ref={register} name="name" />
      <ValidationContainer>
        <FormFieldError error={errors.name}></FormFieldError>
      </ValidationContainer>

      <FormLabel>Organisasjonsnummer</FormLabel>
      <input ref={register} name="orgId" />
      <ValidationContainer>
        <FormFieldError error={errors.orgId}></FormFieldError>
      </ValidationContainer>

      <FormLabel>Type industri</FormLabel>
      <select ref={register} name="industryType">
        <option>Vennligst velg</option>
        {SelectValues(IndustryType)}
      </select>
      <ValidationContainer>
        <FormFieldError error={errors.industryType}></FormFieldError>
      </ValidationContainer>

      <FormLabel>Hovedprodukt</FormLabel>
      <MainProductSelect control={control} register={register} fieldName="mainProduct" />
      <ValidationContainer>
        <FormFieldError error={errors.mainProduct?.value}></FormFieldError>
      </ValidationContainer>

      <FormLabel>By/kommune</FormLabel>
      <MunicipalitySelect control={control} register={register} fieldName="municipality" />
      <ValidationContainer>
        <FormFieldError error={errors.municipality?.value}></FormFieldError>
      </ValidationContainer>

      <FormLabel>Adresse</FormLabel>
      <input ref={register} name="location" />
      <ValidationContainer>
        <FormFieldError error={errors.location}></FormFieldError>
      </ValidationContainer>

      <FormLabel>Kontaktperson</FormLabel>
      <ContactPersonSelect control={control} register={register} fieldName="contactPerson" orgId={props.organization.id} />
      <ValidationContainer>
        <FormFieldError error={errors.contactPerson?.value}></FormFieldError>
      </ValidationContainer>

      {result.error && <ErrorContainer>Kan ikke oppdatere bedrift</ErrorContainer>}

      <ButtonArea>
        <Button type="button" size={ButtonSize.Large} buttonType={ButtonType.Secondary} onClick={() => props.handleCancel()}>
          Avbryt
        </Button>

        <Button type="submit" disabled={result.fetching} size={ButtonSize.Large} buttonType={ButtonType.Primary}>
          {result.fetching ? "Oppdaterer..." : "Oppdater bedrift"}
        </Button>
      </ButtonArea>
    </form>
  );
};

export default UpdateOrganization;
