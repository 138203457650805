import React from "react";
import styled from "styled-components";
import Upload from "../../../../components/Upload";
import { calculateSum } from "../../../../utils/form-utils";
import { useFieldArray, Control } from "react-hook-form";
import { UtilizationLink, WoodUtilizationType, AttachmentTypeEnum } from "../../../../types";
import LinksInput from "../../../../components/LinksInput";
//import ResourcePersonsInput from "../../../../components/ResourcePersonsInput";

const Question = styled.div`
  grid-column: 1/2;
  padding-right: ${(props) => props.theme.padding.light};
`;
const QuestionBorder = styled.div`
  border-bottom: 2px solid #ccc;
  grid-column: 1/3;
  margin-bottom: 1rem;
`;
const CheckboxQuestion = styled.label`
  grid-column: 2/2;
`;
const CheckboxInput = styled.input`
  height: auto;
  margin-right: 1em;
`;
const Sum = styled.div`
  grid-column: 1/2;
  justify-self: end;
  align-self: center;
  padding-right: 1em;
`;
const SumNumberContainer = styled.div`
  grid-column: 2/3;
  background-color: #dcdcdc;
  color: ${(props) => props.color};
  height: 2em;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const SumNumber = styled.p`
  padding-right: 1em;
  font-weight: 600;
`;
const UserInput = styled.div`
  grid-column: 2/3;
`;
const UserInputCheckbox = styled.div`
  grid-column: 1/3;
`;
const Unit = styled.div`
  grid-column: 3/4;
  align-self: center;
  padding-left: 0.5em;
`;
const UploadField = styled.div`
  grid-column: 1/3;
`;

const LinksField = styled.div`
  grid-column: 1/3;
`;

// const ResourcePersonsField = styled.div`
//   grid-column: 1/3;
// `;

const InputField = styled.input``;
const CommentField = styled.textarea`
  height: 100%;
  width: 100%;
`;
const HeaderTitle = styled.div`
  grid-column: 1/2;
  font-weight: 600;
  margin-top: 1rem;
`;
const CommentBoxArea = styled.div`
  grid-column: 1/3;
  margin-bottom: 1em;
`;

const FormError = styled.div`
  grid-column: 2;
  margin-bottom: 1em;
`;

type WoodUtilizationDataProps = {
  register: any;
  watch: any;
  control: Control<WoodUtilizationType>;
  errors: any;
};

const WoodUtilizationData: React.FC<WoodUtilizationDataProps> = ({ register, watch, control, errors }) => {
  const {
    fields: linkFields,
    append: appendLink,
    remove: removeLink,
  } = useFieldArray<UtilizationLink, any>({
    control,
    name: "links",
    keyName: "fieldId",
  });

  // const { fields: resourcePersonFields, append: appendResourcePerson, remove: removeResourcePerson } = useFieldArray<
  //   UtilizationResourcePerson,
  //   any
  // >({
  //   control,
  //   name: "resourcePersons",
  //   keyName: "fieldId",
  // });

  function GetTheSum() {
    return <SumNumber>{GetValueFroMSum()}</SumNumber>;
  }

  function GetValueFroMSum() {
    let inputValues = watch([
      "internalEnergyRecycling",
      "externalEnergyRecycling",
      "internalMaterialRecycling",
      "externalMaterialRecycling",
      "reuse",
    ]);
    return calculateSum(inputValues);
  }

  return (
    <>
      <HeaderTitle>Utnyttelse og avhending av materialet i dag</HeaderTitle>
      <Question>Intern energigjenvinning</Question>
      <UserInput>
        <InputField ref={register} name="internalEnergyRecycling" />
      </UserInput>
      <Unit>%</Unit>
      {errors.internalEnergyRecycling && <FormError className="error">{errors.internalEnergyRecycling.message}</FormError>}

      <Question>Ekstern energigjenvinning</Question>
      <UserInput>
        <InputField ref={register} name="externalEnergyRecycling" />
      </UserInput>
      <Unit>%</Unit>
      {errors.externalEnergyRecycling && <FormError className="error">{errors.externalEnergyRecycling.message}</FormError>}
      <Question>Intern materialgjenvinning</Question>
      <UserInput>
        <InputField ref={register} name="internalMaterialRecycling" />
      </UserInput>
      <Unit>%</Unit>
      {errors.internalMaterialRecycling && <FormError className="error">{errors.internalMaterialRecycling.message}</FormError>}
      <Question>Ekstern materialgjenvinning</Question>
      <UserInput>
        <InputField ref={register} name="externalMaterialRecycling" />
      </UserInput>
      <Unit>%</Unit>
      {errors.externalMaterialRecycling && <FormError className="error">{errors.externalMaterialRecycling.message}</FormError>}
      <Question>Ombruk</Question>
      <UserInput>
        <InputField ref={register} name="reuse" />
      </UserInput>
      <Unit>%</Unit>
      {errors.reuse && <FormError className="error">{errors.reuse.message}</FormError>}
      <Sum>Sum</Sum>
      <SumNumberContainer color={GetValueFroMSum() > 100 ? "#B11C39" : "auto"}>
        <GetTheSum />
      </SumNumberContainer>
      <Unit>%</Unit>
      <Question>Dersom ombruk, fordeling møbler</Question>
      <UserInput>
        <InputField ref={register} name="reuseFurniture" />
      </UserInput>
      <Unit>%</Unit>
      {errors.reuseFurniture && <FormError className="error">{errors.reuseFurniture.message}</FormError>}
      <Question>Dersom ombruk, fordeling byggematerialer</Question>
      <UserInput>
        <InputField ref={register} name="reuseBuildingMaterials" />
      </UserInput>
      <Unit>%</Unit>
      {errors.reuseBuildingMaterials && <FormError className="error">{errors.reuseBuildingMaterials.message}</FormError>}
      <HeaderTitle>Potensiell utnyttelse</HeaderTitle>
      <QuestionBorder> </QuestionBorder>
      <Question>Materialet er forsøkt utnyttet i en intern eller ekstern prosess tidligere</Question>
      <UserInput>
        <input ref={register} name="previousInternalAndExternalUsage" type="checkbox" />
      </UserInput>
      {watch("previousInternalAndExternalUsage") && (
        <>
          <UserInputCheckbox>
            <CheckboxQuestion>
              <CheckboxInput ref={register} name="previousInternalUsage" type="checkbox" />
              Internt i bedriften
            </CheckboxQuestion>
          </UserInputCheckbox>

          <UserInputCheckbox>
            <CheckboxQuestion>
              <CheckboxInput ref={register} name="previousExternalUsage" type="checkbox" />
              Eksternt sammen med en partner
            </CheckboxQuestion>
          </UserInputCheckbox>

          <Question>Beskrivelse av hva som er gjort</Question>
          <CommentBoxArea>
            <CommentField ref={register} name="previousInternalAndExternalUsageComment" placeholder="Comments" />
          </CommentBoxArea>
        </>
      )}
      <QuestionBorder> </QuestionBorder>
      <Question>Det finnes pågående utviklingsaktiviteter</Question>
      <UserInput>
        <input ref={register} name="internalAndExternalActivitesForMaterialUsage" type="checkbox" />
      </UserInput>
      {watch("internalAndExternalActivitesForMaterialUsage") && (
        <>
          <UserInputCheckbox>
            <CheckboxQuestion>
              <CheckboxInput ref={register} name="internalActivitesForMaterialUsage" type="checkbox" />
              Internt i bedriften
            </CheckboxQuestion>
          </UserInputCheckbox>
          <UserInputCheckbox>
            <CheckboxQuestion>
              <CheckboxInput ref={register} name="externalActivitesForMaterialUsage" type="checkbox" />
              Eksternt sammen med en partner
            </CheckboxQuestion>
          </UserInputCheckbox>

          <Question>Beskriv pågående aktiviteter</Question>
          <CommentBoxArea>
            <CommentField ref={register} name="internalAndExternalActivitesForMaterialUsageComment" placeholder="Comments" />
          </CommentBoxArea>
        </>
      )}
      <QuestionBorder> </QuestionBorder>

      <Question>Det finnes et større potensiale for utviklingsaktiviteter enn man foretar seg i dag</Question>
      <UserInput>
        <input ref={register} name="internalAndExternalGreaterPotential" type="checkbox" />
      </UserInput>
      {watch("internalAndExternalGreaterPotential") && (
        <>
          <UserInputCheckbox>
            <CheckboxQuestion>
              <CheckboxInput ref={register} name="internalGreaterPotential" type="checkbox" />
              Internt i bedriften
            </CheckboxQuestion>
          </UserInputCheckbox>
          <UserInputCheckbox>
            <CheckboxQuestion>
              <CheckboxInput ref={register} name="externalGreaterPotential" type="checkbox" />
              Eksternt sammen med en partner
            </CheckboxQuestion>
          </UserInputCheckbox>
          <Question>Beskriv potensialet</Question>
          <CommentBoxArea>
            <CommentField ref={register} name="internalAndExternalGreaterPotentialComment" placeholder="Comments" />
          </CommentBoxArea>
        </>
      )}
      <QuestionBorder> </QuestionBorder>
      <UploadField>
        <Upload
          description="Legg gjerne til vedlegg relatert til potensiell utnyttelse. F.eks forskningsartikler..."
          title="Potensiell utnyttelse - vedlegg"
          attachmentType={AttachmentTypeEnum.PotentialUseDocuments}
        />
      </UploadField>
      <LinksField>
        <LinksInput
          description="Legg gjerne til lenker relatert til potensiell utnyttelse. F.eks forskningsartikler..."
          title="Potensiell utnyttelse - lenke"
          fields={linkFields}
          register={register}
          append={appendLink}
          remove={removeLink}
          errors={errors.links}
          name="links"
        />
      </LinksField>
      {/* <ResourcePersonsField>
        <ResourcePersonsInput
          description="Legg gjerne til ressurspersoner relatert til potensiell utnyttelse."
          title="Potensiell utnyttelse - ressursperson"
          fields={resourcePersonFields}
          register={register}
          append={appendResourcePerson}
          remove={removeResourcePerson}
          name="resourcePersons"
        />
      </ResourcePersonsField> */}
      <Question>Annen relevant info om utnyttelse av sidestrømmen:</Question>
      <CommentBoxArea>
        <CommentField ref={register} name="otherComments" placeholder="Comments" />
      </CommentBoxArea>
    </>
  );
};

export default WoodUtilizationData;
