import { gql } from "urql";

export const getUtilizedWasteHeats = gql`
  query($energySchemaId: ID!) {
    utilizedWasteHeats(filter: { energySchemaId: $energySchemaId }) {
      __typename
      id
      utilizedWasteHeat
      utilizedWasteHeatInformation
      utilizedWasteHeatUsage
    }
  }
`;
