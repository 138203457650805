import React from "react";
import styled from "styled-components";
import Navbar from "../../components/Navbar";

const PageGrid = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
`;

const HeaderArea = styled.div`
  grid-column: 1/-1;
  grid-row: 1/2;
`;

const BodyArea = styled.div`
  grid-column: 1/-1;
  grid-row: 3/-1;
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: 1fr auto;
  padding: 2.8125em;
`;

const Content = styled.div`
  grid-column: 2/3;
`;

const Layout: React.FC = (props) => {
  return (
    <PageGrid>
      <HeaderArea>
        <Navbar />
      </HeaderArea>

      <BodyArea>
        <ContentGrid>
          <Content>{props.children}</Content>
        </ContentGrid>
      </BodyArea>
    </PageGrid>
  );
};

export default Layout;
