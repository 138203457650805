import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "urql";
import ErrorMessage from "../../components/ErrorMessage";
import Spinner from "../../components/Loading";
import { getOrganizationInfo } from "../../graphql/queries/General/getOrganizationInfo";
import { } from "../../graphql/queries/Sidestream/getSidestreams";
import { GetOrganizationInfoQueryResult, GetUsersQueryResult, Param } from "../../types";
import Sidestreams from "./Sidestreams";
import Modal from "react-modal";
import UpdateOrganization from "../UpdateOrganization";
import { defaultStyles } from "../../lib/modal";
import styled from "styled-components";
import Button, { ButtonSize, ButtonType } from "../../components/Button";
import { FiEdit } from "react-icons/fi";
import { getUsers } from "../../graphql/queries/User/getUsers";

const Nav = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

const OrganizationDetail: React.FC = () => {
  const { id: organizationId } = useParams<Param>();
  const [{ data, fetching, error }, refetchOrganizationInfoQuery] = useQuery<GetOrganizationInfoQueryResult>({
    query: getOrganizationInfo,
    variables: { organizationId: organizationId },
  });
  const [updateModalIsOpen, setUpdateModalIsOpen] = React.useState(false);

  const [{ data: organizationUsersResult }, getOrganizationUser] = useQuery<GetUsersQueryResult>({
    query: getUsers,
    variables: { filterByOrganizationId: organizationId },
  });

  if (fetching) return <Spinner />;
  if (error) return <ErrorMessage />;

  if (!data || !data.organization) return <div>Siden ble ikke funnet</div>;


  const contactPersonForOrganization = organizationUsersResult?.users.find(u => u.id === data?.organization.contactPersonId);

  return (
    <div>
      <Nav>
        <h1>{data.organization.name}</h1>

        <Button buttonType={ButtonType.Secondary} size={ButtonSize.Large} onClick={() => setUpdateModalIsOpen(true)}>
          <FiEdit size={"1.2em"} />
        </Button>
      </Nav>

      <Sidestreams organizationId={organizationId} allowedSidestreamTypes={data.organization.allowedSidestreamTypes} />

      <Modal isOpen={updateModalIsOpen} onRequestClose={() => setUpdateModalIsOpen(false)} style={defaultStyles}>
        <UpdateOrganization
          contactPerson={contactPersonForOrganization}
          organization={data.organization}
          handleSave={() => {
            setUpdateModalIsOpen(false);
            refetchOrganizationInfoQuery();
            getOrganizationUser();
          }}
          handleCancel={() => setUpdateModalIsOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default OrganizationDetail;
