import { gql } from "urql";

export const getEnergyWastes = gql`
  query($energySchemaId: ID!) {
    energyWastes(filter: { energySchemaId: $energySchemaId }) {
      __typename
      availability
      averageEffect
      comment
      distanceToExternal
      energyAmount
      energyWasteName
      externalPlan
      id
      internalPlan
      massAmount
      maxEffect
      plannedRecoveredExternal
      plannedRecoveredInternal
      pollutedWith
      pressure
      temperature
      temperatureNeeds
      wasteSource
    }
  }
`;
