import { gql } from "urql";

export const updateAboutWood = gql`
  mutation(
    $id: ID
    $materialTypeComment: String
    $reportPeriod: WoodReportPeriodEnum
    $reportYear: String
    $yearlyProduction: Float
    $productionPeriodPartTime: BoolEnum
    $storage: WoodStorageEnum
    $storageComment: String
  ) {
    updateWoodSchemaAbout(
      input: {
        id: $id
        materialTypeComment: $materialTypeComment
        reportPeriod: $reportPeriod
        reportYear: $reportYear
        yearlyProduction: $yearlyProduction
        productionPeriodPartTime: $productionPeriodPartTime
        storage: $storage
        storageComment: $storageComment
      }
    ) {
      __typename
      id
      success
    }
  }
`;
