import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useMutation, useQuery } from "urql";
import Bottom from "../../../components/Bottom";
import InternalQuestionBox from "../../../components/InternalQuestionBox";
import Navigation from "../../../components/Navigation";
import QuestionBox from "../../../components/QuestionBox";
import { getAboutWood } from "../../../graphql/queries/Wood/getAboutWood";
import { NavigationContext } from "../../../providers/NavigationProvider";
import { AboutWoodType, MonthlyProduction, Param, TBooleanSelect, TMonths, TTimePeriod, TWoodStorage } from "../../../types";
import AboutWoodData from "./AboutWoodData";
import { updateAboutWood } from "../../../graphql/mutations/Wood/updateAboutWood";
import { findSelectValue } from "../../../utils/form-utils";
import { BooleanSelect, Months, TimePeriod, WoodStorage } from "../../../dictionaries";
import Spinner from "../../../components/Loading";
import { createOrUpdateProduction } from "../../../graphql/mutations/Wood/createOrUpdateProduction";
import { yupResolver } from "@hookform/resolvers/yup";
import { aboutWoodSchema } from "../../../schemas";
import { IMutationResult } from "../../../types/mutations/mutationResult";
import { handleMutationError } from "../../../lib/toaster";

const Container = styled.div`
  grid-column: 2/3;
`;
const LoadingContainer = styled.div`
  display: flex;
  place-content: center;
`;

const AboutWood: React.FC = () => {
  const [isReset, setIsReset] = React.useState<boolean>(true);
  const context = React.useContext(NavigationContext);
  const { id } = useParams<Param>();
  const { register, watch, handleSubmit, reset, control, getValues, errors } = useForm<AboutWoodType>({
    resolver: yupResolver(aboutWoodSchema),
  });
  const [aboutWoodResult] = useQuery({
    query: getAboutWood,
    variables: { id: id },
  });
  const [, executeAboutWoodMutation] = useMutation<IMutationResult>(updateAboutWood);
  const [, executeProductionMutation] = useMutation<IMutationResult>(createOrUpdateProduction);
  const { data, fetching } = aboutWoodResult;

  const onSubmit: SubmitHandler<AboutWoodType> = async (data) => {
    data &&
      (await executeAboutWoodMutation({
        id: id,
        materialTypeComment: data.materialTypeComment,
        reportPeriod: findSelectValue<TTimePeriod>(data.reportPeriod, TimePeriod),
        reportYear: data.reportYear,
        yearlyProduction: data.yearlyProduction,
        productionPeriodPartTime: findSelectValue<TBooleanSelect>(data.productionPeriodPartTime, BooleanSelect),
        storage: findSelectValue<TWoodStorage>(data.storage, WoodStorage),
        storageComment: data.storageComment,
      }).catch(() => handleMutationError()));
    if (watch("productionPeriodPartTime") === BooleanSelect.YES && watch("reportPeriod") === TimePeriod.YEAR) {
      /**TODO:
       * If it's per year, send the correct mutation and data...
       */
      let val = getValues();

      executeProductionMutation({
        productionMonthId: val.JANUARY?.id ? val.JANUARY.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Januar", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.JANUARY.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.FEBRUARY.id ? val.FEBRUARY.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Februar", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.FEBRUARY.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.MARCH.id ? val.MARCH.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Mars", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.MARCH.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.APRIL.id ? val.APRIL.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("April", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.APRIL.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.MAY.id ? val.MAY.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Mai", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.MAY.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.JUNE.id ? val.JUNE.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Juni", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.JUNE.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.JULY.id ? val.JULY.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Juli", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.JULY.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.AUGUST.id ? val.AUGUST.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("August", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.AUGUST.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.SEPTEMBER.id ? val.SEPTEMBER.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("September", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.SEPTEMBER.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.OCTOBER.id ? val.OCTOBER.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Oktober", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.OCTOBER.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.NOVEMBER.id ? val.NOVEMBER.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("November", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.NOVEMBER.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.DECEMBER.id ? val.DECEMBER.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Desember", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.DECEMBER.checked,
      }).catch(() => handleMutationError());
    }

    if (watch("reportPeriod") === TimePeriod.MONTH) {
      /**TODO:
       * If it's per month, send the correct mutation and data...
       */
      let val = getValues();

      executeProductionMutation({
        productionMonthId: val.JANUARY?.id ? val.JANUARY.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Januar", Months),
        monthlyProduction: val.JANUARY.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.FEBRUARY.id ? val.FEBRUARY.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Februar", Months),
        monthlyProduction: val.FEBRUARY.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.MARCH.id ? val.MARCH.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Mars", Months),
        monthlyProduction: val.MARCH.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.APRIL.id ? val.APRIL.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("April", Months),
        monthlyProduction: val.APRIL.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.MAY.id ? val.MAY.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Mai", Months),
        monthlyProduction: val.MAY.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.JUNE.id ? val.JUNE.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Juni", Months),
        monthlyProduction: val.JUNE.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.JULY.id ? val.JULY.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Juli", Months),
        monthlyProduction: val.JULY.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.AUGUST.id ? val.AUGUST.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("August", Months),
        monthlyProduction: val.AUGUST.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.SEPTEMBER.id ? val.SEPTEMBER.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("September", Months),
        monthlyProduction: val.SEPTEMBER.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.OCTOBER.id ? val.OCTOBER.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Oktober", Months),
        monthlyProduction: val.OCTOBER.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.NOVEMBER.id ? val.NOVEMBER.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("November", Months),
        monthlyProduction: val.NOVEMBER.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.DECEMBER.id ? val.DECEMBER.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Desember", Months),
        monthlyProduction: val.DECEMBER.production,
      }).catch(() => handleMutationError());
    }

    context.handleNav(true);
  };

  const onPreviousSubmit: SubmitHandler<AboutWoodType> = async () => {
    const data = getValues();
    await executeAboutWoodMutation({
      id: id,
      materialTypeComment: data.materialTypeComment,
      reportPeriod: findSelectValue<TTimePeriod>(data.reportPeriod, TimePeriod),
      reportYear: data.reportYear,
      yearlyProduction: data.yearlyProduction,
      productionPeriodPartTime: findSelectValue<TBooleanSelect>(data.productionPeriodPartTime, BooleanSelect),
      storage: findSelectValue<TWoodStorage>(data.storage, WoodStorage),
      storageComment: data.storageComment,
    }).catch(() => handleMutationError());

    if (watch("productionPeriodPartTime") === BooleanSelect.YES && watch("reportPeriod") === TimePeriod.YEAR) {
      /**TODO:
       * If it's per year, send the correct mutation and data...
       */
      let val = getValues();

      executeProductionMutation({
        productionMonthId: val.JANUARY?.id ? val.JANUARY.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Januar", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.JANUARY.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.FEBRUARY.id ? val.FEBRUARY.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Februar", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.FEBRUARY.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.MARCH.id ? val.MARCH.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Mars", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.MARCH.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.APRIL.id ? val.APRIL.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("April", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.APRIL.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.MAY.id ? val.MAY.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Mai", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.MAY.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.JUNE.id ? val.JUNE.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Juni", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.JUNE.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.JULY.id ? val.JULY.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Juli", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.JULY.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.AUGUST.id ? val.AUGUST.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("August", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.AUGUST.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.SEPTEMBER.id ? val.SEPTEMBER.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("September", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.SEPTEMBER.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.OCTOBER.id ? val.OCTOBER.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Oktober", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.OCTOBER.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.NOVEMBER.id ? val.NOVEMBER.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("November", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.NOVEMBER.checked,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.DECEMBER.id ? val.DECEMBER.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Desember", Months),
        monthlyProduction: null,
        partOfYearlyProduction: val.DECEMBER.checked,
      }).catch(() => handleMutationError());
    }

    if (watch("reportPeriod") === TimePeriod.MONTH) {
      /**TODO:
       * If it's per month, send the correct mutation and data...
       */
      let val = getValues();

      executeProductionMutation({
        productionMonthId: val.JANUARY?.id ? val.JANUARY.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Januar", Months),
        monthlyProduction: val.JANUARY?.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.FEBRUARY.id ? val.FEBRUARY.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Februar", Months),
        monthlyProduction: val.FEBRUARY.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.MARCH.id ? val.MARCH.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Mars", Months),
        monthlyProduction: val.MARCH.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.APRIL.id ? val.APRIL.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("April", Months),
        monthlyProduction: val.APRIL.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.MAY.id ? val.MAY.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Mai", Months),
        monthlyProduction: val.MAY.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.JUNE.id ? val.JUNE.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Juni", Months),
        monthlyProduction: val.JUNE.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.JULY.id ? val.JULY.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Juli", Months),
        monthlyProduction: val.JULY.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.AUGUST.id ? val.AUGUST.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("August", Months),
        monthlyProduction: val.AUGUST.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.SEPTEMBER.id ? val.SEPTEMBER.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("September", Months),
        monthlyProduction: val.SEPTEMBER.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.OCTOBER.id ? val.OCTOBER.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Oktober", Months),
        monthlyProduction: val.OCTOBER.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.NOVEMBER.id ? val.NOVEMBER.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("November", Months),
        monthlyProduction: val.NOVEMBER.production,
      }).catch(() => handleMutationError());

      executeProductionMutation({
        productionMonthId: val.DECEMBER.id ? val.DECEMBER.id : null,
        woodSchemaId: id,
        month: findSelectValue<TMonths>("Desember", Months),
        monthlyProduction: val.DECEMBER.production,
      }).catch(() => handleMutationError());
    }

    context.handleNav(false);
  };

  React.useEffect(() => {
    if (data && !fetching) {
      if (isReset) {
        reset({
          ...data.woodSchema,
          reportPeriod: TimePeriod[data.woodSchema.reportPeriod as TTimePeriod],
          productionPeriodPartTime: BooleanSelect[data.woodSchema.productionPeriodPartTime as TBooleanSelect],
          storage: WoodStorage[data.woodSchema.storage as TWoodStorage],
          JANUARY: {
            id:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "JANUARY")
              ]?.id,
            production:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "JANUARY")
              ]?.monthlyProduction,
            checked:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "JANUARY")
              ]?.partOfYearlyProduction,
          },

          FEBRUARY: {
            id:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "FEBRUARY")
              ]?.id,
            production:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "FEBRUARY")
              ]?.monthlyProduction,
            checked:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "FEBRUARY")
              ]?.partOfYearlyProduction,
          },
          MARCH: {
            id:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "MARCH")
              ]?.id,
            production:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "MARCH")
              ]?.monthlyProduction,
            checked:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "MARCH")
              ]?.partOfYearlyProduction,
          },
          APRIL: {
            id:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "APRIL")
              ]?.id,
            production:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "APRIL")
              ]?.monthlyProduction,
            checked:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "APRIL")
              ]?.partOfYearlyProduction,
          },
          MAY: {
            id:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "MAY")
              ]?.id,
            production:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "MAY")
              ]?.monthlyProduction,
            checked:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "MAY")
              ]?.partOfYearlyProduction,
          },
          JUNE: {
            id:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "JUNE")
              ]?.id,
            production:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "JUNE")
              ]?.monthlyProduction,
            checked:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "JUNE")
              ]?.partOfYearlyProduction,
          },
          JULY: {
            id:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "JULY")
              ]?.id,
            production:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "JULY")
              ]?.monthlyProduction,
            checked:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "JULY")
              ]?.partOfYearlyProduction,
          },
          AUGUST: {
            id:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "AUGUST")
              ]?.id,
            production:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "AUGUST")
              ]?.monthlyProduction,
            checked:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "AUGUST")
              ]?.partOfYearlyProduction,
          },
          SEPTEMBER: {
            id:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "SEPTEMBER")
              ]?.id,
            production:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "SEPTEMBER")
              ]?.monthlyProduction,
            checked:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "SEPTEMBER")
              ]?.partOfYearlyProduction,
          },
          OCTOBER: {
            id:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "OCTOBER")
              ]?.id,
            production:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "OCTOBER")
              ]?.monthlyProduction,
            checked:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "OCTOBER")
              ]?.partOfYearlyProduction,
          },
          NOVEMBER: {
            id:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "NOVEMBER")
              ]?.id,
            production:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "NOVEMBER")
              ]?.monthlyProduction,
            checked:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "NOVEMBER")
              ]?.partOfYearlyProduction,
          },

          DECEMBER: {
            id:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "DECEMBER")
              ]?.id,
            production:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "DECEMBER")
              ]?.monthlyProduction,
            checked:
              data.woodSchema.productionMonths[
                data.woodSchema.productionMonths.findIndex((item: MonthlyProduction) => item.month === "DECEMBER")
              ]?.partOfYearlyProduction,
          },
        });

        setIsReset(false);
      }
    }
  }, [reset, data, fetching, isReset]);

  return (
    <Container>
      <QuestionBox title="Om sidestrømmen">
        {fetching ? (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        ) : (
          <InternalQuestionBox>
            <AboutWoodData register={register} watch={watch} control={control} data={data} errors={errors} />
          </InternalQuestionBox>
        )}
      </QuestionBox>
      <Bottom>
        <Navigation navigation={context.navigation} handleNext={handleSubmit(onSubmit)} handlePrev={handleSubmit(onPreviousSubmit)} />
      </Bottom>
    </Container>
  );
};

export default AboutWood;
