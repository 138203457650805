import React from "react";
import styled from "styled-components";

export enum CircleSize {
  SMALL = "Small",
  MEDIUM = "Medium",
  LARGE = "Large",
  XL = "XL",
}

const CircleContainer = styled.div<CircleProps>`
  border-radius: 50%;
  height: ${(props) =>
    props.size === CircleSize.SMALL
      ? "25px"
      : props.size === CircleSize.MEDIUM
      ? "40px"
      : props.size === CircleSize.LARGE
      ? "100px"
      : props.size === CircleSize.XL
      ? "300px"
      : "2.8125rem"};
  width: ${(props) =>
    props.size === CircleSize.SMALL
      ? "25px"
      : props.size === CircleSize.MEDIUM
      ? "40px"
      : props.size === CircleSize.LARGE
      ? "100px"
      : props.size === CircleSize.XL
      ? "300px"
      : "2.8125rem"};
  background-color: ${(props) =>
    props.active
      ? props.theme.colors.bodyBackgroundColor.bottom
      : props.finished
      ? props.theme.colors.buttonColor.primary
      : props.theme.colors.buttonColor.secondary};
  border: ${(props) =>
    props.active
      ? ` 0.1875rem solid ${props.theme.colors.buttonColor.primary}`
      : props.finished
      ? `0.1875rem solid ${props.theme.colors.buttonColor.primary}`
      : `0.1875rem solid ${props.theme.colors.buttonColor.secondary}`};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    props.finished ? props.theme.colors.textColor.buttonTextColor.inactive : props.theme.colors.textColor.titleTextColor};
  font-weight: 600;
  font-size: ${(props) => (props.size === CircleSize.LARGE ? props.theme.fontSizes.large : props.theme.fontSizes.small)};
`;

type CircleProps = {
  active?: boolean;
  finished?: boolean;
  size?: CircleSize;
};
const Circle: React.FC<CircleProps> = (props) => {
  return (
    <CircleContainer active={props.active} finished={props.finished} size={props.size} data-testid="circletest">
      {props.children}
    </CircleContainer>
  );
};

export default Circle;
