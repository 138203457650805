import { gql } from "urql";

export const signOutMutation = gql`
  mutation signOut {
    signOut {
      id
      success
    }
  }
`;
