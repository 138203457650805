import { gql } from "urql";

export const getEALCodes = gql`
  query {
    eALCodes {
      id
      codeId
      name
      __typename
    }
  }
`;
