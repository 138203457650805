import { gql } from "urql";

export const getAboutWood = gql`
  query($id: ID) {
    woodSchema(filter: { id: $id }) {
      __typename
      id
      materialTypeComment
      reportPeriod
      reportYear
      yearlyProduction
      productionPeriodPartTime
      storage
      storageComment
      productionMonths {
        id
        month
        monthlyProduction
        partOfYearlyProduction
      }
    }
  }
`;
