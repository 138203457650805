import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Bottom from "../../../components/Bottom";
import Button, { ButtonSize, ButtonType } from "../../../components/Button";
import Introduction, { Intro } from "../../../components/Introduction";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
import { INTRODUCTION_SHOWED } from "../../../lib/constants";
import { ROUTES } from "../../../lib/Router/routes";

const DescriptionContainer = styled.div`
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: ${(props) => props.theme.gap.light};
`;

const MaterialFlowIntroduction: React.FC = () => {
  const [, setIntroductionShowed] = useSessionStorage(INTRODUCTION_SHOWED, false);
  const history = useHistory();

  const introText: Intro = {
    title: "Sidestrømmer",
    contactPerson: [
      {
        email: "stine@eydecluster.no",
        name: "Stine Skagestad",
        phone: "992 35 515",
      },
    ],
    description: `Takk for at dere har deltatt i det nasjonale kartleggingsprosjektet for å samle informasjon om sidestrømmer fra den norske prosessindustrien.\n\nProsjektet innhentet høsten 2020 data om bedriftenes sidestrømmer. Disse dataen ble lagret i en database og sammenstilt. Resultatene ble visualiserte i enkle rapporter, som bl.a. inkludert oversikt over farlig uorganisk avfall. Vi ser at kvaliteten på data er varierende og vi ønsker at dere går gjennom innrapporterte sidestrømmer og sjekker at data er korrekt. Eventuelle korrigeringer eller nyregistreringer gjøres i dette webskjema. \n\nVerdien av dataene ligger i mulighetene for andre å se sammenheng i de innsamlede dataene og detaljer om de enkelte sidestrømmene. Kartleggingen er utført på oppdrag fra regjeringen og det overordnede målet er å skape verdi av sidestrømmene og redusere mengden farlig avfall på deponi. \n\nVerdiskapningspotensialet avhenger i stor grad av bedriftenes åpenhet om de kartlagte sidestrømmene og vi oppfordrer til åpenhet. Av respekt for at ikke alle bedriftene ønsker å dele all informasjon om sidestrømmene, er registreringen designet slik at man samtykker til deling, eventuelt ikke deling, av kjemisk innhold for hver sidestrøm. `,
    footNote: ``,
    question: `Spørsmål om prosjektet
    eller undersøkelsen?`,
    orderedList: [],
    boxTitle: "Nasjonal kartlegging",
    boxDescription: `Klima- og Miljødepartementet ga i juli 2020 Eyde-klyngen tilskudd for å «gjennomføre en materialstrømsanalyse i norsk prosessindustri for å kartlegge muligheter for økt ressursutnyttelse og reduserte mengder avfall fra prosessindustrien.»\n\n Eyde-klyngen fikk oppdraget, men arbeidet er utført i tett samarbeid med Arctic Cluster Team, Industrial Green Tech, Øra- miljøet, Norsk Industri og Avfall Norge.\n\nHele 54 bedrifter har rapportert inn sine sidestrømmer.\n\n Vi har så langt sammenstilt detaljerte opplysninger om 245 sidestrømmer fra prosessindustrien, med et samlet volum på rundt 10 millioner tonn pr år. \n\nDataen er gjort tilgjengelig gjennom en standard plattform, Microsoft Power BI, som gir stor fleksibilitet og brukervennlighet.
    `,
    unorderedList: [],
  };

  return (
    <DescriptionContainer>
      <Introduction introText={introText} />
      <Bottom>
        <Button
          buttonType={ButtonType.Primary}
          size={ButtonSize.Large}
          onClick={() => {
            setIntroductionShowed(true);

            history.push(ROUTES.AUTHORIZED.DASHBOARD_ROUTE);
          }}
        >
          Gå videre
        </Button>
      </Bottom>
    </DescriptionContainer>
  );
};

export default MaterialFlowIntroduction;
