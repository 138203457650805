import React from "react";
import styled from "styled-components";
import { useQuery } from "urql";
import Button, { ButtonSize, ButtonType } from "../../../components/Button";
import EmptyState from "../../../components/EmptyState";
import Spinner from "../../../components/Loading";
import Heading from "../../../components/Heading";
import { getSidestreams } from "../../../graphql/queries/Sidestream/getSidestreams";
import { useAuth } from "../../../hooks/useAuth";
import { GetSidestreamsQueryResult, Sidestream, SidestreamTypeEnum } from "../../../types";
import { SidestreamType } from "../../../dictionaries";
import Modal from "react-modal";
import { defaultStyles } from "../../../lib/modal";
import ErrorMessage from "../../../components/ErrorMessage";
import CreateSidestreamForm from "./CreateSidestreamForm";
import UpdateSidestreamForm from "./UpdateSidestreamForm";
import Label, { LabelType } from "../../../components/Label";
import { useHistory } from "react-router-dom";
import { GET_ENERGY_FLOW_SCHEMA_ROUTE, GET_MATERIAL_FLOW_SCHEMA_ROUTE, GET_WOOD_SCHEMA_ROUTE } from "../../../lib/Router/routes";
import { FiEdit, FiFileText, FiPlus, FiRepeat } from "react-icons/fi";
import Circle, { CircleSize } from "../../../components/Circle";
import { getFormattedDate } from "../../../helpers/dateHelpers";
import { groupBy } from "../../../utils/form-utils";
import DeliverSidestreamWithoutChangesForm from "./DeliverSidestreamWithoutChangesForm";

type Props = {
  organizationId: string;
  allowedSidestreamTypes: Array<SidestreamTypeEnum>;
};

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const SidestreamHeader = styled.h2`
  margin: 4rem 0 2rem;
`;

const ItemsContainer = styled.ul`
  list-style: none;
  padding: 0;
`;

const ItemsHeaderContainer = styled(ItemsContainer)`
  margin-bottom: 20px !important;
  border-bottom: 2px solid #ccc;
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  font-weight: 600;
`;

const ItemContainer = styled.li`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 170px;
  padding-bottom: 10px;
  margin-top: 10px;

  :first-child {
    margin-top: 0px;
  }

  :last-child {
    padding-bottom: 0;
  }

  :not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
`;

const ItemContent = styled.div`
  display: flex;
  align-items: center;
`;

const ItemTitle = styled.span`
  font-weight: 500;
  margin-right: 10px;
  margin-left: 10px;
  color: ${(props: { isInactive: boolean }) => (props.isInactive ? "gray" : "unset")};
`;

const SchemaDate = styled.span`
  color: gray;
`;

const SchemaStatus = styled.span`
  color: ${(props: { delivered: boolean }) => (props.delivered ? "unset" : "orange")};
`;

const RowActionsContainer = styled.div`
  width: 100%;
  text-align: right;
`;

const ActionsContainer = styled.div`
  margin-bottom: 20px;
`;

const Sidestreams: React.FC<Props> = (props) => {
  const [{ data, fetching, error }, refetchGetSidestreamsQuery] = useQuery<GetSidestreamsQueryResult>({
    query: getSidestreams,
    variables: { organizationId: props.organizationId },
  });
  const { isAdmin } = useAuth();
  const history = useHistory();
  const [createModalIsOpen, setCreateModalIsOpen] = React.useState(false);
  const [deliverSchemaWithoutChangesModalIsOpen, setDeliverSchemaWithoutChangesModalIsOpen] = React.useState(false);
  const [selectedSidestreamToUpdate, setSelectedSidestreamToUpdate] = React.useState<Sidestream | undefined>(undefined);
  const [selectedSidestreamToDeliver, setSelectedSidestreamToDeliver] = React.useState<Sidestream | undefined>(undefined);

  if (fetching) return <Spinner />;
  if (error) return <ErrorMessage />;

  const groupedSidestreams = groupBy(data?.sidestreams, "type") as Array<Sidestream>;

  return (
    <div>
      <Nav>
        <Heading>{isAdmin() ? "Sidestrømmer" : "Mine sidestrømmer"}</Heading>

        {data && data.sidestreams.length > 0 && (
          <Button buttonType={ButtonType.Primary} size={ButtonSize.Large} onClick={() => setCreateModalIsOpen(true)}>
            <FiPlus size={"1.2em"} /> Legg til sidestrøm
          </Button>
        )}
      </Nav>

      {data && data.sidestreams.length > 0 ? (
        Object.entries(SidestreamTypeEnum).map(([key, value], index) => {
          const sidestreams = groupedSidestreams[key] as Array<Sidestream>;

          if (sidestreams && sidestreams.length > 0) {
            return (
              <div key={index + value}>
                <SidestreamHeader>{SidestreamType[value]}</SidestreamHeader>

                <ItemsHeaderContainer>
                  <ItemContainer>
                    <ItemContent>Sidestrøm</ItemContent>
                    <ItemContent>Sist oppdatert</ItemContent>
                    <ItemContent>Status</ItemContent>
                    <ItemContent></ItemContent>
                    <ItemContent></ItemContent>
                  </ItemContainer>
                </ItemsHeaderContainer>

                <ItemsContainer>
                  {sidestreams.map((sidestream) => {
                    const name = sidestream.name ? sidestream.name : "(Ingen navn på sidestrømmen)";

                    return (
                      <ItemContainer key={sidestream.id}>
                        <ItemContent>
                          <Circle size={CircleSize.MEDIUM}>
                            <FiFileText size="1.4em" />
                          </Circle>

                          <ItemTitle isInactive={sidestream.isInactive}>
                            {name} {sidestream.isInactive && "(Inaktiv)"}
                          </ItemTitle>

                          <Button
                            buttonType={ButtonType.Secondary}
                            size={ButtonSize.Medium}
                            onClick={() => {
                              setSelectedSidestreamToUpdate(sidestream);
                            }}
                          >
                            <FiEdit />
                          </Button>
                        </ItemContent>

                        <ItemContent>
                          {sidestream.latestSchema && sidestream.latestSchema.updatedAt && (
                            <SchemaDate>{getFormattedDate(new Date(sidestream.latestSchema.updatedAt))}</SchemaDate>
                          )}
                        </ItemContent>

                        <ItemContent>
                          {sidestream.latestSchema && (
                            <SchemaStatus delivered={sidestream.latestSchema.delivered}>
                              {sidestream.latestSchema.delivered ? (
                                <Label type={LabelType.SUCCESS}>Oppdatert og godkjent</Label>
                              ) : (
                                <Label type={LabelType.WARNING}>Vennligst oppdater og godkjenn</Label>
                              )}
                            </SchemaStatus>
                          )}
                        </ItemContent>
                        <ItemContent>
                          <RowActionsContainer>
                            <Button
                              buttonType={ButtonType.Secondary}
                              size={ButtonSize.Medium}
                              disabled={!sidestream.latestSchema}
                              hidden={
                                !sidestream.latestSchema?.clonedFromSchemaId || sidestream.latestSchema.delivered || sidestream.isInactive
                              }
                              onClick={() => {
                                if (!sidestream.latestSchema) return;
                                setSelectedSidestreamToDeliver(sidestream);
                                setDeliverSchemaWithoutChangesModalIsOpen(true);
                              }}
                            >
                              <FiRepeat /> Ingen endringer
                            </Button>
                          </RowActionsContainer>
                        </ItemContent>
                        <ItemContent>
                          <RowActionsContainer>
                            <Button
                              buttonType={ButtonType.Secondary}
                              size={ButtonSize.Medium}
                              disabled={!sidestream.latestSchema}
                              onClick={() => {
                                if (!sidestream.latestSchema) return;

                                switch (sidestream.type) {
                                  case SidestreamTypeEnum.WOOD:
                                    history.push(GET_WOOD_SCHEMA_ROUTE(sidestream.latestSchema.id));
                                    break;
                                  case SidestreamTypeEnum.ENERGY:
                                    history.push(GET_ENERGY_FLOW_SCHEMA_ROUTE(sidestream.latestSchema.id));
                                    break;
                                  case SidestreamTypeEnum.MATERIALSTREAM:
                                    history.push(GET_MATERIAL_FLOW_SCHEMA_ROUTE(sidestream.latestSchema.id));
                                    break;
                                  default:
                                    break;
                                }
                              }}
                            >
                              <FiFileText /> Gå til skjema
                            </Button>
                          </RowActionsContainer>
                        </ItemContent>
                      </ItemContainer>
                    );
                  })}
                </ItemsContainer>
              </div>
            );
          } else return <></>;
        })
      ) : (
        <EmptyState
          title="Ingen sidestrømmer funnet."
          description="Du kan legge til sidestrømmer ved å klikke på Legg til sidestrøm-knappen"
        >
          <ActionsContainer>
            <Button buttonType={ButtonType.Primary} size={ButtonSize.Large} onClick={() => setCreateModalIsOpen(true)}>
              <FiPlus size={"1.2em"} /> Legg til sidestrøm
            </Button>
          </ActionsContainer>
        </EmptyState>
      )}

      <Modal isOpen={createModalIsOpen} onRequestClose={() => setCreateModalIsOpen(false)} style={defaultStyles}>
        <CreateSidestreamForm
          organizationId={props.organizationId}
          allowedSidestreamTypes={props.allowedSidestreamTypes}
          handleSave={() => {
            setCreateModalIsOpen(false);
            refetchGetSidestreamsQuery();
          }}
          handleCancel={() => setCreateModalIsOpen(false)}
        />
      </Modal>

      {deliverSchemaWithoutChangesModalIsOpen && (
        <Modal
          isOpen={deliverSchemaWithoutChangesModalIsOpen}
          onRequestClose={() => setDeliverSchemaWithoutChangesModalIsOpen(false)}
          style={defaultStyles}
        >
          <DeliverSidestreamWithoutChangesForm
            organizationId={props.organizationId}
            sideStreamToDeliver={selectedSidestreamToDeliver}
            handleSave={async () => {
              setDeliverSchemaWithoutChangesModalIsOpen(false);
              await refetchGetSidestreamsQuery();
            }}
            handleCancel={() => setDeliverSchemaWithoutChangesModalIsOpen(false)}
          />
        </Modal>
      )}

      {selectedSidestreamToUpdate && (
        <Modal isOpen={!!selectedSidestreamToUpdate} onRequestClose={() => setSelectedSidestreamToUpdate(undefined)} style={defaultStyles}>
          <UpdateSidestreamForm
            sidestream={selectedSidestreamToUpdate}
            handleSave={() => {
              setSelectedSidestreamToUpdate(undefined);
              refetchGetSidestreamsQuery();
            }}
            handleCancel={() => setSelectedSidestreamToUpdate(undefined)}
          />
        </Modal>
      )}
    </div>
  );
};

export default Sidestreams;
