import React from "react";
import styled from "styled-components";
import { useMutation, useQuery } from "urql";
import Bottom from "../../../components/Bottom";
import Navigation from "../../../components/Navigation";
import SummaryBoilerplate from "../../../components/SummaryBoilerplate";
import { NavigationContext } from "../../../providers/NavigationProvider";
import { getWoodSummary } from "../../../graphql/queries/Wood/getWoodSummary";
import { updateWoodSubmit } from "../../../graphql/mutations/Wood/updateWoodSubmit";
import { useParams } from "react-router-dom";

import { Param, TBooleanSelect, TTimePeriod, TTreatment, TWoodStorage } from "../../../types";
import Spinner from "../../../components/Loading";
import { BooleanSelect, TimePeriod, Treatment, WoodStorage } from "../../../dictionaries";
import { IMutationResult } from "../../../types/mutations/mutationResult";
import { handleMutationError } from "../../../lib/toaster";
const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.gap.medium};
`;

const Box = styled.div`
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.middle};
  border-radius: ${(props) => props.theme.borderRadius.light};
  width: 95%;
  padding: ${(props) => props.theme.padding.medium};
`;
const GridBox = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 2fr) 1fr repeat(2, 2fr);
  grid-template-rows: 1fr 1fr auto;
`;
const BoxTitle = styled.div`
  text-align: start;
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  font-weight: 500;
`;
const LineContainer = styled.div`
  grid-column: 1/-1;
  grid-row: 2/3;
`;
const HeaderLine = styled.hr`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.textDecorationColor};
`;
const Headers = styled.div`
  font-weight: 500;
  grid-row: 1/2;
`;
const FirstHeader = styled(Headers)`
  grid-column: 1/2;
`;

const ThirdHeader = styled(Headers)`
  grid-column: 3/4;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface StyledData {
  row: string;
}
const FirstData = styled.div<StyledData>`
  grid-column: 1/3;
  grid-row: ${(props) => props.row};
`;
const DataOne = styled.div<StyledData>`
  grid-column: 3/5;
  grid-row: ${(props) => props.row};
`;

const Summary: React.FC = () => {
  const context = React.useContext(NavigationContext);
  const { id } = useParams<Param>();
  const [summaryResult] = useQuery({
    query: getWoodSummary,
    variables: { id: id },
  });

  const { data, fetching } = summaryResult;
  const [, setSchemaDeliveredMutate] = useMutation<IMutationResult>(updateWoodSubmit);

  const onSubmit = () => {
    setSchemaDeliveredMutate({
      id: id,
      schemaIsDelivered: "YES",
    })
      .then((res) => {
        context.handleNav(true);
      })
      .catch(() => handleMutationError());
  };
  return (
    <Container>
      <SummaryBoilerplate />

      {!fetching ? (
        <>
          <BodyContainer>
            <BoxTitle>Om sidestrømmen</BoxTitle>
            <GridBox>
              <FirstHeader>Spørsmål</FirstHeader>
              <ThirdHeader>Svar</ThirdHeader>
              <LineContainer>
                <HeaderLine />
              </LineContainer>
              <FirstData row="4/5">Rapportering per år eller per måned</FirstData>
              <FirstData row="5/6">År det innrapporteres for</FirstData>
              <FirstData row="6/7">Produksjonsvolum (i råtonn)</FirstData>
              <FirstData row="7/8">Hvordan materialet lagres</FirstData>
              <DataOne row="4/5">{TimePeriod[data.woodSchema.reportPeriod as TTimePeriod]}</DataOne>
              <DataOne row="5/6">{data.woodSchema.reportYear}</DataOne>
              <DataOne row="6/7">
                {data.woodSchema.reportPeriod === "YEAR"
                  ? data.woodSchema.yearlyProduction
                  : data.woodSchema.productionMonths.reduce(
                      (prevValue: any, currentValue: any) => prevValue + currentValue.monthlyProduction,
                      0
                    )}
              </DataOne>
              <DataOne row="7/8">{WoodStorage[data.woodSchema.storage as TWoodStorage]}</DataOne>
            </GridBox>
          </BodyContainer>

          <BodyContainer>
            <BoxTitle>Utnyttelse</BoxTitle>
            <GridBox>
              <FirstHeader>Spørsmål</FirstHeader>
              <ThirdHeader>Svar</ThirdHeader>
              <LineContainer>
                <HeaderLine />
              </LineContainer>

              <FirstData row="3/4"> Intern energigjenvinning</FirstData>
              <FirstData row="4/5">Ekstern energigjenvinning</FirstData>
              <FirstData row="5/6">Intern materialgjenvinning</FirstData>
              <FirstData row="6/7">Ekstern materialgjenvinning</FirstData>
              <FirstData row="7/8">Ombruk</FirstData>
              <FirstData row="8/9">Dersom ombruk, fordeling møbler</FirstData>
              <FirstData row="9/10">Dersom ombruk, fordeling byggematerialer</FirstData>

              <DataOne row="3/4">{data.woodSchema.internalEnergyRecycling} %</DataOne>
              <DataOne row="4/5">{data.woodSchema.externalEnergyRecycling} %</DataOne>
              <DataOne row="5/6">{data.woodSchema.internalMaterialRecycling} %</DataOne>

              <DataOne row="6/7">{data.woodSchema.externalMaterialRecycling} %</DataOne>
              <DataOne row="7/8">{data.woodSchema.reuse} %</DataOne>
              <DataOne row="8/9">{data.woodSchema.reuseFurniture} %</DataOne>
              <DataOne row="9/10">{data.woodSchema.reuseBuildingMaterials} %</DataOne>
            </GridBox>
          </BodyContainer>

          <BodyContainer>
            <BoxTitle>Om materialet</BoxTitle>
            <GridBox>
              <FirstHeader>Spørsmål</FirstHeader>
              <ThirdHeader>Svar</ThirdHeader>
              <LineContainer>
                <HeaderLine />
              </LineContainer>

              <FirstData row="3/4"> Er materialet klassifisert som farlig avfall?</FirstData>
              <FirstData row="4/6">Eksisterer det begrensninger eller andre myndighetskrav til deponering av materialet?</FirstData>
              <FirstData row="6/7">Selvantennelsesfare</FirstData>
              <FirstData row="7/8">Behandling etter mottak?</FirstData>

              <DataOne row="3/4">{BooleanSelect[data.woodSchema.dangerousWaste as TBooleanSelect]} </DataOne>
              <DataOne row="4/6">{BooleanSelect[data.woodSchema.limitationsToMaterialDeposit as TBooleanSelect]} </DataOne>
              <DataOne row="6/7">{BooleanSelect[data.woodSchema.autoIgnitionHazard as TBooleanSelect]} </DataOne>
              <DataOne row="7/8">{Treatment[data.woodSchema.treatmentAfterReceving as TTreatment]} </DataOne>
            </GridBox>
          </BodyContainer>
        </>
      ) : (
        <Spinner />
      )}

      <Bottom>
        <Navigation navigation={context.navigation} handleNext={onSubmit} handlePrev={() => context.handleNav(false)} />
      </Bottom>
    </Container>
  );
};

export default Summary;
