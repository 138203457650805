import { gql } from "urql";

export const getMainProducts = gql`
  query {
    mainProducts {
      id
      name
      __typename
    }
  }
`;
