import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useMutation } from "urql";
import Button, { ButtonSize, ButtonType } from "../../components/Button";
import ContentBox from "../../components/ContentBox";
import ErrorContainer from "../../components/ErrorContainer";
import FormFieldError from "../../components/FormFieldError";
import FormLabel from "../../components/FormLabel";
import Logo from "../../components/Logo";
import { resetPassword } from "../../graphql/mutations/General/resetPassword";
import { ROUTES } from "../../lib/Router/routes";
import { handleMutationError } from "../../lib/toaster";
import { resetPasswordSchema } from "../../schemas";
import { ResetPasswordFormData } from "../../types";
import { IMutationResult } from "../../types/mutations/mutationResult";

type Props = {
  page: "resetPassword" | "completeSignup";
};

const LogoContainer = styled.div`
  text-align: center;
`;

const LoginTitle = styled.div`
  font-size: ${(props) => props.theme.fontSizes.large};
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  text-align: center;
  margin-bottom: 20px;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1em;
`;

const ButtonArea = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ResetPassword: React.FC<Props> = (props) => {
  const [result, executeMutation] = useMutation<IMutationResult>(resetPassword);
  const history = useHistory();
  const tokenQuery = new URLSearchParams(useLocation().search);
  const escapedToken = tokenQuery.get("t");
  const token = escapedToken ? escapedToken.replace(/ /g, "+") : "";

  const { register, handleSubmit, errors } = useForm<ResetPasswordFormData>({
    resolver: yupResolver(resetPasswordSchema),
    defaultValues: { resetToken: token },
  });

  const onSubmit = handleSubmit(async (formValues) => {
    executeMutation({ email: formValues.email, resetToken: formValues.resetToken, newPassword: formValues.newPassword })
      .then(({ data }) => {
        if (data?.resetPassword.success) {
          history.push(ROUTES.PUBLIC.SIGN_IN_ROUTE);
        } else {
          handleMutationError();
        }
      })
      .catch(() => handleMutationError());
  });

  const heading = props.page === "completeSignup" ? "Sett opp din bruker" : "Nullstill passord";
  const passwordLabel = props.page === "completeSignup" ? "Passord" : "Nytt passord";
  const submitLabel = props.page === "completeSignup" ? "Bekreft" : "Oppdater passord";

  return (
    <div>
      <LoginTitle>{heading}</LoginTitle>

      <ContentBox>
        <LogoContainer>
          <Logo />
        </LogoContainer>

        <form onSubmit={onSubmit} noValidate>
          <InputsContainer>
            <FormLabel>E-post</FormLabel>

            <input type="email" name="email" ref={register} placeholder="Din e-post" autoComplete="off" />

            <FormFieldError error={errors.email}></FormFieldError>

            <FormLabel>Engangskode</FormLabel>
            <input type="text" name="resetToken" ref={register} placeholder="Engangskode mottatt på din e-post" autoComplete="off" />

            <FormFieldError error={errors.resetToken}></FormFieldError>

            <FormLabel>{passwordLabel}</FormLabel>

            <input type="password" name="newPassword" ref={register} placeholder="Ditt nye passord" autoComplete="off" />

            <FormFieldError error={errors.newPassword}></FormFieldError>

            {result.error && <ErrorContainer>Innlogging feilet. Sjekk om brukernavn eller passord er riktig</ErrorContainer>}
          </InputsContainer>

          <ButtonArea>
            <Button type="submit" size={ButtonSize.Large} buttonType={ButtonType.Primary}>
              {submitLabel}
            </Button>
          </ButtonArea>
        </form>

        <ButtonArea>
          <Link to={ROUTES.PUBLIC.SIGN_IN_ROUTE}>Tilbake til innlogging</Link>
        </ButtonArea>
      </ContentBox>
    </div>
  );
};

export default ResetPassword;
