import React, { useState } from "react";
import styled from "styled-components";
import SurveyEnergyUsage from "./SurveyEnergyUsage";
import UtilizedEnergy from "./UtilizedEnergy";
import NonUtilizedEnergy from "./NonUtilizedEnergy";
import Summary from "./Summary";
import Steps from "../../components/Steps";
import Navigation from "../../components/Navigation";
import Circle from "../../components/Circle";
import DeliveredSchema from "../../components/DeliveredSchema";
import { StepsArea, StepsOverview, StepsContainer, StepsCircle, StepsName, StepsButtons } from "../../components/Steps/StepsLayout";
import { StepHandling, Param, Navigation as NavigationType } from "../../types";
import SidestreamName from "../../components/SidestreamName";
import { getGeneralInfoConfirmed } from "../../generalInfoConfirmed";
import { useParams } from "react-router-dom";
import { useQuery } from "urql";
import { getEnergySchemaInfo } from "../../graphql/queries/Energyflow/getEnergySchemaInfo";
import General from "../../components/General";
import { NavigationContext } from "../../providers/NavigationProvider";
import SaveText from "../../components/SaveText";

const ButtonContainer = styled.div`
  grid-column: 1/3;
  display: grid;
  grid-template-rows: auto 1fr;
  justify-content: end;
  margin-top: -4.4rem;
  min-width: 1000px;
`;

const EnergyFlow: React.FC = () => {
  const generalInfoIsConfirmed = getGeneralInfoConfirmed() ? true : false;
  const [navigation, setNavigation] = useState<StepHandling>({
    step: generalInfoIsConfirmed ? 2 : 1,
    amountOfSteps: 6,
  });
  const { id } = useParams<Param>();
  const [organizationResult] = useQuery({
    query: getEnergySchemaInfo,
    variables: { id },
  });

  const { data, fetching } = organizationResult;

  const handleNav = (next: boolean) => {
    if (next) {
      if (navigation.step < navigation.amountOfSteps) {
        setNavigation({ ...navigation, step: navigation.step + 1 });
      }
    } else {
      if (navigation.step > 1) {
        setNavigation({ ...navigation, step: navigation.step - 1 });
      }
    }
  };

  const handleNavigation: NavigationType = {
    navigation: navigation,
    handleNav: handleNav,
  };
  return (
    <>
      <Steps>
        <StepsArea>
          <StepsOverview>
            <StepsContainer>
              {/* <StepsCircle className="first">
                <Circle active={navigation.step === 1 ? true : false} finished={navigation.step > 1 ? true : false}>
                  1
                </Circle>
              </StepsCircle>

              <StepsName>Intro</StepsName> */}
              <StepsCircle className="first">
                <Circle active={navigation.step === 2 ? true : false} finished={navigation.step > 2 ? true : false}>
                  1
                </Circle>
              </StepsCircle>
              <StepsName>Energibruk</StepsName>
              <StepsCircle>
                <Circle active={navigation.step === 3 ? true : false} finished={navigation.step > 3 ? true : false}>
                  2
                </Circle>
              </StepsCircle>
              <StepsName>Utnyttet</StepsName>
              <StepsCircle>
                <Circle active={navigation.step === 4 ? true : false} finished={navigation.step > 4 ? true : false}>
                  3
                </Circle>
              </StepsCircle>
              <StepsName>Ikke utnyttet</StepsName>
              <StepsCircle className="last">
                <Circle active={navigation.step === 5 ? true : false} finished={navigation.step > 5 ? true : false}>
                  4
                </Circle>
              </StepsCircle>
              <StepsName>Oppsummering</StepsName>
            </StepsContainer>
            <ButtonContainer>
              <StepsButtons>
                {navigation.step < navigation.amountOfSteps && (
                  <Navigation
                    navigation={navigation}
                    handleNext={() => {
                      let element = document.getElementsByClassName("next-button")[1] as HTMLElement;
                      element?.click();
                    }}
                    handlePrev={() => {
                      let element = document.getElementsByClassName("prev-button")[1] as HTMLElement;
                      element.click();
                    }}
                  />
                )}
              </StepsButtons>
            </ButtonContainer>
          </StepsOverview>
        </StepsArea>
      </Steps>
      {navigation.step > 1 && <SidestreamName name={data?.energySchema.sidestreamName} />}
      <NavigationContext.Provider value={handleNavigation}>
        {/* {navigation.step === 1 && <SurveyIntroduction handleNext={() => setNavigation({ ...navigation, step: 2 })} navigation={navigation} />} */}
        {navigation.step === 1 && !fetching && <General orgId={data?.energySchema?.organizationId} data={data} schemaId={id} />}
        {navigation.step === 2 && <SurveyEnergyUsage navigation={navigation} handleNav={handleNav} />}
        {navigation.step === 3 && <UtilizedEnergy navigation={navigation} handleNav={handleNav} />}
        {navigation.step === 4 && <NonUtilizedEnergy navigation={navigation} handleNav={handleNav} />}
        {navigation.step === 5 && <Summary navigation={navigation} handleNav={handleNav} />}
        {navigation.step === 6 && <DeliveredSchema navigation={navigation} handleNav={() => setNavigation({ ...navigation, step: 1 })} />}
      </NavigationContext.Provider>
      <SaveText />
    </>
  );
};

export default EnergyFlow;
