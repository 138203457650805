import React from "react";
import styled from "styled-components";
import Circle, { CircleSize } from "../../../components/Circle";
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NonUtilizedType, Param, StepHandling, TExternalPlan, TInternalPlan, TPollution, TWasteSource } from "../../../types";
import { nonUtilizedEnergySchema } from "../../../schemas";
import Bottom from "../../../components/Bottom";
import Required from "../../../components/Required";
import Navigation from "../../../components/Navigation";
import { useMutation, useQuery } from "urql";
import { createOrUpdateEnergyWaste } from "../../../graphql/mutations/Energyflow/createOrUpdateEnergyWaste";
import { getEnergyWastes } from "../../../graphql/queries/Energyflow/getEnergyWastes";
import Button, { ButtonSize, ButtonType } from "../../../components/Button";
import NonUtilizedEnergyData from "./NonUtilizedEnergyData";
import { useParams } from "react-router-dom";
import Spinner from "../../../components/Loading";
import { ExternalPlan, InternalPlan, Pollution, WasteSource } from "../../../dictionaries";
import { findSelectValue } from "../../../utils/form-utils";
import { IMutationResult } from "../../../types/mutations/mutationResult";
import { handleMutationError } from "../../../lib/toaster";

const EnergyWasteContainer = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.gap.medium};
`;
const Title = styled.div`
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  font-size: ${(props) => props.theme.fontSizes.large};
  margin-bottom: 1.5rem;
`;
const Description = styled.div`
  margin-bottom: 1.875rem;
`;
const DescriptionBold = styled.span`
  font-weight: 500;
`;

const OuterBody = styled.div`
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.middle};
  display: flex;
  flex-direction: column;
  padding: 3em;
  gap: ${(props) => props.theme.gap.light};
  border-radius: ${(props) => props.theme.borderRadius.light};
`;
// const AmountOfForms = styled.select`
//   width: 10%;
// `;
const InnerBody = styled.div`
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.bottom};
  border-radius: ${(props) => props.theme.borderRadius.heavy};
`;
const BodyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1rem;
`;

const Number = styled.div`
  grid-row: 1/2;
  grid-column: 1/2;
  display: grid;
  grid-template-rows: auto 1fr;
  justify-content: center;
  padding: ${(props) => props.theme.padding.medium};
`;
const AddArea = styled.div``;

type NonUtilizedEnergyProps = {
  navigation: StepHandling;
  handleNav: (next: boolean) => void;
};

const NonUtilizedEnergy: React.FC<NonUtilizedEnergyProps> = (props) => {
  const { id } = useParams<Param>();
  const [, createEnergyTypeMutate] = useMutation<IMutationResult>(createOrUpdateEnergyWaste);
  const [energyWastesResult] = useQuery({
    query: getEnergyWastes,
    variables: { energySchemaId: id },
  });
  const { data, fetching } = energyWastesResult;
  const { register, getValues, reset, watch, errors, handleSubmit, control } = useForm<NonUtilizedType>({
    resolver: yupResolver(nonUtilizedEnergySchema),
    defaultValues: { energyWastes: [{ id: undefined }] },
  });
  const { fields, append, remove } = useFieldArray<NonUtilizedType, any>({
    control,
    name: "energyWastes",
    keyName: "fieldId",
  });
  const [radioState, setRadioState] = React.useState("Nei");
  const [isReset, setIsReset] = React.useState<boolean>(true);
  const watchWasteSource = (index: number): TWasteSource => {
    return watch(`energyWastes[${index}].wasteSource`) as TWasteSource;
  };

  const onSubmit: SubmitHandler<NonUtilizedType> = async (data: NonUtilizedType) => {
    data.energyWastes &&
      data.energyWastes.map(async (entry) => {
        await createEnergyTypeMutate({
          energyWasteId: entry.id ? entry.id : null,
          energyWasteName: entry.energyWasteName,
          wasteSource: findSelectValue<TWasteSource>(entry.wasteSource, WasteSource),
          temperature: entry.temperature,
          pressure: entry.pressure ? entry.pressure : null,
          energyAmount: entry.energyAmount ? entry.energyAmount : null,
          averageEffect: entry.averageEffect ? entry.averageEffect : null,
          maxEffect: entry.maxEffect ? entry.maxEffect : null,
          massAmount: entry.massAmount ? entry.massAmount : null,
          availability: entry.availability,
          pollutedWith: findSelectValue<TPollution>(entry.pollutedWith, Pollution),
          internalPlan: findSelectValue<TInternalPlan>(entry.internalPlan, InternalPlan),
          plannedRecoveredInternal: entry.plannedRecoveredInternal ? entry.plannedRecoveredInternal : null,
          externalPlan: findSelectValue<TExternalPlan>(entry.externalPlan, ExternalPlan),
          distanceToExternal: entry.distanceToExternal ? entry.distanceToExternal : null,
          temperatureNeeds: entry.temperatureNeeds ? entry.temperatureNeeds : null,
          plannedRecoveredExternal: entry.plannedRecoveredExternal ? entry.plannedRecoveredExternal : null,
          comment: entry.comment,
          energySchemaId: id,
        })
          .then((result) => {
            if (result.error) handleMutationError(result.error);
          })
          .catch(() => handleMutationError());
      });
    // await timeout(1000); //1 second timeout so the database have time to update before going to Summary
    props.handleNav(true);
    if (!data.energyWastes) {
      props.handleNav(true);
    }
  };

  const onPreviousSubmit = () => {
    const data = getValues();
    data.energyWastes &&
      data.energyWastes.map(async (entry) => {
        await createEnergyTypeMutate({
          energyWasteId: entry.id ? entry.id : null,
          energyWasteName: entry.energyWasteName,
          wasteSource: findSelectValue<TWasteSource>(entry.wasteSource, WasteSource),
          temperature: entry.temperature,
          pressure: entry.pressure ? entry.pressure : null,
          energyAmount: entry.energyAmount ? entry.energyAmount : null,
          averageEffect: entry.averageEffect ? entry.averageEffect : null,
          maxEffect: entry.maxEffect ? entry.maxEffect : null,
          massAmount: entry.massAmount ? entry.massAmount : null,
          availability: entry.availability,
          pollutedWith: findSelectValue<TPollution>(entry.pollutedWith, Pollution),
          internalPlan: findSelectValue<TInternalPlan>(entry.internalPlan, InternalPlan),
          plannedRecoveredInternal: entry.plannedRecoveredInternal ? entry.plannedRecoveredInternal : null,
          externalPlan: findSelectValue<TExternalPlan>(entry.externalPlan, ExternalPlan),
          distanceToExternal: entry.distanceToExternal ? entry.distanceToExternal : null,
          temperatureNeeds: entry.temperatureNeeds ? entry.temperatureNeeds : null,
          plannedRecoveredExternal: entry.plannedRecoveredExternal ? entry.plannedRecoveredExternal : null,
          comment: entry.comment,
          energySchemaId: id,
        }).then((result: any) => {
          if (result.error) console.log("oh nooo..", result.error);
          else {
            props.handleNav(false);
          }
        });
        props.handleNav(false);
      });

    if (!data.energyWastes) {
      props.handleNav(false);
    }
  };

  React.useEffect(() => {
    if (!fetching && data && data.energyWastes.length !== 0) {
      setRadioState("Ja");
      if (isReset) {
        reset(data);
        setIsReset(false);
      }
    }
  }, [fetching, data, reset, setIsReset, isReset]);

  return (
    <EnergyWasteContainer>
      <Title>Spillvarme som ikke utnyttes</Title>
      <Description>
        Har bedriften spillvarmemengder som <DescriptionBold>ikke utnyttes</DescriptionBold> i dag?
      </Description>

      {!fetching ? (
        <>
          <div>
            <label>
              <input
                type="radio"
                name="answer"
                value={"Nei"}
                checked={"Nei" === radioState}
                onChange={(e) => {
                  setRadioState(e.target.value);
                }}
              />{" "}
              Nei
            </label>
            <br />
            <label>
              <input
                type="radio"
                name="answer"
                value={"Ja"}
                checked={"Ja" === radioState}
                onChange={(e) => setRadioState(e.target.value)}
              />{" "}
              Ja
            </label>
          </div>

          {radioState === "Ja" && (
            <>
              <Required />

              {fields.map((row, index) => {
                return (
                  <React.Fragment key={row.fieldId}>
                    <OuterBody>
                      <InnerBody>
                        <BodyContainer>
                          <Number>
                            <Circle size={CircleSize.LARGE}>{index + 1}</Circle>
                          </Number>
                          <NonUtilizedEnergyData
                            index={index}
                            register={register()}
                            watchWasteSource={watchWasteSource}
                            row={row}
                            remove={remove}
                            data={data}
                            errors={errors}
                          />
                          <Controller
                            control={control}
                            name={`energyWastes[${index}].id`}
                            defaultValue={row.id ? row.id : null}
                            as={<span />}
                          />
                        </BodyContainer>
                      </InnerBody>
                    </OuterBody>
                  </React.Fragment>
                );
              })}
              <AddArea>
                <Button
                  buttonType={ButtonType.Primary}
                  onClick={() => {
                    append({});
                  }}
                  size={ButtonSize.Large}
                >
                  Legg til ny
                </Button>
              </AddArea>
            </>
          )}
        </>
      ) : (
        <Spinner />
      )}

      <Bottom>
        <Navigation navigation={props.navigation} handleNext={handleSubmit(onSubmit)} handlePrev={onPreviousSubmit} />
      </Bottom>
    </EnergyWasteContainer>
  );
};

export default NonUtilizedEnergy;
