import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Bottom from "../../../components/Bottom";
import Navigation from "../../../components/Navigation";
import QuestionBox from "../../../components/QuestionBox";
import { NavigationContext } from "../../../providers/NavigationProvider";
import AboutMaterialData from "./AboutMaterialData";
import {
  AboutMaterial as AboutMaterialType,
  Param,
  TBooleanSelectExtended,
  TParticleType,
  TParticleUnitSize,
  TWaterReactivity,
  TAutoIgnitionHazard,
  HSentence,
  RSentence,
  SSentence,
} from "../../../types";
import { useMutation, useQuery } from "urql";
import { getAboutMaterial } from "../../../graphql/queries/Materialflow/getAboutMaterial";
import { updateAboutMaterial } from "../../../graphql/mutations/Materialflow/updateAboutMaterial";
import { findSelectValue, mapToSelectOption } from "../../../utils/form-utils";
import { BooleanSelectExtended, ParticleType, ParticleUnitSize, WaterReactivity, AutoIgnitionHazard } from "../../../dictionaries";
import InternalQuestionBox from "../../../components/InternalQuestionBox";
import { yupResolver } from "@hookform/resolvers/yup";
import { aboutMaterialSchema } from "../../../schemas";
import Spinner from "../../../components/Loading";
import { IMutationResult } from "../../../types/mutations/mutationResult";
import { handleMutationError } from "../../../lib/toaster";
import { InformationBoilerPlateMaterial } from "../../../components/InformationBoilerPlate";
import Required from "../../../components/Required";

const Container = styled.div`
  grid-column: 2/3;
`;

const LoadingContainer = styled.div`
  display: flex;
  place-content: center;
`;

type IAboutMaterialType = {
  materialStreamMaterial: AboutMaterialType;
};
const AboutMaterial: React.FC = () => {
  const [isReset, setIsReset] = React.useState<boolean>(true);
  const { id } = useParams<Param>();
  const { register, reset, handleSubmit, errors, control, getValues } = useForm<IAboutMaterialType>({
    resolver: yupResolver(aboutMaterialSchema),
    defaultValues: {},
  });
  const [aboutMaterialQueryResult] = useQuery({
    query: getAboutMaterial,
    variables: { id },
  });
  const { data, fetching } = aboutMaterialQueryResult;
  const [, executeMutation] = useMutation<IMutationResult>(updateAboutMaterial);

  function handleExecuteMutation(materialStreamMaterial: any, nav: boolean) {
    materialStreamMaterial &&
      executeMutation({
        id: id,
        dangerousWaste: findSelectValue<TBooleanSelectExtended>(materialStreamMaterial.dangerousWaste, BooleanSelectExtended),
        limitationsToMaterialDeposit: findSelectValue<TBooleanSelectExtended>(
          materialStreamMaterial.limitationsToMaterialDeposit,
          BooleanSelectExtended
        ),

        depositDemandsComment: materialStreamMaterial.depositDemandsComment || null,
        waterReactivity: findSelectValue<TWaterReactivity>(materialStreamMaterial.waterReactivity, WaterReactivity),
        autoIgnitionHazard: findSelectValue<TAutoIgnitionHazard>(materialStreamMaterial.autoIgnitionHazard, AutoIgnitionHazard),
        particleType: findSelectValue<TParticleType>(materialStreamMaterial.particleType, ParticleType),
        particeSizeUnit: findSelectValue<TParticleUnitSize>(materialStreamMaterial.particeSizeUnit, ParticleUnitSize),
        particleSizeD50: materialStreamMaterial.particleSizeD50 || null,
        particleSizeMin: materialStreamMaterial.particleSizeMin || null,
        particleSizeMax: materialStreamMaterial.particleSizeMax || null,
        particleSizeComment: materialStreamMaterial.particleSizeComment || null,
        moistureContent: materialStreamMaterial.moistureContent || null,
        materialStreamRSentences:
          materialStreamMaterial?.rSentences?.length > 0
            ? (materialStreamMaterial?.rSentences?.map((sentence: any) => {
                return { materialStreamSchemaId: id, rSentenceId: sentence.value };
              }) as RSentence)
            : [],
        materialStreamHSentences:
          materialStreamMaterial?.hSentences?.length > 0
            ? (materialStreamMaterial?.hSentences?.map((sentence: any) => {
                return { materialStreamSchemaId: id, hSentenceId: sentence.value };
              }) as HSentence)
            : [],
        materialStreamSSentences:
          materialStreamMaterial?.sSentences?.length > 0
            ? (materialStreamMaterial?.sSentences?.map((sentence: any) => {
                return { materialStreamSchemaId: id, sSentenceId: sentence.value };
              }) as SSentence)
            : [],
      })
        .then((res) => {
          if (res.error) handleMutationError(res.error);
          context.handleNav(nav);
        })
        .catch(() => handleMutationError());
  }

  const onSubmit: SubmitHandler<IAboutMaterialType> = ({ materialStreamMaterial }) => {
    handleExecuteMutation(materialStreamMaterial, true);
  };

  const onPreviousSubmit: SubmitHandler<IAboutMaterialType> = ({ materialStreamMaterial }) => {
    handleExecuteMutation(materialStreamMaterial, false);
  };

  const context = React.useContext(NavigationContext);

  React.useEffect(() => {
    if (!fetching && data?.materialStreamMaterial) {
      if (isReset) {
        reset({
          materialStreamMaterial: {
            ...data.materialStreamMaterial,
            dangerousWaste: BooleanSelectExtended[
              data.materialStreamMaterial.dangerousWaste as TBooleanSelectExtended
            ] as TBooleanSelectExtended,
            limitationsToMaterialDeposit: BooleanSelectExtended[
              data.materialStreamMaterial.limitationsToMaterialDeposit as TBooleanSelectExtended
            ] as TBooleanSelectExtended,
            waterReactivity: WaterReactivity[data.materialStreamMaterial.waterReactivity as TWaterReactivity] as TWaterReactivity,
            autoIgnitionHazard: AutoIgnitionHazard[
              data.materialStreamMaterial.autoIgnitionHazard as TAutoIgnitionHazard
            ] as TAutoIgnitionHazard,
            particleType: ParticleType[data.materialStreamMaterial.particleType as TParticleType] as TParticleType,
            particeSizeUnit: ParticleUnitSize[data.materialStreamMaterial.particeSizeUnit as TParticleUnitSize] as TParticleUnitSize,
            sSentences: data.materialStreamMaterial.materialStreamSSentences
              ? data.materialStreamMaterial.materialStreamSSentences.map((sentence: any) => {
                  return mapToSelectOption(sentence.sSentence.id, sentence.sSentence.sentenceId);
                })
              : [],
            hSentences: data.materialStreamMaterial.materialStreamHSentences
              ? data.materialStreamMaterial.materialStreamHSentences.map((sentence: any) => {
                  return mapToSelectOption(sentence.hSentence.id, sentence.hSentence.sentenceId);
                })
              : [],
            rSentences: data.materialStreamMaterial.materialStreamRSentences
              ? data.materialStreamMaterial.materialStreamRSentences.map((sentence: any) => {
                  return mapToSelectOption(sentence.rSentence.id, sentence.rSentence.sentenceId);
                })
              : [],
          },
        });

        setIsReset(false);
      }
    }
  }, [isReset, data, fetching, reset]);
  return (
    <Container>
      <QuestionBox title="Om materialet" required>
        <InformationBoilerPlateMaterial />
        <Required />
        {fetching ? (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        ) : (
          <InternalQuestionBox>
            <AboutMaterialData register={register} errors={errors} data={data} control={control} getValues={getValues} />{" "}
          </InternalQuestionBox>
        )}
      </QuestionBox>
      <Bottom>
        <Navigation navigation={context.navigation} handleNext={handleSubmit(onSubmit)} handlePrev={handleSubmit(onPreviousSubmit)} />
      </Bottom>
    </Container>
  );
};
export default AboutMaterial;
