import React from "react";
import styled from "styled-components";
import { useQuery } from "urql";
import Button, { ButtonSize, ButtonType } from "../../components/Button";
import EmptyState from "../../components/EmptyState";
import ErrorMessage from "../../components/ErrorMessage";
import Spinner from "../../components/Loading";
import Heading from "../../components/Heading";
import { getUsers } from "../../graphql/queries/User/getUsers";
import { useAuth } from "../../hooks/useAuth";
import { GetUsersQueryResult } from "../../types";
import UserList from "./UsersList";
import { FiPlus } from "react-icons/fi";
import Modal from "react-modal";
import { defaultStyles } from "../../lib/modal";
import CreateUser from "../CreateUser";

const ActionsContainer = styled.div`
  margin-bottom: 20px;
`;

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const Users: React.FC = () => {
  const { isAdmin } = useAuth();
  const [createModalIsOpen, setCreateModalIsOpen] = React.useState(false);

  const [{ data, fetching, error }, refetchGetUsersQuery] = useQuery<GetUsersQueryResult>({
    query: getUsers,
  });

  if (fetching) return <Spinner />;
  if (error) return <ErrorMessage />;

  return (
    <div>
      <Nav>
        <Heading>Brukere</Heading>

        {isAdmin() && (
          <ActionsContainer>
            <Button buttonType={ButtonType.Primary} size={ButtonSize.Large} onClick={() => setCreateModalIsOpen(true)}>
              <FiPlus size={"1.2em"} /> Opprett bruker
            </Button>
          </ActionsContainer>
        )}
      </Nav>

      {!data || data.users.length < 1 ? (
        <EmptyState title="Ingen brukere" />
      ) : (
        <UserList users={data.users} refetchUsersQuery={refetchGetUsersQuery}></UserList>
      )}

      <Modal isOpen={createModalIsOpen} onRequestClose={() => setCreateModalIsOpen(false)} style={defaultStyles}>
        <CreateUser
          handleSave={() => {
            setCreateModalIsOpen(false);
            refetchGetUsersQuery();
          }}
          handleCancel={() => setCreateModalIsOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default Users;
