import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { useMutation } from "urql";
import Button, { ButtonSize, ButtonType } from "../../components/Button";
import ContentBox from "../../components/ContentBox";
import ErrorContainer from "../../components/ErrorContainer";
import FormFieldError from "../../components/FormFieldError";
import FormLabel from "../../components/FormLabel";
import Logo from "../../components/Logo";
import { generateResetPasswordToken } from "../../graphql/mutations/General/generateResetPasswordToken";
import { ROUTES } from "../../lib/Router/routes";
import { handleMutationError } from "../../lib/toaster";
import { generateResetPasswordSchema } from "../../schemas";
import { GenerateResetPasswordFormData } from "../../types";
import { IMutationResult } from "../../types/mutations/mutationResult";

const LogoContainer = styled.div`
  text-align: center;
`;

const LoginTitle = styled.div`
  font-size: ${(props) => props.theme.fontSizes.large};
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  text-align: center;
  margin-bottom: 20px;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1em;
`;

const ButtonArea = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GenerateResetPassword: React.FC = (props) => {
  const [result, executeMutation] = useMutation<IMutationResult>(generateResetPasswordToken);
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm<GenerateResetPasswordFormData>({
    resolver: yupResolver(generateResetPasswordSchema),
  });

  const onSubmit = handleSubmit(async (formValues) => {
    executeMutation({ email: formValues.email })
      .then(({ data }) => {
        if (data?.generateResetPasswordToken.success) {
          history.push(ROUTES.PUBLIC.RESET_PASSWORD_ROUTE);
        } else {
          handleMutationError();
        }
      })
      .catch(() => handleMutationError());
  });

  return (
    <div>
      <LoginTitle>Nullstill passord</LoginTitle>

      <ContentBox>
        <LogoContainer>
          <Logo />
        </LogoContainer>

        <form onSubmit={onSubmit} noValidate>
          <InputsContainer>
            <FormLabel>E-post</FormLabel>

            <input type="email" name="email" ref={register} placeholder="Din e-post" autoComplete="off" />

            <FormFieldError error={errors.email}></FormFieldError>
            {result.error && <ErrorContainer>Det oppstod en feil</ErrorContainer>}
          </InputsContainer>

          <ButtonArea>
            <Button type="submit" size={ButtonSize.Large} buttonType={ButtonType.Primary}>
              Send kode
            </Button>
          </ButtonArea>
        </form>

        <ButtonArea>
          <Link to={ROUTES.PUBLIC.SIGN_IN_ROUTE}>Tilbake til innlogging</Link>

          <Link to={ROUTES.PUBLIC.RESET_PASSWORD_ROUTE}>Har du kode?</Link>
        </ButtonArea>
      </ContentBox>
    </div>
  );
};

export default GenerateResetPassword;
