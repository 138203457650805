import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useMutation, useQuery } from "urql";
import Button, { ButtonSize, ButtonType } from "../../components/Button";
import ContentBox from "../../components/ContentBox";
import ErrorContainer from "../../components/ErrorContainer";
import FormFieldError from "../../components/FormFieldError";
import FormLabel from "../../components/FormLabel";
import Spinner from "../../components/Loading";
import Heading from "../../components/Heading";
import OrganizationUserList from "../../components/OrganizationUserList";
import { getUsers } from "../../graphql/queries/User/getUsers";
import { addOrganizationUserAccessSchema } from "../../schemas";
import { AddOrganizationUserAccessFormData, GetOrganizationInfoQueryResult, GetUsersQueryResult, Param } from "../../types";
import Select from "react-select";
import { mapToSelectOption } from "../../utils/form-utils";
import { addOrganizationUserAccess } from "../../graphql/mutations/Organization/addOrganizationUserAccess";
import { removeOrganizationUserAccess } from "../../graphql/mutations/Organization/removeOrganizationUserAccess";
import EmptyState from "../../components/EmptyState";
import { IMutationResult } from "../../types/mutations/mutationResult";
import { handleMutationError, handleMutationSuccess } from "../../lib/toaster";
import ErrorMessage from "../../components/ErrorMessage";
import { getOrganizationInfo } from "../../graphql/queries/General/getOrganizationInfo";
import { FiPlus } from "react-icons/fi";

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const OrganizationUsersContainer = styled.div`
  margin-top: 15px;
`;

const ButtonArea = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OrganizationAccess: React.FC = () => {
  const [addOrganizationUserAccessResult, executeAddOrganizationUserAccessMutation] = useMutation<IMutationResult>(
    addOrganizationUserAccess
  );
  const [, executeRemoveOrganizationUserAccessMutation] = useMutation<IMutationResult>(removeOrganizationUserAccess);

  const { id: organizationId } = useParams<Param>();

  const [{ data: organization, fetching: organizationFetching, error: organizationError }] = useQuery<GetOrganizationInfoQueryResult>({
    query: getOrganizationInfo,
    variables: { organizationId: organizationId },
  });

  const [
    { data: organizationUsersResult, fetching: organizationUsersFetching, error: organizationUsersError },
    reexecuteQuery,
  ] = useQuery<GetUsersQueryResult>({
    query: getUsers,
    variables: { filterByOrganizationId: organizationId },
  });

  const [{ data: allUsersResult, fetching: allUsersFetching, error: allUsersError }] = useQuery<GetUsersQueryResult>({
    query: getUsers,
  });

  const { handleSubmit, errors, control } = useForm<AddOrganizationUserAccessFormData>({
    resolver: yupResolver(addOrganizationUserAccessSchema),
  });

  const onSubmit = handleSubmit(async (formValues) => {
    executeAddOrganizationUserAccessMutation({ userId: formValues.user.value.toString(), organizationId: organizationId.toString() })
      .then(({ data }) => {
        if (data?.addOrganizationUserAccess.success) {
          handleMutationSuccess(data.addOrganizationUserAccess);

          reexecuteQuery();
        } else {
          handleMutationError();
        }
      })
      .catch(() => handleMutationError());
  });

  const onRemoveOrganizationUserAccess = async (userId: string) => {
    executeRemoveOrganizationUserAccessMutation({ userId, organizationId })
      .then(({ data }) => {
        if (data?.removeOrganizationUserAccess.success) {
          handleMutationSuccess(data.addOrganizationUserAccess);

          reexecuteQuery();
        } else {
          handleMutationError();
        }
      })
      .catch(() => handleMutationError());
  };

  const getUsersWithoutAccess = () => {
    if (allUsersResult && allUsersResult?.users.length > 0) {
      var uniqueResultOne = allUsersResult?.users.filter(function (obj) {
        if (organizationUsersResult && organizationUsersResult?.users.length > 0) {
          return !organizationUsersResult?.users.some(function (obj2) {
            return obj.id === obj2.id;
          });
        }

        return allUsersResult.users;
      });

      return uniqueResultOne;
    }

    return [];
  };

  if (organizationUsersFetching || organizationFetching) return <Spinner />;
  if (organizationUsersError || organizationError) return <ErrorMessage />;

  return (
    <div>
      <ContentBox>
        <Heading>Tilknytt en bruker til {organization?.organization.name}</Heading>

        <form onSubmit={onSubmit} noValidate>
          <InputsContainer>
            <FormLabel>Søk etter bruker</FormLabel>

            {allUsersError && <div>Feil: Kunne ikke hente brukerliste</div>}

            {allUsersFetching || !allUsersResult ? (
              <Spinner />
            ) : (
              <Controller
                name="user"
                control={control}
                placeholder="Velg..."
                options={getUsersWithoutAccess().map((user) => mapToSelectOption(user.id, `${user.fullName} (${user.email})`))}
                defaultValue=""
                as={Select}
              />
            )}

            <FormFieldError error={errors.user?.value}></FormFieldError>

            {addOrganizationUserAccessResult.error && <ErrorContainer>Kunne ikke legge inn tilgang.</ErrorContainer>}
          </InputsContainer>

          <ButtonArea>
            <Button type="submit" size={ButtonSize.Large} buttonType={ButtonType.Primary}>
              <FiPlus size={"1.2em"} /> Legg til
            </Button>
          </ButtonArea>
        </form>
      </ContentBox>

      <OrganizationUsersContainer>
        <Heading>Brukere som har tilgang til {organization?.organization.name}</Heading>

        {organizationUsersResult && organizationUsersResult.users.length > 0 ? (
          <OrganizationUserList users={organizationUsersResult.users} onRemove={onRemoveOrganizationUserAccess} />
        ) : (
          <EmptyState
            title="Ingen tilganger er lagt til"
            description="Du kan legge til nye tilganger til virksomheten i dialogboksen over."
          ></EmptyState>
        )}
      </OrganizationUsersContainer>
    </div>
  );
};

export default OrganizationAccess;
