import styled from "styled-components";

const RequiredText = styled.div`
  font-style: italic;
  font-size: ${(props) => props.theme.fontSizes.small};
  float: right;
  margin-bottom: 1rem;
`;

const Required = () => {
  return <RequiredText>Felt markert med * må fylles ut.</RequiredText>;
};

export default Required;
