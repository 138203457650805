import React from "react";
import { Navigation } from "../types";

export const NavigationContext = React.createContext<Navigation>({
  navigation: {
    step: 1,
    amountOfSteps: 8,
  },
  handleNav: () => {},
});
