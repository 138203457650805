import { gql } from "urql";

export const updateWoodMaterial = gql`
  mutation(
    $id: ID
    $dangerousWaste: BoolEnum
    $limitationsToMaterialDeposit: BoolEnum
    $depositDemandsComment: String
    $autoIgnitionHazard: BoolEnum
    $treatmentAfterReceving: TreatmentAfterRecevingEnum
  ) {
    updateWoodSchemaMaterial(
      input: {
        id: $id
        dangerousWaste: $dangerousWaste
        limitationsToMaterialDeposit: $limitationsToMaterialDeposit
        depositDemandsComment: $depositDemandsComment
        autoIgnitionHazard: $autoIgnitionHazard
        treatmentAfterReceving: $treatmentAfterReceving
      }
    ) {
      __typename
      id
      success
    }
  }
`;
