import { DefaultTheme } from "styled-components";

declare module "styled-components" {
  export interface DefaultTheme extends Theme {}
}

export enum ThemeNames {
  DAY = "Day",
}
type ButtonTextColors = {
  active: string;
  inactive: string;
};
type TextColors = {
  titleTextColor: string;
  mainTextColor: string;
  buttonTextColor: ButtonTextColors;
};

type BodyBackgroundColors = {
  bottom: string;
  middle: string;
  top: string;
};

type Color = {
  textColor: string;
  backgroundColor: string;
};

type ButtonColors = {
  primary: string;
  secondary: string;
};

type HorizontalLineColors = {
  incubas: string;
  primary: string;
};

type InputColors = {
  borderColor: string;
  textColor: string;
};
type Colors = {
  bodyBackgroundColor: BodyBackgroundColors;
  textColor: TextColors;
  borderColor: string;
  buttonColor: ButtonColors;
  textDecorationColor: string;
  bulletColor: string;
  horizontalLineColor: HorizontalLineColors;
  inputColor: InputColors;
  success: Color;
  warning: Color;
  danger: Color;
  default: Color;
};

type FontSizes = {
  xs: string;
  small: string;
  medium: string;
  large: string;
};

type FontFamilies = {
  roboto: string;
  robotoCondensed: string;
};
type BorderRadius = {
  light: string;
  medium: string;
  heavy: string;
};
type Padding = {
  light: string;
  medium: string;
  heavy: string;
};
type Gap = {
  extraLight: string;
  light: string;
  medium: string;
  heavy: string;
};
export type Theme = {
  name: string;
  colors: Colors;
  fontSizes: FontSizes;
  fontFamily: FontFamilies;
  borderRadius: BorderRadius;
  padding: Padding;
  gap: Gap;
};
const day: DefaultTheme = {
  name: ThemeNames.DAY,
  colors: {
    default: {
      textColor: "#064F59",
      backgroundColor: "#F5FAFB",
    },
    success: {
      textColor: "#15803D",
      backgroundColor: "#BBF7D0",
    },
    warning: {
      textColor: "#CA8A04",
      backgroundColor: "#FEF9C3",
    },
    danger: {
      textColor: "#DC2626",
      backgroundColor: "#FCA5A5",
    },
    bodyBackgroundColor: {
      bottom: "#FFF",
      middle: "#F5FAFB",
      top: "#DCEEF0",
    },
    textColor: {
      titleTextColor: "#064F59",
      mainTextColor: "#3B3C38",
      buttonTextColor: {
        active: "#064F59",
        inactive: "#FFF",
      },
    },
    borderColor: "#FFF",
    buttonColor: {
      primary: "#064F59",
      secondary: "#DCEEF0",
    },
    textDecorationColor: "#82A6AB",
    bulletColor: "#B8DDE1",
    horizontalLineColor: {
      incubas: "#C0CFD1",
      primary: "#82A6AB",
    },
    inputColor: {
      borderColor: "#CCC",
      textColor: "#898A88",
    },
  },
  fontSizes: {
    xs: ".8rem",
    small: "1rem",
    medium: "1.5rem",
    large: "2rem",
  },
  fontFamily: { roboto: "Roboto", robotoCondensed: "Roboto Condensed" },
  borderRadius: {
    light: "1em",
    medium: "1.5em",
    heavy: "2em",
  },
  padding: {
    light: "1em",
    medium: "2em",
    heavy: "3em",
  },
  gap: {
    extraLight: "0.5em",
    light: "1em",
    medium: "2em",
    heavy: "3em",
  },
};

export { day };
