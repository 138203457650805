import { gql } from "urql";

export const getChemicalAnalysis = gql`
  query($materialStreamSchemaId: ID!) {
    chemicalAnalysises(filter: { materialStreamSchemaId: $materialStreamSchemaId }) {
      chemicalAnalysisName
      chemicalUnit
      maxValue
      minValue
      typicalValue
      shareChemicalAnalysisApproval
      id
      __typename
    }
  }
`;
