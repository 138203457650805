import React from "react";
import styled from "styled-components";
import Bottom from "../../../components/Bottom";
import { useMutation, useQuery } from "urql";
import Navigation from "../../../components/Navigation";
import SummaryBoilerplate from "../../../components/SummaryBoilerplate";
import { NavigationContext } from "../../../providers/NavigationProvider";
import { getMaterialStreamSummary } from "../../../graphql/queries/Materialflow/getMaterialStreamSummary";
import { updateMaterialFlowSubmit } from "../../../graphql/mutations/Materialflow/updateMaterialFlowSubmit";
import { useParams } from "react-router-dom";
import {
  Param,
  TMaterialType,
  TProductionVolumeUnit,
  TProductionPeriod,
  TMaterialState,
  TProductionBatch,
  TOrganic,
  TBooleanSelectExtended,
  TWaterReactivity,
  TAutoIgnitionHazard,
  TParticleType,
  TParticleUnitSize,
  TChemicalUnit,
} from "../../../types";
import {
  MaterialType,
  ProductionVolumeUnit,
  ProductionPeriod,
  MaterialState,
  ProductionBatch,
  Organic,
  BooleanSelectExtended,
  WaterReactivity,
  AutoIgnitionHazard,
  ParticleType,
  ParticleUnitSize,
  ChemicalUnit,
} from "../../../dictionaries";
import Spinner from "../../../components/Loading";
import { IMutationResult } from "../../../types/mutations/mutationResult";
import { handleMutationError } from "../../../lib/toaster";

const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.gap.medium};
`;

const Box = styled.div`
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.middle};
  border-radius: ${(props) => props.theme.borderRadius.light};
  width: 95%;
  padding: ${(props) => props.theme.padding.medium};
`;
const GridBox = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
`;
const SimpleGridBox = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr;
`;

const BoxTitle = styled.div`
  text-align: start;
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  font-weight: 500;
`;
const LineContainer = styled.div`
  grid-column: 1/-1;
`;
const HeaderLine = styled.hr`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.textDecorationColor};
`;
const Headers = styled.div`
  font-weight: 500;
`;
const FirstHeader = styled(Headers)`
  grid-column: 1/2;
`;
const ThirdHeader = styled(Headers)`
  grid-column: 2/3;
`;
const FourthHeader = styled(Headers)`
  grid-column: 3/3;
`;
const FifthHeader = styled(Headers)`
  grid-column: 4/4;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const FirstData = styled(BodyContainer)`
  grid-column: 1/2;
  margin-bottom: 1em;
`;
const ThirdData = styled(BodyContainer)`
  grid-column: 2/3;
`;
const FourthData = styled(BodyContainer)`
  grid-column: 3/3;
`;
const FifthData = styled(BodyContainer)`
  grid-column: 4/4;
`;
const SentenceContainer = styled.span`
  margin-bottom: 1rem;
`;
const Summary: React.FC = () => {
  const context = React.useContext(NavigationContext);
  const { id } = useParams<Param>();
  const [summaryResult] = useQuery({
    query: getMaterialStreamSummary,
    variables: { id: id },
  });
  const { data, fetching } = summaryResult;
  const [, setSchemaDeliveredMutate] = useMutation<IMutationResult>(updateMaterialFlowSubmit);

  const onSubmit = () => {
    setSchemaDeliveredMutate({
      id: id,
      schemaIsDelivered: "YES",
    })
      .then((res) => {
        context.handleNav(true);
      })
      .catch(() => handleMutationError());
  };
  return (
    <Container>
      <SummaryBoilerplate />
      {!fetching ? (
        <>
          <BodyContainer>
            <BoxTitle>Om sidestrømmen</BoxTitle>

            <GridBox>
              <FirstHeader>Spørsmål</FirstHeader>
              <FourthHeader>Svar</FourthHeader>
              <LineContainer>
                <HeaderLine />
              </LineContainer>
              <FirstData>Hevdnavn på sidestrømmen</FirstData>
              <FourthData>{data.materialStreamSummary.givenName}</FourthData>
              <FirstData>Er materialet biprodukt, avfall eller avgangsmasse?</FirstData>
              <FourthData>{MaterialType[data.materialStreamSummary.materialType as TMaterialType]}</FourthData>
              {data.materialStreamSummary.materialType === "WASTE" && (
                <>
                  <FirstData>EAL kode</FirstData>
                  <FourthData>{data.materialStreamSummary.eALCode?.name}</FourthData>
                  <FirstData>Avfallskode (Norsk standard)</FirstData>
                  <FourthData>{data.materialStreamSummary.wasteCode?.name}</FourthData>
                </>
              )}
              <FirstData>Sidestrømmens opprinnelse</FirstData>
              <FourthData>{data.materialStreamSummary.sideStreamOrigin}</FourthData>
              <FirstData>Årlig produksjon</FirstData>
              <FourthData>
                {data.materialStreamSummary.yearlyProduction}{" "}
                {ProductionVolumeUnit[data.materialStreamSummary.productionVolumeUnit as TProductionVolumeUnit]}
              </FourthData>
              <FirstData>Produseres materialet kontinuerlig eller sporadisk/batch</FirstData>
              <FourthData>{ProductionPeriod[data.materialStreamSummary.productionPeriod as TProductionPeriod]}</FourthData>
              <FirstData>Produksjonsvolum ved sporadisk eller batch</FirstData>
              <FourthData>
                {data.materialStreamSummary.productionVolumeBatch}{" "}
                {ProductionBatch[data.materialStreamSummary.productionBatch as TProductionBatch]}
              </FourthData>

              <FirstData>Materialtilstand</FirstData>
              <FourthData>{MaterialState[data.materialStreamSummary.materialState as TMaterialState]}</FourthData>
              <FirstData>Er materialet organisk eller uorganisk?</FirstData>
              <FourthData>{Organic[data.materialStreamSummary.organic as TOrganic]}</FourthData>
            </GridBox>
          </BodyContainer>

          <BodyContainer>
            <BoxTitle>Utnyttelse</BoxTitle>
            <GridBox>
              <FirstHeader>Spørsmål</FirstHeader>
              <FourthHeader>Svar</FourthHeader>
              <LineContainer>
                <HeaderLine />
              </LineContainer>
              <FirstData>Intern deponering</FirstData>
              <FourthData>{data.materialStreamSummary.internalDeposition} %</FourthData>
              <FirstData>Ekstern deponering</FirstData>
              <FourthData>{data.materialStreamSummary.externalDeposition} %</FourthData>
              <FirstData>Intern gjenvinning</FirstData>
              <FourthData>{data.materialStreamSummary.internalRecycling} %</FourthData>
              <FirstData>Salg/leveranse til ekstern mottaker</FirstData>
              <FourthData>{data.materialStreamSummary.externalSales} %</FourthData>
              <FirstData>Forbrennes materialet?</FirstData>
              <FourthData>{BooleanSelectExtended[data.materialStreamSummary.isBurned as TBooleanSelectExtended]}</FourthData>
            </GridBox>
          </BodyContainer>

          <BodyContainer>
            <BoxTitle>Om materialet</BoxTitle>
            <GridBox>
              <FirstHeader>Spørsmål</FirstHeader>
              <FourthHeader>Svar</FourthHeader>
              <LineContainer>
                <HeaderLine />
              </LineContainer>
              <FirstData>R-setninger</FirstData>
              <FourthData>
                {data.materialStreamSummary.materialStreamRSentences.map((sentence: any) => {
                  return (
                    <SentenceContainer key={sentence.rSentence?.sentenceId}>
                      {sentence.rSentence?.sentenceId} {sentence.rSentence?.description}
                    </SentenceContainer>
                  );
                })}
              </FourthData>
              <FirstData>S-setninger</FirstData>
              <FourthData>
                {" "}
                {data.materialStreamSummary.materialStreamSSentences.map((sentence: any) => {
                  return (
                    <SentenceContainer key={sentence.sSentence?.sentenceId}>
                      {sentence.sSentence?.sentenceId} {sentence.sSentence?.description}
                    </SentenceContainer>
                  );
                })}
              </FourthData>
              <FirstData>H-setninger</FirstData>
              <FourthData>
                {data.materialStreamSummary.materialStreamHSentences.map((sentence: any) => {
                  return (
                    <SentenceContainer key={sentence.hSentence?.sentenceId}>
                      {sentence.hSentence?.sentenceId} {sentence.hSentence?.description}
                    </SentenceContainer>
                  );
                })}
              </FourthData>
              <FirstData>Er materialet klassifisert som farlig avfall?</FirstData>
              <FourthData>{BooleanSelectExtended[data.materialStreamSummary.dangerousWaste as TBooleanSelectExtended]}</FourthData>
              <FirstData>Eksisterer det bergrensninger eller andre myndighetskrav til deponering av materialet?</FirstData>
              <FourthData>
                {BooleanSelectExtended[data.materialStreamSummary.limitationsToMaterialDeposit as TBooleanSelectExtended]}
              </FourthData>
              <FirstData>Reaktivitet ved vann</FirstData>
              <FourthData>{WaterReactivity[data.materialStreamSummary.waterReactivity as TWaterReactivity]}</FourthData>
              <FirstData>Selvantennelsesfare</FirstData>
              <FourthData>{AutoIgnitionHazard[data.materialStreamSummary.autoIgnitionHazard as TAutoIgnitionHazard]}</FourthData>
              <FirstData>Type partikkel</FirstData>
              <FourthData>{ParticleType[data.materialStreamSummary.particleType as TParticleType]}</FourthData>

              <FirstData>Partikelstørrelse D50</FirstData>
              <FourthData>
                {data.materialStreamSummary.particleSizeD50}{" "}
                {ParticleUnitSize[data.materialStreamSummary.particeSizeUnit as TParticleUnitSize]}
              </FourthData>
              <FirstData>Partikkelstørrelse Min</FirstData>
              <FourthData>
                {data.materialStreamSummary.particleSizeMin}{" "}
                {ParticleUnitSize[data.materialStreamSummary.particeSizeUnit as TParticleUnitSize]}
              </FourthData>
              <FirstData>Partikkelstørrelse Max</FirstData>
              <FourthData>
                {data.materialStreamSummary.particleSizeMax}{" "}
                {ParticleUnitSize[data.materialStreamSummary.particeSizeUnit as TParticleUnitSize]}
              </FourthData>
              <FirstData>Typisk fuktighetsinnhold</FirstData>
              <FourthData>{data.materialStreamSummary.moistureContent} %</FourthData>
            </GridBox>
          </BodyContainer>

          <BodyContainer>
            <BoxTitle>Analyse</BoxTitle>
            <GridBox>
              <FirstHeader>Spørsmål</FirstHeader>
              <FourthHeader>Svar</FourthHeader>
              <LineContainer>
                <HeaderLine />
              </LineContainer>
              <FirstHeader>Kjemisk analyse</FirstHeader>
              <FourthHeader>
                <SimpleGridBox>
                  <FirstHeader>Typisk verdi</FirstHeader>
                  <ThirdHeader>Min</ThirdHeader>
                  <FourthHeader>Max</FourthHeader>
                  <FifthHeader>Kan deles</FifthHeader>
                </SimpleGridBox>
              </FourthHeader>
              <LineContainer>
                <HeaderLine />
              </LineContainer>
              {data.materialStreamSummary?.chemicalAnalyses.map((analysis: any) => {
                return (
                  <>
                    <FirstData key={analysis.id}>{analysis.chemicalAnalysisName}</FirstData>
                    <FourthData>
                      <SimpleGridBox>
                        <FirstData>
                          {analysis.typicalValue}
                          {analysis.typicalValue && ChemicalUnit[analysis.chemicalUnit as TChemicalUnit]}
                        </FirstData>
                        <ThirdData>
                          {analysis.minValue}
                          {analysis.minValue && ChemicalUnit[analysis.chemicalUnit as TChemicalUnit]}
                        </ThirdData>
                        <FourthData>
                          {analysis.maxValue}
                          {analysis.maxValue && ChemicalUnit[analysis.chemicalUnit as TChemicalUnit]}
                        </FourthData>
                        <FifthData>{analysis.shareChemicalAnalysisApproval ? "X" : ""}</FifthData>
                      </SimpleGridBox>
                    </FourthData>
                  </>
                );
              })}
            </GridBox>
          </BodyContainer>
        </>
      ) : (
        <Spinner />
      )}
      <Bottom>
        <Navigation navigation={context.navigation} handleNext={onSubmit} handlePrev={() => context.handleNav(false)} />
      </Bottom>
    </Container>
  );
};

export default Summary;
