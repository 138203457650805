import { gql } from "urql";

export const deleteChemicalAnalysis = gql`
  mutation($id: ID) {
    deleteChemicalAnalysis(input: { id: $id }) {
      __typename
      id
      success
    }
  }
`;
