import { gql } from "urql";

export const updateMaterialUtilization = gql`
  mutation(
    $id: ID
    $internalDeposition: Float
    $externalDeposition: Float
    $internalRecycling: Float
    $externalSales: Float
    $isBurned: ExtendedBoolEnum
    $previousInternalAndExternalUsage: ExtendedBoolEnum
    $internalActivitesForMaterialUsage: ExtendedBoolEnum
    $externalActivitesForMaterialUsage: ExtendedBoolEnum
    $internalGreaterPotential: ExtendedBoolEnum
    $externalGreaterPotential: ExtendedBoolEnum
    $previousInternalUsage: ExtendedBoolEnum
    $previousExternalUsage: ExtendedBoolEnum
    $previousInternalAndExternalUsageComment: String
    $internalAndExternalActivitesForMaterialUsage: ExtendedBoolEnum
    $internalAndExternalActivitesForMaterialUsageComment: String
    $internalAndExternalGreaterPotential: ExtendedBoolEnum
    $internalAndExternalGreaterPotentialComment: String
    $links: [CreateOrUpdateMaterialStreamSchemaLinkMutationType]
    $resourcePersons: [CreateOrUpdateMaterialStreamSchemaResourcePersonMutationType]
    $otherComments: String
  ) {
    updateMaterialStreamUsage(
      input: {
        id: $id
        internalDeposition: $internalDeposition
        externalDeposition: $externalDeposition
        internalRecycling: $internalRecycling
        externalSales: $externalSales
        isBurned: $isBurned
        previousInternalAndExternalUsage: $previousInternalAndExternalUsage
        internalActivitesForMaterialUsage: $internalActivitesForMaterialUsage
        externalActivitesForMaterialUsage: $externalActivitesForMaterialUsage
        internalGreaterPotential: $internalGreaterPotential
        externalGreaterPotential: $externalGreaterPotential
        previousInternalUsage: $previousInternalUsage
        previousExternalUsage: $previousExternalUsage
        previousInternalAndExternalUsageComment: $previousInternalAndExternalUsageComment
        internalAndExternalActivitesForMaterialUsage: $internalAndExternalActivitesForMaterialUsage
        internalAndExternalActivitesForMaterialUsageComment: $internalAndExternalActivitesForMaterialUsageComment
        internalAndExternalGreaterPotential: $internalAndExternalGreaterPotential
        internalAndExternalGreaterPotentialComment: $internalAndExternalGreaterPotentialComment
        links: $links
        resourcePersons: $resourcePersons
        otherComments: $otherComments
      }
    ) {
      __typename
      id
      success
    }
  }
`;
