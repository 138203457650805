import React from "react";
import styled from "styled-components";
import { BooleanSelect, TimePeriod, WoodStorage } from "../../../../dictionaries";
import { SelectValues } from "../../../../utils/form-utils";

import MonthInputContainer from "../../../../components/MonthComponents/MonthInputContainer";
import MonthCheckboxContainer from "../../../../components/MonthComponents/MonthCheckboxContainer";

enum InputWidth {
  LONG,
  SHORT,
}
enum InputDirection {
  COLUMN,
  ROW,
}

const InputField = styled.input<InputProps>`
  width: ${(props) => (props.size === InputWidth.LONG ? "100%" : props.size === InputWidth.SHORT ? "50%" : "100%")};
`;
const SelectField = styled.select<InputProps>`
  width: ${(props) => (props.size === InputWidth.LONG ? "100%" : props.size === InputWidth.SHORT ? "50%" : "100%")};
`;
const CommentField = styled.textarea`
  height: 100%;
  grid-column: 1/3;
`;

const FirstColumn = styled.div`
  grid-column: 1/2;
`;

const SecondColumn = styled.div`
  grid-column: 2/3;
`;

const HeaderTitle = styled(FirstColumn)`
  font-weight: 600;
`;

const HeaderSubTitle = styled(FirstColumn)`
  font-style: italic;
  margin-bottom: 1em;
`;

const Question = styled(FirstColumn)``;

const UserInput = styled(SecondColumn)``;
const SpecialUserInput = styled(UserInput)<InputProps>`
  display: flex;
  flex-direction: ${(props) => (props.direction === InputDirection.ROW ? "row" : "column")};
  column-gap: ${(props) => props.theme.gap.extraLight};
  row-gap: ${(props) => props.theme.gap.light};
`;

const FormError = styled.div`
  grid-column: 2;
  margin-bottom: 1em;
`;

interface InputProps {
  size?: InputWidth;
  direction?: InputDirection;
}
type AboutMaterialDataProps = {
  register: any;
  watch: any;
  control: any;
  data: any;
  errors: any;
};

const AboutWoodData: React.FC<AboutMaterialDataProps> = ({ register, watch, control, data, errors }) => {
  return (
    <>
      <HeaderSubTitle>Rapporter inn ett skjema per materialstrøm</HeaderSubTitle>

      <HeaderTitle>Produksjon og frekvens</HeaderTitle>
      <Question>Rapportering per år eller per måned</Question>
      <UserInput>
        <SelectField name="reportPeriod" ref={register}>
          <option value="">Vennligst velg</option>
          {SelectValues(TimePeriod)}
        </SelectField>
        {errors.reportPeriod && <FormError className="error">{errors.reportPeriod.message}</FormError>}
      </UserInput>

      {watch("reportPeriod") === TimePeriod.YEAR && (
        <>
          <Question>År det innrapporteres for</Question>
          <UserInput>
            <InputField name="reportYear" ref={register} />
            {errors.reportYear && <FormError className="error">{errors.reportYear?.message}</FormError>}
          </UserInput>
          <Question>Produksjon i råtonn</Question>
          <UserInput>
            <InputField name="yearlyProduction" ref={register} />
            {errors.yearlyProduction && <FormError className="error">{errors.yearlyProduction.message}</FormError>}
          </UserInput>
          <Question>Produseres material kun i perioder av året?</Question>
          <UserInput>
            <SelectField name="productionPeriodPartTime" ref={register}>
              <option value="">Vennligst velg</option>
              {SelectValues(BooleanSelect)}
            </SelectField>
          </UserInput>
          {watch("productionPeriodPartTime") === BooleanSelect.YES && (
            <>
              <Question>Hvilke måneder produseres det?</Question>
              <MonthCheckboxContainer register={register} control={control} />
            </>
          )}
        </>
      )}

      {/**
       * TODO:
       * Map the values from API to the correct fields according to month
       * Can either be checkbox or inputfields
       */}

      {watch("reportPeriod") === TimePeriod.MONTH && (
        <>
          <Question>År det innrapporteres for</Question>
          <UserInput>
            <InputField name="reportYear" ref={register} />
            {errors.reportYear && <FormError className="error">{errors.reportYear?.message}</FormError>}
          </UserInput>
          <MonthInputContainer register={register} control={control} errors={errors} watch={watch} />
        </>
      )}

      <HeaderTitle>Lagring av materialet</HeaderTitle>
      <Question>Hvordan materialet lagres</Question>
      <SpecialUserInput direction={InputDirection.COLUMN}>
        <SelectField name="storage" ref={register}>
          {" "}
          <option value="">Vennligst velg</option>
          {SelectValues(WoodStorage)}
        </SelectField>
        {errors.storage && <FormError className="error">{errors.storage.message}</FormError>}
      </SpecialUserInput>
      <Question>Eventuell kommentar til lagring av materialet</Question>
      <CommentField name="storageComment" ref={register} placeholder="Comments" />
    </>
  );
};

export default AboutWoodData;
