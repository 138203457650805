import { gql } from "urql";

export const createOrUpdateEnergyType = gql`
  mutation(
    $energyTypeId: ID
    $energyTypeName: EnergyTypeEnum!
    $additionalInformation: String!
    $energyUsage: Float!
    $energySchemaId: ID!
  ) {
    createOrUpdateEnergyType(
      input: {
        energyTypeId: $energyTypeId
        energyTypeName: $energyTypeName
        additionalInformation: $additionalInformation
        energyUsage: $energyUsage
        energySchemaId: $energySchemaId
      }
    ) {
      __typename
      id
      success
    }
  }
`;
