import { gql } from "urql";

export const updateEnergySchema = gql`
  mutation($id: ID!, $schemaIsDelivered: Boolean) {
    updateEnergySchema(
      input: { id: $id, schemaIsDelivered: $schemaIsDelivered }
    ) {
      __typename
      id
      success
    }
  }
`;
