import { gql } from "urql";

export const createOrUpdateProduction = gql`
  mutation(
    $productionMonthId: ID
    $woodSchemaId: ID
    $month: MonthEnum
    $monthlyProduction: Float
    $partOfYearlyProduction: Boolean
  ) {
    createOrUpdateProductionMonth(
      input: {
        woodSchemaId: $woodSchemaId
        productionMonthId: $productionMonthId
        month: $month
        monthlyProduction: $monthlyProduction
        partOfYearlyProduction: $partOfYearlyProduction
      }
    ) {
      __typename
      id
      success
    }
  }
`;
