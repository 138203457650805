import { gql } from "urql";

export const assignUserRole = gql`
  mutation assignUserRole($userId: ID!) {
    assignUserRole(input: { userId: $userId }) {
      id
      success
    }
  }
`;
