import React from "react";
import styled from "styled-components";

const IContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: auto;
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.bottom};
  padding: ${(props) => props.theme.padding.medium} ${(props) => props.theme.padding.light} ${(props) => props.theme.padding.medium}
    ${(props) => props.theme.padding.heavy};
  border-radius: ${(props) => props.theme.borderRadius.light};
  row-gap: ${(props) => props.theme.gap.light};
`;

const InternalQuestionBox: React.FC = (props) => {
  return <IContainer>{props.children}</IContainer>;
};

export default InternalQuestionBox;
