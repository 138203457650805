import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useMutation } from "urql";
import Button, { ButtonSize, ButtonType } from "../../components/Button";
import ErrorContainer from "../../components/ErrorContainer";
import FormFieldError from "../../components/FormFieldError";
import FormLabel from "../../components/FormLabel";
import Heading from "../../components/Heading";
import { createUser } from "../../graphql/mutations/User/createUser";
import { handleMutationError, handleMutationSuccess } from "../../lib/toaster";
import { createUserSchema } from "../../schemas";
import { CreateUserFormData } from "../../types";
import { IMutationResult } from "../../types/mutations/mutationResult";

type Props = {
  handleSave: () => void;
  handleCancel: () => void;
};

const ButtonArea = styled.div`
  text-align: right;
  margin-top: 10px;
`;

const ValidationContainer = styled.div`
  margin-left: 10px;
`;

const CreateUser: React.FC<Props> = (props) => {
  const [result, executeMutation] = useMutation<IMutationResult>(createUser);
  const { register, handleSubmit, errors } = useForm<CreateUserFormData>({
    resolver: yupResolver(createUserSchema),
  });
  const onSubmit = handleSubmit(({ email, fullName, phone }) => {
    executeMutation({ email, fullName, phone })
      .then(({ data }) => {
        if (data?.createUser.success) {
          handleMutationSuccess(data.createUser);
          props.handleSave();
        } else {
          handleMutationError();
        }
      })
      .catch(() => handleMutationError());
  });

  return (
    <form onSubmit={onSubmit} noValidate>
      <Heading>Opprett bruker</Heading>

      <FormLabel>E-post</FormLabel>
      <input ref={register} name="email" />

      <ValidationContainer>
        <FormFieldError error={errors.email}></FormFieldError>
      </ValidationContainer>

      <FormLabel>Full navn</FormLabel>
      <input ref={register} name="fullName" />

      <ValidationContainer>
        <FormFieldError error={errors.fullName}></FormFieldError>
      </ValidationContainer>

      <FormLabel>Telefon</FormLabel>
      <input ref={register} name="phone" />

      <ValidationContainer>
        <FormFieldError error={errors.phone}></FormFieldError>
      </ValidationContainer>

      {result.error && <ErrorContainer>Kan ikke opprette bruker</ErrorContainer>}

      <ButtonArea>
        <Button type="button" size={ButtonSize.Large} buttonType={ButtonType.Secondary} onClick={() => props.handleCancel()}>
          Avbryt
        </Button>

        <Button type="submit" disabled={result.fetching} size={ButtonSize.Large} buttonType={ButtonType.Primary}>
          {result.fetching ? "Oppretter..." : "Opprett bruker"}
        </Button>
      </ButtonArea>
    </form>
  );
};

export default CreateUser;
