import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useMutation } from "urql";
import Button, { ButtonSize, ButtonType } from "../../components/Button";
import ContentBox from "../../components/ContentBox";
import ErrorContainer from "../../components/ErrorContainer";
import FormFieldError from "../../components/FormFieldError";
import FormLabel from "../../components/FormLabel";
import Heading from "../../components/Heading";
import { changePasswordMutation } from "../../graphql/mutations/Account/changePassword";
import { ROUTES } from "../../lib/Router/routes";
import { handleMutationError } from "../../lib/toaster";
import { changePasswordSchema } from "../../schemas";
import { ChangePasswordFormData } from "../../types";
import { IMutationResult } from "../../types/mutations/mutationResult";

const Container = styled.div`
  width: 50%;
  min-width: 400px;
`;

const ActionsContainer = styled.div`
  margin-top: 10px;
`;

const Account: React.FC = () => {
  const history = useHistory();

  const [mutationResult, executeMutation] = useMutation<IMutationResult>(changePasswordMutation);
  const { register, handleSubmit, errors } = useForm<ChangePasswordFormData>({
    resolver: yupResolver(changePasswordSchema),
  });
  const onSubmit = handleSubmit(({ currentPassword, newPassword }) => {
    executeMutation({ currentPassword, newPassword })
      .then(({ data }) => {
        if (data?.signIn.success) {
          history.push(ROUTES.AUTHORIZED.DASHBOARD_ROUTE);
        } else {
          handleMutationError();
        }
      })
      .catch(() => handleMutationError());
  });

  return (
    <div>
      <Heading>Bytt passord</Heading>

      <ContentBox>
        <Container>
          <form onSubmit={onSubmit} noValidate>
            <FormLabel>Nåværende passord</FormLabel>
            <input type="password" name="currentPassword" ref={register} />

            <FormLabel>Nytt passord</FormLabel>
            <input type="password" name="newPassword" ref={register} />
            <FormFieldError error={errors.newPassword}></FormFieldError>

            <FormLabel>Bekreft nytt passord</FormLabel>
            <input type="password" name="confirmPassword" ref={register} />
            <FormFieldError error={errors.confirmPassword}></FormFieldError>

            {mutationResult.error && <ErrorContainer>Det oppstod en feil. Vennligst sjekk at nåværende passord er riktig.</ErrorContainer>}

            <ActionsContainer>
              <Button buttonType={ButtonType.Primary} size={ButtonSize.Medium} onClick={onSubmit}>
                Oppdater passord
              </Button>
            </ActionsContainer>
          </form>
        </Container>
      </ContentBox>
    </div>
  );
};

export default Account;
