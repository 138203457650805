import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.middle};
`;

const Steps: React.FC = (props) => {
  return <Container>{props.children}</Container>;
};
export default Steps;
