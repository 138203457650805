import React from "react";
import styled from "styled-components";
import Label, { LabelType } from "../../../components/Label";
import { getReadableSideStreamType } from "../../../helpers/sidestreamHelpers";
import { AggregatedSchema } from "../../../types";

const ItemsContainer = styled.ul`
  list-style: none;
  padding: 0;
`;

const ItemsHeaderContainer = styled(ItemsContainer)`
  margin-bottom: 20px !important;
  border-bottom: 2px solid #ccc;
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  font-weight: 600;
`;

const ItemContainer = styled.li`
  display: grid;
  grid-template-columns: 0.6fr 0.6fr 1fr 2fr;
  padding-bottom: 10px;
  margin-top: 10px;

  :first-child {
    margin-top: 0px;
  }

  :last-child {
    padding-bottom: 0;
  }

  :not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
`;

type Props = {
  aggregatedSchemas: Array<AggregatedSchema>;
};

const AggregatedSchemasList: React.FC<Props> = (props) => {
  return (
    <>
      <ItemsHeaderContainer>
        <ItemContainer>
          <div>Dato</div>
          <div>Type</div>
          <div>Av hvem</div>
          <div>Til bedrifter</div>
        </ItemContainer>
      </ItemsHeaderContainer>

      <ItemsContainer>
        {props.aggregatedSchemas.map((aggregatedSchema, index) => {
          return (
            <ItemContainer key={index}>
              <div>{aggregatedSchema.issuedAt}</div>

              <div>
                <Label type={LabelType.WARNING}>{getReadableSideStreamType(aggregatedSchema.type)}</Label>
              </div>

              <div>{aggregatedSchema.issuedBy}</div>

              <div>
                {aggregatedSchema.affectedOrganizations.map((organization) => {
                  return <Label key={organization}>{organization}</Label>;
                })}
              </div>
            </ItemContainer>
          );
        })}
      </ItemsContainer>
    </>
  );
};

export default AggregatedSchemasList;
