import { gql } from "urql";

export const getSideStreamSummary = gql`
  query($energySchemaId: ID!) {
    energyTypes(filter: { energySchemaId: $energySchemaId }) {
      __typename
      energyTypeName
      energyUsage
      energySchemaId
      id
    }
    utilizedWasteHeats(filter: { energySchemaId: $energySchemaId }) {
      __typename
      id
      utilizedWasteHeat
      utilizedWasteHeatInformation
      utilizedWasteHeatUsage
    }
    energyWastes(filter: { energySchemaId: $energySchemaId }) {
      __typename
      energyWasteName
      wasteSource
      temperature
      energyAmount
      averageEffect
      id
    }
  }
`;
