import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useMutation } from "urql";
import Button, { ButtonSize, ButtonType } from "../../../../components/Button";
import ErrorContainer from "../../../../components/ErrorContainer";
import FormFieldError from "../../../../components/FormFieldError";
import FormLabel from "../../../../components/FormLabel";
import Heading from "../../../../components/Heading";
import { WoodMaterial } from "../../../../dictionaries";
import { createSidestream } from "../../../../graphql/mutations/Sidestream/createSidestream";
import { handleMutationError, handleMutationSuccess } from "../../../../lib/toaster";
import { createSidestreamSchema } from "../../../../schemas";
import { CreateSidestreamFormData, SidestreamTypeEnum } from "../../../../types";
import { IMutationResult } from "../../../../types/mutations/mutationResult";
import { SelectValues } from "../../../../utils/form-utils";
import { SidestreamType } from "../../../../dictionaries";

type Props = {
  organizationId: string;
  allowedSidestreamTypes: Array<SidestreamTypeEnum>;
  handleSave: () => void;
  handleCancel: () => void;
};

const ActionsContainer = styled.div`
  text-align: right;
  margin-top: 10px;
`;

const CreateSidestreamForm: React.FC<Props> = (props) => {
  const [result, executeMutation] = useMutation<IMutationResult>(createSidestream);
  const { register, handleSubmit, errors, watch } = useForm<CreateSidestreamFormData>({
    resolver: yupResolver(createSidestreamSchema),
    defaultValues: { name: "" },
  });
  const onSubmit = handleSubmit(({ name, type }) => {
    executeMutation({ name, type: type, organizationId: props.organizationId })
      .then(({ data }) => {
        if (data?.createSidestream.success) {
          handleMutationSuccess(data.createSidestream);

          props.handleSave();
        } else {
          handleMutationError();
        }
      })
      .catch(() => handleMutationError());
  });

  const currentSelectedType = watch("type");

  return (
    <form onSubmit={onSubmit} noValidate>
      <Heading>Legg til sidestrøm</Heading>

      <FormLabel>Type sidestrøm</FormLabel>
      <select name="type" ref={register}>
        <option value="">Vennligst velg</option>
        {props.allowedSidestreamTypes.map((item) => {
          return <option value={item}>{SidestreamType[item]}</option>;
        })}
      </select>

      <FormFieldError error={errors.type}></FormFieldError>

      <FormLabel>Navn på sidestrøm (fortrinnsvis engelsk tekst)</FormLabel>
      {currentSelectedType === SidestreamTypeEnum.WOOD ? (
        <select name="name" ref={register}>
          <option value="">Vennligst velg</option>
          {SelectValues(WoodMaterial)}
        </select>
      ) : (
        <input ref={register} name="name" />
      )}

      <FormFieldError error={errors.name}></FormFieldError>

      {result.error && <ErrorContainer>Kan ikke opprette sidestrøm</ErrorContainer>}

      <ActionsContainer>
        <Button type="button" size={ButtonSize.Large} buttonType={ButtonType.Secondary} onClick={() => props.handleCancel()}>
          Avbryt
        </Button>

        <Button type="submit" disabled={result.fetching} size={ButtonSize.Large} buttonType={ButtonType.Primary}>
          {result.fetching ? "Oppretter..." : "Opprett sidestrøm"}
        </Button>
      </ActionsContainer>
    </form>
  );
};

export default CreateSidestreamForm;
