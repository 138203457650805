import React from "react";
import styled from "styled-components";
import { BooleanSelect, Treatment } from "../../../../dictionaries";
import { SelectValues } from "../../../../utils/form-utils";
enum InputWidth {
  LONG,
  SHORT,
}
enum InputDirection {
  COLUMN,
  ROW,
}

const FirstColumn = styled.div`
  grid-column: 1/2;
`;

const Question = styled(FirstColumn)``;

const CommentField = styled.textarea`
  grid-column: 1/3;
  height: 6em;
  justify-self: end;
`;

const SelectField = styled.select<InputProps>`
  width: ${(props) => (props.size === InputWidth.LONG ? "80%" : props.size === InputWidth.SHORT ? "50%" : "100%")};
`;

const FormError = styled.div`
  grid-column: 2;
  margin-bottom: 1em;
`;

interface InputProps {
  size?: InputWidth;
  direction?: InputDirection;
}

type AboutMaterialDataProps = {
  register: any;
  errors: any;
};
const AboutWoodMaterialData: React.FC<AboutMaterialDataProps> = ({ register, errors }) => {
  return (
    <>
      <Question>Er materialet klassifisert som farlig avfall?</Question>
      <SelectField ref={register} name="dangerousWaste">
        <option value="">Vennligst velg</option>
        {SelectValues(BooleanSelect)}
      </SelectField>
      {errors.dangerousWaste && <FormError className="error">{errors.dangerousWaste.message}</FormError>}
      <Question>Eksisterer det begrensninger eller andre myndighetskrav til behandling av materialet?</Question>
      <SelectField ref={register} name="limitationsToMaterialDeposit">
        <option value="">Vennligst velg</option>
        {SelectValues(BooleanSelect)}
      </SelectField>
      {errors.limitationsToMaterialDeposit && <FormError className="error">{errors.limitationsToMaterialDeposit.message}</FormError>}
      <Question>Kort beskrivelse av evt. krav til behandling</Question>
      <CommentField
        ref={register}
        name="depositDemandsComment"
        data-testid="disposaldescriptiontest"
        role="textbox"
        placeholder="Comments"
      />
      <Question>Selvantennelsesfare</Question>
      <SelectField ref={register} name="autoIgnitionHazard">
        <option value="">Vennligst velg</option>
        {SelectValues(BooleanSelect)}
      </SelectField>
      {errors.autoIgnitionHazard && <FormError className="error">{errors.autoIgnitionHazard.message}</FormError>}
      <Question>Behandling etter mottak</Question>
      <SelectField ref={register} name="treatmentAfterDelivery">
        <option value="">Vennligst velg</option>
        {SelectValues(Treatment)}
      </SelectField>
      {errors.treatmentAfterDelivery && <FormError className="error">{errors.treatmentAfterDelivery.message}</FormError>}
    </>
  );
};

export default AboutWoodMaterialData;
