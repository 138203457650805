import React from "react";
import { X } from "react-feather";
import { DeepMap, FieldError } from "react-hook-form";
import styled from "styled-components";
import { useMutation } from "urql";
import { UtilizedWasteHeatNames } from "../../../../dictionaries";
import { deleteUtilizedWasteHeat } from "../../../../graphql/mutations/Energyflow/deleteUtilizedWasteHeat";
import { handleMutationError } from "../../../../lib/toaster";
import { TUtilizedWasteHeatNames, UtilizedEnergyType } from "../../../../types";
import { IMutationResult } from "../../../../types/mutations/mutationResult";
const RowContainer = styled.form`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 2em;
  grid-template-rows: 1fr auto;
  padding: 0.75rem 1rem 0.3125rem 1rem;
  grid-gap: ${(props) => props.theme.gap.extraLight};
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.top};
  border-radius: ${(props) => props.theme.borderRadius.light};
`;
const UtilizedError = styled.div`
  grid-column: 1/2;
  grid-row: 2/3;
  display: flex;
`;
const SelectError = styled.div`
  grid-column: 2/3;
  grid-row: 2/3;
`;
const MeasureError = styled.div`
  grid-column: 3/4;
  grid-row: 2/3;
`;

const WasteUsage = styled.textarea`
  grid-column: 1/2;
`;

const WasteType = styled.select`
  grid-column: 2/3;
  height: 100%;
`;

const Measure = styled.textarea`
  grid-column: 3/4;
`;

const RemoveRow = styled.div`
  grid-column: 4/5;
  cursor: pointer;
  align-self: center;
`;

type UtilizedEnergyDataProps = {
  index: number;
  register: any;
  errors: DeepMap<UtilizedEnergyType, FieldError>;
  data: UtilizedEnergyType;
  remove: (index?: number | number[] | undefined) => void;
  row: any;
};

const UtilizedEnergyData: React.FC<UtilizedEnergyDataProps> = (props) => {
  const [, deleteUtilizedWasteHeatMutate] = useMutation<IMutationResult>(deleteUtilizedWasteHeat);

  const handleDeleteRow = async (removeIndex: number) => {
    if (props.data.utilizedWasteHeats[props.index]) {
      await deleteUtilizedWasteHeatMutate({
        id: props.data.utilizedWasteHeats[props.index].id,
      })
        .then(() => {
          props.remove(removeIndex);
        })
        .catch(() => handleMutationError());
    } else {
      props.remove(removeIndex);
    }
  };

  return (
    <RowContainer key={`utilizedWasteHeats.[${props.index}]}`}>
      <WasteUsage
        ref={props.register}
        name={`utilizedWasteHeats[${props.index}].utilizedWasteHeatInformation`}
        defaultValue={props.row?.utilizedWasteHeatInformation}
      />
      <WasteType
        ref={props.register}
        name={`utilizedWasteHeats[${props.index}].utilizedWasteHeat`}
        defaultValue={UtilizedWasteHeatNames[props.row.utilizedWasteHeat as TUtilizedWasteHeatNames] || props.row.utilizedWasteHeat}
      >
        <option value="">Velg energiform</option>

        {(Object.values(UtilizedWasteHeatNames) as Array<TUtilizedWasteHeatNames>).map((name) => {
          return <option key={name}>{name}</option>;
        })}
      </WasteType>
      <Measure
        ref={props.register}
        name={`utilizedWasteHeats[${props.index}].utilizedWasteHeatUsage`}
        defaultValue={props.row?.utilizedWasteHeatUsage}
      />
      <RemoveRow>
        <X onClick={() => handleDeleteRow(props.index!)} />
      </RemoveRow>
      {props.errors.utilizedWasteHeats && (
        <>
          <UtilizedError className="error">
            {props.errors.utilizedWasteHeats[props.index]?.utilizedWasteHeatInformation?.message}
          </UtilizedError>
          <SelectError className="error">{props.errors.utilizedWasteHeats[props.index]?.utilizedWasteHeat?.message}</SelectError>
          <MeasureError className="error">{props.errors.utilizedWasteHeats[props.index]?.utilizedWasteHeatUsage?.message}</MeasureError>
        </>
      )}
    </RowContainer>
  );
};

export default UtilizedEnergyData;
