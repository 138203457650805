import React from "react";
import styled from "styled-components";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  buttonType: ButtonType;
  size?: ButtonSize;
  inactive?: boolean;
};

export enum ButtonType {
  Primary = "PRIMARY",
  Secondary = "SECONDARY",
}

export enum ButtonSize {
  Medium = "MEDIUM",
  Large = "LARGE",
}

const StyledButton = styled.button<ButtonProps>`
  background-color: ${(props) =>
    props.buttonType === ButtonType.Primary
      ? props.theme.colors.buttonColor.primary
      : props.buttonType === ButtonType.Secondary
      ? props.theme.colors.buttonColor.secondary
      : props.theme.colors.buttonColor.primary};
  color: ${(props) =>
    props.buttonType === ButtonType.Primary
      ? props.theme.colors.textColor.buttonTextColor.inactive
      : props.inactive
      ? props.theme.colors.textColor.buttonTextColor.inactive
      : props.theme.colors.textColor.buttonTextColor.active};
  border: none;
  outline: none;
  border-radius: ${(props) => props.theme.borderRadius.medium};
  padding: 0.4em 0.8em 0.4em 0.8em;
  cursor: ${(props) => (props.inactive ? "auto" : "pointer")};
  margin-left: 0.3125rem;

  &:disabled {
    color: ${(props) => props.theme.colors.textColor.buttonTextColor.inactive};
    background-color: #999;
  }
`;

const Button: React.FC<ButtonProps> = (props) => {
  return (
    <StyledButton data-testid="buttontest" {...props}>
      {props.children}
    </StyledButton>
  );
};

export default Button;
