import { gql } from "urql";

export const updateAboutMaterialFlow = gql`
  mutation(
    $id: ID
    $givenName: String
    $materialType: MaterialTypeEnum
    $eALCodeId: ID
    $wasteCodeId: ID
    $sideStreamOrigin: String
    $yearlyProduction: Float
    $productionVolumeUnit: VolumeEnum
    $productionPeriod: PeriodEnum
    $productionVolumeBatch: Float
    $productionBatch: BatchVolumeEnum
    $materialState: MaterialStateEnum
    $materialStateComment: String
    $organic: OrganicEnum
    $organicComment: String
  ) {
    updateMaterialStreamAbout(
      input: {
        id: $id
        givenName: $givenName
        materialType: $materialType
        eALCodeId: $eALCodeId
        wasteCodeId: $wasteCodeId
        sideStreamOrigin: $sideStreamOrigin
        yearlyProduction: $yearlyProduction
        productionVolumeUnit: $productionVolumeUnit
        productionPeriod: $productionPeriod
        productionVolumeBatch: $productionVolumeBatch
        productionBatch: $productionBatch
        materialState: $materialState
        materialStateComment: $materialStateComment
        organic: $organic
        organicComment: $organicComment
      }
    ) {
      __typename
      id
      success
    }
  }
`;
