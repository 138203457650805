import { gql } from "urql";

export const updateMaterialFlowOtherInformation = gql`
  mutation($id: ID, $otherInformation: String) {
    updateMaterialStreamOtherInformation(input: { id: $id, otherInformation: $otherInformation }) {
      __typename
      id
      success
    }
  }
`;
