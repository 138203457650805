import React from "react";
import styled from "styled-components";
import { InformationBoilerPlateOther } from "../../../../components/InformationBoilerPlate";

const Question = styled.div`
  grid-column: 1;
  margin-bottom: 2rem;
`;

const OuterBox = styled.div`
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.middle};
  padding: ${(props) => props.theme.padding.medium};
`;
const InnerBox = styled.div`
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.bottom};
  display: grid;
  padding: 1.5em;
  border-radius: 1em;
`;

const Title = styled.div`
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.bottom};
  font-size: ${(props) => props.theme.fontSizes.large};
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  margin-bottom: 1.5rem;
`;
const InputField = styled.textarea`
  grid-column: 1;
  height: 6rem;
`;

const FormError = styled.div`
  grid-column: 2;
  margin-bottom: 1em;
`;

type MaterialOtherInformationDataType = {
  register: any;
  errors: any;
  data: any;
};
const MaterialOtherInformationData: React.FC<MaterialOtherInformationDataType> = ({ register, data, errors }) => {
  return (
    <>
      <Title>Øvrig informasjon</Title>
      <OuterBox>
        <InformationBoilerPlateOther />
        <InnerBox>
          <Question>Annen relevant informasjon i forbindelse med sidestrømmen?</Question>
          <InputField
            name={`materialStreamOtherInformation.otherInformation`}
            ref={register}
            defaultValue={data.materialStreamOtherInformation.otherInformation}
            placeholder="Comments"
          />
          <FormError className="error">{errors.message}</FormError>
        </InnerBox>
      </OuterBox>
    </>
  );
};

export default MaterialOtherInformationData;
