import React from "react";
import styled from "styled-components";
// import Required from "../../components/Required";

type StyleProps = Pick<QuestionBoxProps, "isSubHeader">;
const MainContainer = styled.div<StyleProps>`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.isSubHeader ? "0.1em" : "2em")};
  padding-bottom: 1em;
`;

const Title = styled.div<StyleProps>`
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  font-size: ${(props) => (props.isSubHeader ? props.theme.fontSizes.medium : props.theme.fontSizes.large)};
  margin-bottom: ${(props) => (props.isSubHeader ? "0" : "1.5rem")};
`;

const Box = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: auto 2fr auto;
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.middle};
  padding: 3em 5em 3em 5em;
  border-radius: ${(props) => props.theme.borderRadius.light};
`;
const Container = styled.div`
  grid-row: 2/3;
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.gap.heavy};
`;

type QuestionBoxProps = {
  title: string;
  isSubHeader?: boolean;
  required?: boolean;
};
const QuestionBox: React.FC<QuestionBoxProps> = (props) => {
  return (
    <MainContainer isSubHeader={props.isSubHeader} data-testid="questionboxContainer">
      <Title isSubHeader={props.isSubHeader} data-testid="questionboxTitle">
        {props.title}
      </Title>

      <Box>
        {/* {props.required && <Required />} */}
        <Container>{props.children}</Container>
      </Box>
    </MainContainer>
  );
};

export default QuestionBox;
