import { gql } from "urql";

export const getAboutMaterial = gql`
  query($id: ID) {
    materialStreamMaterial(filter: { id: $id }) {
      __typename
      dangerousWaste
      limitationsToMaterialDeposit
      depositDemandsComment
      waterReactivity
      autoIgnitionHazard
      particleType
      particeSizeUnit
      particleSizeD50
      particleSizeMin
      particleSizeMax
      particleSizeComment
      moistureContent
      materialStreamRSentences {
        id
        rSentenceId
        rSentence {
          id
          sentenceId
          description
        }
      }
      materialStreamHSentences {
        id
        hSentenceId
        hSentence {
          id
          sentenceId
          description
        }
      }
      materialStreamSSentences {
        id
        sSentenceId
        sSentence {
          id
          sentenceId
          description
        }
      }
      id
    }
  }
`;
