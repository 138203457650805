import { gql } from "urql";

export const getMaterialStreamAttachment = gql`
  query($id: ID) {
    materialStreamAttachment(filter: { id: $id }) {
      __typename
      id
      attachments {
        id
        sourceFileName
        fileName
        attachementType
      }
    }
  }
`;
