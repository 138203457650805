import React from "react";
import { ArrayField, DeepMap, FieldError } from "react-hook-form";
import styled from "styled-components";
import { UtilizationLink } from "../../types";
import Button, { ButtonSize, ButtonType } from "../Button";
import Circle, { CircleSize } from "../Circle";
import QuestionGroupContainer from "../QuestionGroupContainer";

const ItemsContainer = styled.ul`
  list-style: none;
  padding: 0;
`;

const Delete = styled.div`
  cursor: pointer;
  margin-bottom: 5px;
`;

const ActionsContainer = styled.div`
  margin-top: 20px;
  padding-top: ${(props) => props.theme.padding.light};
  border-top: 1px solid ${(props) => props.theme.colors.inputColor.borderColor};
`;

const InputField = styled.input`
  width: 100%;
`;

const InputFieldsContainer = styled.li`
  border-top: 1px solid #ccc;
  margin-top: 20px;
  padding-top: 10px;
`;

const InputFieldContainer = styled.div`
  width: 100%;
  margin-right: 40px;
`;

const InputFields = styled.div`
  display: flex;
  align-items: flex-end;
`;

interface LinksInputProps {
  title: string;
  description: string;
  register: any;
  fields: Partial<ArrayField<UtilizationLink, any>>[];
  append: (value: Partial<UtilizationLink> | Partial<UtilizationLink>[], shouldFocus?: boolean | undefined) => void;
  remove: (index?: number | number[] | undefined) => void;
  errors?: (DeepMap<UtilizationLink, FieldError> | undefined)[];
  name: string;
}

const LinksInput: React.FC<LinksInputProps> = (props) => {
  return (
    <QuestionGroupContainer title={props.title}>
      <p>{props.description}</p>

      <ItemsContainer>
        {props.fields.map((item, index) => (
          <InputFieldsContainer key={item.fieldId}>
            <InputFields>
              <InputFieldContainer>
                <label htmlFor={`${props.name}[${index}].url`}>Lenke:</label>

                <InputField
                  name={`${props.name}[${index}].url`}
                  ref={props.register()}
                  defaultValue={item.url} // make sure to set up defaultValue
                />
              </InputFieldContainer>

              <Delete onClick={() => props.remove(index)}>
                <Circle size={CircleSize.SMALL}>X</Circle>
              </Delete>
            </InputFields>

            {props.errors && <div className="error">{props.errors[index]?.url?.message}</div>}
          </InputFieldsContainer>
        ))}
      </ItemsContainer>

      <ActionsContainer>
        <Button buttonType={ButtonType.Primary} onClick={() => props.append({ url: "" })} size={ButtonSize.Medium}>
          Legg til
        </Button>
      </ActionsContainer>
    </QuestionGroupContainer>
  );
};

export default LinksInput;
