import React from "react";
import styled from "styled-components";
import MonthInput from "../MonthInput/";
import { calculateSum } from "../../../utils/form-utils";

const MonthsInputContainer = styled.div`
  grid-column: 1/4;
  display: grid;
  grid-template-columns: 3fr repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: ${(props) => props.theme.gap.extraLight};
`;

const Sum = styled.div`
  grid-column: 2/3;
  padding-left: ${(props) => props.theme.padding.medium};
  align-self: center;
  text-align: left;
`;

const SumNumberContainer = styled.div`
  grid-column: 3/4;
  margin-left: 1.1em;
  background-color: #dcdcdc;
  border-radius: 0.5em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const SumUnit = styled.div`
  grid-column: 4/5;
  align-self: center;
`;

const SumNumber = styled.p`
  padding-right: 1em;
  font-weight: 600;
`;

type MonthContainerProps = {
  control: any;
  register: any;
  errors: any;
  watch: any;
};

const MonthInputContainer: React.FC<MonthContainerProps> = ({ control, register, errors, watch }) => {
  /**TODO:
   * Fix totalsum so it gets the values from
   * months' fields
   */
  function TotalSum() {
    let inputValues = watch([
      "JANUARY.production",
      "FEBRUARY.production",
      "MARCH.production",
      "APRIL.production",
      "MAY.production",
      "JUNE.production",
      "JULY.production",
      "AUGUST.production",
      "SEPTEMBER.production",
      "OCTOBER.production",
      "NOVEMBER.production",
      "DECEMBER.production",
    ]);
    // let inputValues = watch("productionMonths");

    return <SumNumber>{calculateSum(inputValues)}</SumNumber>;
  }
  return (
    <>
      <MonthsInputContainer>
        <MonthInput
          name="Januar"
          inputName="JANUARY.production"
          inputId="JANUARY.id"
          register={register}
          control={control}
          errors={errors.JANUARY?.production}
        />
        <MonthInput
          name="Februar"
          inputName="FEBRUARY.production"
          inputId="FEBRUARY.id"
          register={register}
          control={control}
          errors={errors.FEBRUARY?.production}
        />
        <MonthInput
          name="Mars"
          inputName="MARCH.production"
          inputId="MARCH.id"
          register={register}
          control={control}
          errors={errors.MARCH?.production}
        />
        <MonthInput
          name="April"
          inputName="APRIL.production"
          inputId="APRIL.id"
          register={register}
          control={control}
          errors={errors.APRIL?.production}
        />
        <MonthInput
          name="Mai"
          inputName="MAY.production"
          inputId="MAY.id"
          register={register}
          control={control}
          errors={errors.MAY?.production}
        />
        <MonthInput
          name="Juni"
          inputName="JUNE.production"
          inputId="JUNE.id"
          register={register}
          control={control}
          errors={errors.JUNE?.production}
        />
        <MonthInput
          name="Juli"
          inputName="JULY.production"
          inputId="JULY.id"
          register={register}
          control={control}
          errors={errors.JULY?.production}
        />
        <MonthInput
          name="August"
          inputName="AUGUST.production"
          inputId="AUGUST.id"
          register={register}
          control={control}
          errors={errors.AUGUST?.production}
        />
        <MonthInput
          name="September"
          inputName="SEPTEMBER.production"
          inputId="SEPTEMBER.id"
          register={register}
          control={control}
          errors={errors.SEPTEMBER?.production}
        />
        <MonthInput
          name="Oktober"
          inputName="OCTOBER.production"
          inputId="OCTOBER.id"
          register={register}
          control={control}
          errors={errors.OCTOBER?.production}
        />
        <MonthInput
          name="November"
          inputName="NOVEMBER.production"
          inputId="NOVEMBER.id"
          register={register}
          control={control}
          errors={errors.NOVEMBER?.production}
        />
        <MonthInput
          name="Desember"
          inputName="DECEMBER.production"
          inputId="DECEMBER.id"
          register={register}
          control={control}
          errors={errors.DECEMBER?.production}
        />

        <Sum>Sum</Sum>
        <SumNumberContainer>
          <TotalSum />
        </SumNumberContainer>
        <SumUnit>råtonn/år</SumUnit>
      </MonthsInputContainer>
    </>
  );
};

export default MonthInputContainer;
