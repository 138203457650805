import React from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";

const Month = styled.div`
  grid-column: 2/3;
  padding-left: ${(props) => props.theme.padding.medium};
  align-self: center;
  text-align: left;
`;
const MonthInput = styled.input`
  grid-column: 3/4;
  margin-left: 1.1em;
`;

type MonthCheckboxProps = {
  name: string;
  checkboxName: any;
  checkboxId: any;
  control: any;
  register: any;
};

const MonthCheckbox: React.FC<MonthCheckboxProps> = ({
  name,
  checkboxName,
  checkboxId,
  control,
  register,
}) => {
  return (
    <>
      <Month>{name}</Month>

      <MonthInput ref={register} name={checkboxName} type="checkbox" />
      <Controller
        control={control}
        name={checkboxId}
        defaultValue={null}
        as={<span />}
      />
    </>
  );
};

export default MonthCheckbox;
