import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useMutation, useQuery } from "urql";
import Bottom from "../../../components/Bottom";
import InternalQuestionBox from "../../../components/InternalQuestionBox";
import Navigation from "../../../components/Navigation";
import QuestionBox from "../../../components/QuestionBox";
import { NavigationContext } from "../../../providers/NavigationProvider";
import { Param, WoodUtilizationType } from "../../../types";
import WoodUtilizationData from "./WoodUtilizationData";
import { updateWoodUtilization } from "../../../graphql/mutations/Wood/updateWoodUtilization";
import { getWoodUtilization } from "../../../graphql/queries/Wood/getWoodUtilization";
import Spinner from "../../../components/Loading";
import { yupResolver } from "@hookform/resolvers/yup";
import { woodUtilizationSchema } from "../../../schemas";
import { IMutationResult } from "../../../types/mutations/mutationResult";
import { handleMutationError } from "../../../lib/toaster";
const Container = styled.div`
  grid-column: 2/3;
`;
const LoadingContainer = styled.div`
  display: flex;
  place-content: center;
`;

const WoodUtilization: React.FC = () => {
  const [isReset, setIsReset] = React.useState<boolean>(true);

  const context = React.useContext(NavigationContext);

  const { register, handleSubmit, getValues, watch, reset, control, errors } = useForm<WoodUtilizationType>({
    resolver: yupResolver(woodUtilizationSchema),
  });
  const { id } = useParams<Param>();

  const [, executeMutation] = useMutation<IMutationResult>(updateWoodUtilization);

  const [woodUtilizationResult] = useQuery({
    query: getWoodUtilization,
    variables: { id: id },
  });

  const { data, fetching } = woodUtilizationResult;
  const onSubmit: SubmitHandler<WoodUtilizationType> = async (data) => {
    data &&
      (await executeMutation({
        id: id,
        internalEnergyRecycling: data.internalEnergyRecycling,
        externalEnergyRecycling: data.externalEnergyRecycling,
        internalMaterialRecycling: data.internalMaterialRecycling,
        externalMaterialRecycling: data.externalMaterialRecycling,
        reuse: data.reuse,
        reuseFurniture: data.reuseFurniture,
        reuseBuildingMaterials: data.reuseBuildingMaterials,
        previousInternalAndExternalUsage: data.previousInternalAndExternalUsage ? "YES" : "NO",
        previousInternalUsage: data.previousInternalUsage ? "YES" : "NO",
        previousExternalUsage: data.previousExternalUsage ? "YES" : "NO",
        previousInternalAndExternalUsageComment: data.previousInternalAndExternalUsageComment,
        internalAndExternalActivitesForMaterialUsage: data.internalAndExternalActivitesForMaterialUsage ? "YES" : "NO",
        internalActivitesForMaterialUsage: data.internalActivitesForMaterialUsage ? "YES" : "NO",
        externalActivitesForMaterialUsage: data.externalActivitesForMaterialUsage ? "YES" : "NO",
        internalAndExternalActivitesForMaterialUsageComment: data.internalAndExternalActivitesForMaterialUsageComment,
        internalAndExternalGreaterPotential: data.internalAndExternalGreaterPotential ? "YES" : "NO",
        internalGreaterPotential: data.internalGreaterPotential ? "YES" : "NO",
        externalGreaterPotential: data.externalGreaterPotential ? "YES" : "NO",
        internalAndExternalGreaterPotentialComment: data.internalAndExternalGreaterPotentialComment,
        links: data.links,
        resourcePersons: data.resourcePersons,
        otherComments: data.otherComments,
      })
        .then(() => {
          context.handleNav(true);
        })
        .catch(() => handleMutationError()));
  };

  const onPreviousSubmit: SubmitHandler<WoodUtilizationType> = async () => {
    const data = getValues();
    await executeMutation({
      id: id,
      internalEnergyRecycling: data.internalEnergyRecycling,
      externalEnergyRecycling: data.externalEnergyRecycling,
      internalMaterialRecycling: data.internalMaterialRecycling,
      externalMaterialRecycling: data.externalMaterialRecycling,
      reuse: data.reuse,
      reuseFurniture: data.reuseFurniture,
      reuseBuildingMaterials: data.reuseBuildingMaterials,
      previousInternalAndExternalUsage: data.previousInternalAndExternalUsage ? "YES" : "NO",
      previousInternalUsage: data.previousInternalUsage ? "YES" : "NO",
      previousExternalUsage: data.previousExternalUsage ? "YES" : "NO",
      previousInternalAndExternalUsageComment: data.previousInternalAndExternalUsageComment,
      internalAndExternalActivitesForMaterialUsage: data.internalAndExternalActivitesForMaterialUsage ? "YES" : "NO",
      internalActivitesForMaterialUsage: data.internalActivitesForMaterialUsage ? "YES" : "NO",
      externalActivitesForMaterialUsage: data.externalActivitesForMaterialUsage ? "YES" : "NO",
      internalAndExternalActivitesForMaterialUsageComment: data.internalAndExternalActivitesForMaterialUsageComment,
      internalAndExternalGreaterPotential: data.internalAndExternalGreaterPotential ? "YES" : "NO",
      internalGreaterPotential: data.internalGreaterPotential ? "YES" : "NO",
      externalGreaterPotential: data.externalGreaterPotential ? "YES" : "NO",
      internalAndExternalGreaterPotentialComment: data.internalAndExternalGreaterPotentialComment,
      links: data.links,
      resourcePersons: data.resourcePersons,
      otherComments: data.otherComments,
    })
      .then(() => {
        context.handleNav(false);
      })
      .catch(() => handleMutationError());
  };

  React.useEffect(() => {
    if (data && !fetching) {
      if (isReset) {
        reset({
          ...data.woodSchema,
          previousInternalAndExternalUsage: data.woodSchema.previousInternalAndExternalUsage === "YES" ? true : false,
          previousInternalUsage: data.woodSchema.previousInternalUsage === "YES" ? true : false,
          previousExternalUsage: data.woodSchema.previousExternalUsage === "YES" ? true : false,
          internalAndExternalActivitesForMaterialUsage:
            data.woodSchema.internalAndExternalActivitesForMaterialUsage === "YES" ? true : false,
          internalActivitesForMaterialUsage: data.woodSchema.internalActivitesForMaterialUsage === "YES" ? true : false,
          externalActivitesForMaterialUsage: data.woodSchema.externalActivitesForMaterialUsage === "YES" ? true : false,
          internalAndExternalGreaterPotential: data.woodSchema.internalAndExternalGreaterPotential === "YES" ? true : false,
          internalGreaterPotential: data.woodSchema.internalGreaterPotential === "YES" ? true : false,
          externalGreaterPotential: data.woodSchema.externalGreaterPotential === "YES" ? true : false,
          links: data.woodSchema.links,
          resourcePersons: data.woodSchema.resourcePersons,
        });
        setIsReset(false);
      }
    }
  }, [data, fetching, isReset, reset]);

  return (
    <Container>
      <QuestionBox title="Utnyttelse">
        {fetching ? (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        ) : (
          <InternalQuestionBox>
            <WoodUtilizationData register={register} watch={watch} control={control} errors={errors} />
          </InternalQuestionBox>
        )}
      </QuestionBox>
      <Bottom>
        <Navigation navigation={context.navigation} handleNext={handleSubmit(onSubmit)} handlePrev={handleSubmit(onPreviousSubmit)} />
      </Bottom>
    </Container>
  );
};

export default WoodUtilization;
