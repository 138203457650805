import { gql } from "urql";

export const getMaterialStreamSummary = gql`
  query($id: ID) {
    materialStreamSummary(filter: { id: $id }) {
      __typename
      autoIgnitionHazard
      chemicalAnalysisConnection
      dangerousWaste
      eALCode {
        id
        name
      }
      externalActivitesForMaterialUsage
      externalDeposition
      externalSales
      givenName
      greaterPotential
      id
      internalActivitesForMaterialUsage
      internalDeposition
      internalRecycling
      isBurned
      limitationsToMaterialDeposit
      materialState
      materialType
      moistureContent
      organic
      particeSizeUnit
      particleSizeD50
      particleSizeMax
      particleSizeMin
      particleType
      previousInternalAndExternalUsage
      productionBatch
      productionPeriod
      productionVolumeBatch
      productionVolumeUnit
      sideStreamOrigin
      wasteCode {
        id
        name
      }
      waterReactivity
      yearlyProduction
      chemicalAnalyses {
        chemicalAnalysisName
        chemicalUnit
        id
        materialStreamSchemaId
        maxValue
        minValue
        typicalValue
        shareChemicalAnalysisApproval
      }
      materialStreamHSentences {
        hSentence {
          id
          sentenceId
          description
        }
      }
      materialStreamRSentences {
        rSentence {
          id
          sentenceId
          description
        }
      }
      materialStreamSSentences {
        sSentence {
          id
          sentenceId
          description
        }
      }
    }
  }
`;
