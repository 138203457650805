import { gql } from "urql";

export const changePasswordMutation = gql`
  mutation($currentPassword: String!, $newPassword: String!) {
    changePassword(
      input: { currentPassword: $currentPassword, newPassword: $newPassword }
    ) {
      __typename
      success
    }
  }
`;
