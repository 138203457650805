import { gql } from "urql";

export const resetPassword = gql`
  mutation($email: String!, $resetToken: String!, $newPassword: String!) {
    resetPassword(input: { email: $email, resetToken: $resetToken, newPassword: $newPassword }) {
      id
      success
    }
  }
`;
