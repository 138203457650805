import { gql } from "urql";

export const getOrganizationId = gql`
  query($id: ID) {
    woodSchema(filter: { id: $id }) {
      __typename
      id
      organizationId
      sidestreamName
    }
  }
`;
