import { gql } from "urql";

export const deleteAttachment = gql`
  mutation($id: ID) {
    deleteAttachment(input: { id: $id }) {
      __typename
      id
      success
    }
  }
`;
