import React from "react";
import styled from "styled-components";
import Upload from "../../../../components/Upload";
import { BooleanSelectExtended } from "../../../../dictionaries";
import { SelectValues, calculateSum } from "../../../../utils/form-utils";
import { useFieldArray } from "react-hook-form";
import { UtilizationLink, AttachmentTypeEnum } from "../../../../types";
import LinksInput from "../../../../components/LinksInput";
//import ResourcePersonsInput from "../../../../components/ResourcePersonsInput";
//import { getMaterialStreamAttachment } from "../../../../graphql/queries/Attachment/getMaterialStreamAttachment";

const Question = styled.div`
  grid-column: 1/2;
`;
const Sum = styled.div`
  grid-column: 1/2;
  justify-self: end;
  align-self: center;
  padding-right: 1em;
`;
const SumNumberContainer = styled.div`
  background-color: #dcdcdc;
  color: ${(props) => props.color};
  grid-column: 2/3;
  width: 100%;
  height: 100%;
  border-radius: ${(props) => props.theme.borderRadius.light};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const SumNumber = styled.p`
  padding-right: 1em;
  font-weight: 600;
`;
const UserInput = styled.div`
  grid-column: 2/3;
`;
const Unit = styled.div`
  grid-column: 3/4;
  align-self: center;
  margin-left: 1rem;
`;
const UploadField = styled.div`
  grid-column: 1/3;
`;
const InputField = styled.textarea``;
const SelectField = styled.select`
  width: 100%;
`;
const CommentField = styled.textarea`
  height: 100%;
  width: 100%;
`;
const HeaderTitle = styled.div`
  grid-column: 1/2;
  font-weight: 600;
`;
const CommentBoxArea = styled.div`
  grid-column: 1/3;
`;
const FormError = styled.div`
  grid-column: 2;
  margin-bottom: 1em;
`;

const LinksField = styled.div`
  grid-column: 1/3;
`;

// const ResourcePersonsField = styled.div`
//   grid-column: 1/3;
// `;

const QuestionBorder = styled.div`
  border-bottom: 2px solid #ccc;
  grid-column: 1/3;
  margin-bottom: 1rem;
`;
const CheckboxQuestion = styled.label`
  grid-column: 2/2;
`;
const CheckboxInput = styled.input`
  height: auto;
  margin-right: 1em;
`;

const UserInputCheckbox = styled.div`
  grid-column: 1/3;
`;

type MaterialFlowUtilizationDataProps = {
  register: any;
  watch: any;
  control: any;
  errors: any;
};
const MaterialFlowUtilizationData: React.FC<MaterialFlowUtilizationDataProps> = ({ register, watch, control, errors }) => {
  const {
    fields: linkFields,
    append: appendLink,
    remove: removeLink,
  } = useFieldArray<UtilizationLink, any>({
    control,
    name: "materialStreamUsage.links",
    keyName: "fieldId",
  });

  // const { fields: resourcePersonFields, append: appendResourcePerson, remove: removeResourcePerson } = useFieldArray<
  //   UtilizationResourcePerson,
  //   any
  // >({
  //   control,
  //   name: "materialStreamUsage.resourcePersons",
  //   keyName: "fieldId",
  // });

  function GetTheSum() {
    return <SumNumber>{GetValueFroMSum()}</SumNumber>;
  }

  function GetValueFroMSum() {
    let inputValues = watch([
      "materialStreamUsage.internalDeposition",
      "materialStreamUsage.externalDeposition",
      "materialStreamUsage.internalRecycling",
      "materialStreamUsage.externalSales",
    ]);
    return calculateSum(inputValues);
  }

  return (
    <>
      <HeaderTitle>Utnyttelse og avhending av materialet i dag *</HeaderTitle>
      <Question>Intern deponering</Question>
      <UserInput>
        <InputField ref={register} name="materialStreamUsage.internalDeposition" />
      </UserInput>
      <Unit> %</Unit>
      {errors.materialStreamUsage?.internalDeposition && (
        <FormError className="error">{errors.materialStreamUsage?.internalDeposition.message}</FormError>
      )}
      <Question>Ekstern deponering</Question>
      <UserInput>
        <InputField ref={register} name="materialStreamUsage.externalDeposition" />
      </UserInput>
      <Unit>%</Unit>
      {errors.materialStreamUsage?.externalDeposition && (
        <FormError className="error">{errors.materialStreamUsage?.externalDeposition.message}</FormError>
      )}
      <Question>Intern gjenvinning</Question>
      <UserInput>
        <InputField ref={register} name="materialStreamUsage.internalRecycling" />
      </UserInput>
      <Unit>%</Unit>
      {errors.materialStreamUsage?.internalRecycling && (
        <FormError className="error">{errors.materialStreamUsage?.internalRecycling.message}</FormError>
      )}
      <Question>Salg/leveranse til ekstern mottaker</Question>
      <UserInput>
        <InputField ref={register} name="materialStreamUsage.externalSales" />
      </UserInput>
      <Unit>%</Unit>
      {errors.materialStreamUsage?.externalSales && (
        <FormError className="error">{errors.materialStreamUsage?.externalSales.message}</FormError>
      )}
      <Sum>Sum</Sum>
      <SumNumberContainer color={GetValueFroMSum() > 100 ? "#B11C39" : "auto"}>
        <GetTheSum />
      </SumNumberContainer>
      <Unit>%</Unit>
      <Question>Forbrennes materialet? *</Question>

      <UserInput>
        <SelectField ref={register} name="materialStreamUsage.isBurned">
          <option value="">Vennligst velg</option>
          {SelectValues(BooleanSelectExtended)}
        </SelectField>
      </UserInput>
      {errors.materialStreamUsage?.isBurned && <FormError className="error">{errors.materialStreamUsage?.isBurned.message}</FormError>}

      <HeaderTitle>Potensiell utnyttelse</HeaderTitle>
      <QuestionBorder> </QuestionBorder>
      <Question>Materialet er forsøkt utnyttet i en intern eller ekstern prosess tidligere</Question>
      <UserInput>
        <input ref={register} name="materialStreamUsage.previousInternalAndExternalUsage" type="checkbox" />
      </UserInput>
      {watch("materialStreamUsage.previousInternalAndExternalUsage") && (
        <>
          <UserInputCheckbox>
            <CheckboxQuestion>
              <CheckboxInput ref={register} name="materialStreamUsage.previousInternalUsage" type="checkbox" />
              Internt i bedriften
            </CheckboxQuestion>
          </UserInputCheckbox>

          <UserInputCheckbox>
            <CheckboxQuestion>
              <CheckboxInput ref={register} name="materialStreamUsage.previousExternalUsage" type="checkbox" />
              Eksternt sammen med en partner
            </CheckboxQuestion>
          </UserInputCheckbox>

          <Question>Beskrivelse av hva som er gjort</Question>
          <CommentBoxArea>
            <CommentField ref={register} name="materialStreamUsage.previousInternalAndExternalUsageComment" placeholder="Comments" />
          </CommentBoxArea>
        </>
      )}
      <QuestionBorder> </QuestionBorder>
      <Question>Det finnes pågående utviklingsaktiviteter</Question>
      <UserInput>
        <input ref={register} name="materialStreamUsage.internalAndExternalActivitesForMaterialUsage" type="checkbox" />
      </UserInput>
      {watch("materialStreamUsage.internalAndExternalActivitesForMaterialUsage") && (
        <>
          <UserInputCheckbox>
            <CheckboxQuestion>
              <CheckboxInput ref={register} name="materialStreamUsage.internalActivitesForMaterialUsage" type="checkbox" />
              Internt i bedriften
            </CheckboxQuestion>
          </UserInputCheckbox>
          <UserInputCheckbox>
            <CheckboxQuestion>
              <CheckboxInput ref={register} name="materialStreamUsage.externalActivitesForMaterialUsage" type="checkbox" />
              Eksternt sammen med en partner
            </CheckboxQuestion>
          </UserInputCheckbox>

          <Question>Beskriv pågående aktiviteter</Question>
          <CommentBoxArea>
            <CommentField
              ref={register}
              name="materialStreamUsage.internalAndExternalActivitesForMaterialUsageComment"
              placeholder="Comments"
            />
          </CommentBoxArea>
        </>
      )}
      <QuestionBorder> </QuestionBorder>

      <Question>Det finnes et større potensiale for utviklingsaktiviteter enn man foretar seg i dag</Question>
      <UserInput>
        <input ref={register} name="materialStreamUsage.internalAndExternalGreaterPotential" type="checkbox" />
      </UserInput>
      {watch("materialStreamUsage.internalAndExternalGreaterPotential") && (
        <>
          <UserInputCheckbox>
            <CheckboxQuestion>
              <CheckboxInput ref={register} name="materialStreamUsage.internalGreaterPotential" type="checkbox" />
              Internt i bedriften
            </CheckboxQuestion>
          </UserInputCheckbox>
          <UserInputCheckbox>
            <CheckboxQuestion>
              <CheckboxInput ref={register} name="materialStreamUsage.externalGreaterPotential" type="checkbox" />
              Eksternt sammen med en partner
            </CheckboxQuestion>
          </UserInputCheckbox>
          <Question>Beskriv potensialet</Question>
          <CommentBoxArea>
            <CommentField ref={register} name="materialStreamUsage.internalAndExternalGreaterPotentialComment" placeholder="Comments" />
          </CommentBoxArea>
        </>
      )}
      <QuestionBorder> </QuestionBorder>
      <UploadField>
        <Upload
          description="Legg gjerne til link eller vedlegg i forbindelse med potensiell utnyttelse. F.eks forskningsartikler"
          title="Potensiell utnyttelse - vedlegg"
          attachmentType={AttachmentTypeEnum.PotentialUseDocuments}
        />
      </UploadField>
      <LinksField>
        <LinksInput
          description="Legg gjerne til lenker relatert til potensiell utnyttelse. F.eks forskningsartikler..."
          title="Potensiell utnyttelse - lenke"
          fields={linkFields}
          register={register}
          append={appendLink}
          remove={removeLink}
          errors={errors.links}
          name="materialStreamUsage.links"
        />
      </LinksField>

      {/* <ResourcePersonsField>
        <ResourcePersonsInput
          description="Legg gjerne til ressurspersoner relatert til potensiell utnyttelse."
          title="Potensiell utnyttelse - ressursperson"
          fields={resourcePersonFields}
          register={register}
          append={appendResourcePerson}
          remove={removeResourcePerson}
          name="materialStreamUsage.resourcePersons"
        />
      </ResourcePersonsField> */}
      <Question>Annen relevant info om utnyttelse av sidestrømmen:</Question>
      <CommentBoxArea>
        <CommentField ref={register} name="materialStreamUsage.otherComments" placeholder="Comments" />
      </CommentBoxArea>
    </>
  );
};

export default MaterialFlowUtilizationData;
