import styled from "styled-components";

const Heading = styled.h2`
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  font-size: ${(props) => props.theme.fontSizes.medium};
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  font-weight: 500;
`;

export default Heading;
