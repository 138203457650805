const Logo: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='238.218'
      height='45'
      viewBox='0 0 238.218 45'
    >
      <g id='Layer_2' data-name='Layer 2' transform='translate(-0.012 -0.018)'>
        <g id='Layer_1' data-name='Layer 1' transform='translate(0.012 0.018)'>
          <path
            id='Path_14'
            data-name='Path 14'
            d='M115.792,32.6h6.388V29.243h-6.388V26.875h7.1V23.52H112.32V38.628H123V35.274h-7.2Z'
            transform='translate(-63.519 -13.308)'
            fill='#064f59'
          />
          <path
            id='Path_15'
            data-name='Path 15'
            d='M146.068,28.717l-3.224-5.2H138.52l5.805,8.79v6.318h3.468V32.31l5.8-8.79h-4.2Z'
            transform='translate(-78.335 -13.308)'
            fill='#064f59'
          />
          <path
            id='Path_16'
            data-name='Path 16'
            d='M188.381,27.907a7.56,7.56,0,0,0-.926-1.625,6.323,6.323,0,0,0-1.508-1.43,7.513,7.513,0,0,0-2.094-.978,9.629,9.629,0,0,0-2.733-.356h-5.51V38.627h5.214a9.125,9.125,0,0,0,3.5-.634,7.821,7.821,0,0,0,2.581-1.695,7.191,7.191,0,0,0,1.586-2.425,7.544,7.544,0,0,0,.534-2.8,8.829,8.829,0,0,0-.148-1.512,8.113,8.113,0,0,0-.5-1.651Zm-9.3-1.034h1.912a4.871,4.871,0,0,1,2.277.435,3.846,3.846,0,0,1,1.334,1.086,3.741,3.741,0,0,1,.647,1.347,5.65,5.65,0,0,1,.187,1.3,4.189,4.189,0,0,1-.374,1.838,3.672,3.672,0,0,1-1.012,1.3,4.576,4.576,0,0,1-1.538.8,6.848,6.848,0,0,1-1.96.278h-1.477Z'
            transform='translate(-99.308 -13.307)'
            fill='#064f59'
          />
          <path
            id='Path_17'
            data-name='Path 17'
            d='M213.938,35.274V32.6h6.392V29.243h-6.392V26.875h7.1V23.52H210.47V38.628h10.676V35.274Z'
            transform='translate(-119.021 -13.308)'
            fill='#064f59'
          />
          <path
            id='Path_18'
            data-name='Path 18'
            d='M118.079,65.022a2,2,0,0,0-.769-.578,2.42,2.42,0,0,0-.965-.2,2.733,2.733,0,0,0-1.1.217,2.525,2.525,0,0,0-.839.591,2.659,2.659,0,0,0-.543.869,2.994,2.994,0,0,0-.2,1.086,2.855,2.855,0,0,0,.182,1.021,2.516,2.516,0,0,0,.53.869,2.438,2.438,0,0,0,.843.582,2.872,2.872,0,0,0,1.125.209,2.22,2.22,0,0,0,1.877-.913l.821.587a3.433,3.433,0,0,1-.282.3,3.272,3.272,0,0,1-.539.4,4.028,4.028,0,0,1-.808.348,3.6,3.6,0,0,1-1.091.148,3.724,3.724,0,0,1-1.538-.309,3.676,3.676,0,0,1-1.165-.808,3.441,3.441,0,0,1-.734-1.134,3.4,3.4,0,0,1-.256-1.278,3.828,3.828,0,0,1,.274-1.46,3.359,3.359,0,0,1,1.951-1.912,4.067,4.067,0,0,1,1.521-.274,3.911,3.911,0,0,1,1.395.261,2.629,2.629,0,0,1,1.1.791Z'
            transform='translate(-63.694 -35.847)'
            fill='#064f59'
          />
          <path
            id='Path_19'
            data-name='Path 19'
            d='M134.37,63.76h.973v5.957h3.3v.869H134.37Z'
            transform='translate(-75.988 -36.063)'
            fill='#064f59'
          />
          <path
            id='Path_20'
            data-name='Path 20'
            d='M150.638,63.76v4.184a2.543,2.543,0,0,0,.091.66,1.882,1.882,0,0,0,.3.63,1.616,1.616,0,0,0,.561.474,2.077,2.077,0,0,0,1.7,0,1.564,1.564,0,0,0,.556-.474,1.8,1.8,0,0,0,.3-.63,2.381,2.381,0,0,0,.1-.66V63.76h.973v4.345a2.738,2.738,0,0,1-.213,1.086,2.607,2.607,0,0,1-1.473,1.395,3.233,3.233,0,0,1-2.173,0,2.655,2.655,0,0,1-.869-.552,2.607,2.607,0,0,1-.587-.843,2.663,2.663,0,0,1-.213-1.086V63.76Z'
            transform='translate(-84.656 -36.063)'
            fill='#064f59'
          />
          <path
            id='Path_21'
            data-name='Path 21'
            d='M170.63,68.934a1.569,1.569,0,0,0,.674.552,2.06,2.06,0,0,0,.847.178,1.8,1.8,0,0,0,.5-.07,1.573,1.573,0,0,0,.465-.217,1.2,1.2,0,0,0,.339-.356.934.934,0,0,0,.13-.491.726.726,0,0,0-.261-.6,2.281,2.281,0,0,0-.656-.348c-.261-.091-.543-.182-.869-.269a3.637,3.637,0,0,1-.869-.361,2.077,2.077,0,0,1-.656-.621A1.877,1.877,0,0,1,170,65.271a1.738,1.738,0,0,1,.139-.639,1.768,1.768,0,0,1,.435-.617,2.381,2.381,0,0,1,.756-.465,2.933,2.933,0,0,1,1.112-.191,4.024,4.024,0,0,1,1.125.156,1.964,1.964,0,0,1,.943.626l-.791.687a1.3,1.3,0,0,0-.517-.435,1.738,1.738,0,0,0-.76-.165,2.03,2.03,0,0,0-.678.1,1.3,1.3,0,0,0-.435.261.926.926,0,0,0-.226.343,1.012,1.012,0,0,0-.065.339.869.869,0,0,0,.261.665,2.125,2.125,0,0,0,.656.374,8.635,8.635,0,0,0,.869.261,3.972,3.972,0,0,1,.869.322,1.99,1.99,0,0,1,.656.561,1.6,1.6,0,0,1,.265.969,1.955,1.955,0,0,1-.2.869,1.9,1.9,0,0,1-.534.665,2.329,2.329,0,0,1-.791.435,3.237,3.237,0,0,1-.973.143,3.75,3.75,0,0,1-1.3-.23,2.173,2.173,0,0,1-.986-.713Z'
            transform='translate(-96.04 -35.835)'
            fill='#064f59'
          />
          <path
            id='Path_22'
            data-name='Path 22'
            d='M188.882,64.629H186.57V63.76h5.6v.869h-2.316v5.957h-.973Z'
            transform='translate(-105.506 -36.063)'
            fill='#064f59'
          />
          <path
            id='Path_23'
            data-name='Path 23'
            d='M223.91,63.758h2.529a3.1,3.1,0,0,1,1.134.178,2,2,0,0,1,.708.435,1.564,1.564,0,0,1,.361.617,2.39,2.39,0,0,1,.1.66,1.66,1.66,0,0,1-.122.63,1.738,1.738,0,0,1-.352.548,2.007,2.007,0,0,1-.556.435,1.942,1.942,0,0,1-.734.2l2.038,3.116H227.8l-1.829-3.007h-1.086V70.58h-.973Zm.973,3.011h1.282a4.008,4.008,0,0,0,.561-.043,1.608,1.608,0,0,0,.5-.161.869.869,0,0,0,.348-.335,1.243,1.243,0,0,0,0-1.125.917.917,0,0,0-.348-.335,1.6,1.6,0,0,0-.5-.156,3.441,3.441,0,0,0-.561-.043h-1.282Z'
            transform='translate(-126.621 -36.061)'
            fill='#064f59'
          />
          <path
            id='Path_24'
            data-name='Path 24'
            d='M210.409,69.717h-3.846V67.532h3.611v-.869h-3.611V64.629h3.846V63.76H205.59v6.826h4.819Z'
            transform='translate(-116.261 -36.063)'
            fill='#064f59'
          />
          <path
            id='Path_25'
            data-name='Path 25'
            d='M44.8,19.516H24.145V15.653h19.8l-.065-.122h0c-.2-.6-.435-1.186-.665-1.764a22.479,22.479,0,1,0,.022,17.5c.248-.582.469-1.169.66-1.764h-.043l.065-.13H24.145V25.008H44.871a22.747,22.747,0,0,0,.139-2.5A21.439,21.439,0,0,0,44.8,19.516ZM18.47,10.152V34.868H34.2a17.02,17.02,0,1,1,0-24.716Z'
            transform='translate(-0.012 -0.018)'
            fill='#064f59'
          />
          <path
            id='Path_26'
            data-name='Path 26'
            d='M275.88,28.844l-3.515-5.114h-.734v6.531h.8V25.134l3.515,5.127h.734V23.73h-.8Z'
            transform='translate(-153.605 -13.427)'
            fill='#064f59'
          />
          <path
            id='Path_27'
            data-name='Path 27'
            d='M290.82,25.815l.026-.087v-.061a1.921,1.921,0,0,0-.66-1.582,2.668,2.668,0,0,0-1.777-.569,2.338,2.338,0,0,0-1.847.8,3.016,3.016,0,0,0-.7,2.042v1.021a3,3,0,0,0,.7,2.042,2.346,2.346,0,0,0,1.864.817,2.607,2.607,0,0,0,1.769-.582,1.921,1.921,0,0,0,.669-1.59l-.043-.148H290.1v.148a1.378,1.378,0,0,1-.435,1.112,1.838,1.838,0,0,1-1.234.382,1.521,1.521,0,0,1-1.269-.626,2.411,2.411,0,0,1-.491-1.569V26.354a2.412,2.412,0,0,1,.491-1.56,1.534,1.534,0,0,1,1.264-.591,1.8,1.8,0,0,1,1.234.4,1.364,1.364,0,0,1,.435,1.091v.126Z'
            transform='translate(-161.654 -13.304)'
            fill='#064f59'
          />
          <path
            id='Path_28'
            data-name='Path 28'
            d='M300.731,27.219h2.911v-.691h-2.911V24.417h3.346V23.73H299.94v6.531H304.1v-.687h-3.368Z'
            transform='translate(-169.614 -13.427)'
            fill='#064f59'
          />
          <path
            id='Path_29'
            data-name='Path 29'
            d='M317.431,27.219h2.911v-.691h-2.911V24.417h3.346V23.73H316.64v6.531H320.8v-.687h-3.368Z'
            transform='translate(-179.057 -13.427)'
            fill='#064f59'
          />
          <path
            id='Path_30'
            data-name='Path 30'
            d='M329.332,27.089l-1.925-3.359h-.917l2.446,4.119v2.412h.786v-2.39l2.459-4.141h-.917Z'
            transform='translate(-184.627 -13.427)'
            fill='#064f59'
          />
          <path
            id='Path_31'
            data-name='Path 31'
            d='M343.257,23.728H341.15v6.518h2.107a2.833,2.833,0,0,0,2.06-.8,2.772,2.772,0,0,0,.817-2.06v-.791a2.772,2.772,0,0,0-.817-2.06A2.816,2.816,0,0,0,343.257,23.728Zm0,5.844h-1.3V24.415h1.3a1.955,1.955,0,0,1,1.49.613,2.146,2.146,0,0,1,.591,1.551V27.4a2.173,2.173,0,0,1-.587,1.564A1.964,1.964,0,0,1,343.257,29.573Z'
            transform='translate(-192.917 -13.425)'
            fill='#064f59'
          />
          <path
            id='Path_32'
            data-name='Path 32'
            d='M356.051,29.574V27.219h2.916v-.691h-2.916V24.417H359.4V23.73H355.26v6.531h4.158v-.687Z'
            transform='translate(-200.896 -13.427)'
            fill='#064f59'
          />
          <path
            id='Path_33'
            data-name='Path 33'
            d='M276.418,50.652h-.539l-3.685-5.558h-.03v5.558h-.534V44.16h.534l3.689,5.54h.026V44.16h.539Z'
            transform='translate(-153.605 -24.979)'
            fill='#064f59'
          />
          <path
            id='Path_34'
            data-name='Path 34'
            d='M285.488,50.229a2.607,2.607,0,0,1,.582-1.738,2.1,2.1,0,0,1,3.1,0,2.607,2.607,0,0,1,.582,1.738v.148a2.607,2.607,0,0,1-.582,1.738,2.1,2.1,0,0,1-3.1,0,2.607,2.607,0,0,1-.582-1.738Zm.534.148a2.286,2.286,0,0,0,.435,1.408,1.495,1.495,0,0,0,2.355,0,2.286,2.286,0,0,0,.435-1.408v-.148a2.273,2.273,0,0,0-.435-1.4,1.49,1.49,0,0,0-2.351,0,2.294,2.294,0,0,0-.435,1.4Z'
            transform='translate(-161.44 -27.042)'
            fill='#064f59'
          />
          <path
            id='Path_35'
            data-name='Path 35'
            d='M300.133,48.354l-.382-.026a1.221,1.221,0,0,0-.826.282,1.551,1.551,0,0,0-.461.778v3.333h-.534V47.9h.465l.07.8v.065a1.738,1.738,0,0,1,.561-.7,1.417,1.417,0,0,1,.839-.248.869.869,0,0,1,.187,0l.148.03Z'
            transform='translate(-168.477 -27.048)'
            fill='#064f59'
          />
          <path
            id='Path_36'
            data-name='Path 36'
            d='M305.726,51.259l.187.869h.026l.222-.869L307.194,48h.435l1.025,3.263.222.908h.056l.209-.908L310.01,48h.569l-1.421,4.823h-.435l-1.1-3.3-.191-.778h-.03l-.213.778-1.069,3.3h-.461L304.27,48h.569Z'
            transform='translate(-172.062 -27.151)'
            fill='#064f59'
          />
          <path
            id='Path_37'
            data-name='Path 37'
            d='M322.61,52.773a1.929,1.929,0,0,1-1.521-.665,2.49,2.49,0,0,1-.582-1.7v-.248a2.516,2.516,0,0,1,.591-1.738,1.86,1.86,0,0,1,1.464-.678,1.669,1.669,0,0,1,1.356.565,2.251,2.251,0,0,1,.482,1.516v.435h-3.359v.148a2.173,2.173,0,0,0,.435,1.369,1.373,1.373,0,0,0,1.151.543,2.233,2.233,0,0,0,.813-.143,1.877,1.877,0,0,0,.617-.391l.226.365a1.978,1.978,0,0,1-.665.435,2.538,2.538,0,0,1-1.008.187Zm-.048-4.554a1.3,1.3,0,0,0-1.012.435,1.9,1.9,0,0,0-.465,1.121V49.8h2.768v-.113a1.621,1.621,0,0,0-.339-1.056,1.173,1.173,0,0,0-.952-.413Z'
            transform='translate(-181.242 -27.005)'
            fill='#064f59'
          />
          <path
            id='Path_38'
            data-name='Path 38'
            d='M331.5,50.331a3.142,3.142,0,0,1,.487-1.847,1.573,1.573,0,0,1,1.364-.7,1.738,1.738,0,0,1,.891.222,1.794,1.794,0,0,1,.626.626l.074-.76h.435v4.854a1.912,1.912,0,0,1-.508,1.425,1.986,1.986,0,0,1-1.443.5,3.567,3.567,0,0,1-.743-.083,3.042,3.042,0,0,1-.687-.23l.1-.435a2.8,2.8,0,0,0,.617.217,3.259,3.259,0,0,0,.7.074,1.421,1.421,0,0,0,1.069-.365,1.508,1.508,0,0,0,.352-1.082v-.7a1.668,1.668,0,0,1-.626.556,1.842,1.842,0,0,1-.869.2,1.608,1.608,0,0,1-1.356-.647,2.794,2.794,0,0,1-.487-1.738Zm.534.1a2.442,2.442,0,0,0,.352,1.39,1.2,1.2,0,0,0,1.065.517,1.525,1.525,0,0,0,.869-.23,1.56,1.56,0,0,0,.539-.63v-2.29a1.669,1.669,0,0,0-.521-.66,1.373,1.373,0,0,0-.869-.256,1.165,1.165,0,0,0-1.06.578,2.816,2.816,0,0,0-.356,1.5Z'
            transform='translate(-187.46 -27.03)'
            fill='#064f59'
          />
          <path
            id='Path_39'
            data-name='Path 39'
            d='M344.31,43.837h-.53V43.09h.53Zm0,6.209h-.53V45.224h.53Z'
            transform='translate(-194.404 -24.374)'
            fill='#064f59'
          />
          <path
            id='Path_40'
            data-name='Path 40'
            d='M351.156,52.673c-.03-.169-.057-.317-.074-.435a3.485,3.485,0,0,1,0-.374,1.977,1.977,0,0,1-.7.647,2.034,2.034,0,0,1-1.034.261,1.508,1.508,0,0,1-1.1-.378,1.36,1.36,0,0,1-.391-1.025,1.23,1.23,0,0,1,.565-1.047,2.481,2.481,0,0,1,1.473-.4h1.186v-.6a1.03,1.03,0,0,0-.33-.813,1.334,1.334,0,0,0-.926-.291,1.369,1.369,0,0,0-.869.265.826.826,0,0,0-.339.674h-.5v-.026a1.147,1.147,0,0,1,.465-.956,1.955,1.955,0,0,1,1.3-.435,1.921,1.921,0,0,1,1.273.4,1.443,1.443,0,0,1,.482,1.165v2.373a3.693,3.693,0,0,0,.03.5,2.61,2.61,0,0,0,.1.478Zm-1.773-.378a1.908,1.908,0,0,0,1.065-.3,1.46,1.46,0,0,0,.613-.747v-.926H349.87a1.8,1.8,0,0,0-1.078.309.908.908,0,0,0-.435.747.869.869,0,0,0,.269.66,1.034,1.034,0,0,0,.756.252Z'
            transform='translate(-196.709 -27.001)'
            fill='#064f59'
          />
          <path
            id='Path_41'
            data-name='Path 41'
            d='M360.216,47.883l.048.839a1.8,1.8,0,0,1,.63-.682,1.738,1.738,0,0,1,.934-.243,1.5,1.5,0,0,1,1.195.478,2.281,2.281,0,0,1,.408,1.5v2.933h-.526V49.773A1.738,1.738,0,0,0,362.6,48.6a1.125,1.125,0,0,0-.869-.343,1.43,1.43,0,0,0-.921.278,1.525,1.525,0,0,0-.508.756v3.415h-.539V47.883Z'
            transform='translate(-203.44 -27.033)'
            fill='#064f59'
          />
          <path
            id='Path_42'
            data-name='Path 42'
            d='M380.992,48.494v.026a1.886,1.886,0,0,1-.617,1.525,2.433,2.433,0,0,1-1.686.574,2.173,2.173,0,0,1-1.738-.786,3.016,3.016,0,0,1-.665-2.025V46.752a3.042,3.042,0,0,1,.665-2.029,2.173,2.173,0,0,1,1.738-.786,2.494,2.494,0,0,1,1.695.556A1.908,1.908,0,0,1,381,46.026v.03h-.5a1.569,1.569,0,0,0-.5-1.225,1.864,1.864,0,0,0-1.3-.435,1.634,1.634,0,0,0-1.364.66,2.642,2.642,0,0,0-.517,1.69v1.065a2.642,2.642,0,0,0,.517,1.7,1.643,1.643,0,0,0,1.364.66,1.9,1.9,0,0,0,1.3-.435,1.569,1.569,0,0,0,.5-1.243Z'
            transform='translate(-212.784 -24.851)'
            fill='#064f59'
          />
          <path
            id='Path_43'
            data-name='Path 43'
            d='M391.484,52.774a1.929,1.929,0,0,1-1.521-.665,2.472,2.472,0,0,1-.587-1.7v-.248a2.52,2.52,0,0,1,.6-1.738,1.86,1.86,0,0,1,1.464-.678,1.677,1.677,0,0,1,1.356.565,2.251,2.251,0,0,1,.478,1.516v.435h-3.355v.148a2.172,2.172,0,0,0,.435,1.369,1.373,1.373,0,0,0,1.151.543,2.233,2.233,0,0,0,.8-.122,1.811,1.811,0,0,0,.613-.391l.23.365a1.956,1.956,0,0,1-.669.435A2.5,2.5,0,0,1,391.484,52.774Zm-.048-4.554a1.3,1.3,0,0,0-1.012.435,1.9,1.9,0,0,0-.465,1.121V49.8h2.764v-.113a1.642,1.642,0,0,0-.335-1.056,1.173,1.173,0,0,0-.952-.413Z'
            transform='translate(-220.187 -27.006)'
            fill='#064f59'
          />
          <path
            id='Path_44'
            data-name='Path 44'
            d='M401.321,47.883l.048.839a1.682,1.682,0,0,1,1.56-.926,1.49,1.49,0,0,1,1.195.478,2.277,2.277,0,0,1,.413,1.5v2.933H404V49.773a1.738,1.738,0,0,0-.309-1.173,1.117,1.117,0,0,0-.869-.343,1.417,1.417,0,0,0-.917.278,1.564,1.564,0,0,0-.508.756v3.415h-.534V47.883Z'
            transform='translate(-226.681 -27.033)'
            fill='#064f59'
          />
          <path
            id='Path_45'
            data-name='Path 45'
            d='M412.657,45.15v1.238h1.06v.435h-1.06v3.146a1.034,1.034,0,0,0,.169.669.578.578,0,0,0,.461.2,1.3,1.3,0,0,0,.187,0,1.8,1.8,0,0,0,.217-.039l.074.369a1.052,1.052,0,0,1-.265.087,1.534,1.534,0,0,1-.313.035,1,1,0,0,1-.791-.322,1.512,1.512,0,0,1-.282-1.017V46.823h-.834v-.435h.834V45.15Z'
            transform='translate(-232.574 -25.539)'
            fill='#064f59'
          />
          <path
            id='Path_46'
            data-name='Path 46'
            d='M420.77,52.773a1.929,1.929,0,0,1-1.521-.665,2.468,2.468,0,0,1-.582-1.7v-.248a2.516,2.516,0,0,1,.591-1.738,1.86,1.86,0,0,1,1.464-.678,1.669,1.669,0,0,1,1.356.565,2.251,2.251,0,0,1,.482,1.516v.435H419.2v.148a2.173,2.173,0,0,0,.435,1.369,1.373,1.373,0,0,0,1.151.543,2.233,2.233,0,0,0,.813-.143,1.877,1.877,0,0,0,.617-.391l.226.365a1.955,1.955,0,0,1-.669.435A2.5,2.5,0,0,1,420.77,52.773Zm-.048-4.554a1.3,1.3,0,0,0-1.012.435,1.9,1.9,0,0,0-.465,1.125v.026h2.764v-.113a1.616,1.616,0,0,0-.335-1.056,1.173,1.173,0,0,0-.952-.417Z'
            transform='translate(-236.749 -27.005)'
            fill='#064f59'
          />
          <path
            id='Path_47'
            data-name='Path 47'
            d='M432.313,48.354l-.378-.026a1.225,1.225,0,0,0-.83.282,1.581,1.581,0,0,0-.461.778v3.333h-.534V47.9h.469l.065.8v.065a1.738,1.738,0,0,1,.561-.7,1.434,1.434,0,0,1,.839-.248.921.921,0,0,1,.191,0l.143.03Z'
            transform='translate(-243.222 -27.048)'
            fill='#064f59'
          />
          <path
            id='Path_48'
            data-name='Path 48'
            d='M441.708,50.229a2.607,2.607,0,0,1,.582-1.738,2.1,2.1,0,0,1,3.1,0,2.607,2.607,0,0,1,.582,1.738v.148a2.607,2.607,0,0,1-.582,1.738,2.1,2.1,0,0,1-3.1,0,2.607,2.607,0,0,1-.582-1.738Zm.534.148a2.286,2.286,0,0,0,.435,1.408,1.495,1.495,0,0,0,2.355,0,2.285,2.285,0,0,0,.435-1.408v-.148a2.272,2.272,0,0,0-.435-1.4,1.49,1.49,0,0,0-2.351,0,2.294,2.294,0,0,0-.435,1.4Z'
            transform='translate(-249.779 -27.042)'
            fill='#064f59'
          />
          <path
            id='Path_49'
            data-name='Path 49'
            d='M453.9,49.919V45.53h-.8V45.1h.8v-.708a1.6,1.6,0,0,1,.365-1.121,1.3,1.3,0,0,1,1.021-.4,2.367,2.367,0,0,1,.3.022l.313.065-.074.435a1.74,1.74,0,0,0-.217-.039,1.594,1.594,0,0,0-.265,0,.821.821,0,0,0-.669.278,1.16,1.16,0,0,0-.235.773v.7h1.091v.435h-1.091v4.389Z'
            transform='translate(-256.222 -24.247)'
            fill='#064f59'
          />
          <path
            id='Path_50'
            data-name='Path 50'
            d='M469.684,47.506h-2.911V50.2h3.368v.435H466.23V44.16h3.88v.435h-3.346v2.442h2.911Z'
            transform='translate(-263.647 -24.979)'
            fill='#064f59'
          />
          <path
            id='Path_51'
            data-name='Path 51'
            d='M478.421,49.968l1.3-1.968h.634l-1.634,2.368,1.7,2.459H479.8l-1.373-2.055-1.356,2.051h-.634l1.708-2.459L476.51,48h.63Z'
            transform='translate(-269.42 -27.151)'
            fill='#064f59'
          />
          <path
            id='Path_52'
            data-name='Path 52'
            d='M491.737,50.432a2.786,2.786,0,0,1-.491,1.738,1.6,1.6,0,0,1-1.351.647,1.929,1.929,0,0,1-.869-.2,1.625,1.625,0,0,1-.621-.543v2.5h-.534V47.885h.413l.1.756A1.738,1.738,0,0,1,489,48.02a1.769,1.769,0,0,1,.9-.222,1.564,1.564,0,0,1,1.36.7,3.142,3.142,0,0,1,.491,1.847Zm-.534-.1a2.777,2.777,0,0,0-.365-1.5,1.186,1.186,0,0,0-1.073-.578,1.39,1.39,0,0,0-.869.252,1.612,1.612,0,0,0-.508.678v2.338a1.43,1.43,0,0,0,1.391.843,1.2,1.2,0,0,0,1.06-.526,2.408,2.408,0,0,0,.365-1.395Z'
            transform='translate(-275.883 -27.036)'
            fill='#064f59'
          />
          <path
            id='Path_53'
            data-name='Path 53'
            d='M501.01,52.773a1.929,1.929,0,0,1-1.521-.665,2.468,2.468,0,0,1-.582-1.7v-.248a2.516,2.516,0,0,1,.591-1.738,1.86,1.86,0,0,1,1.464-.678,1.669,1.669,0,0,1,1.356.565,2.251,2.251,0,0,1,.482,1.516v.435h-3.359v.148a2.173,2.173,0,0,0,.435,1.369,1.373,1.373,0,0,0,1.152.543,2.233,2.233,0,0,0,.813-.143,1.812,1.812,0,0,0,.613-.391l.23.365a1.956,1.956,0,0,1-.669.435,2.5,2.5,0,0,1-1,.187Zm-.048-4.554a1.3,1.3,0,0,0-1.012.435,1.9,1.9,0,0,0-.465,1.121V49.8h2.764v-.113a1.616,1.616,0,0,0-.335-1.056A1.173,1.173,0,0,0,500.962,48.22Z'
            transform='translate(-282.123 -27.005)'
            fill='#064f59'
          />
          <path
            id='Path_54'
            data-name='Path 54'
            d='M512.553,48.354l-.378-.026a1.225,1.225,0,0,0-.83.282,1.581,1.581,0,0,0-.461.778v3.333h-.534V47.9h.469l.065.8v.065a1.738,1.738,0,0,1,.561-.7,1.425,1.425,0,0,1,.839-.248.922.922,0,0,1,.191,0l.143.03Z'
            transform='translate(-288.595 -27.048)'
            fill='#064f59'
          />
          <path
            id='Path_55'
            data-name='Path 55'
            d='M517.8,45.15v1.238h1.06v.435H517.8v3.146a1.034,1.034,0,0,0,.169.669.574.574,0,0,0,.435.2,1.3,1.3,0,0,0,.191,0,1.8,1.8,0,0,0,.217-.039l.078.4a1.051,1.051,0,0,1-.265.087,1.534,1.534,0,0,1-.313.035,1,1,0,0,1-.791-.322,1.512,1.512,0,0,1-.282-1.017V46.823h-.834v-.435h.834V45.15Z'
            transform='translate(-292.022 -25.539)'
            fill='#064f59'
          />
          <path
            id='Path_56'
            data-name='Path 56'
            d='M525.15,43.837h-.53V43.09h.53Zm0,6.209h-.53V45.224h.53Z'
            transform='translate(-296.665 -24.374)'
            fill='#064f59'
          />
          <path
            id='Path_57'
            data-name='Path 57'
            d='M531.836,51.46a.765.765,0,0,0-.278-.591,2.22,2.22,0,0,0-1-.413,3.315,3.315,0,0,1-1.273-.491,1.013,1.013,0,0,1-.435-.869,1.182,1.182,0,0,1,.465-.939,1.877,1.877,0,0,1,1.234-.382,1.925,1.925,0,0,1,1.3.408,1.2,1.2,0,0,1,.474.991V49.2h-.5a.9.9,0,0,0-.339-.691,1.347,1.347,0,0,0-.9-.291,1.3,1.3,0,0,0-.869.252.773.773,0,0,0-.3.6.682.682,0,0,0,.252.543,2.42,2.42,0,0,0,1,.382,3.346,3.346,0,0,1,1.3.521,1.086,1.086,0,0,1,.435.9,1.186,1.186,0,0,1-.526,1,2.051,2.051,0,0,1-1.3.378,2.1,2.1,0,0,1-1.382-.435,1.186,1.186,0,0,1-.474-1V51.33h.491a.869.869,0,0,0,.435.756,1.738,1.738,0,0,0,.93.239,1.464,1.464,0,0,0,.917-.261A.756.756,0,0,0,531.836,51.46Z'
            transform='translate(-298.987 -27.022)'
            fill='#064f59'
          />
          <path
            id='Path_58'
            data-name='Path 58'
            d='M541.414,52.8a1.925,1.925,0,0,1-1.521-.665,2.472,2.472,0,0,1-.587-1.7v-.248a2.538,2.538,0,0,1,.591-1.738,1.869,1.869,0,0,1,1.447-.66,1.686,1.686,0,0,1,1.36.565,2.273,2.273,0,0,1,.478,1.516V50.3h-3.359v.148a2.173,2.173,0,0,0,.435,1.369,1.382,1.382,0,0,0,1.156.543,2.251,2.251,0,0,0,.8-.139,1.855,1.855,0,0,0,.613-.391l.226.365a1.934,1.934,0,0,1-.665.435A2.5,2.5,0,0,1,541.414,52.8Zm-.069-4.554a1.3,1.3,0,0,0-1.012.435,1.925,1.925,0,0,0-.461,1.121v.026h2.777v-.113a1.643,1.643,0,0,0-.335-1.056A1.173,1.173,0,0,0,541.345,48.243Z'
            transform='translate(-304.969 -27.028)'
            fill='#064f59'
          />
          <path
            id='Path_59'
            data-name='Path 59'
            d='M275.609,69.507a1,1,0,0,0-.391-.813,3.95,3.95,0,0,0-1.3-.6,4.4,4.4,0,0,1-1.547-.7,1.36,1.36,0,0,1-.565-1.134,1.46,1.46,0,0,1,.591-1.2,2.386,2.386,0,0,1,1.516-.469,2.268,2.268,0,0,1,1.564.53,1.69,1.69,0,0,1,.569,1.351V66.5h-.487a1.356,1.356,0,0,0-.456-1.047,1.69,1.69,0,0,0-1.182-.435,1.764,1.764,0,0,0-1.165.352,1.091,1.091,0,0,0-.435.869,1,1,0,0,0,.382.8,3.946,3.946,0,0,0,1.351.582,4.389,4.389,0,0,1,1.534.721,1.391,1.391,0,0,1,.561,1.151,1.412,1.412,0,0,1-.617,1.195,2.577,2.577,0,0,1-1.556.435,2.855,2.855,0,0,1-1.643-.478,1.551,1.551,0,0,1-.678-1.4v-.026h.487a1.217,1.217,0,0,0,.556,1.1,2.281,2.281,0,0,0,1.269.361,1.968,1.968,0,0,0,1.186-.335,1.012,1.012,0,0,0,.456-.843Z'
            transform='translate(-153.617 -36.534)'
            fill='#064f59'
          />
          <path
            id='Path_60'
            data-name='Path 60'
            d='M287.7,72.533a1.647,1.647,0,0,1-.608.634,1.738,1.738,0,0,1-.908.226,1.434,1.434,0,0,1-1.169-.508,2.394,2.394,0,0,1-.435-1.573V68.57h.526v2.751a2.016,2.016,0,0,0,.3,1.243,1,1,0,0,0,.834.374,1.517,1.517,0,0,0,.943-.265,1.391,1.391,0,0,0,.5-.721V68.57h.526V73.3h-.465Z'
            transform='translate(-160.924 -38.782)'
            fill='#064f59'
          />
          <path
            id='Path_61'
            data-name='Path 61'
            d='M298.693,71.973a.765.765,0,0,0-.269-.578,2.238,2.238,0,0,0-.986-.4,3.254,3.254,0,0,1-1.247-.478,1,1,0,0,1-.408-.869,1.143,1.143,0,0,1,.465-.9,1.829,1.829,0,0,1,1.208-.378,1.89,1.89,0,0,1,1.26.4,1.182,1.182,0,0,1,.435.986v.026h-.487a.869.869,0,0,0-.335-.678,1.548,1.548,0,0,0-1.738-.039.743.743,0,0,0-.291.582.669.669,0,0,0,.248.534,2.429,2.429,0,0,0,.986.374,3.15,3.15,0,0,1,1.256.513,1.056,1.056,0,0,1,.435.869,1.169,1.169,0,0,1-.478.96,2.386,2.386,0,0,1-2.607-.043,1.16,1.16,0,0,1-.469-.978v-.026h.482a.869.869,0,0,0,.435.743,1.738,1.738,0,0,0,.913.23,1.425,1.425,0,0,0,.9-.252A.756.756,0,0,0,298.693,71.973Z'
            transform='translate(-167.198 -38.666)'
            fill='#064f59'
          />
          <path
            id='Path_62'
            data-name='Path 62'
            d='M306.72,65.79V67h1.039v.435H306.72v3.081a.991.991,0,0,0,.169.656.556.556,0,0,0,.435.2h.182l.213-.039.078.391a1.085,1.085,0,0,1-.261.083,1.451,1.451,0,0,1-.3.035.973.973,0,0,1-.778-.317,1.464,1.464,0,0,1-.278-1V67.428h-.817v-.435h.817v-1.2Z'
            transform='translate(-172.679 -37.21)'
            fill='#064f59'
          />
          <path
            id='Path_63'
            data-name='Path 63'
            d='M315.887,73.195c-.035-.169-.057-.309-.074-.435a2.54,2.54,0,0,1-.026-.365,1.925,1.925,0,0,1-.682.634,2.051,2.051,0,0,1-1.017.252,1.508,1.508,0,0,1-1.078-.369,1.347,1.347,0,0,1-.382-1,1.2,1.2,0,0,1,.556-1.025,2.42,2.42,0,0,1,1.443-.4h1.16v-.582a1.012,1.012,0,0,0-.317-.8,1.3,1.3,0,0,0-.908-.287,1.36,1.36,0,0,0-.869.261.817.817,0,0,0-.33.66h-.482v-.026a1.121,1.121,0,0,1,.456-.939,2.173,2.173,0,0,1,2.512,0,1.408,1.408,0,0,1,.469,1.138v2.325a3.521,3.521,0,0,0,.03.487,2.982,2.982,0,0,0,.091.469Zm-1.738-.374a1.882,1.882,0,0,0,1.047-.287,1.434,1.434,0,0,0,.6-.734v-.9h-1.165a1.738,1.738,0,0,0-1.052.3.891.891,0,0,0-.435.734.834.834,0,0,0,.265.647,1.03,1.03,0,0,0,.739.243Z'
            transform='translate(-176.788 -38.675)'
            fill='#064f59'
          />
          <path
            id='Path_64'
            data-name='Path 64'
            d='M324.981,64.5h-.521v-.73h.521Zm0,6.083h-.521V65.856h.521Z'
            transform='translate(-183.479 -36.068)'
            fill='#064f59'
          />
          <path
            id='Path_65'
            data-name='Path 65'
            d='M329.456,68.453l.048.821a1.769,1.769,0,0,1,.617-.669,1.681,1.681,0,0,1,.913-.239,1.464,1.464,0,0,1,1.173.469,2.221,2.221,0,0,1,.4,1.469v2.881h-.526V70.308a1.708,1.708,0,0,0-.3-1.151,1.117,1.117,0,0,0-.869-.335,1.39,1.39,0,0,0-.9.274,1.547,1.547,0,0,0-.5.739v3.35H329V68.453Z'
            transform='translate(-186.046 -38.665)'
            fill='#064f59'
          />
          <path
            id='Path_66'
            data-name='Path 66'
            d='M343.1,73.186c-.03-.169-.056-.309-.07-.435a2.536,2.536,0,0,1-.026-.365,1.9,1.9,0,0,1-.687.634,2.021,2.021,0,0,1-1.012.252,1.5,1.5,0,0,1-1.078-.369,1.33,1.33,0,0,1-.382-1,1.2,1.2,0,0,1,.556-1.025,2.42,2.42,0,0,1,1.443-.4h1.16V69.9a1,1,0,0,0-.322-.8,1.3,1.3,0,0,0-.9-.287,1.36,1.36,0,0,0-.869.261.8.8,0,0,0-.33.66H340.1V69.71a1.112,1.112,0,0,1,.461-.939,1.89,1.89,0,0,1,1.26-.4,1.869,1.869,0,0,1,1.247.4,1.4,1.4,0,0,1,.474,1.138V72.23a3.516,3.516,0,0,0,.03.487,2.979,2.979,0,0,0,.091.469Zm-1.738-.374a1.855,1.855,0,0,0,1.043-.287,1.421,1.421,0,0,0,.6-.734v-.9h-1.165a1.764,1.764,0,0,0-1.056.3.9.9,0,0,0-.413.734.834.834,0,0,0,.265.647,1.03,1.03,0,0,0,.726.243Z'
            transform='translate(-192.18 -38.667)'
            fill='#064f59'
          />
          <path
            id='Path_67'
            data-name='Path 67'
            d='M355.363,68.354a2.712,2.712,0,0,1-.482,1.69,1.564,1.564,0,0,1-1.3.634,1.816,1.816,0,0,1-.9-.209,1.63,1.63,0,0,1-.613-.591l-.091.708h-.408V63.77h.526v2.811a1.621,1.621,0,0,1,1.473-.813,1.53,1.53,0,0,1,1.33.682,3.063,3.063,0,0,1,.482,1.812Zm-.53-.091a2.8,2.8,0,0,0-.348-1.473,1.143,1.143,0,0,0-1.039-.565,1.351,1.351,0,0,0-.869.265,1.582,1.582,0,0,0-.5.682v2.173a1.416,1.416,0,0,0,.517.647,1.455,1.455,0,0,0,.869.239,1.165,1.165,0,0,0,1.034-.508,2.372,2.372,0,0,0,.343-1.36Z'
            transform='translate(-198.809 -36.068)'
            fill='#064f59'
          />
          <path
            id='Path_68'
            data-name='Path 68'
            d='M363.667,70.588h-.517V63.77h.517Z'
            transform='translate(-205.357 -36.068)'
            fill='#064f59'
          />
          <path
            id='Path_69'
            data-name='Path 69'
            d='M369.121,73.277a1.886,1.886,0,0,1-1.49-.652,2.416,2.416,0,0,1-.574-1.664v-.243a2.468,2.468,0,0,1,.578-1.686,1.829,1.829,0,0,1,1.438-.665,1.66,1.66,0,0,1,1.33.552,2.229,2.229,0,0,1,.469,1.49v.4h-3.289v.148a2.116,2.116,0,0,0,.408,1.338,1.338,1.338,0,0,0,1.13.534,2.172,2.172,0,0,0,.8-.143,1.712,1.712,0,0,0,.6-.382l.222.361a1.92,1.92,0,0,1-.656.435A2.485,2.485,0,0,1,369.121,73.277Zm-.048-4.463a1.3,1.3,0,0,0-1,.435,1.9,1.9,0,0,0-.435,1.1v.026h2.707v-.109a1.59,1.59,0,0,0-.33-1.039A1.134,1.134,0,0,0,369.074,68.814Z'
            transform='translate(-207.566 -38.666)'
            fill='#064f59'
          />
          <path
            id='Path_70'
            data-name='Path 70'
            d='M384.086,68.524V71.17h-.526V64.8h2.194a2.173,2.173,0,0,1,1.551.517,1.938,1.938,0,0,1,0,2.7,2.194,2.194,0,0,1-1.551.508Zm0-.435h1.669a1.577,1.577,0,0,0,1.182-.4,1.377,1.377,0,0,0,.4-1,1.408,1.408,0,0,0-.4-1.017,1.577,1.577,0,0,0-1.182-.4h-1.669Z'
            transform='translate(-216.899 -36.65)'
            fill='#064f59'
          />
          <path
            id='Path_71'
            data-name='Path 71'
            d='M398.173,68.9l-.374-.026a1.182,1.182,0,0,0-.813.278,1.547,1.547,0,0,0-.435.76v3.268H396V68.45h.435l.065.786v.052a1.738,1.738,0,0,1,.552-.682,1.4,1.4,0,0,1,.821-.243.812.812,0,0,1,.182,0l.148.03Z'
            transform='translate(-223.933 -38.663)'
            fill='#064f59'
          />
          <path
            id='Path_72'
            data-name='Path 72'
            d='M402.137,70.749a2.542,2.542,0,0,1,.574-1.712,2.06,2.06,0,0,1,3.042,0,2.542,2.542,0,0,1,.574,1.712V70.9a2.551,2.551,0,0,1-.569,1.712,2.064,2.064,0,0,1-3.042,0,2.533,2.533,0,0,1-.574-1.712Zm.526.148a2.229,2.229,0,0,0,.435,1.377,1.464,1.464,0,0,0,2.3,0,2.229,2.229,0,0,0,.435-1.377v-.148a2.229,2.229,0,0,0-.435-1.369,1.36,1.36,0,0,0-1.152-.565,1.334,1.334,0,0,0-1.147.565,2.229,2.229,0,0,0-.435,1.369Z'
            transform='translate(-227.403 -38.667)'
            fill='#064f59'
          />
          <path
            id='Path_73'
            data-name='Path 73'
            d='M415.632,72.834a1.425,1.425,0,0,0,.869-.3.978.978,0,0,0,.387-.825h.469v.026a1.3,1.3,0,0,1-.517,1.095,1.873,1.873,0,0,1-1.234.435,1.777,1.777,0,0,1-1.473-.665,2.646,2.646,0,0,1-.526-1.7v-.178a2.642,2.642,0,0,1,.526-1.695,1.76,1.76,0,0,1,1.469-.665,1.8,1.8,0,0,1,1.264.461,1.49,1.49,0,0,1,.491,1.2v.026H416.9a1.16,1.16,0,0,0-.369-.9,1.3,1.3,0,0,0-.908-.339,1.243,1.243,0,0,0-1.108.548,2.39,2.39,0,0,0-.361,1.364v.187a2.424,2.424,0,0,0,.361,1.377A1.251,1.251,0,0,0,415.632,72.834Z'
            transform='translate(-233.888 -38.666)'
            fill='#064f59'
          />
          <path
            id='Path_74'
            data-name='Path 74'
            d='M426.311,73.275a1.89,1.89,0,0,1-1.49-.652,2.416,2.416,0,0,1-.574-1.664v-.243a2.49,2.49,0,0,1,.578-1.686,1.829,1.829,0,0,1,1.438-.665,1.66,1.66,0,0,1,1.351.552,2.229,2.229,0,0,1,.469,1.49v.4h-3.289v.148A2.116,2.116,0,0,0,425.2,72.3a1.343,1.343,0,0,0,1.13.534,2.173,2.173,0,0,0,.8-.143,1.673,1.673,0,0,0,.6-.382l.222.361a1.9,1.9,0,0,1-.652.435,2.507,2.507,0,0,1-.991.174Zm-.048-4.463a1.3,1.3,0,0,0-1,.435,1.894,1.894,0,0,0-.435,1.1v.026h2.707v-.109a1.612,1.612,0,0,0-.326-1.039,1.147,1.147,0,0,0-.943-.413Z'
            transform='translate(-239.905 -38.664)'
            fill='#064f59'
          />
          <path
            id='Path_75'
            data-name='Path 75'
            d='M438.042,71.951a.765.765,0,0,0-.269-.578,2.238,2.238,0,0,0-.986-.4,3.255,3.255,0,0,1-1.247-.478,1,1,0,0,1-.408-.869,1.143,1.143,0,0,1,.456-.917,1.829,1.829,0,0,1,1.208-.378,1.89,1.89,0,0,1,1.26.4,1.182,1.182,0,0,1,.435.986v.026H438a.869.869,0,0,0-.335-.678,1.548,1.548,0,0,0-1.738-.039.743.743,0,0,0-.291.582.669.669,0,0,0,.248.534,2.429,2.429,0,0,0,.986.374,3.15,3.15,0,0,1,1.256.513,1.056,1.056,0,0,1,.435.869,1.169,1.169,0,0,1-.478.96,2.025,2.025,0,0,1-1.264.369,2.064,2.064,0,0,1-1.356-.435,1.165,1.165,0,0,1-.465-.926v-.026h.482a.869.869,0,0,0,.435.743,1.738,1.738,0,0,0,.913.23,1.425,1.425,0,0,0,.9-.252A.756.756,0,0,0,438.042,71.951Z'
            transform='translate(-245.987 -38.644)'
            fill='#064f59'
          />
          <path
            id='Path_76'
            data-name='Path 76'
            d='M448.529,71.951a.756.756,0,0,0-.274-.578,2.238,2.238,0,0,0-.986-.4,3.255,3.255,0,0,1-1.247-.478,1,1,0,0,1-.408-.869,1.143,1.143,0,0,1,.435-.917,1.834,1.834,0,0,1,1.208-.378,1.908,1.908,0,0,1,1.264.4,1.191,1.191,0,0,1,.435.986v.026h-.487a.869.869,0,0,0-.33-.678,1.325,1.325,0,0,0-.869-.287,1.3,1.3,0,0,0-.869.248.743.743,0,0,0-.291.582.669.669,0,0,0,.248.534,2.429,2.429,0,0,0,.986.374,3.15,3.15,0,0,1,1.256.513,1.047,1.047,0,0,1,.435.869,1.169,1.169,0,0,1-.478.96,2.385,2.385,0,0,1-2.607-.043,1.16,1.16,0,0,1-.469-.978v-.026h.482a.869.869,0,0,0,.435.743,1.768,1.768,0,0,0,.913.23,1.425,1.425,0,0,0,.9-.252.76.76,0,0,0,.326-.578Z'
            transform='translate(-251.912 -38.644)'
            fill='#064f59'
          />
          <path
            id='Path_77'
            data-name='Path 77'
            d='M462.6,71.176h-.526V64.81h.526Z'
            transform='translate(-261.294 -36.656)'
            fill='#064f59'
          />
          <path
            id='Path_78'
            data-name='Path 78'
            d='M467.5,68.452l.048.821a1.738,1.738,0,0,1,.613-.669,1.694,1.694,0,0,1,.917-.239,1.447,1.447,0,0,1,1.169.469,2.221,2.221,0,0,1,.4,1.469v2.881h-.526V70.308a1.708,1.708,0,0,0-.3-1.151,1.108,1.108,0,0,0-.869-.335,1.4,1.4,0,0,0-.9.274,1.512,1.512,0,0,0-.5.739v3.35h-.526V68.452Z'
            transform='translate(-264.099 -38.665)'
            fill='#064f59'
          />
          <path
            id='Path_79'
            data-name='Path 79'
            d='M477.995,68.263a3.063,3.063,0,0,1,.482-1.812,1.53,1.53,0,0,1,1.334-.682,1.738,1.738,0,0,1,.847.2,1.769,1.769,0,0,1,.608.582V63.77h.521v6.818h-.435l-.074-.687a1.647,1.647,0,0,1-.613.574,1.8,1.8,0,0,1-.869.2,1.569,1.569,0,0,1-1.325-.634,2.711,2.711,0,0,1-.478-1.69Zm.526.091a2.372,2.372,0,0,0,.343,1.36,1.165,1.165,0,0,0,1.034.508,1.412,1.412,0,0,0,1.369-.869v-2.22a1.7,1.7,0,0,0-.491-.656,1.351,1.351,0,0,0-.869-.252,1.138,1.138,0,0,0-1.038.565,2.8,2.8,0,0,0-.348,1.473Z'
            transform='translate(-270.297 -36.068)'
            fill='#064f59'
          />
          <path
            id='Path_80'
            data-name='Path 80'
            d='M493.031,72.533a1.586,1.586,0,0,1-.608.634,1.738,1.738,0,0,1-.908.226,1.443,1.443,0,0,1-1.169-.508,2.42,2.42,0,0,1-.435-1.573V68.57h.526v2.751a2.047,2.047,0,0,0,.3,1.243,1,1,0,0,0,.839.374,1.512,1.512,0,0,0,.939-.265,1.391,1.391,0,0,0,.5-.721V68.57h.526V73.3h-.456Z'
            transform='translate(-277.034 -38.782)'
            fill='#064f59'
          />
          <path
            id='Path_81'
            data-name='Path 81'
            d='M504.042,71.951a.756.756,0,0,0-.274-.578,2.238,2.238,0,0,0-.986-.4,3.285,3.285,0,0,1-1.247-.478,1,1,0,0,1-.408-.869,1.143,1.143,0,0,1,.456-.917,1.834,1.834,0,0,1,1.208-.378,1.908,1.908,0,0,1,1.264.4,1.178,1.178,0,0,1,.435.986v.026H504a.869.869,0,0,0-.335-.678,1.548,1.548,0,0,0-1.738-.039.752.752,0,0,0-.287.582.665.665,0,0,0,.243.534,2.429,2.429,0,0,0,.986.374,3.124,3.124,0,0,1,1.256.513,1.047,1.047,0,0,1,.435.869,1.156,1.156,0,0,1-.478.96,2.386,2.386,0,0,1-2.607-.043,1.156,1.156,0,0,1-.474-.947v-.026h.478a.869.869,0,0,0,.435.743,1.777,1.777,0,0,0,.913.23,1.425,1.425,0,0,0,.9-.252.747.747,0,0,0,.322-.608Z'
            transform='translate(-283.308 -38.644)'
            fill='#064f59'
          />
          <path
            id='Path_82'
            data-name='Path 82'
            d='M512.05,65.79V67h1.043v.435H512.05v3.081a.991.991,0,0,0,.17.656.56.56,0,0,0,.434.2h.187l.213-.039.078.391a1.086,1.086,0,0,1-.261.083,1.524,1.524,0,0,1-.309.035.973.973,0,0,1-.778-.317,1.486,1.486,0,0,1-.278-1V67.428h-.817v-.435h.817v-1.2Z'
            transform='translate(-288.788 -37.21)'
            fill='#064f59'
          />
          <path
            id='Path_83'
            data-name='Path 83'
            d='M520.752,68.9l-.374-.026a1.173,1.173,0,0,0-.808.278,1.486,1.486,0,0,0-.435.76v3.268h-.526V68.45h.461l.065.786v.052a1.691,1.691,0,0,1,.513-.682,1.4,1.4,0,0,1,.821-.243.87.87,0,0,1,.187,0l.143.03Z'
            transform='translate(-293.266 -38.663)'
            fill='#064f59'
          />
          <path
            id='Path_84'
            data-name='Path 84'
            d='M526.4,72l.165.565h.026l1.43-4.015h.608l-2.077,5.488a2.739,2.739,0,0,1-.478.817,1.073,1.073,0,0,1-.869.335,1.5,1.5,0,0,1-.222,0,1.173,1.173,0,0,1-.191-.043l.061-.435h.335a.686.686,0,0,0,.547-.243,2.082,2.082,0,0,0,.348-.626l.252-.634L524.51,68.54h.582Z'
            transform='translate(-296.602 -38.765)'
            fill='#064f59'
          />
          <rect
            id='Rectangle_152'
            data-name='Rectangle 152'
            width='0.8'
            height='24.307'
            transform='translate(109.956 10.212)'
            fill='#064f59'
          />
        </g>
      </g>
    </svg>
  );
};

export default Logo;
