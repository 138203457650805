import { gql } from "urql";

export const getWoodSummary = gql`
  query($id: ID) {
    woodSchema(filter: { id: $id }) {
      __typename
      id
      reportPeriod
      reportYear
      yearlyProduction
      storage
      internalEnergyRecycling
      externalEnergyRecycling
      internalMaterialRecycling
      externalMaterialRecycling
      reuse
      reuseFurniture
      reuseBuildingMaterials
      dangerousWaste
      limitationsToMaterialDeposit
      treatmentAfterReceving
      autoIgnitionHazard
      productionMonths {
        monthlyProduction
      }
    }
  }
`;
