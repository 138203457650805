import React, { useState } from "react";
import styled from "styled-components";
import Button, { ButtonSize, ButtonType } from "../../../components/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { utilizedEnergySchema } from "../../../schemas";
import { Param, StepHandling, TUtilizedWasteHeatNames, UtilizedEnergyType } from "../../../types";
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import Bottom from "../../../components/Bottom";
import Navigation from "../../../components/Navigation";
import { useMutation, useQuery } from "urql";

import { getUtilizedWasteHeats } from "../../../graphql/queries/Energyflow/getUtilizedWasteHeats";
import { createOrUpdateUtilizedWasteHeat } from "../../../graphql/mutations/Energyflow/createOrUpdateUtilizedWasteHeat";

import UtilizedEnergyData from "./UtilizedEnergyData";
import { useParams } from "react-router-dom";
import Spinner from "../../../components/Loading";
import { UtilizedWasteHeatNames } from "../../../dictionaries";
import { findSelectValue } from "../../../utils/form-utils";
import { IMutationResult } from "../../../types/mutations/mutationResult";
import { handleMutationError } from "../../../lib/toaster";

const EnergyWasteContainer = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.gap.medium};
`;
const Title = styled.div`
  color: ${(props) => props.theme.colors.textColor.titleTextColor};
  font-size: ${(props) => props.theme.fontSizes.large};
  margin-bottom: 1.5rem;
`;
const Description = styled.div`
  margin-bottom: 1.875rem;
`;
const DescriptionBold = styled.span`
  font-weight: 500;
`;

const Box = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: auto 2fr auto;
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.middle};
  padding: ${(props) => props.theme.padding.medium};
  border-radius: ${(props) => props.theme.borderRadius.light};
`;
const Headers = styled.div`
  grid-row: 1/2;
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 2em;
  grid-gap: ${(props) => props.theme.gap.extraLight};
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.middle};
  color: ${(props) => props.theme.colors.textColor.mainTextColor};
`;

const Container = styled.div`
  grid-row: 2/3;
  grid-column: 1/-1;
`;
const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1em;
`;
const QuestionHeader = styled.div`
  grid-column: 1/2;
  margin-left: 1.3em;
`;
const SelectHeader = styled.div`
  grid-column: 2/3;
  margin-left: 0.25em;
`;
const MeasureHeader = styled.div`
  grid-column: 3/4;
  margin-left: -0.3em;
`;
const ButtonArea = styled.div`
  grid-column: 1/2;
  grid-row: 3/4;
  margin-top: 0.937rem;
`;

type UtilizedEnergyProps = {
  navigation: StepHandling;
  handleNav: any;
};

const UtilizedEnergy: React.FC<UtilizedEnergyProps> = (props) => {
  const { id } = useParams<Param>();
  const {
    register,

    handleSubmit,
    errors,
    getValues,
    control,
    reset,
  } = useForm<UtilizedEnergyType>({
    resolver: yupResolver(utilizedEnergySchema),
    defaultValues: { utilizedWasteHeats: [{ id: undefined }] },
  });
  const { fields, append, remove } = useFieldArray<UtilizedEnergyType, any>({
    control,
    name: "utilizedWasteHeats",
    keyName: "fieldId",
  });
  const [, utilizedEnergyMutate] = useMutation<IMutationResult>(createOrUpdateUtilizedWasteHeat);

  const [utilizedWasteHeatsResult] = useQuery({
    query: getUtilizedWasteHeats,
    variables: {
      energySchemaId: id,
    },
  });
  const { data, fetching } = utilizedWasteHeatsResult;
  const [radioState, setRadioState] = React.useState("Nei");
  const [isReset, setIsReset] = useState<boolean>(true);

  const onSubmit: SubmitHandler<UtilizedEnergyType> = (data: UtilizedEnergyType) => {
    data.utilizedWasteHeats &&
      data.utilizedWasteHeats.map(async (utilizedObject) => {
        await utilizedEnergyMutate({
          utilizedWasteHeatId: utilizedObject.id ? utilizedObject.id : null,
          utilizedWasteHeatInformation: utilizedObject.utilizedWasteHeatInformation,
          utilizedWasteHeatEnum: findSelectValue<TUtilizedWasteHeatNames>(utilizedObject.utilizedWasteHeat, UtilizedWasteHeatNames),
          utilizedWasteHeatUsage: utilizedObject.utilizedWasteHeatUsage,
          energySchemaId: id,
        })
          .then((result) => {
            if (result.error) handleMutationError(result.error);
          })
          .catch(() => handleMutationError());
      });
    props.handleNav(true);
  };

  const onPrevSubmit = () => {
    const data = getValues();
    data.utilizedWasteHeats &&
      data.utilizedWasteHeats.map(async (utilizedObject) => {
        await utilizedEnergyMutate({
          utilizedWasteHeatId: utilizedObject.id ? utilizedObject.id : null,
          utilizedWasteHeatInformation: utilizedObject.utilizedWasteHeatInformation,
          utilizedWasteHeatEnum: findSelectValue<TUtilizedWasteHeatNames>(utilizedObject.utilizedWasteHeat, UtilizedWasteHeatNames),
          utilizedWasteHeatUsage: utilizedObject.utilizedWasteHeatUsage,
          energySchemaId: id,
        }).then((result) => {
          if (result.error) console.error("oh nooo", result.error);
        });
      });
    props.handleNav(false);
  };

  React.useEffect(() => {
    if (!fetching && data && data.utilizedWasteHeats.length !== 0) {
      setRadioState("Ja");
      if (isReset) {
        reset(data);
        setIsReset(false);
      }
    }
  }, [fetching, data, reset, setIsReset, isReset]);

  return (
    <EnergyWasteContainer>
      <Title>Spillvarme som utnyttes</Title>
      <Description>
        {" "}
        Har bedriften spillvarmemengder som <DescriptionBold>utnyttes</DescriptionBold> i dag?
      </Description>
      {!fetching ? (
        <>
          <div>
            <label>
              <input
                type="radio"
                name="answer"
                value={"Nei"}
                checked={"Nei" === radioState}
                onChange={(e) => {
                  setRadioState(e.target.value);
                }}
              />{" "}
              Nei
            </label>
            <br />
            <label>
              <input
                type="radio"
                name="answer"
                value={"Ja"}
                checked={"Ja" === radioState}
                onChange={(e) => setRadioState(e.target.value)}
              />{" "}
              Ja
            </label>
          </div>

          {radioState === "Ja" && (
            <Box>
              <Container>
                <QuestionContainer>
                  <Headers>
                    <QuestionHeader>Hva utnyttes spillvarme til?</QuestionHeader>
                    <SelectHeader>Elektrisk eller termisk?</SelectHeader>
                    <MeasureHeader>GWh/år</MeasureHeader>
                  </Headers>

                  {fields.map((row, index) => {
                    return (
                      <React.Fragment key={row.fieldId}>
                        <UtilizedEnergyData register={register()} index={index} errors={errors} data={data} remove={remove} row={row} />
                        <Controller
                          control={control}
                          name={`utilizedWasteHeats[${index}].id`}
                          defaultValue={row.id ? row.id : null}
                          as={<span />}
                        />
                      </React.Fragment>
                    );
                  })}
                </QuestionContainer>
                <ButtonArea>
                  <Button buttonType={ButtonType.Primary} size={ButtonSize.Large} onClick={() => append({})}>
                    Legg til ekstra rad
                  </Button>
                </ButtonArea>
              </Container>
            </Box>
          )}
        </>
      ) : (
        <Spinner />
      )}

      <Bottom>
        <Navigation navigation={props.navigation} handleNext={handleSubmit(onSubmit)} handlePrev={onPrevSubmit} />
      </Bottom>
    </EnergyWasteContainer>
  );
};
export default UtilizedEnergy;
