import React from "react";
import { X } from "react-feather";
import styled from "styled-components";
import { useMutation } from "urql";
import { EnergyUsage, TEnergyUsageNames } from "../../../../types";
import { deleteEnergyType } from "../../../../graphql/mutations/Energyflow/deleteEnergyType";
import { EnergyUsageNames } from "../../../../dictionaries";
import { IMutationResult } from "../../../../types/mutations/mutationResult";
import { handleMutationError } from "../../../../lib/toaster";
const EnergyUsageRow = styled.div`
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.top};
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2em;
  grid-template-rows: 1fr auto;
  padding: 0.75rem 1rem 0.3125rem 1rem;
  grid-gap: ${(props) => props.theme.gap.extraLight};
  //border-bottom: 2px solid #fff;
  border-radius: 20px;
  margin-bottom: 0.3125rem;
`;

const EnergyForm = styled.select<IStyledProps>`
  grid-column: 1/2;
  height: 100%;
`;

const CommentInput = styled.textarea`
  grid-column: 2/3;
`;
const MeasurementInput = styled.textarea<IStyledProps>`
  grid-column: 3/4;
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.bottom};
`;
const RemoveRow = styled.div`
  display: flex;
  align-self: center;
  grid-column: 4/5;
  cursor: pointer;
`;

const EnergyFormErrorDisplay = styled.div`
  grid-column: 1/2;
  grid-row: 2/3;
  display: flex;
`;
const MeasureError = styled.div`
  grid-column: 3/4;
  grid-row: 2/3;
  display: flex;
`;

type SurveyEnergyUsageDataProps = {
  index: number;
  register: any;
  errors: any;
  remove?: any;
  data: EnergyUsage;
  row?: any;
  temp: boolean;
};
type IStyledProps = Pick<SurveyEnergyUsageDataProps, "errors">;
const SurveyEnergyUsageData: React.FC<SurveyEnergyUsageDataProps> = (props) => {
  const [, deleteEnergyTypeMutate] = useMutation<IMutationResult>(deleteEnergyType);
  const handleDeleteRow = (removeIndex: number) => {
    if (props.data.energyTypes[props.index]) {
      props.remove(removeIndex);
      deleteEnergyTypeMutate({
        id: props.data.energyTypes[props.index].id,
      }).catch(() => handleMutationError());
    } else {
      props.remove(removeIndex);
    }
  };

  return (
    <EnergyUsageRow key={`energy.energyType.energyTypeName[${props.index}]`}>
      <EnergyForm
        ref={props.register}
        name={`energyTypes[${props.index}].energyTypeName`}
        defaultValue={EnergyUsageNames[props.row.energyTypeName as TEnergyUsageNames] || props.row.energyTypeName}
        errors={props.errors}
      >
        <option value="">Velg energiform</option>

        {(Object.values(EnergyUsageNames) as Array<TEnergyUsageNames>).map((name) => {
          return <option key={name}>{name} </option>;
        })}
      </EnergyForm>

      <CommentInput
        ref={props.register}
        name={`energyTypes[${props.index}].additionalInformation`}
        defaultValue={props.row?.additionalInformation}
      ></CommentInput>

      <MeasurementInput
        ref={props.register}
        name={`energyTypes[${props.index}].energyUsage`}
        defaultValue={props.row?.energyUsage}
        errors={props.errors}
      ></MeasurementInput>
      <RemoveRow>
        <X onClick={() => handleDeleteRow(props.index!)} />
      </RemoveRow>

      {props.errors.energyTypes && (
        <>
          <EnergyFormErrorDisplay className="error">
            {props.errors.energyTypes[props.index]?.energyTypeName?.message}
          </EnergyFormErrorDisplay>
          <MeasureError className="error">{props.errors.energyTypes[props.index]?.energyUsage?.message}</MeasureError>
        </>
      )}
    </EnergyUsageRow>
  );
};
export default SurveyEnergyUsageData;
