import { gql } from "urql";

export const createNewSchemasMutation = gql`
  mutation ($organizationIds: [ID]!, $sidestreamType: SidestreamTypeEnum!) {
    createNewSchemas(input: { organizationIds: $organizationIds, sidestreamType: $sidestreamType }) {
      __typename
      id
      success
    }
  }
`;
