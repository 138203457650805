import { gql } from "urql";

export const getUser = gql`
  query {
    user {
      fullName
      email
      phone
      id
      privacyConsent
      __typename
    }
  }
`;
