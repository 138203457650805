import { gql } from "urql";

export const getAggregatedSchemas = gql`
  query {
    aggregatedSchemas {
      type
      issuedAt
      issuedBy
      affectedOrganizations
      __typename
    }
  }
`;
