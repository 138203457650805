import React from "react";
import { StepHandling } from "../../types";
import Button, { ButtonSize, ButtonType } from "../Button";

type NavigationProps = {
  handlePrev: () => void;
  handleNext: () => void;
  navigation: StepHandling;
};

const Navigation: React.FC<NavigationProps> = (props) => {
  return (
    <>
      {props.navigation.step < props.navigation.amountOfSteps! && (
        <Button
          className="prev-button"
          onClick={props.handlePrev}
          size={ButtonSize.Medium}
          buttonType={ButtonType.Secondary}
          inactive={props.navigation.step === 1 ? true : false}
        >
          Forrige
        </Button>
      )}

      {props.navigation.step === props.navigation.amountOfSteps - 1 ? (
        <Button className="next-button" onClick={props.handleNext} size={ButtonSize.Medium} buttonType={ButtonType.Primary}>
          Send inn
        </Button>
      ) : props.navigation.step === props.navigation.amountOfSteps ? (
        <Button className="next-button" onClick={props.handleNext} size={ButtonSize.Medium} buttonType={ButtonType.Primary}>
          Logg ut
        </Button>
      ) : (
        <Button className="next-button" onClick={props.handleNext} size={ButtonSize.Medium} buttonType={ButtonType.Primary}>
          Neste
        </Button>
      )}
    </>
  );
};

export default Navigation;
