import { gql } from "urql";

export const createWoodAttachment = gql`
  mutation(
    $attachmentType: AttachementTypeEnum
    $woodSchemaId: ID
    $fileName: String
    $fileContentType: String
    $fileAsBase64: String
  ) {
    createAttachment(
      input: {
        attachmentType: $attachmentType
        woodSchemaId: $woodSchemaId
        fileName: $fileName
        fileContentType: $fileContentType
        fileAsBase64: $fileAsBase64
      }
    ) {
      __typename
      id
      success
    }
  }
`;
