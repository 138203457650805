import {
  TBooleanSelect,
  TBooleanSelectExtended,
  TChemicalUnit,
  TEnergyUsageNames,
  TExternalPlan,
  TInternalPlan,
  TMaterialState,
  TMaterialType,
  TMonths,
  TOrganic,
  TParticleType,
  TParticleUnitSize,
  TPollution,
  TProductionBatch,
  TProductionPeriod,
  TProductionVolumeUnit,
  TTimePeriod,
  TTreatment,
  TUtilizedWasteHeatNames,
  TWasteSource,
  TWaterReactivity,
  TAutoIgnitionHazard,
  TWoodMaterialType,
  TWoodStorage,
  TIndustryType,
  TSidestreamType,
} from "./types";

export const EnergyUsageNames: Readonly<Record<TEnergyUsageNames, string>> = {
  PURCHASED_ELECTRIC_POWER: "Innkjøpt elektrisk fastkraft",
  PURCHASED_ELECTRIC_FUEL_OIL: "Innkjøpt elektrisk kjelkraft",
  PURCHASED_FUEL_OIL: "Innkjøpt fyringsolje",
  PURCHASED_GAS: "Innkjøpt gass",
  PURCHASED_COAL_PRODUCTS: "Innkjøpt kullprodukter",
  PURCHASED_DISTICT_HEAT_OR_STEAM: "Innkjøpt fjernvarme og damp",
  PURCHASED_BIO_ENERGY: "Innkjøpt bioenergi",
  PURCHASED_OTHER: "Innkjøpt annet",
  SELF_MADE_BIO_ENERGY: "Egentilvirket bioenergi",
  SELF_MADE_POWER: "Egentilvirket kraft",
  SELF_MADE_GAS: "Egentilvirket gass",
  SELF_MADE_OTHER: "Egentilvirket annet",
};

export const UtilizedWasteHeatNames: Readonly<Record<TUtilizedWasteHeatNames, string>> = {
  ELECTRIC: "Elektrisk",
  THERMAL: "Termisk",
};

export const WasteSource: Readonly<Record<TWasteSource, string>> = {
  WATER: "Vann",
  STEAM: "Damp",
  GAS: "Gass",
  SOLIDS: "Fast stoff (slagg etc.)",
  AIR: "Luft",
};

export const Pollution: Readonly<Record<TPollution, string>> = {
  NONE: "Ingen",
  DUST: "Partikler/støv",
  GAS: "Gasser",
  CORROSIVE_ELEMENTS: "Korrosive elementer",
  OTHER: "Annet",
};

export const InternalPlan: Readonly<Record<TInternalPlan, string>> = {
  NONE: "Ingen",
  PROCESSED_HEAT: "Prosessvarme",
  HEAT_BUILDING: "Oppvarming av bygg",
  OTHER: "Annet",
};

export const ExternalPlan: Readonly<Record<TExternalPlan, string>> = {
  NONE: "Ingen",
  POWER_RECOVERY: "Kraftgjenvinning",
  DISTRICT_OR_LOCAL_HEATING: "Fjern/nærvarme",
  AGRICULTURE: "Landbruk",
  FISH_FARMING: "Fiskeoppdrett",
  OTHER: "Annet",
};

export const MaterialType: Readonly<Record<TMaterialType, string>> = {
  WASTE: "Avfall",
  BY_PRODUCT: "Biprodukt",
  DEPARTURE_MASS: "Avgangsmasse",
};

export const ProductionVolumeUnit: Readonly<Record<TProductionVolumeUnit, string>> = {
  T_YEAR: "tonn/år",
  KT_YEAR: "kilotonn/år",
  TD: "tonn/døgn",
};

export const ProductionPeriod: Readonly<Record<TProductionPeriod, string>> = {
  CONTINOUS: "Kontinuerlig",
  WEEKLY: "Ukentlig",
  MONTHLY: "Månedlig",
  QUARTERLY: "Kvartalvis",
  SEMI_ANNUALLY: "Halvårlig",
  NOT_ANSWERED: "Ikke svart",
};

export const ProductionBatch: Readonly<Record<TProductionBatch, string>> = {
  KG_BATCH: "kg/batch",
  T_BATCH: "t/batch",
  KT_BATCH: "kt/batch",
};

export const Organic: Readonly<Record<TOrganic, string>> = {
  ORGANIC: "Organisk",
  NOT_ORGANIC: "Uorganisk",
};

export const MaterialState: Readonly<Record<TMaterialState, string>> = {
  SOLID: "Fast stoff",
  LIQUID: "Væske",
  GAS: "Gass",
  DISPERSION: "Dispersjon",
  EMULSION: "Emulsjon",
};

export const BooleanSelect: Readonly<Record<TBooleanSelect, string>> = {
  YES: "Ja",
  NO: "Nei",
};

export const BooleanSelectExtended: Readonly<Record<TBooleanSelectExtended, string>> = {
  ...BooleanSelect,
};

export const WaterReactivity: Readonly<Record<TWaterReactivity, string>> = {
  ...BooleanSelect,
  EXPLOTION: "Eksplosivt",
  DONT_KNOW: "Vet ikke",
};

export const AutoIgnitionHazard: Readonly<Record<TAutoIgnitionHazard, string>> = {
  ...BooleanSelect,
  DONT_KNOW: "Vet ikke",
};

export const ParticleType: Readonly<Record<TParticleType, string>> = {
  NOT_RELEVANT: "Ikke valgt",
  DUST: "Støv",
  SLAM: "Slam",
  POWDER: "Pulver",
  NOT_HOMOGENEOUS: "Ikke homogent",
  FIBER: "Fiber",
  GRAVEL: "Grus",
  STONE: "Stein",
};

export const ParticleUnitSize: Readonly<Record<TParticleUnitSize, string>> = {
  CM: "cm",
  MM: "mm",
  UM: "um",
  NM: "nm",
};

export const ChemicalUnit: Readonly<Record<TChemicalUnit, string>> = {
  PERCENT_WT: "% WT",
  PERCENT_VOL: "% VOL",
  PPM_WT: "ppm wt",
  PPM_VOL: "ppm vol",
  GL: "g/l",
  MG_L: "mg/l",
  UG_L: "ug/l",
  MG_ML: "mg/ml",
  UG_ML: "ug/ml",
  M: "M",
};

export const WoodMaterial: Readonly<Record<TWoodMaterialType, string>> = {
  PURE_WOOD_SPON: "Ren trevirke (flis, spon)",
  PURE_WOOD: "Ren trevirke (helved)",
  PAINTED_OILED_WOOD: "Malt/oljet trevirke",
  IMPREGNATED: "Trykkimpregnert",
  VENEER: "Glulam/Finer",
  MDF: "MDF",
  UNSORTED: "Usortert",
  OTHER: "Annet",
};

export const TimePeriod: Readonly<Record<TTimePeriod, string>> = {
  MONTH: "Per måned",
  YEAR: "Per år",
};

export const Months: Readonly<Record<TMonths, string>> = {
  JANUARY: "Januar",
  FEBRUARY: "Februar",
  MARCH: "Mars",
  APRIL: "April",
  MAY: "Mai",
  JUNE: "Juni",
  JULY: "Juli",
  AUGUST: "August",
  SEPTEMBER: "September",
  OCTOBER: "Oktober",
  NOVEMBER: "November",
  DECEMBER: "Desember",
};
export const WoodStorage: Readonly<Record<TWoodStorage, string>> = {
  INSIDE: "Inne",
  OPEN: "Lagres åpent",
  UNDER_ROOF: "Under tak",
};
export const Treatment: Readonly<Record<TTreatment, string>> = {
  CLEANED: "Renses",
  DISASSEMBLED: "Demonteres",
  SOURCE_SORTED: "Sorteres",
  PACKED: "Pakkes",
  DIVISION: "Oppdeling",
  NONE: "Ingen",
};

export const IndustryType: Readonly<Record<TIndustryType, string>> = {
  NOTSET: "Ikke valgt",
  RECYCLING: "Avfallshåndtering",
  CEMENT_INDUSTRY: "Sementindustri",
  WOOD_PROCESSING: "Treforedling",
  METALLURGICAL_INDUSTRY: "Metallurgisk industri",
  MINERAL_INDUSTRY: "Mineralindustri",
  CHEMICAL_INDUSTRY: "Kjemisk industri",
  OTHER_PROCESS_INDUSTRY: "Annen prosessindustri",
};

export const SidestreamType: Readonly<Record<TSidestreamType, string>> = {
  WOOD: "Trevirke",
  MATERIALSTREAM: "Materialstrømmer",
  ENERGY: "Energistrømmer",
};
