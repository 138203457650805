import styled from "styled-components";

export enum LabelType {
  DEFAULT,
  SUCCESS,
  WARNING,
  DANGER,
}

type LabelProps = {
  type?: LabelType;
};

const Label = styled.span<LabelProps>`
  display: inline-block;
  font-size: ${(props) => props.theme.fontSizes.xs};
  color: ${(props) =>
    props.type === LabelType.SUCCESS
      ? props.theme.colors.success.textColor
      : props.type === LabelType.WARNING
      ? props.theme.colors.warning.textColor
      : props.type === LabelType.DANGER
      ? props.theme.colors.danger.textColor
      : props.theme.colors.default.textColor};
  background-color: ${(props) =>
    props.type === LabelType.SUCCESS
      ? props.theme.colors.success.backgroundColor
      : props.type === LabelType.WARNING
      ? props.theme.colors.warning.backgroundColor
      : props.type === LabelType.DANGER
      ? props.theme.colors.danger.backgroundColor
      : props.theme.colors.default.backgroundColor};
  padding: 5px;
  border-radius: ${(props) => props.theme.borderRadius.heavy};
  border: 1px solid
    ${(props) =>
      props.type === LabelType.SUCCESS
        ? props.theme.colors.success.backgroundColor
        : props.type === LabelType.WARNING
        ? props.theme.colors.warning.backgroundColor
        : props.type === LabelType.DANGER
        ? props.theme.colors.danger.backgroundColor
        : props.theme.colors.default.backgroundColor};
  margin-right: 5px;
  text-align: center;
`;

export default Label;
