import React from "react";
import styled from "styled-components";
import Heading from "../Heading";

type Props = {
  title: string;
  description?: string;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor.middle};

  border-radius: 1em;
`;

const Description = styled.p`
  margin-bottom: 20px;
`;

const Children = styled.div`
  margin-top: 20px;
`;

const EmptyState: React.FC<Props> = (props) => {
  return (
    <Container>
      <div>
        <Heading>{props.title}</Heading>
        {props.description && <Description>{props.description}</Description>}

        <Children>{props.children}</Children>
      </div>
    </Container>
  );
};

export default EmptyState;
