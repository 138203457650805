import React from "react";
import styled from "styled-components";
import MaterialAnalysisData from "./MaterialAnalysisData";
import Bottom from "../../../components/Bottom";
import Navigation from "../../../components/Navigation";

import { NavigationContext } from "../../../providers/NavigationProvider";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { ChemicalAnalysis, ChemicalAnalysisType, FormType, Param, TChemicalUnit } from "../../../types";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "urql";
import { getChemicalAnalysis } from "../../../graphql/queries/Materialflow/getChemicalAnalysis";
import { createOrUpdateChemicalAnalysis } from "../../../graphql/mutations/Materialflow/createOrUpdateChemicalAnalysis";
import { findSelectValue } from "../../../utils/form-utils";
import { ChemicalUnit } from "../../../dictionaries";
import { yupResolver } from "@hookform/resolvers/yup";
import { materialAnalysisSchema } from "../../../schemas";
import Spinner from "../../../components/Loading";
import { IMutationResult } from "../../../types/mutations/mutationResult";
import { handleMutationError } from "../../../lib/toaster";

const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const LoadingContainer = styled.div`
  display: flex;
  place-content: center;
`;

const MaterialAnalysis: React.FC = () => {
  const context = React.useContext(NavigationContext);
  const [isReset, setIsReset] = React.useState<boolean>(true);
  const { id } = useParams<Param>();
  const [chemicalAnalysisResult] = useQuery({
    query: getChemicalAnalysis,
    variables: { materialStreamSchemaId: id },
  });

  const { data, fetching } = chemicalAnalysisResult;
  const [, executeMutation] = useMutation<IMutationResult>(createOrUpdateChemicalAnalysis);
  const { register, control, handleSubmit, reset, errors } = useForm<ChemicalAnalysisType>({
    defaultValues: {},
    resolver: yupResolver(materialAnalysisSchema),
  });
  const { fields, append, remove } = useFieldArray<ChemicalAnalysis, any>({
    control,
    name: "chemicalAnalysises",
    keyName: "fieldId",
  });

  function handleExecuteMutation(data: any, nav: boolean) {
    data.chemicalAnalysises &&
      data.chemicalAnalysises.map(async (ma: any) => {
        await executeMutation({
          materialStreamSchemaId: id,
          chemicalAnalysisId: ma.id || null,
          chemicalAnalysisName: ma.chemicalAnalysisName || null,
          chemicalUnit: findSelectValue<TChemicalUnit>(ma.chemicalUnit, ChemicalUnit),
          typicalValue: ma.typicalValue || null,
          minValue: ma.minValue || null,
          maxValue: ma.maxValue || null,
          shareChemicalAnalysisApproval: ma.shareChemicalAnalysisApproval,
        })
          .then(() => {
            context.handleNav(nav);
          })
          .catch(() => handleMutationError());
      });
  }

  const onSubmit: SubmitHandler<ChemicalAnalysisType> = (data) => {
    handleExecuteMutation(data, true);
  };
  const onPreviousSubmit: SubmitHandler<ChemicalAnalysisType> = (data) => {
    handleExecuteMutation(data, false);
  };

  const form: FormType = {
    register,
    fields,
    append,
    remove,
    control,
  };

  React.useEffect(() => {
    if (!fetching && data.chemicalAnalysises) {
      if (isReset) {
        // reset({
        //   ...data,
        //   chemicalAnalysises: data.chemicalAnalysises
        //     ? data.chemicalAnalysises.map((analysis: any) => {
        //         return {
        //           ...analysis,
        //           shareChemicalAnalysisApproval: {
        //             id: analysis.shareChemicalAnalysisApproval,
        //             checked: analysis.shareChemicalAnalysisApproval,
        //           },
        //         };
        //       })
        //     : [],
        // });
        reset(data);

        setIsReset(false);
      }
    }
  }, [data, fetching, reset, isReset]);
  return (
    <Container>
      {fetching ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : (
        <MaterialAnalysisData form={form} errors={errors} control={control} />
      )}

      <Bottom>
        <Navigation navigation={context.navigation} handleNext={handleSubmit(onSubmit)} handlePrev={handleSubmit(onPreviousSubmit)} />
      </Bottom>
    </Container>
  );
};

export default MaterialAnalysis;
