import { Selects, ISelectOption } from "../types";

/**
 * Returns all values from a dictionary as option tags
 *
 * @param value - A dictionary
 */
export function SelectValues<T>(value: T) {
  return Object.values(value).map((name) => {
    return <option key={`${name}`}>{name}</option>;
  });
}
/**
 * Returns the mapped value from a dictionary
 *
 * @param value - The user input string
 * @param object - The dictionary containing T
 */
export function findSelectValue<T extends Selects>(value: string, object: Readonly<Record<T, string>>) {
  return (Object.keys(object) as Array<T>).find((key) => {
    return object[key] === value;
  });
}

const reducer = (acc: number, curr: number) => acc + curr;

/**
 * Returns the sum of input values
 *
 * @param inputValues - Object from watch()
 */
export function calculateSum(inputValues: any) {
  let filteredInputValues = Object.values(inputValues).filter((val) => Number(val));
  let convertedInputValues = filteredInputValues.map(Number);

  if (convertedInputValues.length > 0) {
    return convertedInputValues.reduce(reducer);
  } else {
    return "";
  }
}

export const compare = (a: any, b: any) => {
  if (a.codeId < b.codeId) {
    return -1;
  }
  if (a.codeId > b.codeId) {
    return 1;
  }
  return 0;
}

/**
 * Returns ISelect option
 *
 * @param inputValues - guid and string
 */
export const mapToSelectOption = (value: string | number, label: string) => {
  const selectOption: ISelectOption = {
    label: label,
    value: value,
  };

  return selectOption;
};

/**
 * Groups by
 * @param values, key
 */
export const groupBy = function (values: any, key: string) {
  return values.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

/**
 * Used in selects where a "Select all" option applies
 */
export const selectAllOption = {
  label: "Velg alle",
  value: "00000000-0000-0000-0000-000000000000",
};
