import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useMutation } from "urql";
import Button, { ButtonSize, ButtonType } from "../../../../components/Button";
import ErrorContainer from "../../../../components/ErrorContainer";
import FormFieldError from "../../../../components/FormFieldError";
import FormLabel from "../../../../components/FormLabel";
import Heading from "../../../../components/Heading";
import { WoodMaterial } from "../../../../dictionaries";
import { updateSidestream } from "../../../../graphql/mutations/Sidestream/updateSidestream";
import { handleMutationError, handleMutationSuccess } from "../../../../lib/toaster";
import { updateSidestreamSchema } from "../../../../schemas";
import { UpdateSidestreamFormData, Sidestream, SidestreamTypeEnum } from "../../../../types";
import { IMutationResult } from "../../../../types/mutations/mutationResult";
import { SelectValues } from "../../../../utils/form-utils";

type Props = {
  sidestream: Sidestream;
  handleSave: () => void;
  handleCancel: () => void;
};

const InputContainer = styled.div`
  margin-top: 10px;
`;

const ActionsContainer = styled.div`
  text-align: right;
  margin-top: 10px;
`;

const UpdateSidestreamForm: React.FC<Props> = (props) => {
  const [result, executeMutation] = useMutation<IMutationResult>(updateSidestream);
  const { register, handleSubmit, errors } = useForm<UpdateSidestreamFormData>({
    resolver: yupResolver(updateSidestreamSchema),
    defaultValues: {
      name: props.sidestream.name,
      isInactive: props.sidestream.isInactive,
    },
  });
  const onSubmit = handleSubmit(({ name, isInactive }) => {
    executeMutation({ id: props.sidestream.id, name, isInactive })
      .then(({ data }) => {
        if (data?.updateSidestream.success) {
          handleMutationSuccess(data.updateSidestream);

          props.handleSave();
        } else {
          handleMutationError();
        }
      })
      .catch(() => handleMutationError());
  });

  return (
    <form onSubmit={onSubmit} noValidate>
      <Heading>Oppdater sidestrøm</Heading>

      <InputContainer>
        <FormLabel>Navn på sidestrøm (fortrinnsvis engelsk tekst)</FormLabel>
        {props.sidestream.type === SidestreamTypeEnum.WOOD ? (
          <select name="name" ref={register}>
            <option value="">Vennligst velg</option>
            {SelectValues(WoodMaterial)}
          </select>
        ) : (
          <input ref={register} name="name" />
        )}

        <FormFieldError error={errors.name}></FormFieldError>
      </InputContainer>

      <InputContainer>
        <label>
          <input ref={register} name="isInactive" type="checkbox" />
          Sett sidestrøm som inaktiv
        </label>

        <FormFieldError error={errors.isInactive}></FormFieldError>
      </InputContainer>

      {result.error && <ErrorContainer>Kan ikke oppdatere sidestrøm</ErrorContainer>}

      <ActionsContainer>
        <Button type="button" size={ButtonSize.Large} buttonType={ButtonType.Secondary} onClick={() => props.handleCancel()}>
          Avbryt
        </Button>

        <Button type="submit" disabled={result.fetching} size={ButtonSize.Large} buttonType={ButtonType.Primary}>
          {result.fetching ? "Oppdaterer..." : "Oppdater sidestrøm"}
        </Button>
      </ActionsContainer>
    </form>
  );
};

export default UpdateSidestreamForm;
